import React from 'react';

export default props => {
    return (
        <svg >
            <rect x="1.5" y="3.5" width="2" height="6" />
            <rect x="3.5" y="3.5" width="2" height="6" />
            <rect x="5.5" y="3.5" width="2" height="6" />
            <rect x="0.5" y="1.5" width="8" height="2" />
            <rect x="3.25" y="0.25" width="2.5" height="0.5" strokeWidth="0.5"/>
      </svg>
    );
};
