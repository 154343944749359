import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    call,
} from 'redux-saga/effects';

import {
    FETCH_ACCOUNT_SUCCEEDED,

    ATTEMPT_FETCH_PROGRAMS,
    FETCH_PROGRAMS_SUCCEEDED,

    ATTEMPT_FETCH_PROGRAM,
    FETCH_PROGRAM_SUCCEEDED,

    ATTEMPT_CREATE_PROGRAM,
    CREATE_PROGRAM_SUCCEEDED,

    ATTEMPT_DUPLICATE_PROGRAM,
    DUPLICATE_PROGRAM_SUCCEEDED,

    ATTEMPT_UPDATE_PROGRAM,
    UPDATE_PROGRAM_SUCCEEDED,

    ATTEMPT_DELETE_PROGRAM,
    DELETE_PROGRAM_SUCCEEDED,
    DELETE_PROGRAM_FAILED,

    QUEUE_ITEM_SUCCEEDED,
    QUEUE_ITEM_FAILED,
} from 'shared_redux/actions';
import API from 'services/programs';
import { handleError } from 'services/alerts';
import * as AccountsSelectors from 'shared_redux/selectors/models/accounts';

export default function* ProgramsSaga() {
    yield all([
        takeLatest(ATTEMPT_FETCH_PROGRAMS, fetchPrograms),
        takeLatest(ATTEMPT_FETCH_PROGRAM, fetchProgram),
        takeEvery(ATTEMPT_CREATE_PROGRAM, createProgram),
        takeEvery(ATTEMPT_DUPLICATE_PROGRAM, duplicateProgram),
        takeEvery(ATTEMPT_UPDATE_PROGRAM, updateProgram),
        takeEvery(ATTEMPT_DELETE_PROGRAM, deleteProgram),
    ]);
};

function *fetchPrograms(action) {
    try {
        var account = yield select(AccountsSelectors.getAccount);
        if (!account) {
            yield take(FETCH_ACCOUNT_SUCCEEDED);
            account = yield select(AccountsSelectors.getAccount);
        }
        const programs = yield apply(API, API.getPrograms, [account.organization_id]);
        yield put({
            type: FETCH_PROGRAMS_SUCCEEDED,
            payload: programs,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch programs');
    }
}

function *fetchProgram(action) {
    try {
        var account = yield select(AccountsSelectors.getAccount);
        if (!account) {
            yield take(FETCH_ACCOUNT_SUCCEEDED);
            account = yield select(AccountsSelectors.getAccount);
        }
        const program = yield apply(API, API.getProgram, [action.program_id, account.organization_id]);
        yield put({
            type: FETCH_PROGRAM_SUCCEEDED,
            payload: program,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch program');
    }
}

function *createProgram(action) {
    try {
        var account = yield select(AccountsSelectors.getAccount);
        if (!account) {
            yield take(FETCH_ACCOUNT_SUCCEEDED);
            account = yield select(AccountsSelectors.getAccount);
        }
        const payload = {
            ...action.payload,
            organization_id: account.organization_id,
        };
        const result = yield apply(API, API.createProgram, [payload]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
        yield put({
            type: CREATE_PROGRAM_SUCCEEDED,
            payload: result,
        });
    } catch(error) {
        if (error.statusCode === 409) {
            // already created, treat as success
            yield put({ type: QUEUE_ITEM_SUCCEEDED });
        } else {
            yield put({ type: QUEUE_ITEM_FAILED });
            yield handleError(error, 'Error: Unable to create program');
        }
    }
}

// TODO: consider making this not part of the queue
function *duplicateProgram(action) {
    try {
        const result = yield apply(API, API.createProgram, [{source_program_id: action.id}]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
        yield put({
            type: DUPLICATE_PROGRAM_SUCCEEDED,
            payload: result,
        });
    } catch(error) {
        yield put({ type: QUEUE_ITEM_FAILED });
        yield handleError(error, 'Error: Unable to duplicate program');
    }
}

function *updateProgram(action) {
    try {
        const result = yield apply(API, API.updateProgram, [action.payload]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
        yield put({
            type: UPDATE_PROGRAM_SUCCEEDED,
            payload: result,
        });
    } catch(error) {
        yield put({ type: QUEUE_ITEM_FAILED });
        yield handleError(error, 'Error: Unable to update program');
    }
}

// TODO: spinner for delete program rather than instant
function *deleteProgram(action) {
    try {
        yield apply(API, API.deleteProgram, [action.id]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
        yield put({
            type: DELETE_PROGRAM_SUCCEEDED,
            id: action.id,
        });
    } catch(error) {
        if (error.statusCode === 403) {
            // POSSIBLY already deleted, going to assume it and treat it as success
            yield put({
                type: DELETE_PROGRAM_SUCCEEDED,
                id: action.id,
            });
        } else {
            yield put({
                type: DELETE_PROGRAM_FAILED,
                id: action.id,
            });
            yield handleError(error, 'Error: Unable to delete program');
        }
    }
}
