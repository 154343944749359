import React from 'react';

import SilentSaveTextField from 'shared_features/silent_save_textfield';
import styles from './styles.module.scss';

export default props => {
    const sensor_number = props.sensor_name ? props.sensor_name.replace('RepOne ','') : null;
    let version = null;
    if (props.updateAvailable) {
        version = <div className={styles.updateAvailable}>Update available - V{props.app_version}</div>
    } else if (props.updateRequired) {
        version = <div className={styles.updateRequired}>Update required - V{props.app_version}</div>
    } else {
        version = <div className={styles.upToDate}>Up to date - V{props.app_version}</div>
    }

    return (
        <div className={styles.stationrow}>
            <div>
                <SilentSaveTextField
                    type="number"
                    value={props.number}
                    onComplete={(value) => props.updateRackNumber(props.id, value)} />
            </div>
            <div>
                <SilentSaveTextField
                    type="text"
                    value={sensor_number}
                    onComplete={(value) => props.updateSensorName(props.id, value ? `RepOne ${value}` : null)} />
            </div>
            {/* {version} */}
        </div>
    );
}
