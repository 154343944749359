import uuidv4 from 'uuid/v4';
import { all, takeEvery, select, put } from 'redux-saga/effects';

import {
    DUPLICATE_PLAN,
    DUPLICATE_BLOCK,
    DUPLICATE_MICROCYCLE_SESSION,
    DUPLICATE_MICROCYCLE,

    DRAFT_CREATE_PLAN,
    DRAFT_CREATE_BLOCK,
    ATTEMPT_CREATE_BLOCK,
    DRAFT_CREATE_MICROCYCLE_SESSION,
    ATTEMPT_CREATE_MICROCYCLE_SESSION,
    DRAFT_CREATE_MICROCYCLE,
    ATTEMPT_CREATE_MICROCYCLE,
} from '../actions';
import orm from 'shared_redux/reducers/models/orm';

export default function* DuplicateSaga() {
    yield all([
        takeEvery(DUPLICATE_PLAN, duplicatePlan),
        takeEvery(DUPLICATE_BLOCK, duplicateBlock),
        takeEvery(DUPLICATE_MICROCYCLE_SESSION, duplicateMicrocycleSession),
        takeEvery(DUPLICATE_MICROCYCLE, duplicateMicrocycle),
    ]);
}

function *duplicatePlan(action) {
    const database = yield select(state => state.draft_database);
    const { Plans } = orm.mutableSession(database);
    const plan = Plans.withId(action.id);

    if (!plan) {
        console.log(`cannot duplicate as plan ${action.id} cannot be found`);
        return;
    }

    // duplicate plan
    yield put({
        type: DRAFT_CREATE_PLAN,
        payload: {
            ...plan.ref,
            id: uuidv4(),
            order: plan.ref.order+1,
        }
    });
}

function *duplicateBlock(action) {
    const database = yield select(state => state.draft_database);
    const { Blocks } = orm.mutableSession(database);
    const block = Blocks.withId(action.id);

    if (!block) {
        console.log(`cannot duplicate as block ${action.id} cannot be found`);
        return;
    }

    // duplicate block
    const block_id = uuidv4();
    yield put({
        type: DRAFT_CREATE_BLOCK,
        pushType: ATTEMPT_CREATE_BLOCK,
        payload: {
            ...block.ref,
            id: block_id,
            order: block.ref.order+1,
        }
    });

    // duplicate plans
    const plans = block.plans.orderBy('order', 'asc').toModelArray();
    for (let plan of plans) {
        yield put({
            type: DRAFT_CREATE_PLAN,
            payload: {
                ...plan.ref,
                block_id, 
                id: uuidv4(),
            }
        });
    }
}

function *duplicateMicrocycleSession(action) {
    const database = yield select(state => state.draft_database);
    const { MicrocycleSessions } = orm.mutableSession(database);
    const microcycleSession = MicrocycleSessions.withId(action.session_id);

    if (!microcycleSession) {
        console.log(`cannot duplicate as microcycle session ${action.session_id} cannot be found`);
        return;
    }

    // duplicate microcycle session
    const session_id = uuidv4();
    yield put({
        type: DRAFT_CREATE_MICROCYCLE_SESSION,
        pushType: ATTEMPT_CREATE_MICROCYCLE_SESSION,
        payload: {
            ...microcycleSession.ref,
            session_id,
            order: microcycleSession.ref.order+1,
        }
    });

    // duplicate blocks
    const blocks = microcycleSession.session.blocks.orderBy('order', 'asc').toModelArray();
    for (let block of blocks) {
        const block_id = uuidv4();
        yield put({
            type: DRAFT_CREATE_BLOCK,
            pushType: ATTEMPT_CREATE_BLOCK,
            payload: {
                ...block.ref,
                session_id,
                id: block_id,
            }
        });

        // duplicate plans
        const plans = block.plans.orderBy('order', 'asc').toModelArray();
        for (let plan of plans) {
            yield put({
                type: DRAFT_CREATE_PLAN,
                payload: {
                    ...plan.ref,
                    block_id,
                    id: uuidv4(),
                }
            });
        }
    }
}

function *duplicateMicrocycle(action) {
    const database = yield select(state => state.draft_database);
    const { Microcycles } = orm.mutableSession(database);
    const microcycle = Microcycles.withId(action.id);

    if (!microcycle) {
        console.log(`cannot duplicate as microcycle ${action.id} cannot be found`);
        return;
    }

    // duplicate microcycle
    const microcycle_id = uuidv4();
    yield put({
        type: DRAFT_CREATE_MICROCYCLE,
        pushType: ATTEMPT_CREATE_MICROCYCLE,
        payload: {
            ...microcycle.ref,
            id: microcycle_id,
            order: microcycle.ref.order+1,
        }
    });

    // duplicate microcycle sessions
    const microcyleSessions = microcycle.microcycle_sessions.orderBy('order', 'asc').toModelArray();
    for (let microcycleSession of microcyleSessions) {
        const session_id = uuidv4();
        yield put({
            type: DRAFT_CREATE_MICROCYCLE_SESSION,
            pushType: ATTEMPT_CREATE_MICROCYCLE_SESSION,
            payload: {
                ...microcycleSession.ref,
                microcycle_id,
                session_id,
            }
        });

        const blocks = microcycleSession.session.blocks.orderBy('order', 'asc').toModelArray();
        for (let block of blocks) {
            // duplicate blocks
            const block_id = uuidv4();
            yield put({
                type: DRAFT_CREATE_BLOCK,
                pushType: ATTEMPT_CREATE_BLOCK,
                payload: {
                    ...block.ref,
                    session_id,
                    id: block_id,
                }
            });

            // duplicate plans
            const plans = block.plans.orderBy('order', 'asc').toModelArray();
            for (let plan of plans) {
                yield put({
                    type: DRAFT_CREATE_PLAN,
                    payload: {
                        ...plan.ref,
                        block_id,
                        id: uuidv4(),
                    }
                });
            }
        }
    }
}
