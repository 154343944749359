import {
    PUSH_QUEUE,
    POP_QUEUE,
    QUEUE_EXECUTING,
    QUEUE_EXECUTION_COMPLETE,
    CANCEL_DRAFT_AND_QUEUE,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    executing: false,
    items: [],
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case PUSH_QUEUE:
            return {
                ...state,
                items: [
                    ...state.items,
                    action.item,
                ]
            };
        case POP_QUEUE:
            return {
                ...state,
                items: [
                    ...state.items.slice(1),
                ],
            };
        case QUEUE_EXECUTING:
            return {
                ...state,
                executing: true,
            };
        case QUEUE_EXECUTION_COMPLETE:
            return {
                ...state,
                executing: false,
            };
        case CANCEL_DRAFT_AND_QUEUE:
        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
