import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

export default props => {
    return (
        <div>
            <div className={styles.main}>
                <img src="https://assets.reponestrength.com/black_logo.png" />
                <div>Log in to RepOne</div>

                <form className={styles.form} autoComplete={"on"} onSubmit={e => { e.preventDefault() }}>
                    <label>Email address</label>
                    <input type="text"
                        className={styles.textInput}
                        placeholder="name@domain.com"
                        value={props.email}
                        onChange={(event)=> props.updateEmail(event.target.value)} />
                    <label>Password</label>
                    <input type="password"
                        className={styles.textInput}
                        placeholder="password"
                        value={props.password}
                        onChange={(event)=> props.updatePassword(event.target.value)} />
                    <div className={styles.link}><Link to='/forgotpw'>Forgot password</Link></div>
                    <input className={styles.submit} type="submit" value="Log in" onClick={()=>props.signIn(props.email, props.password)} />
                </form>
            </div>
        </div>
    );
};
