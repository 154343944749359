const stateRoot = (state) => state.teams_manager;

export const getIsCreatingTeam = (state) => stateRoot(state).isCreating && stateRoot(state).isTeam;

export const getIsEditingTeam = (state) => stateRoot(state).isEditing && stateRoot(state).isTeam;

export const getIsCreatingGroup = (state) => stateRoot(state).isCreating && stateRoot(state).isGroup;

export const getIsEditingGroup = (state) => stateRoot(state).isEditing && stateRoot(state).isGroup;

export const getName = (state) => stateRoot(state).name || "";

export const getGroupId = (state) => stateRoot(state).groupId;

export const getTeamId = (state) => stateRoot(state).teamId;

// messy,this shouldn't be here
export const getOrganizationId = (state) => stateRoot(state).organizationId;

export const getIsTeamCollapsed = (state, teamId) => stateRoot(state)[teamId];

export const getTeamJSON = (state) => {
    let json = {
        name: getName(state),
        organization_id: getOrganizationId(state),
    };

    // for edit
    const teamId = getTeamId(state);
    if (teamId) {
        json.id = teamId;
    }

    return json;
};

export const getGroupJSON = (state) => {
    let json = {
        name: getName(state),
        team_id: getTeamId(state),
    };

    // for edit
    const groupId = getGroupId(state);
    if (groupId) {
        json.id = groupId;
    }

    return json;
};
