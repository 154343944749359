
import API from './api';

export default {

    getGroupAthletes: (groupId) => API({
        url: `athletes?group_id=${groupId}&include=team,group,maxes`,
        method: 'GET',
    }),

    getTeamAthletes: (teamId) => API({
        url: `athletes?team_id=${teamId}&include=team,group,maxes`,
        method: 'GET',
    }),

    getAllAthletes: (organizationId) => API({
        url: `athletes?organization_id=${organizationId}&include=team,group,maxes`,
        method: 'GET',
    }),

    getAthlete: (athleteId) => API({
        url: `athletes/${athleteId}?include=team,group`,
        method: 'GET',
    }),

    createAthlete: (json) => API({
        url: 'athletes',
        method: 'POST',
        json,
    }),

    patchAthlete: (json) => API({
        url: `athletes/${json.id}`,
        method: 'PATCH',
        json,
    }),

    uploadAvatar: (athleteId) => API({
        url: `athletes/${athleteId}/upload`,
        method: 'PUT',
    }),

    importAthletes: (file) => {
        const formData = new FormData();
        formData.append('csv', file, file.name);

        return API({
            url: `athletes/import`,
            method: 'POST',
            formData,
        });
    },

    patchAthleteGroup: (athleteId, groupId) => API({
        url: `athletes/${athleteId}`,
        method: 'PATCH',
        json: { group_id: groupId },
    }),

    removeAthlete: (athleteId) => API({
        url: `athletes/${athleteId}`,
        method: 'DELETE',
    }),

    patchMultipleAthleteCategories: (athlete_ids, team_id, group_id) => API({
        url: `athletes`,
        method: 'PATCH',
        json: { athlete_ids, team_id, group_id },
    }),

    removeMultipleAthletes: (athlete_ids) => API({
        url: `athletes`,
        method: 'DELETE',
        json: { athlete_ids },
    }),

};
