import React from 'react';
import { transitions, positions, Provider as AlertProvider, useAlert } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { setAlertRef } from 'services/alerts';

const options = {
    position: positions.BOTTOM_RIGHT,
    timeout: 5000,
    transition: transitions.FADE,
};  

let alertRef = null;

const Inner = () => {
    alertRef = useAlert();
    setAlertRef(alertRef);
    return <div />;
};

export default props => {
    return (
        <AlertProvider template={AlertTemplate} {...options}>
            <Inner />
            {props.children}
        </AlertProvider>
    );
};
