import {
    SIGN_IN_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
    FETCH_ORGANIZATION_SETTINGS_SUCCEEDED,
    UPDATE_AUTO_PROGRESS_SUCCEEDED,
    SAVE_DEFAULTS_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

// this assumes a single organization ever for simplicity
class Organizations extends Model {

    static reducer(action, Organizations, session) {
        switch (action.type) {
            case SIGN_IN_SUCCEEDED: {
                const copy = action.payload.organization;
                delete copy.organization_settings['organization_id'];
                Organizations.upsert({
                    ...copy,
                    id: 0,
                });
                localStorage.setItem('organizations', JSON.stringify(Organizations.first().ref));
                break;
            }
            case FETCH_ORGANIZATION_SETTINGS_SUCCEEDED:
            case UPDATE_AUTO_PROGRESS_SUCCEEDED:
            case SAVE_DEFAULTS_SUCCEEDED: {
                const copy = action.payload;
                delete copy['organization_id'];
                const orig = Organizations.withId(0);
                orig.update({
                    id: 0,
                    organization_settings: {
                        ...orig.organization_settings,
                        ...action.payload,
                    }
                });
                localStorage.setItem('organizations', JSON.stringify(Organizations.first().ref));
                break;
            }
            case SIGN_OUT_SUCCEEDED:
                Organizations.all().delete();
                localStorage.removeItem('organizations');
                break;
        }
    }
}
Organizations.modelName = 'Organizations';

Organizations.fields = {
    name: attr(),
    timezone: attr(),
    primary_color: attr(),
    secondary_color: attr(),
    destructive_color: attr(),
    nav_background_color: attr(),
    nav_text_color: attr(),
    nav_active_color: attr(),
    logo_url: attr(),
    organization_settings: attr(),
};

export default Organizations;
