import React, {Component} from 'react';
import Select from 'react-select';

import styles from './styles.module.scss';
import { selectStyles } from '../block/view';
import BlockTypes from 'constants/blockTypes';

export default class View extends Component {

    constructor(props) {
        super(props);
        this.selector = React.createRef();
        this.cover = React.createRef();
        this.state = {
            open: false,
        };
    }

    _open() {
        this.cover.current.style.opacity=0;
        this.selector.current.focus();
        this.setState({open: true});
    }

    _close() {
        this.cover.current.style.opacity=1;
        this.selector.current.blur();
        this.setState({open: false});
    }

    // TODO: styling on each dropdown option, may need to iterate through types to do so unfortunately
    render() {
        const types = [
            { value: BlockTypes.STANDARD, label: 'Standard Block'},
            { value: BlockTypes.SUPERSET, label: 'Superset Block'},
            { value: BlockTypes.ALTERNATING, label: 'Alternating Block'},
        ];

        const style = this.state.open ? styles.containerBig : styles.containerSmall;
        return (
            <div className={style}>
                <Select
                    name="block types"
                    value={null}
                    menuIsOpen={this.state.open}
                    className={styles.select}
                    styles={selectStyles}
                    ref={this.selector}
                    clearable={false}
                    deleteRemoves={false}
                    tabSelectsValue={false}
                    onChange={(selectedOption)=> {
                        if (selectedOption) {
                            this.props.createBlock(this.props.session_id, selectedOption.value, this.props.order);
                        }
                        this._close();
                    }}
                    options={types}
                    onFocus={()=>this._open()}
                    onBlur={()=>this._close()}
                />
                <div ref={this.cover} className={styles.cover} onClick={()=>this._open()}>
                    <span>+</span> BLOCK
                </div>
            </div>
        );
    }

};
