import {
    SET_FEED_ITEMS,
    DELETE_FEED_ITEM,
    UPDATE_FEED_ENTRY_ITEM,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, oneToOne, many, attr } from 'redux-orm';

class FeedEntryItems extends Model {

    static reducer(action, FeedEntryItems, session) {
        switch (action.type) {
            case SET_FEED_ITEMS:
                action.feed_items.forEach(i => {
                    if (i.feed_entry_item) {
                        FeedEntryItems.upsert(i.feed_entry_item);
                    }
                });
                break;
            case UPDATE_FEED_ENTRY_ITEM:
                FeedEntryItems.upsert(action.feed_item.feed_entry_item);
                break;
            case DELETE_FEED_ITEM: {
                const item = FeedEntryItems.withId(action.feed_item_id);
                if (item) {
                    item.delete();
                }
                break;
            }
            case SIGN_OUT_SUCCEEDED:
                FeedEntryItems.all().delete();
                break;
        }
    }
    
}

FeedEntryItems.modelName = 'FeedEntryItems';

FeedEntryItems.options = {
    idAttribute: 'feed_item_id',
};

FeedEntryItems.fields = {
    feed_item_id: oneToOne({
        to: 'FeedItems',
        as: 'feed_item',
        relatedName: 'feed_entry_item',
    }),
    entry_id: fk({
        to: 'Entries',
        as: 'entry',
        relatedName: 'feed_entry_items',
    }),
};

export default FeedEntryItems;
