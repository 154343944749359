import API from './api';

export default {

    createMax: (max) => API({
        url: 'maxes',
        method: 'POST',
        json: max,
    }),

    patchMax: (max) => API({
        url: `maxes/${max.id}`,
        method: 'PATCH',
        json: max,
    }),

    removeMax: (max) => API({
        url: `maxes/${max.id}`,
        method: 'DELETE',
        json: max,
    }),
    
};
