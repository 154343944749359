import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import TitleView from 'shared_features/title';
import Copy from 'shared_features/svgs/copy';
import Trash from 'shared_features/svgs/trash';

class View extends Component {

    componentDidMount() {
        this.props.fetchPrograms();
    }

    _renderEmpty() {
        return (<div className={styles.emptyContainer}>
            <div className={styles.dashedContainer}>
                <h2 className={styles.subheader}>Create your first program</h2>
                <div className={styles.description}>We highly recommend watching this quick tutorial video before you get started</div>
                <iframe src='https://www.youtube.com/embed/8EOzGSRBfcE'
                    className={styles.video}
                    frameBorder='0'
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'
                />
                <Link to='/programs/new'>
                    <div className={styles.createButton}>Create a program</div>
                </Link>
            </div>
        </div>);
    }

    _renderPrograms() {
        return this.props.programs.map((program) => {
            const url = `/programs/${program.id}`;
            let name;
            let style;
            if (program.name) {
                name = program.name;
                style = styles.program;
            } else {
                name = 'UNNAMED';
                style = styles.unnamedProgram;
            }

            return (<div className={styles.container} key={`program${program.id}`}>
                <Link className={style} to={url}>
                    {name}
                </Link>
                <div className={styles.options}>
                    <span onClick={() => this.props.duplicateProgram(program.id)}><Copy />COPY</span>
                    <span onClick={() => window.confirm("Are you sure?") && this.props.deleteProgram(program.id)}><Trash />DELETE</span>
                </div>
            </div>);
        });
    }

    render() {
        return (
            <div>
                <TitleView title='Select Program'>
                    <Link to='/programs/new'>+ CREATE PROGRAM</Link>
                </TitleView>
                <div className={styles.main}>
                    {!this.props.isLoading && this.props.programs.length <= 0 ? this._renderEmpty() : this._renderPrograms()}
                </div>
            </div>
        );
    }
}

export default View;
