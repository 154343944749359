import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as RacksSelectors from 'shared_redux/selectors/models/racks';
import * as SettingsSelectors from 'shared_redux/selectors/view/settings';

const mapStateToProps = (state) => {
    document.title = "Settings - Station";

    return {
        racks: RacksSelectors.getRacks(state),
        station_auto_progress: SettingsSelectors.getOrganizationSettings(state).station_auto_progress,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSettings: Actions.fetchSettings,
        fetchRacks: Actions.fetchRacks,
        updateStationAutoProgress: Actions.updateStationAutoProgress,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
