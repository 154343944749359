import uuidv4 from 'uuid/v4';

import {
    DUPLICATE_PLAN,

    INSERT_PLAN,

    DRAFT_CREATE_EXERCISE,
    ATTEMPT_CREATE_EXERCISE,
    
    DRAFT_UPDATE_PLAN_EXERCISE,
    DRAFT_UPDATE_PLAN_REST,
    DRAFT_UPDATE_PLAN_NOTES,
    DRAFT_UPDATE_PLAN_AUTOREGULATION,
    DRAFT_UPDATE_PLAN_SENSOR_TRACKING,
    DRAFT_UPDATE_PLAN_MIN_SETS,
    DRAFT_UPDATE_PLAN_MAX_SETS,
    DRAFT_UPDATE_PLAN_SET_COUNT_FIXED,
    DRAFT_UPDATE_PLAN_NUM_REPS,
    DRAFT_UPDATE_PLAN_AMRAP,
    DRAFT_UPDATE_PLAN_AUTOREGULATION_QUANTIFIER,
    DRAFT_UPDATE_PLAN_AUTOREGULATION_TARGET,
    DRAFT_UPDATE_PLAN_AUTOREGULATION_RANGE,
    DRAFT_UPDATE_PLAN_AUTOREGULATION_METRIC,
    DRAFT_UPDATE_PLAN_WEIGHT,
    DRAFT_UPDATE_PLAN_WEIGHT_UNIT,
    DRAFT_SAVE_PLAN,

    DRAFT_MOVE_PLAN,
    ATTEMPT_MOVE_PLAN,

    DRAFT_DELETE_PLAN,
    ATTEMPT_DELETE_PLAN,
} from 'shared_redux/actions';

export const insertPlan = (block_id, order) => ({
    type: INSERT_PLAN,
    block_id,
    order,
});

export const createExercise = (organization_id, name, plan_id) => (dispatch, getState) => {
    const id = uuidv4();
    dispatch({
        type: DRAFT_CREATE_EXERCISE,
        pushType: ATTEMPT_CREATE_EXERCISE,
        payload: {
            id,
            name,
            organization_id,
        },
    });
    dispatch(updatePlanExercise(plan_id, id));
};

export const updatePlanExercise = (id, exercise_id) => ({
    type: DRAFT_UPDATE_PLAN_EXERCISE,
    payload: {
        id,
        exercise_id,
    },
});

export const updatePlanRest = (id, rest) => ({
    type: DRAFT_UPDATE_PLAN_REST,
    payload: {
        id,
        rest,
    },
});

export const updatePlanNotes = (id, notes) => ({
    type: DRAFT_UPDATE_PLAN_NOTES,
    payload: {
        id,
        notes,
    },
});

export const updatePlanAutoregulation = (id, autoregulation) => ({
    type: DRAFT_UPDATE_PLAN_AUTOREGULATION,
    payload: {
        id,
        autoregulation,
    },
});

export const updatePlanSensorTracking = (id, sensor_tracking) => ({
    type: DRAFT_UPDATE_PLAN_SENSOR_TRACKING,
    payload: {
        id,
        sensor_tracking,
    },
})

export const updatePlanMinSets = (id, min_sets) => {
    if (Number.isInteger(min_sets) && min_sets <= 0) {
        min_sets = 1;
    }
    return {
        type: DRAFT_UPDATE_PLAN_MIN_SETS,
        payload: {
            id,
            min_sets,
        },
    };
};

export const updatePlanMaxSets = (id, max_sets) => {
    if (Number.isInteger(max_sets) && max_sets <= 0) {
        max_sets = 1;
    }
    return {
        type: DRAFT_UPDATE_PLAN_MAX_SETS,
        payload: {
            id,
            max_sets,
        },
    };
};

export const updatePlanSetCountFixed = (id, set_count_fixed) => {
    if (set_count_fixed === true) {
        return {
            type: DRAFT_UPDATE_PLAN_SET_COUNT_FIXED,
            payload: {
                id,
                set_count_fixed,
                max_sets: null,
            },
        };
    } else {
        return {
            type: DRAFT_UPDATE_PLAN_SET_COUNT_FIXED,
            payload: {
                id,
                set_count_fixed,
            },
        };
    }
};

export const updatePlanNumReps = (id, num_reps) => {
    if (Number.isInteger(num_reps) && num_reps <= 0) {
        num_reps = 1;
    }
    return {
        type: DRAFT_UPDATE_PLAN_NUM_REPS,
        payload: {
            id,
            num_reps,
        },
    };
};

export const updatePlanAMRAP = (id, amrap, current_num_reps) => {
    let num_reps = current_num_reps;
    if (!Number.isInteger(num_reps) || num_reps < 1) {
        num_reps = 1;
    }
    return {
        type: DRAFT_UPDATE_PLAN_AMRAP,
        payload: {
            id,
            amrap,
            num_reps,
        },
    };
};

export const updatePlanAutoregulationQuantifier = (id, autoregulation_quantifier) => ({
    type: DRAFT_UPDATE_PLAN_AUTOREGULATION_QUANTIFIER,
    payload: {
        id,
        autoregulation_quantifier,
    },
});

export const updatePlanAutoregulationTarget = (id, autoregulation_target) => ({
    type: DRAFT_UPDATE_PLAN_AUTOREGULATION_TARGET,
    payload: {
        id,
        autoregulation_target,
    },
});

export const updatePlanAutoregulationRange = (id, autoregulation_range) => ({
    type: DRAFT_UPDATE_PLAN_AUTOREGULATION_RANGE,
    payload: {
        id,
        autoregulation_range,
    },
});

export const updatePlanAutoregulationMetric = (id, autoregulation_metric) => ({
    type: DRAFT_UPDATE_PLAN_AUTOREGULATION_METRIC,
    payload: {
        id,
        autoregulation_metric,
    },
});

export const updatePlanWeight = (id, weight) => ({
    type: DRAFT_UPDATE_PLAN_WEIGHT,
    payload: {
        id,
        weight,
    },
});

export const updatePlanWeightUnit = (id, weight_unit) => ({
    type: DRAFT_UPDATE_PLAN_WEIGHT_UNIT,
    payload: {
        id,
        weight_unit,
    },
});

export const savePlan = (id) => ({
    type: DRAFT_SAVE_PLAN,
    id,
});

export const movePlan = (id, block_id, order) => ({
    type: DRAFT_MOVE_PLAN,
    pushType: ATTEMPT_MOVE_PLAN,
    payload: {
        id,
        block_id,
        order,
    },
});

export const deletePlan = (id) => ({
    type: DRAFT_DELETE_PLAN,
    pushType: ATTEMPT_DELETE_PLAN,
    id,
});

export const duplicatePlan = (id) => ({
    type: DUPLICATE_PLAN,
    id,
});
