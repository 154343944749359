import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import View from './view';
import * as Actions from './actions';
import * as RosterSelectors from 'shared_redux/selectors/view/roster';

const mapStateToProps = (state, props) => {
    return {
        models: RosterSelectors.getRosterFilterViewModels(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchTeams: Actions.fetchTeams,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default withRouter(Container);
