import {
    FETCH_ATHLETE_SESSIONS_SUCCEEDED,

    SET_FEED_ITEMS,
    DELETE_FEED_ITEM,
    UPDATE_FEED_ENTRY_ITEM,
    UPDATE_FEED_COMPLETE_ITEM,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class Reps extends Model {

    static reducer(action, Reps, session) {
        switch (action.type) {
            case FETCH_ATHLETE_SESSIONS_SUCCEEDED: {
                for (const athlete_session of action.payload) {
                    for (const block of athlete_session.session.blocks) {
                        for (const plan of block.plans) {
                            for (const entry of plan.entries) {
                                for (const rep of entry.reps) {
                                    Reps.upsert({
                                        id: rep.id,
                                        entry_id: rep.entry_id,
                                        removed: rep.removed,
                                        valid: rep.valid,
                                        start_time: rep.start_time,
                                        duration: rep.duration,
                                        average_velocity: rep.average_velocity,
                                        peak_velocity: rep.peak_velocity,
                                        rom: rep.rom,
                                        peak_height: rep.peak_height,
                                        raw: rep.raw, 
                                    });
                                }
                            }
                        }
                    }
                }
                break;
            }

            case SET_FEED_ITEMS:
            case DELETE_FEED_ITEM:
            case UPDATE_FEED_ENTRY_ITEM:
            case UPDATE_FEED_COMPLETE_ITEM:
                action.entries.forEach(e => {
                    e.reps.forEach(rep => {
                        Reps.upsert({
                            id: rep.id,
                            entry_id: rep.entry_id,
                            removed: rep.removed,
                            valid: rep.valid,
                            start_time: rep.start_time,
                            duration: rep.duration,
                            average_velocity: rep.average_velocity,
                            peak_velocity: rep.peak_velocity,
                            rom: rep.rom,
                            peak_height: rep.peak_height,
                            raw: rep.raw, 
                        });
                    });
                });
                break;
            
            case SIGN_OUT_SUCCEEDED:
                Reps.all().delete();
                break;
        }
    }

}
Reps.modelName = 'Reps';

Reps.fields = {
    id: attr(),
    entry_id: fk({
        to: 'Entries',
        as: 'entry',
        relatedName: 'reps',
    }),
    removed: attr(),
    valid: attr(),
    start_time: attr(),
    duration: attr(),
    average_velocity: attr(),
    peak_velocity: attr(),
    rom: attr(),
    peak_height: attr(),
    raw: attr(),
};

export default Reps;
