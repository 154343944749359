import {
    FETCH_ATHLETE_SUCCEEDED,
    FETCH_ATHLETES_SUCCEEDED,
    FETCH_TEAMS_SUCCEEDED,
    FETCH_PROGRAM_SCHEDULES_SUCCEEDED,
    CREATE_TEAM_SUCCEEDED,
    PATCH_TEAM_SUCCEEDED,
    REMOVE_TEAM_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

const updateIfChanged = (Model, json) => {
    const existing = Model.withId(json.id);
    if (existing && existing.last_modified === json.last_modified && existing.created_on === json.created_on) {
        return;
    }
    Model.upsert(json);
};

class Teams extends Model {

    static reducer(action, Teams, session) {
        switch (action.type) {
            case FETCH_ATHLETE_SUCCEEDED:
                if (action.payload.team) {
                    updateIfChanged(Teams, action.payload.team);
                }
                break;
            case FETCH_ATHLETES_SUCCEEDED:
                action.payload.forEach(athlete => {
                    if (athlete.team) {
                        updateIfChanged(Teams, athlete.team);
                    }
                });
                break;
            case FETCH_TEAMS_SUCCEEDED:
                action.payload.forEach(team => {
                    const {['group']: group, ...rawTeam} = team;
                    updateIfChanged(Teams, rawTeam);
                });
                break;
            case FETCH_PROGRAM_SCHEDULES_SUCCEEDED: {
                for (var schedule of action.payload) {
                    if (schedule.team) {
                        updateIfChanged(Teams, schedule.team);
                    }
                }
                break;
            }
            case CREATE_TEAM_SUCCEEDED:
                Teams.upsert(action.payload);
                break;
            case PATCH_TEAM_SUCCEEDED:
                Teams.withId(action.payload.id).update(action.payload);
                break;
            case REMOVE_TEAM_SUCCEEDED:
                Teams.withId(action.id).delete();
                break;
            case SIGN_OUT_SUCCEEDED:
                Teams.all().delete();
                break;
        }
    }

}
Teams.modelName = 'Teams';

Teams.fields = {
    id: attr(),
    name: attr(),
    organization_id: attr(),
};

export default Teams;
