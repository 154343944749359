import {
    FETCH_PROGRAM_SUCCEEDED,
    FETCH_ATHLETE_SESSIONS_SUCCEEDED,

    SET_FEED_ITEMS,
    DELETE_FEED_ITEM,
    UPDATE_FEED_ENTRY_ITEM,
    UPDATE_FEED_COMPLETE_ITEM,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';
import AutoregulationTypes from 'constants/autoregulationTypes';

import { Model, fk, many, attr } from 'redux-orm';

class Plans extends Model {

    static reducer(action, Plans, session) {
        switch (action.type) {
            case FETCH_PROGRAM_SUCCEEDED: {
                for (const microcycle of action.payload.microcycles) {
                    for (const microcycle_session of microcycle.microcycle_sessions) {
                        for (const block of microcycle_session.session.blocks) {
                            for (const plan of block.plans) {
                                Plans.upsert(Plans.normalize(plan));
                            }
                        }
                    }
                }
                break;
            }
            case FETCH_ATHLETE_SESSIONS_SUCCEEDED: {
                for (const athlete_session of action.payload) {
                    for (const block of athlete_session.session.blocks) {
                        for (const plan of block.plans) {
                            Plans.upsert(Plans.normalize(plan));
                        }
                    }
                }
                break;
            }

            case SET_FEED_ITEMS:
            case DELETE_FEED_ITEM:
            case UPDATE_FEED_ENTRY_ITEM:
            case UPDATE_FEED_COMPLETE_ITEM:
                action.entries.forEach(e => {
                    if (e.plan) {
                        const {['exercise']: exercise, ...raw} = e.plan;
                        Plans.upsert(Plans.normalize(raw));
                    }
                });
                break;

            case SIGN_OUT_SUCCEEDED:
                Plans.all().delete();
                break;
        }
    }
    
}
Plans.modelName = 'Plans';

Plans.fields = {
    id: attr(),
    exercise_id: fk({
        to: 'Exercises',
        as: 'exercise',
        relatedName: 'plans',
    }),
    block_id: fk({
        to: 'Blocks',
        as: 'block',
        relatedName: 'plans',
    }),
    rest: attr(),
    notes: attr(),
    order: attr(),

    // NOTE: these need to be transformed appropriately when pulling from server or pushing to server
    autoregulation: attr(),
    num_reps: attr(),
    amrap: attr(),
    min_sets: attr(),
    max_sets: attr(),
    set_count_fixed: attr(),
    autoregulation_quantifier: attr(),
    autoregulation_target: attr(),
    autoregulation_range: attr(),
    autoregulation_metric: attr(),
    weight: attr(),
    weight_unit: attr(),
    sensor_tracking: attr(),
};

Plans.normalize = (plan) => {
    var normalizedPlan = {
        id: plan.id,
        exercise_id: plan.exercise_id,
        block_id: plan.block_id,
        rest: plan.rest,
        notes: plan.notes,
        order: plan.order,
    };
    if (plan.no_autoregulation) {
        normalizedPlan.autoregulation = AutoregulationTypes.NO_AUTOREGULATION;
        normalizedPlan.num_reps = plan.no_autoregulation.num_reps;
        normalizedPlan.amrap = plan.no_autoregulation.amrap;
        normalizedPlan.min_sets = plan.no_autoregulation.min_sets;
        normalizedPlan.sensor_tracking = plan.no_autoregulation.sensor_tracking;
        normalizedPlan.weight = plan.no_autoregulation.weight;
        normalizedPlan.weight_unit = plan.no_autoregulation.weight_unit;

        // defaults
        normalizedPlan.max_sets = null;
        normalizedPlan.set_count_fixed = true;
        normalizedPlan.autoregulation_quantifier = 'MIN';
        normalizedPlan.autoregulation_target = 0.3;
        normalizedPlan.autoregulation_range = 0.025;
        normalizedPlan.autoregulation_metric = 'AVERAGE_VELOCITY';
    } else if (plan.weight_autoregulation) {
        normalizedPlan.autoregulation = AutoregulationTypes.WEIGHT_AUTOREGULATION;
        normalizedPlan.num_reps = plan.weight_autoregulation.num_reps;
        normalizedPlan.amrap = plan.weight_autoregulation.amrap;
        normalizedPlan.min_sets = plan.weight_autoregulation.min_sets;
        normalizedPlan.max_sets = plan.weight_autoregulation.max_sets;
        normalizedPlan.set_count_fixed = plan.weight_autoregulation.set_count_fixed;
        normalizedPlan.autoregulation_quantifier = plan.weight_autoregulation.autoregulation_quantifier;
        normalizedPlan.autoregulation_target = plan.weight_autoregulation.autoregulation_target;
        normalizedPlan.autoregulation_range = plan.weight_autoregulation.autoregulation_range;
        normalizedPlan.autoregulation_metric = plan.weight_autoregulation.autoregulation_metric;
        normalizedPlan.weight = plan.weight_autoregulation.weight;
        normalizedPlan.weight_unit = plan.weight_autoregulation.weight_unit;

        // defaults
        normalizedPlan.sensor_tracking = true;
    } else if (plan.weight_autoregulation_speed) {
        normalizedPlan.autoregulation = AutoregulationTypes.WEIGHT_AUTOREGULATION_SPEED;
        normalizedPlan.num_reps = plan.weight_autoregulation_speed.num_reps;
        normalizedPlan.amrap = plan.weight_autoregulation_speed.amrap;
        normalizedPlan.min_sets = plan.weight_autoregulation_speed.min_sets;
        normalizedPlan.max_sets = plan.weight_autoregulation_speed.max_sets;
        normalizedPlan.set_count_fixed = plan.weight_autoregulation_speed.set_count_fixed;
        normalizedPlan.autoregulation_quantifier = plan.weight_autoregulation_speed.autoregulation_quantifier;
        normalizedPlan.autoregulation_target = plan.weight_autoregulation_speed.autoregulation_target;
        normalizedPlan.autoregulation_range = plan.weight_autoregulation_speed.autoregulation_range;
        normalizedPlan.autoregulation_metric = plan.weight_autoregulation_speed.autoregulation_metric;
        normalizedPlan.weight = plan.weight_autoregulation_speed.weight;
        normalizedPlan.weight_unit = plan.weight_autoregulation_speed.weight_unit;

        // defaults
        normalizedPlan.sensor_tracking = true;
    }
    return normalizedPlan;
}


Plans.denormalize = (json) => {
    var result = {
        id: json.id,
        exercise_id: json.exercise_id,
        block_id: json.block_id,
        rest: json.rest,
        notes: json.notes,
        order: json.order
    };
    const autoregulation = result[json.autoregulation.toLowerCase()] = {};

    if (json.autoregulation !== AutoregulationTypes.NO_AUTOREGULATION) {
        autoregulation.autoregulation_quantifier = json.autoregulation_quantifier;
        autoregulation.autoregulation_target = json.autoregulation_target;
        autoregulation.autoregulation_range = json.autoregulation_range;
        autoregulation.autoregulation_metric = json.autoregulation_metric;
        autoregulation.max_sets = json.max_sets;
        autoregulation.set_count_fixed = json.set_count_fixed;
    } else {
        autoregulation.sensor_tracking = json.sensor_tracking;
    }
    autoregulation.weight = json.weight;
    autoregulation.weight_unit = json.weight_unit;
    autoregulation.num_reps = json.num_reps;
    autoregulation.amrap = json.amrap;
    autoregulation.min_sets = json.min_sets;
    return result;
};

// TODO: consider using json schema instead
Plans.isValid = plan => {
    // exercise check
    if (!plan.exercise_id) {
        return false;
    }

    // sets check
    if (!Number.isInteger(plan.min_sets) || plan.min_sets <= 0) {
        return false;
    }

    // reps check
    if (!Number.isInteger(plan.num_reps) || plan.num_reps <= 0) {
        return false;
    }

    // weight check
    if (plan.weight !== null && Number.isNaN(plan.weight)) {
        // weight must be a number if it exists
        return false;
    }
    if (plan.weight_unit === null) {
        // plan metric should always exist, load can be null but weight type can be still set
        return false;
    }

     if (plan.autoregulation === AutoregulationTypes.WEIGHT_AUTOREGULATION || plan.autoregulation === AutoregulationTypes.WEIGHT_AUTOREGULATION_SPEED) {
        // sets check
        if (plan.max_sets && (!Number.isInteger(plan.max_sets) || plan.min_sets > plan.max_sets)) {
            return false;
        }

        // velocity check
        if (Number.isNaN(plan.autoregulation_target) || Number.isNaN(plan.autoregulation_range)) {
            return false;
        }
        if (!plan.autoregulation_metric || !plan.autoregulation_quantifier) {
            return false;
        }
    } else if (plan.autoregulation !== AutoregulationTypes.NO_AUTOREGULATION) {
        // needs an autoregulation type
        return false;
    }

    return true;
};

Plans.toJSON = plan => {
    var payload = Plans.denormalize(plan);
     for (var prop in payload) {
        if (!payload[prop] && prop !== 'weight' && prop !== 'max_sets') {
            // delete if it doesn't exist in payload, UNLESS it could be nullable
            delete payload[prop];
        } else if (typeof payload[prop] === 'object') {
            for (var nestedProp in payload[prop]) {
                if (payload[prop][nestedProp] === null) {
                    delete payload[prop][nestedProp];
                }
            }
        }
    }
    return payload;
};

export default Plans;
