const stateRoot = (state) => state.register;

export const getEmail = (state) => stateRoot(state).email;

export const getPassword = (state) => stateRoot(state).password;

export const getOrganizationName = (state) => stateRoot(state).organization_name;

export const getIsRegistering = (state) => stateRoot(state).registering;

export const getIsRegistered = (state) => stateRoot(state).registered;
