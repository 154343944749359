import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DropTarget } from 'react-dnd';

/**
 * Properties:
 * hoverIndicatorOffset
 * childIds
 * 
 * Internal Properties:
 * childType
 **/
class ProgramBuilderFooter extends Component {
    static displayName = 'ProgramBuilderFooter';

    render() {
        return this.props.connectDropTarget(<div>{this.props.children}</div>);
    }
}

const target = {

    hover(props, monitor, component) {
        const origItem = monitor.getItem();
        const origId = origItem.id;
        const origParentId = origItem.orig_parent_id;
        const origOrder = origItem.orig_order;
        const fromParentId = origItem.parent_id;
        const fromOrder = origItem.order;
        const toParentId = props.id; // this is the parent so props.id which was passed from ProgramBuilderComponent

        // to order
        let toOrder = props.childIds.length+1;
        if (props.childIds.length > 0 && props.childIds[props.childIds.length-1] === origId) {
            // same id, reset to order
            toOrder = origItem.orig_order;
        }

        if (fromParentId === toParentId && fromOrder === toOrder) {
            return;
        }

        // hover position
        let hoverPosition = false;
        if (origParentId === toParentId && toOrder === origOrder) {
            // if it would've been the original position, hide the hover position
            hoverPosition = null;
        }

        // update
        origItem.parent_id = toParentId;
        origItem.order = toOrder;

        // render
        const node = ReactDOM.findDOMNode(component);
        const hoverBoundingRect = node.getBoundingClientRect();
        if (hoverPosition === null) {
            props.updateHoverIndicator(null);
        } else {
            props.updateHoverIndicator(`${hoverBoundingRect.top+window.scrollY+props.hoverIndicatorOffset}px`);
        }
   },

};

const collect = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
    };
};

export default DropTarget((props) => props.childType, target, collect)(ProgramBuilderFooter);
