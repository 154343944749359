import uuidv4 from 'uuid/v4';

import {
    DRAFT_CREATE_PLAN,

    DRAFT_CREATE_EXERCISE,
    ATTEMPT_CREATE_EXERCISE,
} from 'shared_redux/actions';
import * as SettingsSelectors from 'shared_redux/selectors/view/settings';

// TODO: have this not be so convoluted
// Combining create exercise and create plan into a single action was the fastest way to get it done
// May be a cleaner method
export const createPlan = (block_id, order, exercise, organization_id) => (dispatch, getState) => {
    // create exercise first
    if (organization_id) {
        var exercise_id = uuidv4();
        dispatch({
            type: DRAFT_CREATE_EXERCISE,
            pushType: ATTEMPT_CREATE_EXERCISE,
            payload: {
                id: exercise_id,
                name: exercise,
                organization_id,
            },
        });
    } else {
        var exercise_id = exercise;
    }

    const state = getState();
    const id = uuidv4();
    const planObject = SettingsSelectors.getPlanObject(state);
    dispatch({
        type: DRAFT_CREATE_PLAN,
        payload: {
            id,
            exercise_id,
            block_id,
            order,
            ...planObject,
        }
    });
};
