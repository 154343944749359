import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import View from './view';
import * as Actions from './actions';

const mapStateToProps = (state, props) => {
    let updateAvailable = false;
    let updateRequired = false;
    if (props.expiration) {
        const cutoff = moment().add(1, 'week');
        const expiration = moment(props.expiration);
        if (expiration.isBefore(cutoff)) {
            updateRequired = true;
        } else {
            updateAvailable = true;
        }
    }

    return {
        updateAvailable,
        updateRequired,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateSensorName: Actions.updateSensorName,
        updateRackNumber: Actions.updateRackNumber,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
