import React from 'react';

export default props => {
    return (
        <svg >
            <circle cx="10" cy="10" r="9.5" fill="#F2F2F2" />
            <path d="M10.5 6V10M10.5 14V10M10.5 10H6.5M10.5 10H14.5" />
        </svg>
    );
};
