import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'redux-orm';

import View from './view';
import * as Actions from './actions';
import orm from 'shared_redux/reducers/models/orm';
import * as ProfileSelectors from 'shared_redux/selectors/view/profile';

const getViewModel = createSelector(
    orm,
    state => state.database,
    (state, props) => props.athleteId,
    (state, props) => ProfileSelectors.getIsUploadingAvatar(state),
    (session, athleteId, isUploadingAvatar) => {
        const Athletes = session.Athletes;
        const Teams = session.Teams;
        const Groups = session.Groups;
        var displayName = null;
        var imageURL = null;
        var teamName = "None";
        var groupName = "None";

        // really ugly way of parsing out the values I need
        const athlete = Athletes.withId(athleteId);
        if (athlete) {
            displayName = athlete.displayName();
            imageURL = athlete.avatar_url;
            const team = athlete.team_id ? Teams.withId(athlete.team_id) : null;
            if (team) {
                teamName = team.name;
            }
            const group = athlete.group_id ? Groups.withId(athlete.group_id) : null;
            if (group) {
                groupName = group.name;
                // TODO: get program name
                // if (group.program_id) {
                //     const program = ProgramsSelectors.getProgram(state, group.program_id);
                //     if (program) {
                //         programName = program.name;
                //     }
                // }
            }
        }

        return {
            displayName,
            imageURL,
            teamName,
            groupName,
            programName: "",
            isUploadingAvatar,
        };
    }
);

const mapStateToProps = (state, props) => getViewModel(state, props);

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        upload: Actions.upload,
        editAthlete: Actions.editAthlete,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
