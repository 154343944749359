import React from 'react';
import TitleView from 'shared_features/title';
import styles from './styles.module.scss';

export default props => {
    return (
        <div>
            <TitleView title='Forgot Password' />
            <div className={styles.main}>
                <input type="text"
                    placeholder="email"
                    value={props.email}
                    onChange={(event)=> props.updateEmail(event.target.value)} />
                <input type="button" value="Reset Password" onClick={()=>props.resetPassword(props.email)} />
            </div>
        </div>
    );
};
