import {
    FETCH_NEWS_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class News extends Model {

    static reducer(action, News, session) {
        switch (action.type) {
            case FETCH_NEWS_SUCCEEDED:
                News.all().delete();
                for (var i=0; i<action.payload.length; i++) {
                    var news = action.payload[i];
                    news.id = i;
                    News.create(news);
                }
                break;
        }
    }
}
News.modelName = 'News';

News.fields = {
    title: attr(),
    body: attr(),
};

export default News;
