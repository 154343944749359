import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Actions from './actions';
import View from 'shared_features/name_popup';
import * as TeamsManagerSelectors from 'shared_redux/selectors/view/teams_manager';

const mapStateToProps = (state) => ({
    isShowing: TeamsManagerSelectors.getIsEditingGroup(state),
    title: "Group Information",
    description: "Group Name",
    saveText: "Save group",
    name: TeamsManagerSelectors.getName(state),
    removeText: "Remove group",
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        closeModal: Actions.closeModal,
        updateName: Actions.updateName,
        remove: Actions.remove,
        save: Actions.patchGroup,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
