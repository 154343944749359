import React, {Component} from 'react';

import EditAthleteProfile from 'shared_features/athlete_popup/edit_profile';
import CreateAthleteView from '../create_athlete';
import RosterLabels from './labels';
import RosterRow from './row';
import Spinner from 'shared_features/spinner';

import styles from './styles.module.scss';

class View extends Component {

    // TODO: remove hack around using title to determine if i should fetch

    componentDidMount() {
        if (this.props.title) {
            this.props.fetchAthletes(this.props.groupId, this.props.teamId);
            this.props.fetchRosterSettings();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.title && this.props.title !== prevProps.title) {
            this.props.fetchAthletes(this.props.groupId, this.props.teamId);
        }
    }

    _renderEmpty() {
        const props = this.props;
        return (<div className={styles.emptyContainer}>
            <div className={styles.dashedContainer}>
                <h2 className={styles.subheader}>Upload your athletes</h2>
                <div className={styles.description}>Get your roster up and running with this quick guide</div>
                <iframe src='https://www.youtube.com/embed/zB-Xw0qiYo8'
                    className={styles.video}
                    frameBorder='0'
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'
                />
                <div className={styles.emptyButton} onClick={()=>props.createAthlete(props.organizationId, props.teamId, props.groupId)}>Create athlete</div>
                <div className={styles.or}>OR</div>
                <div className={styles.emptyButton} onClick={props.importAthletes.bind(this)}>Import CSV roster</div>
            </div>
        </div>);
    }

    _renderTable() {
        return (<ul className={styles.resultsContainer}>
            <RosterLabels />
            {this._renderAthletes()}
        </ul>);
    }

    _renderAthletes() {
        const props = this.props;
        if (props.isLoading) {
            return null;
        } else {
            return props.athleteIds.map(id => <RosterRow id={id} key={`/athletes/${id}`} />);
        }
    }

    _renderSpinner() {
        const props = this.props;
        if (!props.isLoading) {
            return null;
        }
        return <Spinner className={styles.spinner} />;
    }

    render() {
        const props = this.props;
        return (
            <div>
                <div className={styles.titleText}>{props.title}</div>
                <div className={styles.optionsContainer}>
                    <span className={styles.columnsButton} onClick={props.filterColumns.bind(this)}><img src="https://assets.reponestrength.com/columns.png" />Columns</span>
                    <span className={styles.importButton} onClick={props.importAthletes.bind(this)}><img src="https://assets.reponestrength.com/up_arrow.png" />Import</span>
                    <span className={styles.createButton} onClick={()=>props.createAthlete(props.organizationId, props.teamId, props.groupId)}>
                        + New Athlete
                    </span>
                </div>
                <input type="text"
                    className={styles.search}
                    placeholder="Search by name..."
                    value={props.search}
                    onChange={(event)=>props.filterAthletes(event.target.value)}
                    autoFocus />
                {!props.isLoading && !props.hasAthletes ? this._renderEmpty() : this._renderTable()}
                {this._renderSpinner()}
                <CreateAthleteView />
                <EditAthleteProfile />
            </div>
        );
    }

}

export default View;
