const stateRoot = (state) => state.profile;

export const getIsUploadingAvatar = (state) => stateRoot(state).isUploadingAvatar;

export const getIsCreating = (state) => stateRoot(state).isCreating;

export const getIsEditing = (state) => stateRoot(state).isEditing;

export const getAthleteId = (state) => stateRoot(state).athleteId;

export const getOrganizationId = (state) => stateRoot(state).organizationId;

export const getGroupId = (state) => stateRoot(state).groupId;

export const getTeamId = (state) => stateRoot(state).teamId;

export const getFirstName = (state) => stateRoot(state).firstName || '';

export const getMiddleName = (state) => stateRoot(state).middleName || '';

export const getLastName = (state) => stateRoot(state).lastName || '';

export const getAthleteJSON = (state) => {
    let json = {
        first_name: getFirstName(state),
        middle_name: getMiddleName(state),
        last_name: getLastName(state),
        group_id: getGroupId(state),
        team_id: getTeamId(state),
    };

    // add id - optional as it's about create vs edit
    const id = getAthleteId(state);
    if (id) {
        json.id = id;
    }

    // add org id - optional as it's about create vs edit
    const organization_id = getOrganizationId(state);
    if (organization_id) {
        json.organization_id = organization_id;
    }

    return json;
};
