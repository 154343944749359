import {
    CLOSE_EDIT_GROUP,
    UPDATE_GROUP_NAME,
    ATTEMPT_PATCH_GROUP,
    ATTEMPT_REMOVE_GROUP,
} from 'shared_redux/actions';

export const closeModal = () => ({ type: CLOSE_EDIT_GROUP });

export const updateName = (name) => ({ type: UPDATE_GROUP_NAME, name });

export const patchGroup = () => ({ type: ATTEMPT_PATCH_GROUP });

export const remove = () => ({ type: ATTEMPT_REMOVE_GROUP });

