import React, {Component} from 'react';

import styles from './styles.module.scss';
import PlanComponent from '../plan';

export default props => {
    if (!props.visible) {
        return null;
    }

    const plans = props.planIds.map(id => <PlanComponent key={`athlete-session-plan-${id}`} id={id} />);

    return (
        <div>
            <div className={styles.container}>
                {props.name}
            </div>
            {plans}
        </div>
    );
};
