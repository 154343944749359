import API from './api';

export default {
    
    getPlan: (planId) => API({
        url: `plans/${planId}`,
        method: 'GET',
    }),

    createPlan: (json) => API({
        url: 'plans',
        method: 'POST',
        json,
    }),

    updatePlan: (json) => API({
        url: `plans/${json.id}`,
        method: 'PATCH',
        json,
    }),

    deletePlan: (planId) => API({
        url: `plans/${planId}`,
        method: 'DELETE',
    }),

};
