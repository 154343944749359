import { createSelector } from 'reselect';
import * as OrganizationSelectors from 'shared_redux/selectors/models/organizations';
import Plans from 'shared_redux/reducers/models/plans';

const stateRoot = (state) => state.settings;

export const getOrganizationSettings = createSelector(
    state => OrganizationSelectors.getOrganizationSettings(state),
    state => stateRoot(state),
    (model, overrides) => {
        if (!model) {
            return null;
        }

        const result = { ...model };
        for (let key in overrides) {
            if (overrides[key] !== undefined) {
                result[key] = overrides[key];
            }
        }

        return result;
    }
);

export const getSettingsLoaded = createSelector(
    state => OrganizationSelectors.getOrganizationSettings(state),
    (model) => {
        return model !== null && model.default_autoregulation !== undefined;
    }
);

export const getPlanObject = createSelector(
    getOrganizationSettings,
    (modelWithOverrides) => {
        // TODO: don't use such a hacky valid check
        // settings should be properly tied to the actual plan logic instead of copy pasted and then hacked together

        if (!modelWithOverrides) {
            return null;
        }

        const fakePlan = {};
        for (const key in modelWithOverrides) {
            if (key.startsWith('default_')) {
                fakePlan[key.slice(8)] = modelWithOverrides[key];
            }
        }

        return fakePlan;
    }
);

export const getSettingsValid = createSelector(
    getPlanObject,
    (planObject) => {
        if (!planObject) {
            return false;
        }
        const obj = {...planObject, exercise_id: 'foobar'};
        return Plans.isValid(obj);
    }
);
