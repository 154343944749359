export default [
    'MIN',
    'LAST',
    'AVERAGE',
    'MAX',
    'FIRST',
    'PEAK_END',
    'ABSOLUTE_LOSS',
    '%_LOSS',
];
