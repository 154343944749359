import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as ProgramBuilderSelectors from 'shared_redux/selectors/view/program_builder';

const makeMapStateToProps = () => {
    const getDraftMicrocycleSession = ProgramBuilderSelectors.makeGetDraftMicrocycleSession();
    return (state, props) => {
        return getDraftMicrocycleSession(state, props);
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        expandMicrocycleSession: Actions.expandMicrocycleSession,
        collapseMicrocycleSession: Actions.collapseMicrocycleSession,

        createMicrocycleSession: Actions.createMicrocycleSession,
        duplicateMicrocycleSession: Actions.duplicateMicrocycleSession,
        moveMicrocycleSession: Actions.moveMicrocycleSession,
        deleteMicrocycleSession: Actions.deleteMicrocycleSession,
    }, dispatch);
};

const Container = connect(
    makeMapStateToProps,
    mapDispatchToProps,
)(View);

export default Container;
