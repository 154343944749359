import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from 'shared_features/spinner';

import styles from './styles.module.scss';

// TODO: ignore when creating
export default props => {
    const recaptchaRef = React.useRef();
    const onSubmitWithReCAPTCHA = async () => {
        const token = await recaptchaRef.current.executeAsync();
        props.register(token, props.email, props.password, props.organization_name, props.beta);
        recaptchaRef.current.reset();
    }

    if (!props.beta && props.registered) {
        return (<div className={styles.error}>
            <h2>We’re setting up your RepOne account, you’ll receive an email when it has been activated</h2>
            <p>Tip: bookmark this address so you can return to the coaching portal with a single click</p>
        </div>);
    } else 
    {
        let submitText;
        if (props.registering) {
            submitText = 'Creating...';
        } else if (props.registered) {
            submitText = 'Loading...';
        } else if (props.beta) {
            submitText = 'Create Beta Account';
        } else {
            submitText = 'Create Account';
        }
        return (
            <div>
                <div className={styles.main}>
                    <img src="https://assets.reponestrength.com/black_logo.png" />
                    <div>{!props.beta ? 'Create your RepOne Account' : 'Create your RepOne Beta Account' }</div>

                    <form className={styles.form} autoComplete={"on"} onSubmit={e => { e.preventDefault() }}>
                        <label>Email address</label>
                        <input type="text"
                            className={styles.textInput}
                            placeholder="name@domain.com"
                            value={props.email}
                            onChange={(event)=> props.updateEmail(event.target.value)} />
                        <label>Organization</label>
                        <input type="text"
                            className={styles.textInput}
                            placeholder="Organization"
                            value={props.organization_name}
                            onChange={(event)=> props.updateOrganizationName(event.target.value)} />
                        <label>Password</label>
                        <input type="password"
                            className={styles.textInput}
                            placeholder="Password"
                            value={props.password}
                            onChange={(event)=> props.updatePassword(event.target.value)} />
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6LdxPboZAAAAADdtY2YARZm2eOlRlkt5XAebeUgT"
                        />
                        <div>
                            <input className={styles.submit} type="submit" value={submitText} onClick={onSubmitWithReCAPTCHA} disabled={props.registering || props.registered} />
                            {props.registering ? <div className={styles.spinnerContainer}><Spinner /></div> : null}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
};
