import {
    UPDATE_REGISTER_EMAIL,
    UPDATE_REGISTER_PASSWORD,
    UPDATE_REGISTER_ORGANIZATION_NAME,
    REGISTER_ATTEMPT,
} from 'shared_redux/actions';

export const updateEmail = (email) => ({ type: UPDATE_REGISTER_EMAIL, email });

export const updatePassword = (password) => ({ type: UPDATE_REGISTER_PASSWORD, password });

export const updateOrganizationName = (organization_name) => ({ type: UPDATE_REGISTER_ORGANIZATION_NAME, organization_name });

export const register = (token, email, password, organization_name, beta) => ({ type: REGISTER_ATTEMPT, token, email, password, organization_name, beta });
