import {
    FETCH_PROGRAM_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, oneToOne, fk, many, attr } from 'redux-orm';

class MicrocycleSessions extends Model {

    static reducer(action, MicrocycleSessions) {
        switch (action.type) {
            case FETCH_PROGRAM_SUCCEEDED:
                for (var microcycle of action.payload.microcycles) {
                    for (var microcycle_session of microcycle.microcycle_sessions) {
                        const {['session']: session, ...rawMicrocycleSession} = microcycle_session;
                        MicrocycleSessions.upsert(rawMicrocycleSession);
                    }
                }
                break;
            case SIGN_OUT_SUCCEEDED:
                MicrocycleSessions.all().delete();
                break;
        }
    }
    
}
MicrocycleSessions.modelName = 'MicrocycleSessions';

MicrocycleSessions.options = {
    idAttribute: 'session_id',
};

MicrocycleSessions.fields = {
    session_id: oneToOne({
        to: 'Sessions',
        as: 'session',
        relatedName: 'microcycle_session',
    }),
    microcycle_id: fk({
        to: 'Microcycles',
        as: 'microcycle',
        relatedName: 'microcycle_sessions',
    }),
    order: attr(),
};

export default MicrocycleSessions;
