// note: taken from https://github.com/intljusticemission/react-big-calendar/issues/118
// leaving in javascript as I don't want to spend the time converting it to typescript

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    Calendar,
    momentLocalizer,
} from 'react-big-calendar';
import moment from 'moment-timezone';
import {accessor} from 'react-big-calendar/lib/utils/accessors';

// NOTE: In theory moment should always use local timezone, which means browser timezone
const localizer = momentLocalizer(moment);

export const convertDateTimeToDate = (datetime, timeZoneName) => {
    const m = moment.tz(datetime, timeZoneName);
    return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), 0)
};

export const convertDateToDateTime = (date, timeZoneName) => {
    const dateM = moment.tz(date, Calendar.tz);
    return moment.tz({
        year: dateM.year(),
        month: dateM.month(),
        date: dateM.date(),
        hour: dateM.hour(),
        minute: dateM.minute(),
    }, Calendar.tz);
};

class TimeZoneAgnosticBigCalendar extends Component {

    static propTypes = {
        events: PropTypes.array,
        onSelectSlot: PropTypes.func,
        onEventDrop: PropTypes.func,
        timeZoneName: PropTypes.string,
        startAccessor: PropTypes.string,
        endAccessor: PropTypes.string,
    };

    static defaultProps = {
        startAccessor: 'start',
        endAccessor:'end'
    };

    startAccessor = (event) => {
        const start = accessor(event, this.props.startAccessor);
        return convertDateTimeToDate(start, this.props.timeZoneName);
    };

    endAccessor = (event) => {
        const end = accessor(event, this.props.endAccessor);
        return convertDateTimeToDate(end, this.props.timeZoneName);
    };

    render() {
        const { onSelectSlot, onEventDrop, timeZoneName, ...props } = this.props;
        const bigCalendarProps = {
            ...props,
            startAccessor: this.startAccessor,
            endAccessor: this.endAccessor,
            // showAllEvents: true, // an alternative that allows you to scroll through it
            onSelectSlot: onSelectSlot && (({start, end, slots}) => {
                onSelectSlot({
                    start: convertDateToDateTime(start, timeZoneName),
                    end: convertDateToDateTime(end, timeZoneName),
                    slots: slots.map(date => convertDateToDateTime(date, timeZoneName)),
                })
            }),
            onEventDrop: onEventDrop && (({event, start, end}) => {
                onEventDrop({
                    event,
                    start: convertDateToDateTime(start, timeZoneName),
                    end: convertDateToDateTime(end, timeZoneName),
                })
            }),
        };
        return <Calendar
            localizer={localizer}
            {...bigCalendarProps} />
    }
}
export default TimeZoneAgnosticBigCalendar;
