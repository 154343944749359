import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as AccountsSelectors from 'shared_redux/selectors/models/accounts';
import * as ProgramBuilderSelectors from 'shared_redux/selectors/view/program_builder';

const mapStateToProps = (state, props) => {
    const account = AccountsSelectors.getAccount(state);
    return {
        organizationId: account ? account.organization_id : null,
        exercises: ProgramBuilderSelectors.getDraftExercises(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createPlan: Actions.createPlan,
        cancelInsertPlan: Actions.cancelInsertPlan,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
)(View);

export default Container;
