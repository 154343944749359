import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as ReportsManagerSelectors from 'shared_redux/selectors/view/reports_manager';

const mapStateToProps = (state) => {
    document.title = "Reports";

    return {
        isFetching: ReportsManagerSelectors.getIsFetchingDataExports(state),
        dataExports: ReportsManagerSelectors.getDataExports(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchTeams: Actions.fetchTeams,
        fetchDataExports: Actions.fetchDataExports,
        startDataExport: Actions.startDataExport,
        retryDataExport: Actions.retryDataExport,
        downloadDataExport: Actions.downloadDataExport,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
