import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import View from './view';
import * as Actions from './actions';
import * as ProgramBuilderSelectors from 'shared_redux/selectors/view/program_builder';

const mapStateToProps = (state, props) => {
    // replace url
    const programURLName = ProgramBuilderSelectors.getProgramURLName(state, props);
    const draftProgramURLName = ProgramBuilderSelectors.getDraftProgramURLName(state, props);
    const name = draftProgramURLName !== null ? draftProgramURLName : programURLName;
    if (name && props.match.params.programname !== name) {
        if (props.match.params.tab) {
            props.history.replace(`/programs/${props.match.params.programid}/${name}/${props.match.params.tab}`);
        } else {
            props.history.replace(`/programs/${props.match.params.programid}/${name}`);
        }
    }
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchProgram: Actions.fetchProgram,
        fetchExercises: Actions.fetchExercises,
        fetchSettings: Actions.fetchSettings,
        createProgram: Actions.createProgram,
        createMicrocycle: Actions.createMicrocycle,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default withRouter(Container);
