import React from 'react';

export default props => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="14" stroke="#333333" stroke-width="2"/>
            <circle cx="25" cy="5" r="3" fill="#333333"/>
            <circle cx="15" cy="15" r="5" fill="#333333"/>
        </svg>
    );
};
