import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Actions from './actions';
import View from './view';
import * as ProgramSchedulerSelectors from 'shared_redux/selectors/view/program_scheduler';
import * as ProgramSchedulerPopupSelectors from 'shared_redux/selectors/view/program_scheduler_popup';
import * as CategoriesSelectors from 'shared_redux/selectors/view/categories';

// TODO: confirm if this needs withRouter so props can access the params
const mapStateToProps = (state, props) => ({
    modalVisible: ProgramSchedulerPopupSelectors.getIsCreating(state, props) || ProgramSchedulerPopupSelectors.getIsEditing(state, props),
    programLengthInDays: ProgramSchedulerSelectors.getProgramLengthInDays(state, props),
    calendarEvents: ProgramSchedulerSelectors.getCalendarEvents(state, props),
    groups: CategoriesSelectors.getTeamsAndGroups(state),
    selectedTeam: ProgramSchedulerSelectors.getSelectedTeamFilter(state),
    selectedGroup: ProgramSchedulerSelectors.getSelectedGroupFilter(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCategories: Actions.fetchCategories,
        fetchProgramSchedules: Actions.fetchProgramSchedules,
        createSchedule: Actions.createSchedule,
        editSchedule: Actions.editSchedule,
        updateGroupFilter: Actions.updateGroupFilter,
     }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;


