import React, {Component} from 'react';
import styles from './styles.module.scss';

import StationRow from './station_row';

class View extends Component {

    componentDidMount() {
        this.props.fetchRacks();
        this.props.fetchSettings(); // autoprogress is normal setting
    }

    // render

    _renderStations() {
        return this.props.racks.map(rack => <StationRow
            key={`rackrow${rack.id}`}
            id={rack.id}
            number={rack.number}
            sensor_name={rack.sensor_name}
            app_version={rack.app_version}
            expiration={rack.expiration}
        />);
    }

    render() {
        const props = this.props;
        return (
            <div className={styles.container}>
                <div className={styles.settingsSection}>
                    <h1>Manage your training stations</h1>
                    <div className={styles.description}>Remotely manage the connections between your tablets and your RepOne hardware.</div>
                    <div className={styles.labels}>
                        <div>STATION NUMBER</div>
                        <div>REPONE UNIT #</div>
                    </div>
                    <div>
                        {this._renderStations()}
                    </div>
                </div>

                <div className={styles.settingsSection}>
                    <h1>Training station settings</h1>
                    <label>
                        <input
                            name="auto_progress"
                            type="checkbox"
                            checked={props.station_auto_progress}
                            onChange={(event)=>props.updateStationAutoProgress(event.target.checked)} />
                        Auto-progress
                    </label>
                    <p className={styles.description}>When an athlete completes the current set in their workout card, move them to the next set automatically. Keep in mind: this may make it harder for athletes to update load, RPE or other fields if they log after completing sets.</p>
                </div>
            </div>
        );
    }
}

export default View;
