import {
    FETCH_ATHLETE_SESSIONS_SUCCEEDED,
    UPDATE_ENTRY_SUCCEEDED,

    SET_FEED_ITEMS,
    DELETE_FEED_ITEM,
    UPDATE_FEED_ENTRY_ITEM,
    UPDATE_FEED_COMPLETE_ITEM,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class Entries extends Model {

    static reducer(action, Entries, session) {
        switch (action.type) {
            case FETCH_ATHLETE_SESSIONS_SUCCEEDED: {
                for (const athlete_session of action.payload) {
                    for (const block of athlete_session.session.blocks) {
                        for (const plan of block.plans) {
                            for (const entry of plan.entries) {
                                Entries.upsert({
                                    id: entry.id,
                                    plan_id: entry.plan_id,
                                    rpe: entry.rpe,
                                    weight: entry.weight,
                                    weight_unit: entry.weight_unit,
                                    rack_id: entry.rack_id,
                                    working_set_type: entry.working_set_type,
                                    autoregulation_feedback: entry.autoregulation_feedback,
                                    manual_feedback: entry.manual_feedback,
                                    manual_rep_count: entry.manual_rep_count,
                                    manually_completed_time: entry.manually_completed_time,
                                    manually_completed: entry.manually_completed,
                                    load_modified: entry.load_modified,
                                    order: entry.order,
                                    archived: entry.archived,
                                });
                            }
                        }
                    }
                }
                break;
            }
            case UPDATE_ENTRY_SUCCEEDED:
                Entries.upsert({
                    ...action.payload,
                });
                break;

            case SET_FEED_ITEMS:
            case DELETE_FEED_ITEM:
            case UPDATE_FEED_ENTRY_ITEM:
            case UPDATE_FEED_COMPLETE_ITEM:
                action.entries.forEach(e => {
                    const {['reps']: reps, ['plan']: plan, ...raw} = e;
                    Entries.upsert(raw);
                });
                break;

            case SIGN_OUT_SUCCEEDED:
                Entries.all().delete();
                break;
        }
    }

    entryLoadLbs() {
        if (!this.weight) {
            return null;
        }
        if (this.weight_unit === 'KGS') {
            return (this.weight * 2.20462).toFixed(2);
        }
        return this.weight;
    }

}
Entries.modelName = 'Entries';

Entries.fields = {
    id: attr(),
    plan_id: fk({
        to: 'Plans',
        as: 'plan',
        relatedName: 'entries',
    }),
    rpe: attr(),
    weight: attr(),
    weight_unit: attr(),
    rack_id: attr(),
    working_set_type: attr(),
    autoregulation_feedback: attr(),
    manual_feedback: attr(),
    manual_rep_count: attr(),
    manually_completed: attr(),
    manually_completed_time: attr(),
    load_modified: attr(),
    order: attr(), 
    archived: attr(),
};

export default Entries;
