import {
    FETCH_DATA_EXPORTS_SUCCEEDED,
    START_DATA_EXPORTS_SUCCEEDED,
    RETRY_DATA_EXPORTS_ATTEMPT,
    RETRY_DATA_EXPORTS_FAILED,
    UPDATE_DATA_EXPORT,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';
import { Model, fk, attr } from 'redux-orm';

class DataExports extends Model {

    static reducer(action, DataExports, session) {
        switch (action.type) {
            case FETCH_DATA_EXPORTS_SUCCEEDED:
                if (action.payload && action.payload.length > 0) {
                    action.payload.forEach(de => {
                        DataExports.upsert(de);
                    });
                }
                break;
            case START_DATA_EXPORTS_SUCCEEDED:
                DataExports.upsert(action.payload);
                break;
            case RETRY_DATA_EXPORTS_ATTEMPT:
                DataExports.withId(action.payload)?.update({status: 'WAITING'});
                break;
            case RETRY_DATA_EXPORTS_FAILED:
                DataExports.withId(action.payload)?.update({status: 'FAILED'});
                break;
            case UPDATE_DATA_EXPORT:
                DataExports.upsert(action.payload);
                break;
            case SIGN_OUT_SUCCEEDED:
                DataExports.all().delete();
                break;
        }
    }

}

DataExports.modelName = 'DataExports';

DataExports.fields = {
    id: attr(),
    status: attr(),
	start_date: attr(),
	end_date: attr(),
    organization_id: attr(), // not linking to orgs
    team_id: fk({
        to: 'Teams',
        as: 'team',
        relatedName: 'data_exports',
    }),
    group_id: fk({
        to: 'Groups',
        as: 'group',
        relatedName: 'data_exports',
    }),
};

export default DataExports;
