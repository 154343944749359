import {
    FETCH_PROGRAM_SUCCEEDED,
    FETCH_ATHLETE_SESSIONS_SUCCEEDED,

    SET_FEED_ITEMS,
    UPDATE_FEED_ENTRY_ITEM,
    UPDATE_FEED_COMPLETE_ITEM,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class Sessions extends Model {

    static reducer(action, Sessions, session) {
        switch (action.type) {
            case FETCH_PROGRAM_SUCCEEDED:
                for (var microcycle of action.payload.microcycles) {
                    for (var microcycle_session of microcycle.microcycle_sessions) {
                        Sessions.upsert({
                            id: microcycle_session.session_id,
                        });
                    }
                }
                break;
            case FETCH_ATHLETE_SESSIONS_SUCCEEDED:
                for (var athlete_session of action.payload) {
                    Sessions.upsert({
                        id: athlete_session.session_id,
                    });
                }
                break;

            case SET_FEED_ITEMS:
                action.feed_items.forEach(i => {
                    Sessions.upsert({ id: i.athlete_session_id });
                });
                break;

            case UPDATE_FEED_ENTRY_ITEM:
            case UPDATE_FEED_COMPLETE_ITEM:
                Sessions.upsert({ id: action.feed_item.athlete_session_id });
                break;

           case SIGN_OUT_SUCCEEDED:
                Sessions.all().delete();
                break;
        }
    }

}
Sessions.modelName = 'Sessions';

Sessions.fields = {
    id: attr(), // session_id
};

export default Sessions;
