import API from './api';

export default {

    getSettings: (organization_id) => API({
        url: `organizations/${organization_id}/settings`,
        method: 'GET',
    }),

    updateSettings: (organization_id, json) => API({
        url: `organizations/${organization_id}/settings`,
        method: 'PATCH',
        json,
    }),
    
};
