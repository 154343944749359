import { createSelector } from 'redux-orm';
import * as _ from 'lodash';
import orm from 'shared_redux/reducers/models/orm';

const stateRoot = state => state.columns_filter;

export const getEditableRosterSettings = createSelector(
    orm,
    state => state.database,
    state => stateRoot(state),
    (session, overrides) => {
        return session.RosterSettings.all().orderBy('order', 'asc').toRefArray() // get model sorted
            .slice(2) // ignore avatar and name
            .map(s => {
                // handle overrides
                if (s.type === 'CATEGORY') {
                    if (overrides.category === true) {
                        // toggle
                        return { ...s, visible: !s.visible };
                    } else {
                        // use existing
                        return s;
                    }
                } else if (s.type === 'MAXABLE_EXERCISE' || s.type === 'CALCULATED_MAX_EXERCISE') {
                    if (overrides[s.exercise_id] === true) {
                        // toggle
                        return { ...s, visible: !s.visible };
                    } else {
                        // use existing
                        return s;
                    }
                }
            })
            .map(s => {
                // make it title case
                return {
                    ...s,
                    value: s.value.replace(/\w+/g, _.capitalize)
                };
            });
    }
);

export const getIsShowingColumnsFilter = state => stateRoot(state).isShowingColumnsFilter;

export const getIsUploading = state => stateRoot(state).isUploading;
