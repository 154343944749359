import API from './api';

export default {

    getRacks: (organization_id) => API({
        url: `racks?organization_id=${organization_id}`,
        method: 'GET',
    }),

    getExpiringRacks: (organization_id, expiration) => API({
        url: `racks?organization_id=${organization_id}&expiration=${expiration}`,
        method: 'GET',
    }),

    updateRack: (rackId, json) => API({
        url: `racks/${rackId}`,
        method: 'PATCH',
        json,
    }),
    
};
