import {
    ATTEMPT_FETCH_TEAMS,
    CREATE_TEAM,
    COLLAPSE_TEAM,
    EXPAND_TEAM,
    EDIT_TEAM,
    EDIT_GROUP,
    CREATE_GROUP,
} from 'shared_redux/actions';

export const fetchTeams = () => ({ type: ATTEMPT_FETCH_TEAMS });

export const createTeam = (organizationId) => ({ type: CREATE_TEAM, organizationId });

export const collapseTeam = (teamId) => ({ type: COLLAPSE_TEAM, teamId });

export const expandTeam = (teamId) => ({ type: EXPAND_TEAM, teamId });

export const editTeam = (teamId) => ({ type: EDIT_TEAM, teamId });

export const editGroup = (groupId) => ({ type: EDIT_GROUP, groupId });

export const createGroup = (teamId) => ({ type: CREATE_GROUP, teamId });
