import React, {Component} from 'react';
import Modal from 'react-modal';

import styles from './styles.module.scss';

const renderTitle = props => {
    if (props.uploadSucceeded) {
        return <h1>Import success <img src="https://assets.reponestrength.com/schedule_success.png" /></h1>
    } else if (props.missingColumns.length > 0) {
        return <h1>Columns not recognized</h1>
    } else if (props.failedRow > 0) {
        return <h1>Import failed</h1>
    } else if (props.uploadFailed) {
        return <h1>Import failed</h1>
    } else {
        return <h1>Import Athletes</h1>
    }
};

const renderBody = props => {
    if (props.uploadSucceeded) {
        return <div>You uploaded <b>{props.numAthletesCreated} new athletes</b> and created <b>{props.numTeamsCreated} new teams</b> and <b>{props.numGroupsCreated} new groups</b>.</div>
    } else if (props.missingColumns.length > 0) {
        return <div>
            <div>The CSV is missing the following columns: <b>{props.missingColumns.join(', ')}</b>.</div>
            <div>Please check spelling or add the columns if they are missing and try again.</div>
        </div>;
    } else if (props.failedRow > 0) {
        return <div>
            <div>We ran into the following error on line {props.failedRow} and had to stop.</div>
            <div>{props.failedMessage}.</div>
        </div>;
    } else if (props.generalError) {
        return <div>Something went wrong, check your connection and try again</div>
    } else {
        return (<div>
            Roster must be formatted as a <b>CSV</b> with the following columns. Only first and last name fields are required for every athlete.
            Click <a href="https://assets.reponestrength.com/sample_import.csv">here</a> to download a template.
            <ul>
                <li>First Name</li>
                <li>Middle Name</li>
                <li>Last Name</li>
                <li>Team</li>
                <li>Group</li>
            </ul>
        </div>);
    }
};

const renderFooter = (props, uploader) => {
    if (props.uploadSucceeded) {
        return (<div className={styles.optionsContainer}>
            <div className={styles.doneButton} onClick={()=>props.closeModal()}>Done</div>
        </div>);
    } else {
        return (<div className={styles.optionsContainer}>
            <div className={styles.chooseButton} onClick={()=>uploader.current.click()}>Choose CSV file</div>
        </div>);
    }
};

const selectedFile = (props, event, uploader) => {
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        props.importAthletes(file);
    }

    // clear it
    uploader.current.value = "";
};

class View extends Component {

    constructor(props) {
        super(props);
        this.uploader = React.createRef();
    }

    render() {
        const props = this.props;
        return (<div>
            <Modal
                className={styles.modal}
                overlayClassName={styles.overlay}
                isOpen={props.isShowing}
                onRequestClose={()=>props.closeModal()}>
                {renderTitle(props)}
                {renderBody(props)}
                {renderFooter(props, this.uploader)}
            </Modal>
            <input type="file"
                ref={this.uploader}
                accept=".csv"
                onChange={(event)=>selectedFile(props, event, this.uploader)} />
        </div>);
    };

}

export default View;
