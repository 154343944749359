import {
    UPDATE_REPORTS_START_DATE_FILTER,
    UPDATE_REPORTS_END_DATE_FILTER,
    UPDATE_REPORTS_TIME_RANGE_FILTER,
    UPDATE_REPORTS_INTERVAL_FILTER,
    UPDATE_REPORTS_GROUPS_FILTER,
} from 'shared_redux/actions';

export const updateStartDate = (date) => ({ type: UPDATE_REPORTS_START_DATE_FILTER, date });

export const updateEndDate = (date) => ({ type: UPDATE_REPORTS_END_DATE_FILTER, date });

export const updateTimeRange = (timeRange) => ({ type: UPDATE_REPORTS_TIME_RANGE_FILTER, timeRange });

export const updateInterval = (interval) => ({ type: UPDATE_REPORTS_INTERVAL_FILTER, interval });

export const updateGroupFilter = (team, group) => ({ type: UPDATE_REPORTS_GROUPS_FILTER, team, group });
