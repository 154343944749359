// note: this is not expansive, there are more to add in as features are re-implemented

import {
    FETCH_PROGRAMS_SUCCEEDED,
    FETCH_PROGRAM_SUCCEEDED,
    FETCH_PROGRAM_SCHEDULES_SUCCEEDED,
    DUPLICATE_PROGRAM_SUCCEEDED,
    CREATE_PROGRAM_SUCCEEDED,
    UPDATE_PROGRAM_SUCCEEDED,
    DELETE_PROGRAM_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class Programs extends Model {

    static reducer(action, Programs, session) {
        switch (action.type) {
            case FETCH_PROGRAMS_SUCCEEDED:
                for (var program of action.payload) {
                    const {['microcycles']: microcycles, ...rawProgram} = program;
                    Programs.upsert(rawProgram);
                }
                break;
            case FETCH_PROGRAM_SUCCEEDED: {
                const {['microcycles']: microcycles, ...program} = action.payload;
                Programs.upsert(program);
                break;
            }
            case CREATE_PROGRAM_SUCCEEDED:
            case DUPLICATE_PROGRAM_SUCCEEDED:
            case UPDATE_PROGRAM_SUCCEEDED:
                Programs.upsert(action.payload);
                break;
            case DELETE_PROGRAM_SUCCEEDED:
                Programs.withId(action.id).delete();
                break;
            case FETCH_PROGRAM_SCHEDULES_SUCCEEDED: {
                for (var schedule of action.payload) {
                    Programs.upsert(schedule.program);
                }
                break;
            }
            case SIGN_OUT_SUCCEEDED:
                Programs.all().delete();
                break;
        }
    }
    
}
Programs.modelName = 'Programs';

Programs.fields = {
    id: attr(),
    name: attr(),
    microcycle_name: attr(),
    organization_id: attr(),
    notes: attr(),
    last_updated: attr(),
    archived: attr(),
    order: attr(),
};

export default Programs;
