import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'redux-orm';

import View from './view';
import * as Actions from './actions';
import orm from 'shared_redux/reducers/models/orm';

const getViewModels = createSelector(
    orm,
    state => state.database,
    state => state.teams_manager, // TODO: rework the teams manager, I need to pass in the dictionary to use seletors
    (session, dictionary) => {
        var models = [];
        const account = session.Accounts.all().first() || null;

        session.Teams.all().toModelArray().forEach(team => {
            const collapsed = dictionary[team.id];
            models.push({
                type: "team",
                id: team.id,
                name: team.name,
                collapsed,
            });
            
            if (!collapsed) {
                team.groups.toModelArray().forEach(group => {
                    models.push({
                        type: "group",
                        id: group.id,
                        name: group.name,
                    });
                });

                models.push({ type: "new", teamId: team.id });
            }
        });

        document.title = "Teams & Groups";


        return {
            models,
            organizationId: account ? account.organization_id : null,
        };
    }
);

const mapStateToProps = (state) => {
    return getViewModels(state); 
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchTeams: Actions.fetchTeams,
        createTeam: Actions.createTeam,
        collapseTeam: Actions.collapseTeam,
        expandTeam: Actions.expandTeam,
        editTeam: Actions.editTeam,
        editGroup: Actions.editGroup,
        createGroup: Actions.createGroup,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
