import {
    SELECT_ROSTER_ATHLETE,
    DESELECT_ROSTER_ATHLETE,
    SELECT_VISIBLE_ROSTER_ATHLETES,
    DESELECT_ROSTER_ATHLETES,

    FETCH_ATHLETES_SUCCEEDED,

    UPDATE_ROSTER_SELECTED_TEAM,
    UPDATE_ROSTER_SELECTED_GROUP,

    DELETE_ROSTER_SELECTED_ATHLETES_ATTEMPT,
    DELETE_ROSTER_SELECTED_ATHLETES_SUCCEEDED,
    DELETE_ROSTER_SELECTED_ATHLETES_FAILED,

    SAVE_SELECTED_ATHLETES_CATEGORIES_ATTEMPT,
    SAVE_SELECTED_ATHLETES_CATEGORIES_SUCCEEDED,
    SAVE_SELECTED_ATHLETES_CATEGORIES_FAILED,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    team_id: null,
    group_id: null,
    numAthletesSelected: 0,
    allAthletesSelected: false,
    loaded: false,
    isBatchUploading: false,
    // NOTE: the rest will be dictionary keys for selected athletes
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        // macros
        case SELECT_ROSTER_ATHLETE: {
            let obj = {
                ...state,
                numAthletesSelected: state.numAthletesSelected+1,
            };
            obj[action.athleteId] = true;
            return obj;
        }
        case DESELECT_ROSTER_ATHLETE: {
            let obj = {
                ...state,
                numAthletesSelected: state.numAthletesSelected-1,
                allAthletesSelected: false,
            };
            obj[action.athleteId] = false;
            return obj;
        }
        case SELECT_VISIBLE_ROSTER_ATHLETES: {
            let obj = {
                ...state,
                numAthletesSelected: state.numAthletesSelected + action.athleteIds.length,
                allAthletesSelected: true,
            };
            for (const athleteId of action.athleteIds) {
                obj[athleteId] = true;
            }
            return obj;
        }
        case DESELECT_ROSTER_ATHLETES: {
            return {
                team_id: state.team_id,
                group_id: state.group_id,
                numAthletesSelected: 0,
                allAthletesSelected: false,
                loaded: state.loaded,
                isBatchUploading: state.isBatchUploading,
            };
        }

        case FETCH_ATHLETES_SUCCEEDED:
            return {
                ...state,
                loaded: true,
            };

        case UPDATE_ROSTER_SELECTED_TEAM:
            return {
                ...state,
                team_id: action.team_id,
            };
        case UPDATE_ROSTER_SELECTED_GROUP:
            return {
                ...state,
                group_id: action.group_id,
            };
        
        case DELETE_ROSTER_SELECTED_ATHLETES_ATTEMPT:
        case SAVE_SELECTED_ATHLETES_CATEGORIES_ATTEMPT:
            return {
                ...state,
                isBatchUploading: true,
            };
        case DELETE_ROSTER_SELECTED_ATHLETES_SUCCEEDED:
        case SAVE_SELECTED_ATHLETES_CATEGORIES_SUCCEEDED: {
            const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
            const result = {
                ...state,
                numAthletesSelected: 0,
                isBatchUploading: false,
                team_id: null,
                group_id: null,
            };
            for (let key in result) {
                if (key.match(regex)) {
                    result[key] = false;
                }
            }
            return result;
        }
        case DELETE_ROSTER_SELECTED_ATHLETES_FAILED:
        case SAVE_SELECTED_ATHLETES_CATEGORIES_FAILED:
            return {
                ...state,
                isBatchUploading: false,
            };
        
        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
