import React from 'react';
import styles from './styles.module.scss';

export default props => {
    return (<li className={styles.labelsContainer} key={`ColumnLabels`}>
        <input type="checkbox"
            checked={props.allAthletesSelected}
            onChange={()=> props.allAthletesSelected ? props.deselectAthletes() : props.selectVisibleAthletes(props.athleteIds)}/>
        <span className={styles.selectLabel}>{props.allAthletesSelected ? 'DESELECT ALL' : 'SELECT ALL'}</span>
        {props.labels.map(l => {
            switch (l.type) {
                // case 'AVATAR':
                //     return <span key={`${l.value}Label`} className={styles.avatarLabel}>{l.value}</span>;
                // case 'NAME':
                //     return <span key={`${l.value}Label`} className={styles.nameLabel}>{l.value}</span>
                case 'CATEGORY':
                    return <span key={`${l.value}Label`} className={styles.categoryLabel}>{l.value}</span>
                case 'MAXABLE_EXERCISE':
                case 'CALCULATED_MAX_EXERCISE':
                    return <span key={`${l.value}Label`} className={styles.maxableExerciseLabel}>{l.value}</span>
            }
        })}
    </li>);
};
