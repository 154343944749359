import React, {Component} from 'react';
import Modal from 'react-modal';
import Select from 'react-select';

import Avatar from '../avatar';
import styles from './styles.module.scss';
import Spinner from 'shared_features/spinner';

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            image: null,
        };
    }

    // TODO: Consider having image be in the redux store instead of do a boolean check
    componentWillReceiveProps(nextProps) {
        if (nextProps.isShowing !== this.props.isShowing) {
            this.setState({ image: null });
        }
    }

    _renderAvatar() {
        if (!this.props.showAvatarOption) {
            return null;
        }

        if (!this.state.image) {
            var image = "https://profiles.reponestrength.com/default.png";
        } else {
            var image = URL.createObjectURL(this.state.image);
        }
        return (<div className={styles.avatar}>
                    <Avatar
                        imageURL={image}
                        uploadEnabled={false}
                        description="+ Upload athlete photo"
                        upload={(image)=>this.setState({image: image}) } />
                </div>);
    }

    _renderMetadata() {
        return (
            <div>
                <div className={styles.horizontalGrid}>
                    <div className={styles.label}>Team</div>
                    <div className={styles.label}>Group</div>
                </div>
                <div className={styles.horizontalGrid}>
                    {this._renderTeamSelect()}
                    {this._renderGroupSelect()}
                </div>
            </div>
        );
    }

    _renderTeamSelect() {
        let value = null;
        let teams = [];
        for (var team of this.props.teams) {
            const option = { value: team.id, label: team.name };
            teams.push(option);
            if (team.id === this.props.teamId) {
                value = option;
            }
        }

        return (
            <Select
                name="team"
                value={value}
                isClearable
                clearable={true}
                deleteRemoves={false}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        this.props.updateTeam(selectedOption.value);
                    } else {
                        this.props.updateTeam(null);
                    }
                }}
                options={teams}
            />
        );
    }

    _renderGroupSelect() {
        let value = null;
        let groups = [];
        for (var group of this.props.groups) {
            const option = { value: group.id, label: group.name };
            groups.push(option);
            if (group.id === this.props.groupId) {
                value = option;
            }
        }

        return (
            <Select
                name="group"
                value={value}
                isClearable
                clearable={true}
                deleteRemoves={false}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        this.props.updateGroup(selectedOption.value);
                    } else {
                        this.props.updateGroup(null);
                    }
                }}
                options={groups}
            />
        );
    }

    _renderRemove() {
        if (this.props.showRemove) {
            return <div className={styles.deleteButton} onClick={()=>window.confirm("Are you sure?") && this.props.remove()}>Remove athlete</div>
        }
    }

    _renderDone() {
        if (!this.props.showAvatarOption || !this.props.isUploadingAvatar) {
            const actionText = this.props.showAvatarOption ? "Create athlete" : "Save athlete";
            return <div className={styles.saveButton} onClick={()=>this.props.save(this.state.image)}>{actionText}</div>;
        } else {
            return <div>
                <Spinner />
                <div className={styles.saveButton}>Creating...</div>
            </div>;
        }
    }

    render() {
        const title = this.props.showAvatarOption ? "New Athlete" : "Athlete Profile";

        return (
            <Modal
                className={styles.modal}
                overlayClassName={styles.overlay}
                isOpen={this.props.isShowing}
                onRequestClose={()=>this.props.closeModal()}>

                <div className={styles.cardTitle}>{title}</div>
                {this._renderAvatar()}
                <div className={styles.label}>Name</div>
                <div className={styles.horizontalGrid}>
                    <input type="text"
                        placeholder="First"
                        value={this.props.firstName}
                        onChange={(event)=> this.props.updateFirstName(event.target.value)} autoFocus />
                    <input type="text"
                        placeholder="Middle (optional)"
                        value={this.props.middleName}
                        onChange={(event)=> this.props.updateMiddleName(event.target.value)} />
                    <input type="text"
                        placeholder="Last"
                        value={this.props.lastName}
                        onChange={(event)=> this.props.updateLastName(event.target.value)} />
                </div>

                {this._renderMetadata()}

                <div className={styles.optionsContainer}>
                    {this._renderRemove()}
                    {this._renderDone()}
                </div>
            </Modal>
        );
    }

}

export default View;
