import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';

import * as SettingsSelectors from 'shared_redux/selectors/view/settings';

const mapStateToProps = (state) => {
    document.title = 'Settings';
    const settings = SettingsSelectors.getOrganizationSettings(state);
    const loaded = SettingsSelectors.getSettingsLoaded(state);
    const isValid = SettingsSelectors.getSettingsValid(state);
    return {
        ...settings,
        loaded,
        isValid,
    };
};

// TODO: save
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSettings: Actions.fetchSettings,
        updateDefaultRest: Actions.updateDefaultRest,
        updateDefaultNotes: Actions.updateDefaultNotes,
        updateDefaultAutoregulation: Actions.updateDefaultAutoregulation,
        updateDefaultSensorTracking: Actions.updateDefaultSensorTracking,
        updateDefaultNumReps: Actions.updateDefaultNumReps,
        updateDefaultAMRAP: Actions.updateDefaultAMRAP,
        updateDefaultMinSets: Actions.updateDefaultMinSets,
        updateDefaultMaxSets: Actions.updateDefaultMaxSets,
        updateDefaultSetCountFixed: Actions.updateDefaultSetCountFixed,
        updateDefaultWeight: Actions.updateDefaultWeight,
        updateDefaultWeightUnit: Actions.updateDefaultWeightUnit,
        updateDefaultAutoregulationTarget: Actions.updateDefaultAutoregulationTarget,
        updateDefaultAutoregulationRange: Actions.updateDefaultAutoregulationRange,
        updateDefaultAutoregulationQuantifier: Actions.updateDefaultAutoregulationQuantifier,
        updateDefaultAutoregulationMetric: Actions.updateDefaultAutoregulationMetric,
        saveDefaults: Actions.saveDefaults,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
