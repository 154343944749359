import React, {Component} from 'react';
import moment from 'moment';
import Select from 'react-select';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';

import styles from './styles.module.scss';
import Spinner from 'shared_features/spinner';

export const selectStyles = {
    control: () => ({
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center',
        boxSizing: 'border-box',
    }),
    container: () => ({
        position: 'relative',
    }),
    singleValue: () => ({
        marginLeft: 4,
        color: '#333333',
        fontSize: 14,
        fontWeight: 'regular',
    }),
    input: () => ({
        marginLeft: 4,
        color: '#333333',
        fontSize: 14,
        fontWeight: 'regular',
    }),
    indicatorSeparator: (styles) => ({display:'none'}),
};

const renderTitle = props => {
    if (props.failed) {
        return <h1>Error</h1>
    }
    if (!props.isRequesting && props.conflicts.length > 0) {
        return <h1>Conflict</h1>
    }
    if (props.isCreating) {
        return <h1>Schedule program</h1>
    }
    if (props.isEditing) {
        return <h1>Edit Schedule</h1>
    }
    return null;
};

const renderSubtitle = props => {
    if (!props.isEditing) {
        return;
    }
    return (
        <div className={styles.subtitle}>
            <div>{props.editingProgramName}</div>
            <div>{props.editingProgramAssignee}</div>
        </div>
    );
};

const renderDates = props => {
    if (props.failed || (!props.isCreating && !props.isEditing) || props.conflicts.length > 0) {
        return null;
    }

    return (
        <div className={styles.dates}>
            <DatePicker
                className={styles.datePicker}
                selected={moment(props.startDate).hour(12).toDate()}
                placeholderText="Start Date"
                onChange={(e) => props.updateStartDate(moment(e).format('YYYY-MM-DD'))} />
            <span>through</span>
            <DatePicker
                className={styles.datePicker}
                selected={moment(props.endDate).hour(12).toDate()}
                placeholderText="End Date"
                onChange={(e) => props.updateEndDate(moment(e).format('YYYY-MM-DD'))} />
        </div>
    );
};

const renderAssignees = props => {
    if (props.failed || !props.isCreating || props.conflicts.length > 0) {
        return null;
    }

    // values
    var value = [];
    props.assignees.forEach(assignee => {
        const search = props.assignOptions.filter(o => o.value === assignee);
        if (search.length > 0) {
            value.push(search[0]);
        }
    });
    return (
        <Select
            name="assignees"
            isMulti
            className={styles.select}
            styles={selectStyles}
            value={value}
            isClearable={false}
            deleteRemoves={false}
            onChange={(selectedOption) => {
                // vars
                const result = [];

                if (selectedOption) {
                    // vars
                    const resultMap = {};

                    // loop
                    selectedOption.forEach(o => {
                        if (o.child_values) {
                            // add groups of team instead of the team itself
                            o.child_values.forEach(c => {
                                if (!resultMap[c]) {
                                    resultMap[c] = true;
                                    result.push(c);
                                }
                            });
                        } else if (!resultMap[o.value]) {
                            // add the group or athlete
                            resultMap[o.value] = true;
                            result.push(o.value);
                        }
                    });
                }
                props.updateAssignees(result);
            }}
            options={props.assignOptions}
        />
    );
};

const renderText = props => {
    if (props.failed) {
        return (
            <div>
                <p>We were unable to schedule one or more of your selected groups due to a technical issue.</p>
                <p>Check your connection and try again.</p>
            </div>
        );
    }
    if (!props.isRequesting && props.conflicts.length > 0) {
        var result = props.conflicts.map(conflict => {
            return (
                <div>
                    <div className={styles.errorAssignee}><img src='https://assets.reponestrength.com/schedule_error.png' />{conflict.assignee}</div>
                    <div className={styles.errorDescription}>has {conflict.programName} {conflict.programStartDate} - {conflict.programEndDate}</div>
                </div>
            )
        });
        if (props.succeeded) {
            result.push(<div><img src='https://assets.reponestrength.com/schedule_success.png' />All others successfully scheduled</div>);
        }
        return result;
    }
    return null;
};

const renderSpinner = props => {
    // TODO: spinner should take over the entire popup and prevent interactions
    if (!props.isRequesting) {
        return null;
    }
    return <Spinner />
};

const renderOptions = props => {
    if (props.failed || (!props.isRequesting && props.conflicts.length > 0)) {
        return (
            <div className={styles.optionsContainer}>
                <div className={styles.doneButton} onClick={()=>props.closeModal()}>Done</div>
            </div>
        );
    }
    if (props.isEditing) {
        return (
            <div className={styles.optionsContainer}>
                <div className={styles.doneButton} onClick={()=>props.saveSchedule()}>Done</div>
                <div className={styles.deleteButton} onClick={()=>window.confirm("Are you sure?") && props.deleteSchedule()}>REMOVE</div>
            </div>
        );
    }
    if (props.isCreating) {
        return (
            <div className={styles.optionsContainer}>
                <div className={styles.doneButton} onClick={()=>props.createSchedule(props.assignees.length)}>Done</div>
            </div>
        );
    }
};

export default class View extends Component {

    componentDidMount() {
        this.props.fetchAthletes();
        this.props.fetchTeams();
    }

    render() {
        return (
            <Modal
                className={styles.modal}
                overlayClassName={styles.overlay}
                isOpen={this.props.isCreating || this.props.isEditing}
                onRequestClose={()=>this.props.closeModal()}>
                {renderTitle(this.props)}
                {renderSubtitle(this.props)}
                {renderDates(this.props)}
                {renderAssignees(this.props)}
                {renderText(this.props)}
                {renderOptions(this.props)}
                {renderSpinner(this.props)}
            </Modal>
        );
    }

};
