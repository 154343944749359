import {
    UPDATE_ATHLETE_HISTORY_FILTER,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    selectedDate: null,
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case UPDATE_ATHLETE_HISTORY_FILTER:
            return {
                ...state,
                selectedDate: action.date.clone(),
            };
        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
