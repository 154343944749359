import {
    ATTEMPT_FETCH_ATHLETES,
    ATTEMPT_FETCH_TEAMS,
    UPDATE_PROGRAM_SCHEDULE_START_DATE,
    UPDATE_PROGRAM_SCHEDULE_END_DATE,
    UPDATE_PROGRAM_SCHEDULE_ASSIGNEES,
    ATTEMPT_CREATE_PROGRAM_SCHEDULES,
    ATTEMPT_DELETE_PROGRAM_SCHEDULE,
    ATTEMPT_SAVE_PROGRAM_SCHEDULE,
    CLOSE_PROGRAM_SCHEDULE,
} from 'shared_redux/actions';

export const fetchAthletes = () => ({ type: ATTEMPT_FETCH_ATHLETES });

export const fetchTeams = () => ({ type: ATTEMPT_FETCH_TEAMS });

export const updateStartDate = (startDate) => ({
    type: UPDATE_PROGRAM_SCHEDULE_START_DATE,
    startDate,
});

export const updateEndDate = (endDate) => ({
    type: UPDATE_PROGRAM_SCHEDULE_END_DATE,
    endDate, 
});

export const updateAssignees = (assignees) => ({
    type: UPDATE_PROGRAM_SCHEDULE_ASSIGNEES,
    assignees,
});

export const deleteSchedule = () => ({ type: ATTEMPT_DELETE_PROGRAM_SCHEDULE });

export const createSchedule = (numRequests) => ({
    type: ATTEMPT_CREATE_PROGRAM_SCHEDULES,
    numRequests
});

export const saveSchedule = () => ({ type: ATTEMPT_SAVE_PROGRAM_SCHEDULE });

export const closeModal = () => ({ type: CLOSE_PROGRAM_SCHEDULE });
