import { createSelector } from 'redux-orm';
import moment from 'moment';
import orm from 'shared_redux/reducers/models/orm';

export const getRacks = createSelector(
    orm,
    state => state.database,
    session => {
        return session.Racks.all().orderBy('number', 'asc').toRefArray();
    }
);

export const getNumRecommendUpdates = createSelector(
    orm,
    state => state.database,
    session => {
        const cutoffDate = moment().add(1, 'week');
        return session.Racks.all().filter(r => r.expiration !== null && moment(r.expiration).isAfter(cutoffDate)).count();
    }
);

export const getNumImminentUpdates = createSelector(
    orm,
    state => state.database,
    session => {
        const today = moment();
        const cutoffDate = moment().add(1, 'week');
        return session.Racks.all().filter(r => r.expiration !== null && moment(r.expiration).isAfter(today) && moment(r.expiration).isBefore(cutoffDate)).count();
    }
);

export const getNextExpiration = createSelector(
    orm,
    state => state.database,
    session => {
        // get racks
        const today = moment();
        const cutoffDate = moment().add(1, 'week');
        const racks = session.Racks.all().filter(r => r.expiration !== null && moment(r.expiration).isAfter(today) && moment(r.expiration).isBefore(cutoffDate)).toRefArray();

        let date = null;
        racks.forEach(r => {
            const checkDate = moment(r.expiration);
            if (!date || checkDate.isBefore(date)) {
                date = checkDate;
            }
        });

        return date !== null ? moment.duration(date.diff(today)).humanize() : null;
    }
);

export const getNumCriticalUpdates = createSelector(
    orm,
    state => state.database,
    session => {
        const today = moment();
        const wtf = moment("2020-02-18T01:59:08.587Z");
        // const wtf = moment("2020-02-17 20:59:08.587-05");
        console.log(`is expiration ${wtf} before ${today} ${wtf.isBefore(today)} diff ${wtf.diff(today)}`);
        return session.Racks.all().filter(r => r.expiration !== null && moment(r.expiration).isBefore(today)).count();
    }
);
