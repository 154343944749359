import moment from 'moment';
import { createSelector } from 'redux-orm';
import orm from 'shared_redux/reducers/models/orm';
import * as Calculator from 'math/autoregulation_calculator';
import AutoregulationTypes from 'constants/autoregulationTypes';
import FeedbackTypes from 'constants/feedbackTypes';

const stateRoot = (state) => state.feed;

export const getIsConnected = (state) => stateRoot(state).connected;

export const getUpdatedTime = (state) => stateRoot(state).updated;

export const getTickTime = (state) => stateRoot(state).tickTime;

export const getSelectedGroupFilter = (state) => stateRoot(state).selectedGroupFilter;

export const getSelectedTeamFilter = (state) => stateRoot(state).selectedTeamFilter;

// this is the core view models minus human readable timing information
const getViewModelsMinusTime = createSelector(
    orm,
    state => state.database,
    getSelectedTeamFilter,
    getSelectedGroupFilter,
    (session, team, group) => {
        const { FeedItems } = session;

        // return models
        const feedItems = FeedItems
            .all()
            .orderBy('time', 'desc')
            .toModelArray()
            .filter(feedItem => {
                // NOTE: filter must be AFTER model array as it checks a relationship and not an immediate property
                if (team && feedItem.athlete.team_id !== team) {
                    return false;
                }
                if (group && feedItem.athlete.group_id !== group) {
                    return false;
                }
                return true;
            });

        return feedItems.map(feedItem => {
            if (feedItem.feed_entry_item) {
                return mapFeedEntryItem(feedItem);
            } else if (feedItem.feed_complete_item) {
                return mapFeedCompleteItem(feedItem);
            } else {
                return null;
            }
        });
    }
);

// this adds time to the view models
export const getViewModels = createSelector(
    orm,
    state => state.database,
    getViewModelsMinusTime, // the data
    getTickTime, // to refresh timing
    getUpdatedTime, // to refresh timing
    (session, viewModels, tickTime, updatedTime) => {
        return viewModels.map(model => {
            const seconds = moment().diff(moment(model._time), 'seconds');
            if (seconds > 3600) {
                const hours = parseInt(seconds / 3600);
                model.time = `${hours}h ago`
            } else if (seconds > 60) {
                const minutes = parseInt(seconds / 60);
                model.time = `${minutes}m ago`
            } else if (seconds === 0) {
                model.time = 'now';
            } else {
                model.time = `${seconds}s ago`;
            }
            return model;
        });
    }
);

// TODO: fail gracefully rather than assuming all models are accessible
// easier if there's typescript to catch me
// TODO: This needs to account for how the local models are a BAG form, so even non autoregulation can "have" a velocity when it shouldn't
const mapFeedEntryItem = (feedItem) => {
    // variables
    const entry = feedItem.feed_entry_item.entry;
    const reps = entry.reps.filter(r => r.removed === false).toRefArray();
    const plan = entry.plan;
    const athlete = feedItem.athlete;
    const exercise = plan.exercise;

    // target
    const targetReps = plan.amrap ? 'AMRAP' : `${plan.num_reps}`;
    const targetLoad = plan.weight && plan.weight_unit ? ` x ${plan.weight} ${plan.weight_unit}` : ''; // TODO: consider calculating the actual load instead of having it at a %

    // reps
    let repCount = 0;
    if (entry.manual_rep_count) {
        repCount = entry.manual_rep_count;
    } else if (reps && reps.length > 0) {
        repCount = reps.length;
    } else if (plan.num_reps) {
        repCount = plan.num_reps;
    } else {
        console.log(`Error getting number of reps for feed entry item`);
    }

    // load
    const load = entry.weight && entry.weight_unit ? ` x ${entry.weight} ${entry.weight_unit}` : '';

    // rpe
    const rpe = entry.rpe ? ` ${entry.rpe} RPE` : '';

    if (plan.autoregulation !== AutoregulationTypes.NO_AUTOREGULATION) {
        // type
        var type = "autoregulated";
        
        // target
        var targetVelocity = ` @ ${plan.autoregulation_target} m/s`;

        // velocity
        const calculatedVelocityUsed = Calculator.entryVelocity(plan, reps);
        if (calculatedVelocityUsed !== null) {
            const velocityUsed = (calculatedVelocityUsed.value / 1000).toFixed(2);
            var velocity = ` @ ${velocityUsed} m/s`;
        } else {
            var velocity = '';
        }

        // feedback (including failed)
        const f = Calculator.entryFeedback(plan, entry, reps);
        if (!f) {
            // Occurs if the data is in a transitory state, where a subsequent update will fix the feed
            var feedback = null;
            var feedbackDescription = null;
        } else {
            var feedback = f.feedback;
            var feedbackDescription = null;
            switch (feedback) {
                case FeedbackTypes.FAILED:
                    feedbackDescription = 'Failed';
                    break;
                case FeedbackTypes.KEEP:
                    feedbackDescription = 'In Range';
                    break;
                case FeedbackTypes.RAISE:
                    feedbackDescription = 'Fast';
                    break;
                case FeedbackTypes.LOWER:
                    feedbackDescription = 'Slow';
                    break;
                default:
                    break;
            }
        }
        // avg or peak velocity
        var autoregulationMetric = plan.autoregulation_metric === 'AVERAGE_VELOCITY' ? 'Avg. velocity' : 'Peak velocity';
    } else {
        var type = "manual;"
        var targetVelocity = '';
        var velocity = '';
        var feedback = null;
        var autoregulationMetric = null;
    }

    // rep description
    const repDescription = load || velocity ? '' : ' reps';

    return {
        id: feedItem.id,
        type,
        avatar_url: athlete.avatar_url,
        name: athlete.displayName(),
        exercise: `${exercise.name} #${entry.order}`,
        target: `Target: ${targetReps}${targetLoad}${targetVelocity}`,
        data: `${repCount}${repDescription}${load}${velocity}${rpe}`,
        feedback,
        feedbackDescription,
        autoregulationMetric,
        _time: feedItem.time,
    };
};

const mapFeedCompleteItem = (feedItem) => {
    // variables
    const athleteSession = feedItem.athlete_session;
    const athlete = feedItem.athlete;

    // model
    let model = {
        id: feedItem.id,
        type: 'complete',
    };

    // time
    model._time = feedItem.time;

    // percentage
    model.percentage = feedItem.feed_complete_item.percentage;

    // athlete
    model.avatar_url = athlete.avatar_url;
    model.name = athlete.displayName();

    // program
    model.programName = athleteSession.program_name;
    model.sessionDescription = `Week ${athleteSession.microcycle_number} Session ${athleteSession.session_number}`;

    return model;
};
