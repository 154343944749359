import AutoregulationTypes from 'constants/autoregulationTypes';
import FeedbackTypes from 'constants/feedbackTypes';

// TODO: for future autoregulation, this should eventually have a more extensible system to calculate
// aka you might want the minimum ROM for example, and this should handle that
// see how OpenBarbell calculates it for example?

export const entryFeedback = (plan, entry, reps) => {
    if (reps.some(rep => rep.failed)) {
        return {
            feedback: FeedbackTypes.FAILED,
            isWarmup: plan.autoregulation !== AutoregulationTypes.WEIGHT_AUTOREGULATION,
        };
    }

    // keep and complete always counts
    if (entry.autoregulation_feedback === FeedbackTypes.KEEP || entry.autoregulation_feedback === FeedbackTypes.COMPLETED) {
        return {
            feedback: FeedbackTypes.KEEP,
            isWarmup: false,
        };
    }

    // raise is always too light
    if (entry.autoregulation_feedback === FeedbackTypes.RAISE) {
        return {
            feedback: FeedbackTypes.RAISE,
            isWarmup: true,
        };
    }

    // lower depends on autoregulation type
    if (entry.autoregulation_feedback === FeedbackTypes.LOWER) {
        return {
            feedback: FeedbackTypes.LOWER,
            isWarmup: plan.autoregulation !== AutoregulationTypes.WEIGHT_AUTOREGULATION,
        };
    }

     console.log(`reached impossible state for entry ${JSON.stringify(entry)}`);
     return null;


    // NOTE: The following should never be reached
    // This is legacy from back when entries did NOT have autoregulation feedback. It only stored feedback if it was overriden
    // It ended up being too cumbersome so feedback is now appended to all entries. Hence the following should never be needed

    // no reps, can't get feedback
    if (reps.length <= 0) {
        return null;
    }

    // check last rep
    if (isLastRep(plan)) {
        const rep = reps[reps.length-1];
        return repFeedback(plan, rep);
    }

    // check slowest rep
    if (isSlowestRep(plan)) {
        const rep = slowestRep(plan, reps);
        if (!rep) {
            // error, no reps, or there was a failed rep
            return null;
        }
        return repFeedback(plan, rep);
    }

    // TODO: other types, like peak end

    return null;
};

// TODO: consider making this metric in general rather than specific to velocity
// null if invalid or removed or failed
export const entryVelocity = (plan, reps) => {
    // valid check
    if (plan.autoregulation === AutoregulationTypes.NO_AUTOREGULATION || reps.length <= 0) {
        return null;
    }

    // rep
    var rep = null;
    if (isLastRep(plan)) {
        rep = reps[reps.length-1];
    } else if (isSlowestRep(plan)) {
        rep = slowestRep(plan, reps);
    }
    if (!rep || !rep.valid || rep.failed || rep.removed) {
        return null;
    }

    // autoregulation metric
    const autoregulationMetric = plan.autoregulation_metric;

    // value 
    if (autoregulationMetric === 'AVERAGE_VELOCITY') {
        return { type: autoregulationMetric, value: rep.average_velocity };
    }
    if (autoregulationMetric === 'PEAK_VELOCITY') {
        return { type: autoregulationMetric, value: rep.peak_velocity };
    }
    return null;
};

export const entryLoadLbs = (entry) => {
    if (!entry.weight) {
        return null;
    }
    if (entry.weight_unit === 'KGS') {
        return (entry.weight * 2.20462).toFixed(2);
    }
    return entry.weight;
};

export const repsDisplayValues = (plan, reps) => {
    if (plan.autoregulation !== AutoregulationTypes.NO_AUTOREGULATION) {
        var target = plan.autoregulation_target;
        var range = plan.autoregulation_range;
        var autoregulationMetric = plan.autoregulation_metric;
    }

    return reps.map(rep => {
        if (rep.failed) {
            return { id: rep.id, text: 'FAIL', status: 'failed' };
        }
        if (!rep.valid) {
            return { id: rep.id, text: 'INVALID', status: 'invalid' };
        }
        if (autoregulationMetric === 'AVERAGE_VELOCITY') {
            // average
            var velocity = rep.average_velocity;
        } else {
            // peak
            var velocity = rep.peak_velocity;
        }

        var inRange = velocityInRange(velocity, target, range);
        return {
            id: rep.id,
            text: velocity,
            status: inRange === 0 ? 'inside' : 'outside'
        };
    });
};

const isLastRep = (plan) => {
    if (plan.autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return false;
    }
    return plan.autoregulation_quantifier === 'LAST';
};

const isSlowestRep = (plan) => {
    if (plan.autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return false;
    }
    return plan.autoregulation_quantifier === 'MIN';
};

const slowestRep = (plan, reps) => {
    if (reps.length <= 0) {
        return null;
    }

    const autoregulationMetric = plan.autoregulation_metric;
    var min = reps[0];
    for (var rep of reps) {
        // there's a failed rep, choose that one instead
        if (rep.failed) {
            return rep;
        }

        if (autoregulationMetric === 'AVERAGE_VELOCITY') {
            // average
            if (rep.average_velocity < min.average_velocity) {
                min = rep;
            }
        } else {
            // peak
            if (rep.peak_velocity < min.peak_velocity) {
                min = rep;
            }
        }
    }

    return min;
};

// -2 -1 0 and 1 for lower raise and keep
// null if it's unknown or invalid as cannot process
const repFeedback = (plan, rep) => {
    // cannot process invalid, always ignore
    if (!rep.valid) {
        return null;
    }

    // failed reps always lower, but count is based on autoregulation type
    if (rep.failed) {
        var feedback = -2;
    } else {
        // check if in range
        if (plan.autoregulation !== AutoregulationTypes.NO_AUTOREGULATION) {
            var target = plan.autoregulation_target;
            var range = plan.autoregulation_range;
            if (plan.autoregulation_metric === 'AVERAGE_VELOCITY') {
                // average
                var velocity = rep.average_velocity;
            } else {
                // peak
                var velocity = rep.peak_velocity;
            }
        } else {
            // unknown autoregulation type
            return null;
        }

        // generate feedback and is warmup
        var feedback = velocityInRange(velocity, target, range);
    }

    if (plan.autoregulation == AutoregulationTypes.WEIGHT_AUTOREGULATION) {
        var isWarmup = feedback === 1;
    } else if (plan.autoregulation == AutoregulationTypes.WEIGHT_AUTOREGULATION_SPEED) {
        var isWarmup = feedback !== 0;
    }
    return {
        feedback,
        isWarmup,
    };
};

const velocityInRange = (velocity, target, range) => {
    if (velocity < target - range) {
        return -1;
    }
    if (velocity > target + range) {
        return 1;
    }
    return 0;
};
