import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as ProgramSchedulerPopupSelectors from 'shared_redux/selectors/view/program_scheduler_popup';

const mapStateToProps = (state, props) => ({
    isCreating: ProgramSchedulerPopupSelectors.getIsCreating(state, props), 
    isEditing: ProgramSchedulerPopupSelectors.getIsEditing(state, props), 
    editingProgramName: ProgramSchedulerPopupSelectors.getEditingProgramName(state, props),
    editingProgramAssignee: ProgramSchedulerPopupSelectors.getEditingProgramAssignee(state, props),
    assignOptions: ProgramSchedulerPopupSelectors.getAssignOptions(state, props),
    startDate: ProgramSchedulerPopupSelectors.getStartDate(state, props), 
    endDate: ProgramSchedulerPopupSelectors.getEndDate(state, props), 
    assignees: ProgramSchedulerPopupSelectors.getAssignees(state, props), 
    conflicts: ProgramSchedulerPopupSelectors.getConflicts(state, props),
    isRequesting: ProgramSchedulerPopupSelectors.getIsRequesting(state, props), 
    succeeded: ProgramSchedulerPopupSelectors.getSucceeded(state, props),
    failed: ProgramSchedulerPopupSelectors.getFailed(state, props), 
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAthletes: Actions.fetchAthletes,
        fetchTeams: Actions.fetchTeams,
        updateStartDate: Actions.updateStartDate,
        updateEndDate: Actions.updateEndDate,
        updateAssignees: Actions.updateAssignees,
        deleteSchedule: Actions.deleteSchedule,
        createSchedule: Actions.createSchedule,
        saveSchedule: Actions.saveSchedule,
        closeModal: Actions.closeModal,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
