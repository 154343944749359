import React, { Component } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Validator from 'validator';
import _ from 'lodash';

import AutoregulationTypes from 'constants/autoregulationTypes';
import BlockTypes from 'constants/blockTypes';
import DragItemTypes from 'constants/dragItemTypes';
import WeightUnits from 'constants/weightUnits';
import AutoregulationQuantifiers from 'constants/autoregulationQuantifiers';
import AutoregulationMetrics from 'constants/autoregulationMetrics';

import {
    ProgramBuilderComponent,
    ProgramBuilderHeader,
    ProgramBuilderMenu,
    ProgramBuilderMenuItem,
} from '../program_builder_component';
import styles from './styles.module.scss';

export const selectStyles = {
    control: () => ({
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        height: 48,
        alignItems: 'center',
        boxSizing: 'border-box',
    }),
    container: () => ({
        position: 'relative',
    }),
    singleValue: () => ({
        position: 'absolute',
        marginTop: 8,
        marginLeft: 4,
        color: '#333333',
        maxWidth: 217,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: 14,
        fontWeight: 'regular',
    }),
    input: () => ({
        marginTop: 14,
        marginLeft: 4,
        color: '#333333',
        fontSize: 14,
        fontWeight: 'regular',
    }),
    placeholder: () => ({
        marginTop: 14,
        marginLeft: 4,
        color: '#333333',
        fontSize: 14,
        fontWeight: 'regular',
    }),
    indicatorSeparator: (styles) => ({display:'none'}),
};

export default class PlanComponent extends Component {

    insertBefore() {
        this.props.insertPlan(this.props.plan.block_id, this.props.plan.order);
    }

    insertAfter() {
        this.props.insertPlan(this.props.plan.block_id, this.props.plan.order+1);
    }

    render() {
        const props = this.props;

        // TODO: cannot remove top border on drag anymore as isDragging is owned by ProgramBuilderComponent
        // ideally find some way to add that functionality back so it looks nicer
 
        let rowStyle = styles.plan;
        if (!props.isValid) {
            rowStyle = [rowStyle, styles.invalid].join(' ');
        }

        return (
            <ProgramBuilderComponent
                parentType={DragItemTypes.PLAN}
                id={props.plan.id}
                parentId={props.plan.block_id}
                order={props.plan.order}
                isReordering={props.isReordering}
                finishDrag={props.finishDrag}
                updateHoverIndicator={props.updateHoverIndicator}
                hoverIndicatorTopOffset={-2}
                hoverIndicatorBottomOffset={-2}>

                <ProgramBuilderHeader
                    hoverIndicatorOffset={0}
                    dropParentHandler={props.movePlan}
                    insertBeforeHandler={this.insertBefore.bind(this)}
                    insertAfterHandler={this.insertAfter.bind(this)}>

                    <div className={styles.planRow}>
                        <div className={styles.topBorder} />
                        <div className={rowStyle}>
                            {renderMovement(props)}
                            {renderAutoregulation(props)}
                            {renderSensorTracking(props)}
                            {renderMinSets(props)}
                            {renderMaxSets(props)}
                            {renderNumReps(props)}
                            {renderLoad(props)}
                            {renderAutoregulationMetric(props)}
                            {renderAutoregulationQuantifier(props)}
                            {renderAutoregulationTarget(props)}
                            {renderAutoregulationRange(props)}
                            {renderSpeedWork(props)}
                            {/* {renderRest(props)} */}
                        </div>
                    </div>

                </ProgramBuilderHeader>

                <ProgramBuilderMenu>
                    <ProgramBuilderMenuItem onClick={()=>props.duplicatePlan(props.id)}>
                        DUPLICATE
                    </ProgramBuilderMenuItem>
                    <ProgramBuilderMenuItem onClick={this.insertBefore.bind(this)} rightClickOnly={true}>
                        INSERT BEFORE
                    </ProgramBuilderMenuItem>
                    <ProgramBuilderMenuItem onClick={this.insertAfter.bind(this)} rightClickOnly={true}>
                        INSERT AFTER
                    </ProgramBuilderMenuItem>
                    <ProgramBuilderMenuItem onClick={()=>props.deletePlan(props.id)}>
                        DELETE
                    </ProgramBuilderMenuItem>
                </ProgramBuilderMenu>

            </ProgramBuilderComponent>
        );
    }

}

const renderMovement = props => {
    let value = null;
    let exercises = [];
    for (var exercise of props.exercises) {
        const option = { value: exercise.id, label: exercise.name };
        exercises.push(option);
        if (exercise.id === props.plan.exercise_id) {
            value = option;
        }
    }

    return (
        <div className={styles.movementContainer}>
            <CreatableSelect
                name="exercise"
                value={value}
                className={styles.select}
                styles={selectStyles}
                isClearable={false}
                deleteRemoves={false}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        if (!Validator.isUUID(selectedOption.value)) {
                            props.createExercise(props.organization_id, selectedOption.value, props.id);
                        } else {
                            props.updatePlanExercise(props.id, selectedOption.value);
                        }
                    }
                }}
                options={exercises}
            />
            <label>MOVEMENT</label>
        </div>
    );
};

const renderAutoregulation = props => {
    // TODO: consider having these passed in instead of hardcoding them in the view
    const types = [
        { value: AutoregulationTypes.NO_AUTOREGULATION, label: 'None' },
        { value: AutoregulationTypes.WEIGHT_AUTOREGULATION, label: 'Load' }, // this accounts for weight and weight_speed
    ];

    if (props.plan.autoregulation && props.plan.autoregulation === types[0].value) {
        // no autoregulation
        var value = types[0];
    } else {
        // is either weight or weight speed
        var value = types[1];
    }

    return (
        <div className={styles.autoregulationContainer}>
            <Select
                name="autoregulation"
                value={value}
                className={styles.select}
                styles={selectStyles}
                clearable={false}
                deleteRemoves={false}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        // TODO: ideally setting it again shouldn't run it unless it changed, as otherwise it may flip the speed work vlue
                        props.updatePlanAutoregulation(props.id, selectedOption.value);
                    }
                }}
                options={types}
            />
            <label>REGULATE</label>
        </div>
    );
};

const renderSensorTracking = props => {
    // only for no auto sets
    if (props.plan.autoregulation !== AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    const types = [
        { value: 'sensor', label: 'Sensor' },
        { value: 'manual', label: 'Manual' },
    ];
    const value = props.plan.sensor_tracking ? types[0] : types[1];
    return (
        <div className={styles.sensorContainer}>
            <Select
                name="sensor_tracking"
                value={value}
                className={styles.select}
                styles={selectStyles}
                clearable={false}
                deleteRemoves={false}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        props.updatePlanSensorTracking(props.id, selectedOption.value === 'sensor');
                    }
                }}
                options={types}
            />
            <label>TRACKING</label>
        </div>
    );
};

const renderMinSets = props => {
    // only for standard blocks
    if (props.blockType !== BlockTypes.STANDARD) {
        return null;
    }

    if (props.plan.autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return (
            <div className={styles.minSetsContainer}>
                <input type="number"
                    value={props.plan.min_sets}
                    placeholder=""
                    onChange={(event) => props.updatePlanMinSets(props.id, parseInt(event.target.value))}
                    onFocus={(event) => event.target.select()}
                    onBlur={() => props.savePlan(props.id)} />
                <label>SETS</label>
            </div>
        );
    } else {
        return (
            <div className={styles.minSetsWithToggleContainer}>
                <div>
                    <input type="number"
                        value={props.plan.min_sets}
                        placeholder=""
                        onChange={(event) => props.updatePlanMinSets(props.id, parseInt(event.target.value))}
                        onFocus={(event) => event.target.select()}
                        onBlur={() => props.savePlan(props.id)} />
                    <label>MIN SETS</label>
                </div>
                <div>
                    <div onClick={()=>props.updatePlanSetCountFixed(props.id, !props.plan.set_count_fixed)}>
                        <p>{props.plan.set_count_fixed ? "FIXED" : 'RANGE'}</p>
                    </div>
                </div>
            </div>
        );
    }
};

const renderMaxSets = props => {
    // do not show for non autoreg / non standard blocks / fixed
    if (props.plan.autoregulation === AutoregulationTypes.NO_AUTOREGULATION || props.blockType !== BlockTypes.STANDARD || props.plan.set_count_fixed === true) {
        return null;
    }

    return (
        <div className={styles.maxSetsContainer}>
            <input type="number"
                value={props.plan.max_sets || ''}
                placeholder="None"
                onChange={(event)=> props.updatePlanMaxSets(props.id, event.target.value ? parseInt(event.target.value) : null)}
                onFocus={(event) => event.target.select()}
                onBlur={() => props.savePlan(props.id)} />
            <label>MAX SETS</label>
        </div>
    );
};

const renderNumReps = props => {
    let repDisplay = null;
    if (props.plan.amrap) {
        repDisplay = (<div>N/A</div>)
    } else {
        repDisplay = (
            <input type="number"
                value={props.plan.num_reps}
                placeholder=""
                onChange={(event) => props.updatePlanNumReps(props.id, parseInt(event.target.value))}
                onFocus={(event) => event.target.select()}
                onBlur={() => props.savePlan(props.id)} />
        );
    }

    return (
        <div className={styles.repsContainer}>
            <div>
                {repDisplay}
                <label>REPS</label>
            </div>
            <div>
                <div onClick={()=>props.updatePlanAMRAP(props.id, !props.plan.amrap, props.plan.num_reps)}>
                    <p>{props.plan.amrap ? "AM RAP" : '#'}</p>
                </div>
            </div>
        </div>
    );
};

const renderLoad = props => {
    // weight unit
    if (!props.plan.weight_unit) {
        // quick hack fix, if it's null then it'll display null, but tapping on it will set it to kgs at least
        // note: this shouldn't be necessary anymore as metric is now required, but leaving it here for safety
        var weight_unit = WeightUnits[0];
    } else {
        switch (props.plan.weight_unit) {
            case WeightUnits[0]:
                var weight_unit = WeightUnits[1];
                break;
            case WeightUnits[1]:
                var weight_unit = WeightUnits[2];
                break;
            case WeightUnits[2]:
                var weight_unit = WeightUnits[0];
                break;
        }
    }

    // label
    if (props.plan.autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        var label = 'LOAD';
        var style = styles.loadContainer;
    } else {
        var label = 'START LOAD';
        var style = styles.startLoadContainer;
    }

    return (
        <div className={style}>
            <div>
                 <input type="number"
                    value={props.plan.weight === null ? '' : props.plan.weight}
                    placeholder="Any"
                    onChange={(event) => props.updatePlanWeight(props.id, event.target.value === '' ? null : parseFloat(event.target.value))}
                    onFocus={(event) => event.target.select()}
                    onBlur={() => props.savePlan(props.id)} />
                <label>{label}</label>
            </div>
            <div>
                <div onClick={()=>props.updatePlanWeightUnit(props.id, weight_unit)}>
                    <p>{props.plan.weight_unit}</p>
                </div>
            </div>
        </div>
    );
};

const renderAutoregulationQuantifier = props => {
    // only for no auto sets
    if (props.plan.autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    let value = null;
    let quantifiers = [];
    for (var quantifier of AutoregulationQuantifiers) {

        let option = { value: quantifier, label: _.startCase(_.toLower(quantifier.replace('%', 'percent')))};
        quantifiers.push(option);
        if (quantifier === props.plan.autoregulation_quantifier) {
            value = option;
        }
    }

    return (
        <div className={styles.quantifierContainer}>
            <Select
                name="autoregulationquantifier"
                value={value}
                className={styles.select}
                styles={selectStyles}
                clearable={false}
                deleteRemoves={false}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        props.updatePlanAutoregulationQuantifier(props.id, selectedOption.value);
                    }
                }}
                options={quantifiers}
            />
            <label>{_.toUpper(props.plan.autoregulation_metric.replace('_', ' '))} METRIC</label>
        </div>
    );
};

const renderAutoregulationTarget = props => {
    // only for no auto sets
    if (props.plan.autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    return (
        <div className={styles.targetContainer}>
            <input type="number"
                value={props.plan.autoregulation_target}
                placeholder=""
                step={0.1}
                onChange={(event)=> props.updatePlanAutoregulationTarget(props.id, parseFloat(event.target.value))}
                onFocus={(event) => event.target.select()}
                onBlur={() => props.savePlan(props.id)} />
            <label>TARGET</label>
        </div>
    );
};

const renderAutoregulationRange = props => {
    // only for no auto sets
    if (props.plan.autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    return (
        <div className={styles.rangeContainer}>
            <label>RANGE</label>
            <input type="number"
                value={props.plan.autoregulation_range}
                placeholder=""
                step={0.1}
                onChange={(event)=> props.updatePlanAutoregulationRange(props.id, parseFloat(event.target.value))}
                onFocus={(event) => event.target.select()}
                onBlur={() => props.savePlan(props.id)} />
        </div>
    );
};

const renderAutoregulationMetric = props => {
    // only for no auto sets
    if (props.plan.autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    let value = null;
    let types = [];
    for (var type of AutoregulationMetrics) {
        const option = { value: type, label: _.startCase(_.toLower(type))};
        types.push(option);
        if (type === props.plan.autoregulation_metric) {
            value = option;
        }
    }

    return (
        <div className={styles.metricContainer}>
            <Select
                name="autoregulationmetric"
                value={value}
                className={styles.select}
                styles={selectStyles}
                clearable={false}
                deleteRemoves={false}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        props.updatePlanAutoregulationMetric(props.id, selectedOption.value);
                    }
                }}
                options={types}
            />
            <label>METRIC</label>
        </div>
    );
};

const renderSpeedWork = props => {
    // only for no auto sets
    if (props.plan.autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    return (
        <div className={styles.speedWorkContainer}>
            <input type="checkbox"
                checked={props.plan.autoregulation === AutoregulationTypes.WEIGHT_AUTOREGULATION_SPEED}
                onChange={(event)=> props.updatePlanAutoregulation(props.id, event.target.checked ? AutoregulationTypes.WEIGHT_AUTOREGULATION_SPEED : AutoregulationTypes.WEIGHT_AUTOREGULATION)} />
            <label>SPEEDWORK</label>
        </div>
    );
};

// const renderRest = props => {
//     return (
//         <input type="number"
//             value={props.plan.rest}
//             placeholder=""
//             onChange={(event)=> props.updatePlanRest(props.id, parseInt(event.target.value))}
//             onFocus={(event) => event.target.select()}
//             onBlur={() => props.savePlan(props.id)} />
//     );
// };
