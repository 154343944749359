import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    call,
} from 'redux-saga/effects';

import {
    ATTEMPT_CREATE_GROUP,
    CREATE_GROUP_SUCCEEDED,

    ATTEMPT_PATCH_GROUP,
    PATCH_GROUP_SUCCEEDED,

    EDIT_GROUP,
    LOADED_CACHED_GROUP,
    
    ATTEMPT_REMOVE_GROUP,
    REMOVE_GROUP_SUCCEEDED,
} from 'shared_redux/actions';

import orm from 'shared_redux/reducers/models/orm';

import API from 'services/groups';
import { handleError } from 'services/alerts';
import * as TeamsManagerSelectors from 'shared_redux/selectors/view/teams_manager';

export default function* GroupsSaga() {
    yield all([
        takeEvery(ATTEMPT_CREATE_GROUP, createGroup),
        takeEvery(ATTEMPT_PATCH_GROUP, patchGroup),
        takeEvery(ATTEMPT_REMOVE_GROUP, removeGroup),
        takeLatest(EDIT_GROUP, fetchCachedGroup),
    ]);
};

function *createGroup(action) {
    try {
        const json = yield select(TeamsManagerSelectors.getGroupJSON);
        const group = yield apply(API, API.createGroup, [json]);
        yield put({
            type: CREATE_GROUP_SUCCEEDED,
            payload: group,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to create group');
    }
}

function *patchGroup(action) {
    try {
        const json = yield select(TeamsManagerSelectors.getGroupJSON);
        const group = yield apply(API, API.updateGroup, [json]);
        yield put({
            type: PATCH_GROUP_SUCCEEDED,
            payload: group,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to patch group');
    }
}

function *removeGroup(action) {
    try {
        const id = yield select(TeamsManagerSelectors.getGroupId);
        yield apply(API, API.removeGroup, [id]);
        yield put({
            type: REMOVE_GROUP_SUCCEEDED,
            id: id,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to remove group');
    }
}

function *fetchCachedGroup(action) {
    const database = yield select(state => state.database);
    const { Groups } = orm.mutableSession(database);
    const group = Groups.withId(action.groupId);

    yield put({
        type: LOADED_CACHED_GROUP,
        group,
    });
}
