import {
    FETCH_ORGANIZATION_SETTINGS_ATTEMPT,

    UPDATE_DEFAULT_REST,
    UPDATE_DEFAULT_NOTES,
    UPDATE_DEFAULT_AUTOREGULATION,
    UPDATE_DEFAULT_SENSOR_TRACKING,
    UPDATE_DEFAULT_NUM_REPS,
    UPDATE_DEFAULT_AMRAP,
    UPDATE_DEFAULT_MIN_SETS,
    UPDATE_DEFAULT_MAX_SETS,
    UPDATE_DEFAULT_SET_COUNT_FIXED,
    UPDATE_DEFAULT_WEIGHT,
    UPDATE_DEFAULT_WEIGHT_UNIT,
    UPDATE_DEFAULT_AUTOREGULATION_TARGET,
    UPDATE_DEFAULT_AUTOREGULATION_RANGE,
    UPDATE_DEFAULT_AUTOREGULATION_QUANTIFIER,
    UPDATE_DEFAULT_AUTOREGULATION_METRIC,
    SAVE_DEFAULTS_ATTEMPT,
} from 'shared_redux/actions';

export const fetchSettings = () => ({ type: FETCH_ORGANIZATION_SETTINGS_ATTEMPT });

export const updateDefaultRest = rest => ({ type: UPDATE_DEFAULT_REST, rest });

export const updateDefaultNotes = notes => ({ type: UPDATE_DEFAULT_NOTES, notes });

export const updateDefaultAutoregulation = autoregulation => ({ type: UPDATE_DEFAULT_AUTOREGULATION, autoregulation });

export const updateDefaultSensorTracking = sensor_tracking => ({ type: UPDATE_DEFAULT_SENSOR_TRACKING, sensor_tracking });

export const updateDefaultNumReps = num_reps => ({ type: UPDATE_DEFAULT_NUM_REPS, num_reps });

export const updateDefaultAMRAP = amrap => ({ type: UPDATE_DEFAULT_AMRAP, amrap });

export const updateDefaultMinSets = min_sets => ({ type: UPDATE_DEFAULT_MIN_SETS, min_sets });

export const updateDefaultMaxSets = max_sets => ({ type: UPDATE_DEFAULT_MAX_SETS, max_sets });

export const updateDefaultSetCountFixed = set_count_fixed => ({ type: UPDATE_DEFAULT_SET_COUNT_FIXED, set_count_fixed });

export const updateDefaultWeight = weight => ({ type: UPDATE_DEFAULT_WEIGHT, weight });

export const updateDefaultWeightUnit = weight_unit => ({ type: UPDATE_DEFAULT_WEIGHT_UNIT, weight_unit });

export const updateDefaultAutoregulationTarget = autoregulation_target => ({ type: UPDATE_DEFAULT_AUTOREGULATION_TARGET, autoregulation_target });

export const updateDefaultAutoregulationRange = autoregulation_range => ({ type: UPDATE_DEFAULT_AUTOREGULATION_RANGE, autoregulation_range });

export const updateDefaultAutoregulationQuantifier = autoregulation_quantifier => ({ type: UPDATE_DEFAULT_AUTOREGULATION_QUANTIFIER, autoregulation_quantifier });

export const updateDefaultAutoregulationMetric = autoregulation_metric => ({ type: UPDATE_DEFAULT_AUTOREGULATION_METRIC, autoregulation_metric });

export const saveDefaults = () => ({ type: SAVE_DEFAULTS_ATTEMPT });