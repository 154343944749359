import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';

import * as HistorySessionsSelectors from 'shared_redux/selectors/view/history_sessions';
import * as AthleteHistorySelectors from 'shared_redux/selectors/view/athlete_history';

// expects
// id
// displayNumber
// athleteId
const makeMapStateToProps = () => {
    const getAthleteHistorySessions = AthleteHistorySelectors.makeGetAthleteHistorySession();
    return (state, props) => {
        return {
            ...getAthleteHistorySessions(state, props),
            expanded: HistorySessionsSelectors.getIsSessionExpanded(state, props.id),
        };
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        expand: Actions.expand,
        collapse: Actions.collapse,
    }, dispatch);
};

const Container = connect(
    makeMapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
