import {
    CLOSE_IMPORT_ATHLETES,
    ATTEMPT_IMPORT_ATHLETES,
}from 'shared_redux/actions';

export const closeModal = () => ({ type: CLOSE_IMPORT_ATHLETES });

export const importAthletes = csv => ({
    type: ATTEMPT_IMPORT_ATHLETES,
    csv,
});
