import {
    QUEUE_EXECUTING,
    QUEUE_ITEM_SUCCEEDED,
    QUEUE_ITEM_FAILED,

    EXPAND_MICROCYCLE,
    COLLAPSE_MICROCYCLE,

    EXPAND_MICROCYCLE_SESSION,
    COLLAPSE_MICROCYCLE_SESSION,

    INSERT_PLAN,
    CANCEL_INSERT_PLAN,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';
import { ATTEMPT_CREATE_PLAN } from '../../actions';

const defaultState = {
    // for showing PlanInserter after tapping on a +
    insert_plan_block_id: null,
    insert_plan_order: null,
    errorState: null, // null, "ERROR", "RETRYING"
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case QUEUE_ITEM_FAILED:
            return {
                ...state,
                errorState: 'ERROR',
            };
        case QUEUE_EXECUTING:
            if (state.errorState !== null) {
                return {
                    ...state,
                    errorState: 'RETRYING',
                };
            } else {
                return state;
            }
        case QUEUE_ITEM_SUCCEEDED:
            return {
                ...state,
                errorState: null,
            };
        // expand collapse
        case EXPAND_MICROCYCLE: {
            let obj = {
                ...state
            };
            obj[action.id] = false;
            return obj;
        }
        case EXPAND_MICROCYCLE_SESSION: {
            let obj = {
                ...state
            };
            obj[action.session_id] = false;
            return obj;
        }
        case COLLAPSE_MICROCYCLE: {
            let obj = {
                ...state
            };
            obj[action.id] = true;
            return obj;
        }
        case COLLAPSE_MICROCYCLE_SESSION: {
            let obj = {
                ...state
            };
            obj[action.session_id] = true;
            return obj;
        }

        // insert plan
        case INSERT_PLAN:
            return {
                ...state,
                insert_plan_block_id: action.block_id,
                insert_plan_order: action.order,
            };
        case ATTEMPT_CREATE_PLAN:
        case CANCEL_INSERT_PLAN:
            return {
                ...state,
                insert_plan_block_id: null,
                insert_plan_order: null,
            };

        // auth
        case SIGN_OUT_SUCCEEDED:
            return defaultState;
        default:
            return state;
    }
};
