import {
    CLOSE_CREATE_ATHLETE,
    UPDATE_ATHLETE_FIRST_NAME,
    UPDATE_ATHLETE_MIDDLE_NAME,
    UPDATE_ATHLETE_LAST_NAME,
    UPDATE_ATHLETE_GROUP,
    UPDATE_ATHLETE_TEAM,
    ATTEMPT_CREATE_ATHLETE,
} from 'shared_redux/actions';

// TODO: can create and edit share the same view store for the popup?!

export const closeModal = () => ({ type: CLOSE_CREATE_ATHLETE });

export const updateFirstName = (firstName) => ({ type: UPDATE_ATHLETE_FIRST_NAME, firstName });

export const updateMiddleName = (middleName) => ({ type: UPDATE_ATHLETE_MIDDLE_NAME, middleName });

export const updateLastName = (lastName) => ({ type: UPDATE_ATHLETE_LAST_NAME, lastName });

export const updateGroup = (groupId) => ({ type: UPDATE_ATHLETE_GROUP, groupId });

export const updateTeam = (teamId) => ({ type: UPDATE_ATHLETE_TEAM, teamId });

export const create = (file) => ({ type: ATTEMPT_CREATE_ATHLETE, file });
