import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import View from './view';
import * as Actions from './actions';
import * as ColumnsFilterSelectors from 'shared_redux/selectors/view/columns_filter';

const mapStateToProps = (state) => {
    return {
        isShowing: ColumnsFilterSelectors.getIsShowingColumnsFilter(state),
        isUploading: ColumnsFilterSelectors.getIsUploading(state),
        models: ColumnsFilterSelectors.getEditableRosterSettings(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deselectColumn: Actions.deselectColumn,
        selectColumn: Actions.selectColumn,
        closeModal: Actions.closeModal,
        save: Actions.save,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default withRouter(Container);
