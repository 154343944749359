import {
    FETCH_ATHLETES_SUCCEEDED,
    CREATE_MAX_SUCCEEDED,
    UPDATE_MAX_SUCCEEDED,
    REMOVE_MAX_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class Maxes extends Model {

    static reducer(action, Maxes, session) {
        switch (action.type) {
            case FETCH_ATHLETES_SUCCEEDED:
                for (const athlete of action.payload) {
                    for (const max of athlete.maxes) {
                        Maxes.upsert(max);
                    }
                }
            case CREATE_MAX_SUCCEEDED:
            case UPDATE_MAX_SUCCEEDED:
                Maxes.upsert(action.payload);
                break;
            case REMOVE_MAX_SUCCEEDED:
                Maxes.withId(action.id).delete();
                break;
            case SIGN_OUT_SUCCEEDED:
                Maxes.all().delete();
                break;
        }
    }

}
Maxes.modelName = 'Maxes';

Maxes.fields = {
    id: attr(),
    exercise_id: fk({
        to: 'Exercises',
        as: 'exercise',
        relatedName: 'maxes',
    }),
    athlete_id: fk({
        to: 'Athletes',
        as: 'athlete',
        relatedName: 'maxes',
    }),
    weight: attr(),
    weight_unit: attr(),
};

export default Maxes;
