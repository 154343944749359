import { createSelector } from 'redux-orm';
import orm from 'shared_redux/reducers/models/orm';
import * as AccountsSelectors from 'shared_redux/selectors/models/accounts';

const stateRoot = (state) => state.program_builder;

const getProgramId = (state, props) => props.match.params.programid;
const getMicrocycleId = (state, props) => props.id;
const getMicrocycleSessionId = (state, props) => props.session_id;
const getBlockId = (state, props) => props.id;
const getPlanId = (state, props) => props.id;

export const getErrorState = (state) => stateRoot(state).errorState;

export const getIsPlanHovering = (state, props) => props.id === stateRoot(state).hover_plan_id;
export const getIsBlockHovering = (state, props) => props.id === stateRoot(state).hover_block_id;
export const getIsMicrocycleSessionHovering = (state, props) => props.session_id === stateRoot(state).hover_microcycle_session_id;
export const getIsMicrocycleHovering = (state, props) => props.id === stateRoot(state).hover_microcycle_id;

export const getInsertPlanPosition = (state, props) => stateRoot(state).insert_plan_block_id === props.id ? stateRoot(state).insert_plan_order : null;

const getMicrocycleIsExpanded = (state, props) => stateRoot(state)[props.id] !== true;
const getMicrocycleSessionIsExpanded = (state, props) => stateRoot(state)[props.session_id] !== true;

export const getDraftExercises = createSelector(
    orm,
    state => state.draft_database,
    session => {
        const { Exercises } = session;
        return Exercises.all().orderBy('name').toRefArray();
    }
);

export const getActualProgramName = createSelector(
    orm,
    state => state.database,
    getProgramId,
    (session, program_id) => {
        const { Programs } = session;
        const program = Programs.withId(program_id);
        return program ? program.name : '';
    }
);

export const getDraftProgramName = createSelector(
    orm,
    state => state.draft_database,
    getProgramId,
    (session, program_id) => {
        const { Programs } = session;
        const program = Programs.withId(program_id);
        return program ? program.name : '';
    }
);

export const getActualProgramNotes = createSelector(
    orm,
    state => state.database,
    getProgramId,
    (session, program_id) => {
        const { Programs } = session;
        const program = Programs.withId(program_id);
        return program ? program.notes : '';
    }
);

export const getDraftProgramNotes = createSelector(
    orm,
    state => state.draft_database,
    getProgramId,
    (session, program_id) => {
        const { Programs } = session;
        const program = Programs.withId(program_id);
        return program ? program.notes : '';
    }
);

export const getDraftProgram = createSelector(
    orm,
    state => state.draft_database,
    getProgramId,
    getDraftExercises,
    (session, program_id, exercises) => {
        const { Programs, Microcycles } = session;
        const program = Programs.withId(program_id);
        if (!program || exercises.length <= 0) {
            return {
                isLoaded: false,
                microcycle_name: null,
                microcycle_ids: [],
            };
        }
        const microcycle_ids = Microcycles
            .all()
            .filter(microcycle => microcycle.program_id === program_id)
            .orderBy('order')
            .toModelArray()
            .map(microcycle => {
                return microcycle.id;
            });
        return {
            isLoaded: true,
            microcycle_name: program ? program.microcycle_name : null,
            microcycle_ids,
        };
    }
);

export const makeGetDraftMicrocycle = () => createSelector(
    orm,
    state => state.draft_database,
    getMicrocycleId,
    getMicrocycleIsExpanded,
    (session, microcycle_id, expanded) => {
        const { Microcycles } = session;
        const microcycle = Microcycles.withId(microcycle_id);
        const microcycle_session_ids = microcycle.microcycle_sessions.orderBy('order').toModelArray().map(microcycle_session => microcycle_session.session_id);
        return {
            ...microcycle.ref,
            microcycle_session_ids,
            expanded,
        };
    }
);

export const makeGetDraftMicrocycleSession = () => createSelector(
    orm,
    state => state.draft_database,
    getMicrocycleSessionId,
    getMicrocycleSessionIsExpanded,
    (session, session_id, expanded) => {
        const { MicrocycleSessions } = session;
        const microcycle_session = MicrocycleSessions.withId(session_id);
        const block_ids = microcycle_session.session.blocks.orderBy('order').toModelArray().map(block => block.id);
        return {
            ...microcycle_session.ref,
            block_ids,
            expanded,
        };
    }
);

export const makeGetDraftBlock = () => createSelector(
    orm,
    state => state.draft_database,
    getBlockId,
    (session, block_id) => {
        const { Blocks, Exercises } = session;
        const block = Blocks.withId(block_id);
        const microcycle_id = block.session.microcycle_session.microcycle_id;
        const session_order = block.session.microcycle_session.order;
        const plan_ids = block.plans.orderBy('order').toModelArray().map(plan => plan.id);
        const lastBlock = block.session.blocks.orderBy('order', 'desc').first();
        const default_exercise_id = Exercises.all().first().id;
        return {
            ...block.ref,
            isValid: Blocks.isValid(block.ref),
            isLast: lastBlock.id === block_id,
            microcycle_id,
            session_order,
            plan_ids,
            default_exercise_id,
        };
    }
);

export const makeGetDraftPlan = () => createSelector(
    orm,
    state => state.draft_database,
    getPlanId,
    getDraftExercises,
    AccountsSelectors.getAccount,
    (session, plan_id, exercises, account) => {
        const { Plans } = session;
        const plan = Plans.withId(plan_id);
        return {
            plan: {
                ...plan.ref,
                exercise: plan.exercise.ref,
            },
            blockType: plan.block.type,
            isValid: Plans.isValid(plan.ref),
            exercises,
            organization_id: account.organization_id,
        };
    }
);

// NOTE: because updating name is not part of the queue, this should work fine
// Once it is part of the queue though, then this will no longer update the url properly
export const getProgramURLName = createSelector(
    orm,
    state => state.database,
    getProgramId,
    (session, program_id) => {
        const { Programs } = session;
        const program = Programs.withId(program_id);
        return program ? encodeURIComponent(program.name.replace(/\s+/g, '-')).toLowerCase() : null;
    }
);

export const getDraftProgramURLName = createSelector(
    orm,
    state => state.draft_database,
    getProgramId,
    (session, program_id) => {
        const { Programs } = session;
        const program = Programs.withId(program_id);
        return program ? encodeURIComponent(program.name.replace(/\s+/g, '-')).toLowerCase() : null;
    }
);

export const getProgramTabs = createSelector(
    orm,
    state => state.database,
    getProgramId,
    getProgramURLName,
    (session, program_id, url_name) => {
        if (url_name) {
            return {
                tabs: [{
                    text: "PROGRAM",
                    url: `/programs/${program_id}/${url_name}`,
                }, {
                    text: "SCHEDULE",
                    url: `/programs/${program_id}/${url_name}/schedule`,
                }]
            };
        } else {
            return { tabs: [] };
        }
    }
);
