import {
    FETCH_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED,
    UPDATE_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class RosterSettings extends Model {

    static reducer(action, RosterSettings, session) {
        switch (action.type) {
            case FETCH_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED:
            case UPDATE_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED:
                action.payload.forEach(s => {
                    RosterSettings.upsert({
                        ...s,
                        id: s.order,
                    });
                });
                break;
            case SIGN_OUT_SUCCEEDED:
                RosterSettings.all().delete();
                break;
        }
    }

}
RosterSettings.modelName = 'RosterSettings';

RosterSettings.fields = {
    type: attr(),
    exercise_id: attr(), // optional
    value: attr(),
    order: attr(),
};

export default RosterSettings;
