import React, {Component} from 'react';
import styles from './styles.module.scss';

class View extends Component {

    constructor(props) {
        super(props);
        this.textField = React.createRef();
    }

    _handleKeyPress(e) {
        if(e.key === 'Enter') {
            this.textField.current.blur();
        }
    }

    _renderTitle() {
        if (this.props.isEditable) {
            return <input type="text" value={this.props.title}
                ref={this.textField}
                className={styles.editableInput}
                onKeyPress={(e)=> this._handleKeyPress(e)}
                onChange={(event)=>this.props.updateTitle(event.target.value)}
                onBlur={()=>this.props.saveTitle()}
                />;
        } else {
            return <input type="text" value={this.props.title} disabled />;
        }
    }

    render() {
        return (
            <div className={styles.titlebg}>
                <div className={styles.title}>
                    <div className={styles.subtitle}>{this.props.subtitle}</div>
                    {this._renderTitle()}
                    <div className={styles.options}>{this.props.children}</div>
                </div>
            </div>
        );
    }

}

export default View;
