import React from 'react';
import styles from './styles.module.scss';

export default props => {
    if (props.errorState === 'ERROR') {
        console.log(props.callback)
        return (
            <div className={styles.tappableBanner} onClick={props.callback}>
                We were unable to save your changes. Click here to try again
            </div>
        );
    } else if (props.errorState === 'RETRYING') {
        return (
            <div className={styles.banner}>Retrying...</div>
        )
    } else {
        return null;
    }
};
