import API from './api';

export default {

    getGroups: (organization_id) => API({
        url: `groups?organization_id=${organization_id}`,
        method: 'GET',
    }),

    createGroup: (group) => API({
        url: 'groups',
        method: 'POST',
        json: group,
    }),

    updateGroup: (group) => API({
        url: `groups/${group.id}`,
        method: 'PATCH',
        json: group,
    }),

    removeGroup: (groupId) => API({
        url: `groups/${groupId}`,
        method: 'DELETE',
    }),
    
};
