import { all, take, takeEvery, takeLatest, select, apply, put, call } from 'redux-saga/effects';

import {
    ATTEMPT_FETCH_RACKS,
    FETCH_RACKS_SUCCEEDED,

    UPDATE_RACK_ATTEMPT,
    UPDATE_RACK_SUCCEEDED,

    FETCH_ACCOUNT_SUCCEEDED,
} from 'shared_redux/actions';
import API from 'services/racks';
import { handleError } from 'services/alerts';
import * as AccountsSelectors from 'shared_redux/selectors/models/accounts';

export default function* RacksSaga() {
    yield all([
        takeLatest(ATTEMPT_FETCH_RACKS, fetchRacks),
        takeEvery(UPDATE_RACK_ATTEMPT, updateRack),
    ]);
};

function *fetchRacks(action) {
    try {
        var account = yield select(AccountsSelectors.getAccount);
        if (!account) {
            yield take(FETCH_ACCOUNT_SUCCEEDED);
            account = yield select(AccountsSelectors.getAccount);
        }
        const payload = yield apply(API, API.getRacks, [account.organization_id]);
        yield put({
            type: FETCH_RACKS_SUCCEEDED,
            payload,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch station data');
    }
}

function *updateRack(action) {
    try {
        const payload = yield apply(API, API.updateRack, [action.rackId, action.payload]);
        yield put({
            type: UPDATE_RACK_SUCCEEDED,
            payload,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to update rack');
    }
}
