import moment from 'moment';
import {
    CREATE_PROGRAM_SCHEDULE,
    EDIT_PROGRAM_SCHEDULE,
    ATTEMPT_FETCH_PROGRAM_SCHEDULES,
    UPDATE_PROGRAM_SCHEDULER_GROUPS_FILTER,
    ATTEMPT_FETCH_TEAMS,
} from 'shared_redux/actions';

export const createSchedule = (programId, startDate, programLengthInDays) => ({
    type: CREATE_PROGRAM_SCHEDULE,
    programId,
    startDate: moment(startDate).format('YYYY-MM-DD'),
    endDate: moment(startDate).add(programLengthInDays, 'days').format('YYYY-MM-DD'),
});

export const editSchedule = (id, startDate, endDate) => ({
    type: EDIT_PROGRAM_SCHEDULE,
    id,
    startDate: moment(startDate).format('YYYY-MM-DD'),
    endDate: moment(endDate).format('YYYY-MM-DD'),
});

export const fetchProgramSchedules = (organization_id) => ({
    type: ATTEMPT_FETCH_PROGRAM_SCHEDULES,
    organization_id,
});

export const fetchCategories = () => ({ type: ATTEMPT_FETCH_TEAMS });

export const updateGroupFilter = (team, group) => ({ type: UPDATE_PROGRAM_SCHEDULER_GROUPS_FILTER, team, group });
