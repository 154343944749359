import API from './api';

export default {

    signIn: (token) => API({
        url: 'accounts/login',
        method: 'POST',
        noAuth: true,
        json: { token }
    }),

    signOut: () => API({
        url: 'accounts/logout',
        method: 'POST',
        noAuth: true,
    }),

    getCurrentAccount: () => API({
        url: 'accounts/current',
        method: 'GET',
    }),

    getCurrentAccountRosterSettings: () => API({
        url: 'accounts/current/settings/roster',
        method: 'GET',
    }),

    patchAccountRosterSettings: (columns) => API({
        url: `accounts/current/settings/roster`,
        method: 'PATCH',
        json: { columns },
    }),

    register: (token, email, password, organization_name, beta) => API({
        url: 'accounts',
        method: 'POST',
        json: { token, email, password, organization_name, beta }
    }),
    
};
