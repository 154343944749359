import {
    UPDATE_SIGN_IN_EMAIL,
    UPDATE_SIGN_IN_PASSWORD,
    SIGN_IN_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    email: "",
    password: "",
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case UPDATE_SIGN_IN_EMAIL:
            return {
                ...state,
                email: action.email
            };
        case UPDATE_SIGN_IN_PASSWORD:
            return {
                ...state,
                password: action.password
            };
        case SIGN_IN_SUCCEEDED:
            return {
                ...state,
                email: "",
                password: "",
            };
        case SIGN_OUT_SUCCEEDED:
            return {
                ...state,
                email: "",
                password: "",
            };
        default:
            return state;
    }
};
