import React, {Component} from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import styles from './styles.module.scss';
import TitleView from 'shared_features/title';

export default class View extends Component {

    render() {
        return (
            <div>
                <TitleView title={this.props.name}
                    isEditable={this.props.isEditable}
                    updateTitle={(text)=>this.props.updateProgramName(this.props.match.params.programid, text)}
                    saveTitle={()=>this.props.saveProgramName(this.props.match.params.programid, this.props.name)} />
                <div className={styles.notesContainer}>
                    <TextareaAutosize
                        className={styles.notes}
                        minRows={2}
                        maxRows={6}
                        value={this.props.notes || ''}
                        placeholder="Add note..."
                        onChange={(event)=>this.props.updateProgramNotes(this.props.match.params.programid, event.target.value)}
                        onBlur={()=>this.props.saveProgramNotes(this.props.match.params.programid, this.props.notes)} />
                </div>
            </div>
        );
    }
};
