import {
    SHOW_ROSTER_COLUMNS_FILTER,
    CLOSE_ROSTER_COLUMNS_FILTER,

    SELECT_ROSTER_COLUMN_FILTER,
    DESELECT_ROSTER_COLUMN_FILTER,

    UPDATE_ACCOUNT_ROSTER_SETTINGS_ATTEMPT,
    UPDATE_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED,
    UPDATE_ACCOUNT_ROSTER_SETTINGS_FAILED,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    isShowingColumnsFilter: false,
    isUploading: false,

    // NOTE: the rest will be dictionary keys for settings overrides
    // the existing of an override indicates it's the opposite of whatever the actual state is
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case SHOW_ROSTER_COLUMNS_FILTER:
            return {
                ...state,
                isShowingColumnsFilter: true,
            };
        case UPDATE_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED:
        case CLOSE_ROSTER_COLUMNS_FILTER:
            return createDefaultState();
        case UPDATE_ACCOUNT_ROSTER_SETTINGS_ATTEMPT:
            return {
                ...state,
                isUploading: true,
            };
        case UPDATE_ACCOUNT_ROSTER_SETTINGS_FAILED:
            return {
                ...state,
                isUploading: false,
            };

        case SELECT_ROSTER_COLUMN_FILTER:
        case DESELECT_ROSTER_COLUMN_FILTER: {
            if (action.column_type === 'CATEGORY') {
                if ('category' in state) {
                    // remove it
                    let obj = { ...state };
                    delete obj.category;
                    return obj;
                } else {
                    // add it
                    return {
                        ...state,
                        category: true,
                    };
                }
            } else if ((action.column_type === 'MAXABLE_EXERCISE' || action.column_type === 'CALCULATED_MAX_EXERCISE') && 'exercise_id' in action) {
                let obj = { ...state };
                if (action.exercise_id in state) {
                    // remove it
                    delete obj[action.exercise_id];
                } else {
                    obj[action.exercise_id] = true;
                }
                return obj;
            }
            return state;
        }

        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
