import { all, takeEvery, takeLatest, apply, put, call, delay, select } from 'redux-saga/effects';
import {
    initializeAuth,
    inMemoryPersistence,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
} from 'firebase/auth';

import firebase from 'firebaseInstance';
import * as AuthActions from 'shared_redux/actions/auth';

import {
    ATTEMPT_SIGN_IN,
    ATTEMPT_SIGN_OUT,
    AUTH_STATUS_CHANGED,
    SIGN_IN_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,

    ATTEMPT_RESET_PASSWORD,
    RESET_PASSWORD_SUCCEEDED,

    ATTEMPT_FETCH_ACCOUNT,
    FETCH_ACCOUNT_SUCCEEDED,

    FETCH_ACCOUNT_ROSTER_SETTINGS_ATTEMPT,
    FETCH_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED,

    UPDATE_ACCOUNT_ROSTER_SETTINGS_ATTEMPT,
    UPDATE_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED,
    UPDATE_ACCOUNT_ROSTER_SETTINGS_FAILED,

    REGISTER_ATTEMPT,
    REGISTER_SUCCEEDED,
    REGISTER_FAILED,
} from 'shared_redux/actions';
import ConnectionConfig from 'constants/connectionConfig';
import API from 'services/accounts';
import history from 'shared_redux/history';

import * as OrganizationsSelectors from 'shared_redux/selectors/models/organizations';
import * as ColumnsFilterSelectors from 'shared_redux/selectors/view/columns_filter';

import { handleError, displayErrorAlert, displaySuccessAlert } from 'services/alerts';

export default function* AccountsSaga(dispatch) {
    // setup firebase auth
    const auth = initializeAuth(firebase, {persistence: inMemoryPersistence});
    // setPersistence(auth, 'NONE'); // TODO: typescript this
    onAuthStateChanged(auth, async user => {
        dispatch(AuthActions.authStatusChanged(user));
    });

    yield all([
        takeLatest(ATTEMPT_SIGN_IN, login, auth),
        takeEvery(ATTEMPT_SIGN_OUT, logout),
        takeEvery(AUTH_STATUS_CHANGED, statusChanged, auth),
        takeLatest(ATTEMPT_RESET_PASSWORD, resetPassword, auth),
        takeLatest(ATTEMPT_FETCH_ACCOUNT, fetchAccount),
        takeLatest(FETCH_ACCOUNT_ROSTER_SETTINGS_ATTEMPT, fetchAccountRosterSettings),
        takeEvery(UPDATE_ACCOUNT_ROSTER_SETTINGS_ATTEMPT, updateAccountRosterSettings),
        takeEvery(REGISTER_ATTEMPT, register, auth),
    ]);
};

function *login(auth, action) {
    try {
        yield signInWithEmailAndPassword(auth, action.email, action.password);
    } catch(error) {
        if (error.code === 'auth/user-disabled') {
            yield put({ type: REGISTER_SUCCEEDED });
        } else {
            console.log(error)
            displayErrorAlert('Error: Sign in failed');
        }
    }
}

function *logout(action) {
    try {
        yield apply(API, API.signOut);
        yield put({
            type: SIGN_OUT_SUCCEEDED,
        });
    } catch(error) {
        displayErrorAlert('Error: Sign Out failed');
    }
}

function *statusChanged(auth, action) {
    try {
        if (!action.user) {
            return;
        }

        const idToken = yield action.user.getIdToken();
        yield signOut(auth);
        const account = yield apply(API, API.signIn, [idToken]);
        yield put({
            type: SIGN_IN_SUCCEEDED,
            payload: account,
        });
        yield call(history.push, '/');
    } catch(error) {
        displayErrorAlert('Error: Sign in failed!');
        console.log(error);
    }
}

function *resetPassword(auth, action) {
    try {
        yield sendPasswordResetEmail(auth, action.email);
        yield put({
            type: RESET_PASSWORD_SUCCEEDED,
        });
        displaySuccessAlert('Password reset email sent!');
    } catch(error) {
        displayErrorAlert('Error: Reset password failed!');
    }
}

function *fetchAccount(action) {
    try {
        const json = yield apply(API, API.getCurrentAccount);
        yield put({
            type: FETCH_ACCOUNT_SUCCEEDED,
            payload: json,
        });
    } catch(err) {
        // handle error
        yield handleError(err, "Fetch account failed", false);

        // retry if not 401
        if (err.statusCode !== 401) {
            yield delay(ConnectionConfig.FETCH_ACCOUNT_RETRY_INTERVAL);
            const organization = yield select(OrganizationsSelectors.getOrganization);
            if (organization) {
                yield put({ type: ATTEMPT_FETCH_ACCOUNT });
            }
        }
    }
}

function *fetchAccountRosterSettings(action) {
    try {
        const json = yield apply(API, API.getCurrentAccountRosterSettings);
        yield put({
            type: FETCH_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED,
            payload: json,
        });
    } catch (err) {
        yield handleError(err, "Fetch account roster settings failed");
    }
}

function *updateAccountRosterSettings(action) {
    try {
        const settings = yield select(ColumnsFilterSelectors.getEditableRosterSettings);
        const json = yield apply(API, API.patchAccountRosterSettings, [settings]);
        yield put({
            type: UPDATE_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED,
            payload: json,
        });
    } catch (err) {
        yield handleError(err, "Fetch account roster settings failed");
        yield put({
            type: UPDATE_ACCOUNT_ROSTER_SETTINGS_FAILED,
            error: err,
        });
    }
}

function *register(auth, action) {
    let registerSucceeded = false;
    try {
        yield apply(API, API.register, [action.token, action.email, action.password, action.organization_name, action.beta]);
        yield put({
            type: REGISTER_SUCCEEDED
        });
        registerSucceeded = true;
    } catch (err) {
        // handle error
        yield displayErrorAlert(err.message);
        yield put({
            type: REGISTER_FAILED,
        });
    }
    
    // hack sign in and push next screen
    if (action.beta && registerSucceeded) {
        try {
            // sign in
            yield signInWithEmailAndPassword(auth, action.email, action.password);
        } catch (err) {
            // fallback, try alert
            alert(`Welcome to RepOne Beta! Please sign in to continue.`);
            
            // push to next screen
            yield call(history.push, '/');
        }
    }
}
