import React, {Component}  from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';

import ManageMaxesView from 'shared_features/manage_maxes';

import AutoregulationTypes from 'constants/autoregulationTypes';
import WeightUnits from 'constants/weightUnits';
import AutoregulationQuantifiers from 'constants/autoregulationQuantifiers';
import AutoregulationMetrics from 'constants/autoregulationMetrics';

import styles from './styles.module.scss';

const selectStyles = {
    control: () => ({
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        height: 48,
        alignItems: 'center',
        boxSizing: 'border-box',
    }),
    container: () => ({
        position: 'relative',
    }),
    singleValue: () => ({
        position: 'absolute',
        marginTop: 8,
        marginLeft: 4,
        color: '#333333',
        maxWidth: 217,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: 14,
        fontWeight: 'regular',
    }),
    input: () => ({
        marginTop: 14,
        marginLeft: 4,
        color: '#333333',
        fontSize: 14,
        fontWeight: 'regular',
    }),
    placeholder: () => ({
        marginTop: 14,
        marginLeft: 4,
        color: '#333333',
        fontSize: 14,
        fontWeight: 'regular',
    }),
    indicatorSeparator: (styles) => ({display:'none'}),
};

export default class View extends Component {

    componentDidMount() {
        this.props.fetchSettings();
    }

    render() {
        const props = this.props;
        if (!props.loaded) {
            return null;
        }

        let rowStyle = styles.plan;
        if (!props.isValid) {
            rowStyle = [rowStyle, styles.invalid].join(' ');
        }

        return (<div className={styles.main}>
            <div className={styles.settingsSection}>
                <h1>Maxes</h1>
                <ManageMaxesView />
            </div>
            <div className={styles.settingsSection}>
                <h1>Program builder default values</h1>
                <div>Change the default values that appear when you create a new movement in the program builder</div>
                <div className={styles.planRow}>
                    <div className={styles.topBorder} />
                    <div className={rowStyle}>
                        {renderMovement(props)}
                        {renderAutoregulation(props)}
                        {renderSensorTracking(props)}
                        {renderMinSets(props)}
                        {renderMaxSets(props)}
                        {renderNumReps(props)}
                        {renderLoad(props)}
                        {renderAutoregulationQuantifier(props)}
                        {renderAutoregulationTarget(props)}
                        {renderAutoregulationRange(props)}
                        {renderAutoregulationMetric(props)}
                        {renderSpeedWork(props)}
                    </div>
                </div>
            </div>
        </div>);
    }

}


// TODO: share code with program builder plan
// rendering copy pasted from program builder plan and modified
// ideally it isn't done this way, but it was just much faster than trying to make it re-usable


const renderMovement = props => {
    const value = { value: null, label: 'Example' };
    return (
        <div className={styles.movementContainer}>
            <CreatableSelect
                isClearable
                name="exercise"
                value={value}
                className={styles.select}
                styles={selectStyles}
                isClearable={false}
            />
            <label>MOVEMENT</label>
        </div>
    );
};

const renderAutoregulation = props => {
    // TODO: consider having these passed in instead of hardcoding them in the view
    const types = [
        { value: AutoregulationTypes.NO_AUTOREGULATION, label: 'None' },
        { value: AutoregulationTypes.WEIGHT_AUTOREGULATION, label: 'Load' }, // this accounts for weight and weight_speed
    ];

    if (props.default_autoregulation && props.default_autoregulation === types[0].value) {
        // no autoregulation
        var value = types[0];
    } else {
        // is either weight or weight speed
        var value = types[1];
    }

    return (
        <div className={styles.autoregulationContainer}>
            <Select
                name="autoregulation"
                value={value}
                className={styles.select}
                styles={selectStyles}
                clearable={false}
                deleteRemoves={false}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        // TODO: ideally setting it again shouldn't run it unless it changed, as otherwise it may flip the speed work vlue
                        props.updateDefaultAutoregulation(selectedOption.value);
                    }
                }}
                options={types}
            />
            <label>REGULATE</label>
        </div>
    );
};

const renderSensorTracking = props => {
    // only for no auto sets
    if (props.default_autoregulation !== AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    const types = [
        { value: 'sensor', label: 'Sensor' },
        { value: 'manual', label: 'Manual' },
    ];
    const value = props.default_sensor_tracking ? types[0] : types[1];
    return (
        <div className={styles.sensorContainer}>
            <Select
                name="sensor_tracking"
                value={value}
                className={styles.select}
                styles={selectStyles}
                clearable={false}
                deleteRemoves={false}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        props.updateDefaultSensorTracking(selectedOption.value === 'sensor');
                    }
                }}
                options={types}
            />
            <label>TRACKING</label>
        </div>
    );
};

const renderMinSets = props => {
    if (props.default_autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return (
            <div className={styles.minSetsContainer}>
                <input type="number"
                    value={props.default_min_sets}
                    placeholder=""
                    onChange={(event) => props.updateDefaultMinSets(parseInt(event.target.value))}
                    onFocus={(event) => event.target.select()}
                    onBlur={(event)=>props.saveDefaults()}/>
                <label>SETS</label>
            </div>
        );
    } else {
        return (
            <div className={styles.minSetsWithToggleContainer}>
                <div>
                    <input type="number"
                        value={props.default_min_sets}
                        placeholder=""
                        onChange={(event) => props.updateDefaultMinSets(parseInt(event.target.value))}
                        onFocus={(event) => event.target.select()}
                        onBlur={(event)=>props.saveDefaults()}/>
                    <label>MIN SETS</label>
                </div>
                <div>
                    <div onClick={()=>props.updateDefaultSetCountFixed(!props.default_set_count_fixed)}>
                        <p>{props.default_set_count_fixed ? "FIXED" : 'RANGE'}</p>
                    </div>
                </div>
            </div>
        );
    }
};

const renderMaxSets = props => {
    // do not show for non autoreg / fixed
    if (props.default_autoregulation === AutoregulationTypes.NO_AUTOREGULATION || props.default_set_count_fixed === true) {
        return null;
    }

    return (
        <div className={styles.maxSetsContainer}>
            <input type="number"
                value={props.default_max_sets || ''}
                placeholder="None"
                onChange={(event)=> props.updateDefaultMaxSets(event.target.value ? parseInt(event.target.value) : null)}
                onFocus={(event) => event.target.select()}
                onBlur={(event)=>props.saveDefaults()}/>
            <label>MAX SETS</label>
        </div>
    );
};

const renderNumReps = props => {
    let repDisplay = null;
    if (props.default_amrap) {
        repDisplay = (<div>N/A</div>)
    } else {
        repDisplay = (
            <input type="number"
                value={props.default_num_reps}
                placeholder=""
                onChange={(event) => props.updateDefaultNumReps(parseInt(event.target.value))}
                onFocus={(event) => event.target.select()}
                onBlur={(event)=>props.saveDefaults()}/>
        );
    }

    return (
        <div className={styles.repsContainer}>
            <div>
                {repDisplay}
                <label>REPS</label>
            </div>
            <div>
                <div onClick={()=>props.updateDefaultAMRAP(!props.default_amrap)}>
                    <p>{props.default_amrap ? "AM RAP" : '#'}</p>
                </div>
            </div>
        </div>
    );
};

const renderLoad = props => {
    // weight unit
    if (!props.default_weight_unit) {
        // quick hack fix, if it's null then it'll display null, but tapping on it will set it to kgs at least
        // note: this shouldn't be necessary anymore as metric is now required, but leaving it here for safety
        var weight_unit = WeightUnits[0];
    } else {
        switch (props.default_weight_unit) {
            case WeightUnits[0]:
                var weight_unit = WeightUnits[1];
                break;
            case WeightUnits[1]:
                var weight_unit = WeightUnits[2];
                break;
            case WeightUnits[2]:
                var weight_unit = WeightUnits[0];
                break;
        }
    }

    // label
    if (props.default_autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        var label = 'LOAD';
        var style = styles.loadContainer;
    } else {
        var label = 'START LOAD';
        var style = styles.startLoadContainer;
    }

    return (
        <div className={style}>
            <div>
                 <input type="number"
                    value={props.default_weight || ''}
                    placeholder="Any"
                    onChange={(event)=> props.updateDefaultWeight(event.target.value === '' ? null : parseFloat(event.target.value))}
                    onFocus={(event) => event.target.select()}
                    onBlur={(event)=>props.saveDefaults()}/>
                <label>{label}</label>
            </div>
            <div>
                <div onClick={()=>props.updateDefaultWeightUnit(weight_unit)}>
                    <p>{props.default_weight_unit}</p>
                </div>
            </div>
        </div>
    );
};

const renderAutoregulationQuantifier = props => {
    // only for no auto sets
    if (props.default_autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    let value = null;
    let quantifiers = [];
    for (var quantifier of AutoregulationQuantifiers) {

        let option = { value: quantifier, label: _.startCase(_.toLower(quantifier.replace('%', 'percent')))};
        quantifiers.push(option);
        if (quantifier === props.default_autoregulation_quantifier) {
            value = option;
        }
    }

    return (
        <div className={styles.quantifierContainer}>
            <Select
                name="autoregulationquantifier"
                value={value}
                className={styles.select}
                styles={selectStyles}
                clearable={false}
                deleteRemoves={false}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        props.updateDefaultAutoregulationQuantifier(selectedOption.value);
                    }
                }}
                options={quantifiers}
            />
            <label>{_.toUpper(props.default_autoregulation_metric.replace('_', ' '))} METRIC</label>
        </div>
    );
};

const renderAutoregulationTarget = props => {
    // only for no auto sets
    if (props.default_autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    return (
        <div className={styles.targetContainer}>
            <input type="number"
                value={props.default_autoregulation_target}
                placeholder=""
                step={0.1}
                onChange={(event)=> props.updateDefaultAutoregulationTarget(parseFloat(event.target.value))}
                onFocus={(event) => event.target.select()}
                onBlur={(event)=>props.saveDefaults()}/>
            <label>TARGET</label>
        </div>
    );
};

const renderAutoregulationRange = props => {
    // only for no auto sets
    if (props.default_autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    return (
        <div className={styles.rangeContainer}>
            <label>RANGE</label>
            <input type="number"
                value={props.default_autoregulation_range}
                placeholder=""
                step={0.1}
                onChange={(event)=> props.updateDefaultAutoregulationRange(parseFloat(event.target.value))}
                onFocus={(event) => event.target.select()}
                onBlur={(event)=>props.saveDefaults()}/>
        </div>
    );
};

const renderAutoregulationMetric = props => {
    // only for no auto sets
    if (props.default_autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    let value = null;
    let types = [];
    for (var type of AutoregulationMetrics) {
        const option = { value: type, label: _.startCase(_.toLower(type))};
        types.push(option);
        if (type === props.default_autoregulation_metric) {
            value = option;
        }
    }

    return (
        <div className={styles.metricContainer}>
            <Select
                name="autoregulationmetric"
                value={value}
                className={styles.select}
                styles={selectStyles}
                clearable={false}
                deleteRemoves={false}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        props.updateDefaultAutoregulationMetric(selectedOption.value);
                    }
                }}
                options={types}
            />
            <label>METRIC</label>
        </div>
    );
};

const renderSpeedWork = props => {
    // only for no auto sets
    if (props.default_autoregulation === AutoregulationTypes.NO_AUTOREGULATION) {
        return null;
    }

    return (
        <div className={styles.speedWorkContainer}>
            <input type="checkbox"
                checked={props.default_autoregulation === AutoregulationTypes.WEIGHT_AUTOREGULATION_SPEED}
                onChange={(event)=> props.updateDefaultAutoregulation(event.target.checked ? AutoregulationTypes.WEIGHT_AUTOREGULATION_SPEED : AutoregulationTypes.WEIGHT_AUTOREGULATION)} />
            <label>SPEEDWORK</label>
        </div>
    );
};
