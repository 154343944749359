import { createSelector } from 'redux-orm';
import orm from 'shared_redux/reducers/models/orm';

const stateRoot = (state) => state.manage_maxes;

export const getRemoveId = (state) => stateRoot(state).removeId;

const getExerciseId = (state, props) => props.id;

export const makeGetMaxableExerciseModel = () => createSelector(
    orm,
    state => state.database,
    getExerciseId,
    (session, id) => {
        const { Exercises } = session;

        // check if exercise exists
        const current = Exercises.withId(id);
        if (!current) {
            console.log(`exercise ${id} is not found`);
            return null;
        }

        // check if exercise is maxable
        if (current.maxable !== true) {
            console.log(`exercise ${id} is not maxable`);
            return null;
        }

        // is parent check
        const isParent = current.parent_exercise_links.all().exists(); // TODO: confirm this check works

        // link properties
        const currentLink = current.child_exercise_links.all().toRefArray();
        if (currentLink.length === 1) {
            var formula = currentLink[0].formula;
            var parent_exercise_id = currentLink[0].parent_exercise_id;
        } else {
            var formula = null;
            var parent_exercise_id = null;
        }

        // linkable
        // fetch parents where...
        // - parent must be maxable
        // - parent must not be a child
        var linkedExercise = null;
        if (!isParent) {
            const maxable = Exercises.all().filter(e => e.maxable === true && e.id !== id).orderBy('name', 'asc').toModelArray();
            const linkable = maxable.filter(e => !e.child_exercise_links.all().exists()); // has to be separate as the redux-orm filter gets the ref itself
            var linkableExercises = linkable.map(le => {
                const result = { value: le.id, label: le.name };
                if (le.id === parent_exercise_id) {
                    linkedExercise = result;
                }

                return result;
            });
        } else {
            var linkableExercises = [];
        }

        return {
            name: current.name,
            isParent, // determines if disabled or not
            formula,
            parent_exercise_id,
            linkedExercise,
            linkableExercises,
        };
    }
);
