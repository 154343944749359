import {
    FETCH_TEAMS_SUCCEEDED,
    EXPAND_TEAM,
    COLLAPSE_TEAM,

    // popup
    CREATE_TEAM,
    CREATE_GROUP,
    EDIT_TEAM,
    EDIT_GROUP,
    LOADED_CACHED_TEAM,
    LOADED_CACHED_GROUP,

    UPDATE_TEAM_NAME,
    UPDATE_GROUP_NAME,

    CLOSE_CREATE_TEAM,
    CLOSE_EDIT_TEAM,
    CLOSE_CREATE_GROUP,
    CLOSE_EDIT_GROUP,
    CREATE_GROUP_SUCCEEDED,
    CREATE_TEAM_SUCCEEDED,
    PATCH_TEAM_SUCCEEDED,
    PATCH_GROUP_SUCCEEDED,
    REMOVE_GROUP_SUCCEEDED,
    REMOVE_TEAM_SUCCEEDED,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    // popup
    isCreating: false,
    isEditing: false,
    isGroup: false,
    isTeam: false,
    organizationId: null,
    groupId: null,
    teamId: null,
    name: null,

    // NOTE: the rest will be dictionary keys for collapse
});

// consider loading from cache instead of passing it through the view?
export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case FETCH_TEAMS_SUCCEEDED: {
            let obj = {
                ...state,
            };
            for (var team of action.payload) {
                if (!(team.id in obj)) {
                    obj[team.id] = false;
                }
            }
            return obj;
        }
        case EXPAND_TEAM: {
            let obj = {
                ...state
            };
            obj[action.teamId] = false;
            return obj;
        }
        case COLLAPSE_TEAM: {
            let obj = {
                ...state
            };
            obj[action.teamId] = true;
            return obj;
        }
        case CREATE_TEAM:
            return {
                ...state,
                isCreating: true,
                isEditing: false,
                isGroup: false,
                isTeam: true,
                organizationId: action.organizationId,
                groupId: null,
                teamId: null,
                name: "",
            };
        case EDIT_TEAM:
            return {
                ...state,
                isCreating: false,
                isEditing: true,
                isGroup: false,
                isTeam: true,
                organizationId: null,
                groupId: null,
                teamId: action.teamId,
                name: null,
            };
        case LOADED_CACHED_TEAM:
            return {
                ...state,
                organizationId: action.team.organization_id,
                groupId: action.team.group_id,
                name: action.team.name,
            };
        case CREATE_GROUP:
            return {
                ...state,
                isCreating: true,
                isEditing: false,
                isGroup: true,
                isTeam: false,
                organizationId: null,
                groupId: null,
                teamId: action.teamId,
                name: "",
            };
        case EDIT_GROUP:
            return {
                ...state,
                isCreating: false,
                isEditing: true,
                isGroup: true,
                isTeam: false,
                organizationId: null,
                groupId: action.groupId,
                teamId: null,
                name: null,
            };
        case LOADED_CACHED_GROUP:
            return {
                ...state,
                organizationId: action.group.organization_id,
                teamId: action.group.team_id,
                name: action.group.name,
            };
        case UPDATE_TEAM_NAME:
        case UPDATE_GROUP_NAME:
            return {
                ...state,
                name: action.name,
            };
        case CLOSE_CREATE_TEAM:
        case CLOSE_EDIT_TEAM:
        case CLOSE_CREATE_GROUP:
        case CLOSE_EDIT_GROUP:
        case CREATE_GROUP_SUCCEEDED:
        case CREATE_TEAM_SUCCEEDED:
        case PATCH_TEAM_SUCCEEDED:
        case PATCH_GROUP_SUCCEEDED:
        case REMOVE_GROUP_SUCCEEDED:
        case REMOVE_TEAM_SUCCEEDED:
            return {
                ...state,
                isCreating: false,
                isEditing: false,
                isGroup: false,
                isTeam: false,
                organizationId: null,
                groupId: null,
                teamId: null,
                name: null,            
            };
        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
