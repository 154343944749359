import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DropTarget, DragSource } from 'react-dnd';
import { ContextMenuTrigger } from 'react-contextmenu';

import ProgramBuilderContext from './programBuilderContext';
import Plus from 'shared_features/svgs/plus';
import Reorder from 'shared_features/svgs/reorder';
import Hamburger from 'shared_features/svgs/hamburger';

import styles from './styles.module.scss';

/**
 * Properties:
 * hoverIndicatorOffset
 * dropParentHandler - source callback for drag drop (as this does the final update)
 * insertBeforeHandler - callback for insert before, will cause + to show
 * insertAfterHandler - callback for insert after, will cause + to show
 * 
 * Internal Properties:
 * parentType - for dragging
 * childType - for dropping, if it's blocks then this is the plan, which allows plans to insert onto the addmovement area
 * parentId
 * order
 **/

// TODO: split this out so there's a ProgramBuilderPreview within this child to handle the preview aspects of things
// because i want the right click to function outside the element
// for now it's fine to drag the entire row, that just has to change later
class ProgramBuilderHeader extends Component {
    static displayName = 'ProgramBuilderHeader';
    static contextType = ProgramBuilderContext;

    componentDidUpdate() {
        // is dragging
        if (this.context.isDragging !== this.props.isDragging) {
            this.context.setIsDragging(this.props.isDragging);
        }
    }

    renderInsertBefore(builderMenuItemStyle) {
        if (this.props.insertBeforeHandler) {
            return <span className={builderMenuItemStyle} onClick={this.props.insertBeforeHandler}>
                <Plus />
            </span>;
        } else {
            return <span className={builderMenuItemStyle} />;
        }
    }

    renderInsertAfter(builderMenuItemStyle) {
        if (this.props.insertAfterHandler) {
            return <span className={builderMenuItemStyle} onClick={this.props.insertAfterHandler}>
                <Plus />
            </span>;
        } else {
            return <span className={builderMenuItemStyle} />;
        }
    }

    renderHoverMenu(props) {
        return (
            <ProgramBuilderContext.Consumer>
                {({isDragging, dragReady, hamburgerActive, tapDrag, releaseDrag, mouseDownMenu, mouseUpMenu, tapMenu}) => {
                    // styles
                    let builderMenuStyle = styles.builderMenu;
                    let builderMenuItemStyle = styles.builderMenuItem;
                    let hamburgerMenuItemStyle = null;
            
                    if (isDragging || props.isReordering) {
                        builderMenuStyle = [builderMenuStyle, styles.hidden].join(' ');
                    } else if (dragReady) {
                        builderMenuItemStyle = [builderMenuItemStyle, styles.hidden].join(' ');
                    } else if (hamburgerActive) {
                        builderMenuStyle = styles.builderMenuOpen;
                        hamburgerMenuItemStyle = styles.selectedMenuItem;
                    }
                    if (!hamburgerMenuItemStyle) {
                        hamburgerMenuItemStyle = builderMenuItemStyle;
                    }

                    // render
                    return (<div className={builderMenuStyle}>
                        <div>
                            {this.renderInsertBefore(builderMenuItemStyle)}
                        </div>
                        <div>
                            {props.connectDragSource(<span className={styles.dragMenuItem} onMouseDown={tapDrag} onMouseUp={releaseDrag} onDrag={releaseDrag}>
                                <Reorder />
                            </span>)}
                            <span className={hamburgerMenuItemStyle} onMouseDown={mouseDownMenu} onMouseUp={mouseUpMenu} onClick={tapMenu}>
                                <Hamburger />
                            </span>
                        </div>
                        <div>
                            {this.renderInsertAfter(builderMenuItemStyle)}
                        </div>
                    </div>);
                }}
            </ProgramBuilderContext.Consumer>
        );
    }

    render() {
        const props = this.props;

        return props.connectDropTarget(<div className={styles.container}>
            <ContextMenuTrigger id={`programbuildercontext${props.id}`}>
                <div className={styles.headerContainer}>
                    {this.renderHoverMenu(props)}
                    {props.connectDragPreview(props.children)}
                </div>
            </ContextMenuTrigger>
        </div>);
    }

}

// drag parent

const source = {
    beginDrag(props) {
        return {
            id: props.id,
            orig_parent_id: props.parentId,
            orig_order: props.order,
            parent_id: props.parentId,
            order: props.order,
        };
    },
    isDragging(props, monitor) {
        return monitor.getItem().id === props.id;
    },
    endDrag(props, monitor) {
        const origItem = monitor.getItem();
        props.dropParentHandler(origItem.id, origItem.parent_id, origItem.order);
        props.finishDrag();
    }
};

const sourceCollect = (connect, monitor) => {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),    
    };
};

// drop child

const target = {

    hover(props, monitor, component) {
        const origItem = monitor.getItem();
        const origParentId = origItem.orig_parent_id;
        const origOrder = origItem.orig_order;
        const fromParentId = origItem.parent_id;
        const fromOrder = origItem.order;
        const toParentId = props.id;
        const toOrder = 1;

        if (fromParentId === toParentId && fromOrder === toOrder) {
            return;
        }

        // hover position
        let hoverPosition = false;
        if (origParentId === toParentId && toOrder === origOrder) {
            // if it would've been the original position, hide the hover position
            hoverPosition = null;
        }

        // update
        origItem.parent_id = toParentId;
        origItem.order = toOrder;

        // render
        const node = ReactDOM.findDOMNode(component);
        const hoverBoundingRect = node.getBoundingClientRect();
        if (hoverPosition === null) {
            props.updateHoverIndicator(null);
        } else {
            props.updateHoverIndicator(`${hoverBoundingRect.bottom+window.scrollY+props.hoverIndicatorOffset}px`);
        }
    },
};

const targetCollect = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
    };
};

export default DropTarget((props) => props.childType, target, targetCollect)(
    DragSource((props) => props.parentType, source, sourceCollect)(ProgramBuilderHeader)
);
