import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import * as LoginSelectors from 'shared_redux/selectors/view/login';
import View from './view';

const mapStateToProps = (state) => {
    document.title = "RepOne";

    return {
        email: LoginSelectors.getEmail(state),
        password: LoginSelectors.getPassword(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        signIn: Actions.signIn,
        updateEmail: Actions.updateEmail,
        updatePassword: Actions.updatePassword,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
