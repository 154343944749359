import React from 'react';

import {
    ProgramBuilderComponent,
    ProgramBuilderHeader,
    ProgramBuilderMenu,
    ProgramBuilderMenuItem,
    ProgramBuilderFooter,
} from '../program_builder_component';
import styles from './styles.module.scss';
import MicrocycleSessionsView from '../microcycle_session';

import DragItemTypes from 'constants/dragItemTypes';

export default props => {
    if (props.expanded) {
        var title = <span onClick={()=>props.collapseMicrocycle(props.id)}>{props.name.toUpperCase()} {props.order} <img src="https://assets.reponestrength.com/carrot_down.png" /></span>;
        var microcycleSessions = props.microcycle_session_ids.map(session_id => {
            return <MicrocycleSessionsView
                key={`MicrocycleSession${session_id}`}
                session_id={session_id}
                isReordering={props.isReordering}
                finishDrag={props.finishDrag}
                updateHoverIndicator={props.updateHoverIndicator}/>;
        });
        if (props.microcycle_session_ids.length > 0) {
            var createMicrocycleSession = null;
        } else {
            var createMicrocycleSession = (
                <ProgramBuilderFooter
                    hoverIndicatorOffset={-2}
                    childIds={props.microcycle_session_ids}>

                    <div className={styles.addSession} onClick={()=>props.createMicrocycleSession(props.id, props.microcycle_session_ids.length+1)}>
                        <span>+</span>SESSION {props.microcycle_session_ids.length+1}
                    </div>

                </ProgramBuilderFooter>
            );
        }
    } else {
        var title = <span onClick={()=>props.expandMicrocycle(props.id)}>{props.name.toUpperCase()} {props.order} <img src="https://assets.reponestrength.com/carrot_right.png" /></span>;
        var microcycleSessions = null;
        var createMicrocycleSession = null;
    }

    return (
        <div className={styles.microcycle}>
            <div className={styles.microcycleBorder} />

            <ProgramBuilderComponent
                parentType={DragItemTypes.MICROCYCLE}
                childType={DragItemTypes.MICROCYCLE_SESSION}
                id={props.id}
                parentId={props.program_id}
                order={props.order}
                isReordering={props.isReordering}
                finishDrag={props.finishDrag}
                updateHoverIndicator={props.updateHoverIndicator}
                hoverIndicatorTopOffset={-2}
                hoverIndicatorBottomOffset={-2}>

                <ProgramBuilderHeader
                    hoverIndicatorOffset={0}
                    dropParentHandler={props.moveMicrocycle}>

                    <h2>{title}</h2>

                </ProgramBuilderHeader>

                <ProgramBuilderMenu>
                    <ProgramBuilderMenuItem onClick={()=>props.duplicateMicrocycle(props.id)}>
                        DUPLICATE
                    </ProgramBuilderMenuItem>
                    <ProgramBuilderMenuItem onClick={()=>window.confirm("Are you sure?") && props.deleteMicrocycle(props.id)}>
                        DELETE
                    </ProgramBuilderMenuItem>
                </ProgramBuilderMenu>

                {microcycleSessions}

                {createMicrocycleSession}

            </ProgramBuilderComponent>
        </div>
    );
};
