import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as ProgramBuilderSelectors from 'shared_redux/selectors/view/program_builder';

const makeMapStateToProps = () => {
    const getDraftBlock = ProgramBuilderSelectors.makeGetDraftBlock();
    return (state, props) => {
        return {
            ...getDraftBlock(state, props),
            insertPlanPosition: ProgramBuilderSelectors.getInsertPlanPosition(state, props),
        };
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createBlock: Actions.createBlock,
        createMicrocycleSession: Actions.createMicrocycleSession,
        updateBlockType: Actions.updateBlockType,
        updateBlockMin: Actions.updateBlockMin,
        updateBlockMax: Actions.updateBlockMax,
        updateBlockRest: Actions.updateBlockRest,
        saveBlock: Actions.saveBlock,
        duplicateBlock: Actions.duplicateBlock,
        moveBlock: Actions.moveBlock,
        deleteBlock: Actions.deleteBlock,

        insertPlan: Actions.insertPlan,
    }, dispatch);
};

const Container = connect(
    makeMapStateToProps,
    mapDispatchToProps,
)(View);

export default Container;
