import uuidv4 from 'uuid/v4';

import {
    DRAFT_CREATE_BLOCK,
    ATTEMPT_CREATE_BLOCK,
} from 'shared_redux/actions';

export const createBlock = (session_id, type, order) => {
    const id = uuidv4();
    return {
        type: DRAFT_CREATE_BLOCK,
        pushType: ATTEMPT_CREATE_BLOCK,
        payload: {
            id,
            session_id: session_id,
            type,
            min_sets: 3,
            max_sets: 3,
            order: order,
        }
    };
};
