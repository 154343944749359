import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

class View extends Component {

    componentDidMount() {
        this.props.fetchTeams();
    }

    _renderModels() {
        var results = [];
        for (var model of this.props.models) {
            if (model.type === 'all') {
                if (model.selected) {
                    var style = styles.selectedTeamFilter;
                } else {
                    var style = styles.teamFilter;
                }
            } else if (model.type === 'team') {
                if (model.selected) {
                    var style = styles.selectedTeamFilter;
                } else {
                    var style = styles.teamFilter;
                }
            } else if (model.type === 'group') {
                if (model.selected) {
                    var style = styles.selectedGroupFilter;
                } else {
                    var style = styles.groupFilter;
                }
            } else {
                continue;
            }
            results.push(
                (<Link to={model.url} key={model.url} className={style}>{model.name}</Link>)
            );
        }
        return results;
    }

    render() {
        return (
            <div>
                {this._renderModels()}
            </div>
        );
    }

}

export default View;
