import React from 'react';

export default props => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H8C8.55228 0 9 0.447715 9 1C9 1.55228 8.55228 2 8 2H2V18H18V12C18 11.4477 18.4477 11 19 11C19.5523 11 20 11.4477 20 12V19C20 19.5523 19.5523 20 19 20H1C0.447715 20 0 19.5523 0 19V1Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11 1C11 0.447715 11.4477 0 12 0H19C19.5523 0 20 0.447715 20 1V8C20 8.55228 19.5523 9 19 9C18.4477 9 18 8.55228 18 8V3.41421L10.7071 10.7071C10.3166 11.0976 9.68342 11.0976 9.29289 10.7071C8.90237 10.3166 8.90237 9.68342 9.29289 9.29289L16.5858 2H12C11.4477 2 11 1.55228 11 1Z"/>
        </svg>
    );
};
