import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'redux-orm';

import View from './view';
import * as Actions from './actions';
import orm from 'shared_redux/reducers/models/orm';

const getViewModel = createSelector(
    orm,
    state => state.database,
    (state, props) => props.match.params.athleteid,
    (state, props) => props.match.params.athletename,
    (state, props) => props.match.params.tab,
    (state, props) => props.history,
    (session, athleteId, athleteURLName, tab, history) => {
        // vars
        const Athletes = session.Athletes;
        const athlete = Athletes.withId(athleteId);
        const calculatedAthleteURLName = athlete ? athlete.urlName() : null;
        const displayName = athlete ? athlete.displayName() : null;

        if (calculatedAthleteURLName && athleteURLName !== calculatedAthleteURLName) {
            // add url name
            if (tab) {
                history.replace(`/athletes/${athleteId}/${calculatedAthleteURLName}/${tab}`);
            } else {
                history.replace(`/athletes/${athleteId}/${calculatedAthleteURLName}`);
            }
        }

        // set title
        if (displayName) {
            if (tab === 'analysis') {
                document.title = `${displayName} Analysis`;
            } else if (tab === 'maxes') {
                document.title = `${displayName} Maxes`;
            } else {
                document.title = `${displayName} History`;
            }
        }

        return {
            athlete,
            displayName,
        };
    }
);

const mapStateToProps = (state, props) => getViewModel(state, props);

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAthlete: Actions.fetchAthlete,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
