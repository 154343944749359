import {
    FETCH_ATHLETE_SUCCEEDED,
    FETCH_ATHLETES_SUCCEEDED,
    FETCH_PROGRAM_SCHEDULES_SUCCEEDED,
    CREATE_ATHLETE_SUCCEEDED,
    SAVE_ATHLETE_METADATA_SUCCEEDED,
    UPDATE_ATHLETE_CATEGORY_SUCCEEDED,
    UPLOAD_AVATAR_SUCCEEDED,
    REMOVE_ATHLETE_SUCCEEDED,
    DELETE_ROSTER_SELECTED_ATHLETES_SUCCEEDED,
    SAVE_SELECTED_ATHLETES_CATEGORIES_SUCCEEDED,

    SET_FEED_ITEMS,
    UPDATE_FEED_ENTRY_ITEM,
    UPDATE_FEED_COMPLETE_ITEM,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

const updateIfChanged = (Athletes, json) => {
    const existing = Athletes.withId(json.id);
    if (existing && existing.avatar_url === json.avatar_url && existing.last_modified === json.last_modified && existing.created_on === json.created_on) {
        return;
    }
    Athletes.upsert(json);
};

class Athletes extends Model {

    displayName() {
        if (this.middle_name) {
            return `${this.first_name} ${this.middle_name} ${this.last_name}`;
        } else {
            return `${this.first_name} ${this.last_name}`;
        }
    }

    urlName() {
        if (this.middle_name) {
            var name = `${this.first_name}-${this.middle_name}-${this.last_name}`;
        } else {
            var name = `${this.first_name}-${this.last_name}`;
        }
    
        return encodeURIComponent(name.replace(' ', '-')).toLowerCase();
    }

    static reducer(action, Athletes, session) {
        switch (action.type) {
            case FETCH_ATHLETES_SUCCEEDED:
                action.payload.forEach(athlete => {
                    const {['team']:team, ['group']:group, ['maxes']:maxes, ...rawAthlete} = athlete;
                    updateIfChanged(Athletes, rawAthlete);
                });
                break;
            case FETCH_PROGRAM_SCHEDULES_SUCCEEDED: {
                for (var schedule of action.payload) {
                    if (schedule.athlete) {
                        updateIfChanged(Athletes, schedule.athlete);
                    }
                }
                break;
            }
            case CREATE_ATHLETE_SUCCEEDED:
                Athletes.upsert(action.payload);
                break;
            case FETCH_ATHLETE_SUCCEEDED:
                updateIfChanged(Athletes, action.payload);
                break;
            case SAVE_ATHLETE_METADATA_SUCCEEDED:
            case UPDATE_ATHLETE_CATEGORY_SUCCEEDED:
            case UPLOAD_AVATAR_SUCCEEDED:
                Athletes.withId(action.payload.id).update(action.payload);
                break;
            case REMOVE_ATHLETE_SUCCEEDED:
                Athletes.withId(action.id).delete();
                break;
            case DELETE_ROSTER_SELECTED_ATHLETES_SUCCEEDED:
                for (let id of action.athlete_ids) {
                    Athletes.withId(id).delete();
                }
                break;
            case SAVE_SELECTED_ATHLETES_CATEGORIES_SUCCEEDED:
                for (let athlete of action.payload) {
                    const {['team']:team, ['group']:group, ['maxes']:maxes, ...rawAthlete} = athlete;
                    updateIfChanged(Athletes, rawAthlete);
                }
                break;

            
            case SET_FEED_ITEMS:
                action.feed_items.forEach(i => {
                    updateIfChanged(Athletes, i.athlete);
                });
                break;

            case UPDATE_FEED_ENTRY_ITEM:
            case UPDATE_FEED_COMPLETE_ITEM:
                updateIfChanged(Athletes, action.feed_item.athlete);
                break;

            case SIGN_OUT_SUCCEEDED:
                Athletes.all().delete();
                break;
        }
    }

}
Athletes.modelName = 'Athletes';

Athletes.fields = {
    id: attr(),
    rack_id: attr(), // TODO: link this
    first_name: attr(),
    middle_name: attr(),
    last_name: attr(),
    avatar_url: attr(),
    organization_id: attr(),
    team_id: fk({
        to: 'Teams',
        as: 'team',
        relatedName: 'athletes',
    }),
    group_id: fk({
        to: 'Groups',
        as: 'group',
        relatedName: 'athletes',
    }),
    last_activity_time: attr(),
    openbarbell_id: attr(),
    has_avatar: attr(),
    position: attr(),
    number: attr(),
    archived: attr(),
};

export default Athletes;
