import React from 'react';

export default props => {
    return (
        <svg >
            <circle cx="10" cy="10" r="9.5" />
            <path d="M10.5 5L10.5 10L10.5 15M10.5 5L8 7.5M10.5 5L13 7.5M10.5 15L8 12.5M10.5 15L13 12.5" />
        </svg>
    );
};
