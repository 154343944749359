import React from 'react';

export default props => {
    return (
        <svg >
            <circle cx="10" cy="10" r="9.5" />
            <line x1="6.66699" y1="6.16669" x2="13.3337" y2="6.16669" />
            <line x1="6.66699" y1="9.5" x2="13.3337" y2="9.5" />
            <line x1="6.66699" y1="12.8333" x2="13.3337" y2="12.8333" />
        </svg>
    );
};
