import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/browser';

import reducers from './shared_redux/reducers';
import sagas from './shared_redux/sagas';
import Application from './features/application';
import Alerts from './shared_features/alerts';

import { INITIALIZED } from 'shared_redux/actions';

// initialize sentry
Sentry.init({dsn: "https://79dc68859013434dba36d94c797c2649@sentry.io/1370756"});

// create store
var sagaMiddleware = createSagaMiddleware();
const middlewares = applyMiddleware(
    thunk,
    sagaMiddleware
);
if (process.env.NODE_ENV === 'development') {
    const devTools = require('redux-devtools-extension');
    var enhancers = devTools.composeWithDevTools(middlewares);
} else {
    var enhancers = compose(middlewares);
}
const store = createStore(reducers, {}, enhancers);
sagaMiddleware.run(sagas, store.dispatch, store.getState);

// render
ReactDOM.render(
    <Provider store={store}>
        <Alerts>
            <Application />
        </Alerts>
    </Provider>,
    document.getElementById('root')
);

// initialization action
store.dispatch({ type: INITIALIZED });
