import API from './api';

export default {

    getTeams: (organization_id) => API({
        url: `teams?organization_id=${organization_id}`,
        method: 'GET',
    }),

    createTeam: (team) => API({
        url: 'teams',
        method: 'POST',
        json: team,
    }),

    updateTeam: (team) => API({
        url: `teams/${team.id}`,
        method: 'PATCH',
        json: team,
    }),

    removeTeam: (teamId) => API({
        url: `teams/${teamId}`,
        method: 'DELETE',
    }),
    
};
