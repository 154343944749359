import {
    FETCH_ACCOUNT_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class Accounts extends Model {

    static reducer(action, Accounts, session) {
        switch (action.type) {
            case FETCH_ACCOUNT_SUCCEEDED:
                Accounts.upsert(action.payload);
                break;
            case SIGN_OUT_SUCCEEDED:
                Accounts.all().delete();
                break;
        }
    }

}
Accounts.modelName = 'Accounts';

Accounts.fields = {
    email: attr(), // note: this is generated, not part of the actual server model
    organization_id: attr(),
};

export default Accounts;
