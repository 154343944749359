import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as ProgramBuilderSelectors from 'shared_redux/selectors/view/program_builder';
import * as QueueSelectors from 'shared_redux/selectors/system/queue';

const mapStateToProps = (state, props) => {
    // TODO: confirm should be duplicating the results via a spread selector
    return {
        ...ProgramBuilderSelectors.getDraftProgram(state, props),
        isSyncing: QueueSelectors.getIsExecuting(state, props),
        shouldWarnLeave: QueueSelectors.hasItems(state, props),
        errorState: ProgramBuilderSelectors.getErrorState(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        cancelAndClear: Actions.cancelAndClear,
        retryQueue: Actions.retryQueue,
        fetchProgram: Actions.fetchProgram,
        fetchExercises: Actions.fetchExercises,
        createMicrocycle: Actions.createMicrocycle,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
)(View);

export default Container;
