import { connect } from 'react-redux';
import View from './view';

import * as AthleteHistorySelectors from 'shared_redux/selectors/view/athlete_history';
import * as HistorySessionsSelectors from 'shared_redux/selectors/view/history_sessions';

// expects id
const makeMapStateToProps = () => {
    const getAthleteHistoryBlock = AthleteHistorySelectors.makeGetAthleteHistoryBlock();
    return (state, props) => {
        const model = getAthleteHistoryBlock(state, props);
        return {
            ...model,
            visible: HistorySessionsSelectors.getIsSessionExpanded(state, model.session_id),
        };
    }
};

const Container = connect(
    makeMapStateToProps,
    null,
)(View);

export default Container;
