import React, {Component} from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import styles from './styles.module.scss';
import colors from 'shared_styles/colors.scss';

class View extends Component {

    // expects { type, id, description }
    _renderGroups() {
        if (!this.props.groups) {
            return
        }

        var groups = this.props.groups.map(model => ({
            type: model.type,
            value: model.id,
            label: model.description,
            team_id: model.team_id,
            group_id: model.group_id,
        }));
        groups.unshift({
            type: 'all',
            value: 'all',
            label: 'Everyone',
            team_id: null,
            group_id: null,
        })

        if (this.props.selectedGroup) {
            var value = groups.find(g => g.type === 'group' && g.value === this.props.selectedGroup);
        } else if (this.props.selectedTeam) {
            var value = groups.find(g => g.type === 'team' && g.value === this.props.selectedTeam);
        } else {
            var value = groups[0];
        }

        return (
            <div className={styles.group}>
                <div>GROUP</div>
                <Select
                    name="group"
                    placeholder={"groups"}
                    className={styles.select}
                    clearable={false}
                    deleteRemoves={false}
                    value={value}
                    styles={{
                        control: (base) => ({
                            ...base,
                            '&:hover': {
                                borderColor: colors.border_color,
                            },
                            borderColor: colors.border_color,
                            boxShadow: 'none',
                            height: 41,
                            minHeight: 41,
                        })
                    }}
                    onChange={(selectedOption)=> {
                        if (selectedOption) {
                            switch (selectedOption.type) {
                            case 'group':
                                this.props.updateGroupFilter(selectedOption.team_id, selectedOption.group_id);
                                break;
                            case 'team':
                                this.props.updateGroupFilter(selectedOption.team_id, selectedOption.group_id);
                                break;
                            default:
                                this.props.updateGroupFilter(null, null);
                                break;
                            }
                        }
                    }}
                    options={groups}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                />
            </div>
        );
    }

    _renderInterval() {
        if (!this.props.showIntervals) {
            return null;
        }

        const intervals = [
            { value: 'daily', label: 'Daily' },
            { value: 'weekly', label: 'Weekly' },
            { value: 'monthly', label: 'Monthly' },
        ];
        if (this.props.interval) {
            var value = intervals.find(i => i.value == this.props.interval);
        } else {
            var value = intervals[1];
        }

        return (
            <div className={styles.interval}>
                <div>INTERVAL</div>
                <Select
                    name="interval"
                    placeholder={"interval"}
                    className={styles.select}
                    clearable={false}
                    deleteRemoves={false}
                    value={value}
                    styles={{
                        control: (base) => ({
                            ...base,
                            '&:hover': {
                                borderColor: colors.border_color,
                            },
                            borderColor: colors.border_color,
                            boxShadow: 'none',
                            height: 41,
                            minHeight: 41,
                        })
                    }}
                    onChange={(selectedOption)=> {
                        if (selectedOption) {
                            this.props.updateInterval(selectedOption.value);
                        }
                    }}
                    options={intervals}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                />
            </div>
        );
    }

    // TODO: if filters are to be used in analysis, then move the render intervals to its own shared feature
    // for now just doing filters on reports and default on analysis until confirming how filters operate

    _renderTimeRange() {
        const timeRanges = [
            { value: 7, label: 'Past Week' },
            { value: 30, label: 'Past Month' },
            { value: 90, label: 'Past 3 Months' },
            { value: 365, label: 'Past Year' },
            { value: 0, label: 'Custom' },

        ];
        if (this.props.timeRange || this.props.timeRange === 0) {
            var value = timeRanges.find(i => i.value == this.props.timeRange);
        } else {
            var value = timeRanges[1];
        }

        return (
            <div className={styles.timeRange}>
                <div>TIME RANGE</div>
                <Select
                    name="timeRange"
                    placeholder={"time range"}
                    className={styles.select}
                    clearable={false}
                    deleteRemoves={false}
                    value={value}
                    styles={{
                        control: (base) => ({
                            ...base,
                            border: `1px solid ${colors.border_color}`,
                            boxShadow: 'none',
                            height: 41,
                            minHeight: 41,
                            borderRadius: 0,
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            '&:hover': {
                                borderColor: colors.border_color,
                            },
                        })
                    }}
                    onChange={(selectedOption)=> {
                        if (selectedOption) {
                            this.props.updateTimeRange(selectedOption.value);
                        }
                    }}
                    options={timeRanges}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                />
            </div>
        );
    }

    // TODO: timezone should be based on settings
    _renderStartDate() {
        return (
            <div>
                <div>START</div>
                <DatePicker
                    className={styles.startDatePicker}
                    selected={moment(this.props.startDate).toDate()}
                    placeholderText="From"
                    dateFormat="M/d/yy"
                    onChange={(date) => this.props.updateStartDate(moment(date))} />
            </div>
        );
    }

    // TODO: timezone should be based on settings
    _renderEndDate() {
        return (
            <div>
                <div>END</div>
                <DatePicker
                    className={styles.endDatePicker}
                    selected={moment(this.props.endDate).toDate()}
                    placeholderText="To"
                    dateFormat="M/d/yy"
                    onChange={(date) => this.props.updateEndDate(moment(date))} />
            </div>
        );
    }

    render() {
        return (
            <div className={styles.container}>
                {this._renderGroups()}
                {this._renderInterval()}
                {this._renderTimeRange()}
                {this._renderStartDate()}
                {this._renderEndDate()}
            </div>
        );
    }

}

export default View;
