import {
	ATTEMPT_FETCH_PROGRAMS,
    FETCH_PROGRAMS_SUCCEEDED,
} from 'shared_redux/actions';

export default (state = false, action) => {
    switch (action.type) {
		case ATTEMPT_FETCH_PROGRAMS:
			return true;
        case FETCH_PROGRAMS_SUCCEEDED:
			return false;
		default:
			return state;
	}
};
