import React, {Component} from 'react';

import styles from './styles.module.scss';
import EntryComponent from '../entry';

class View extends Component {

    _renderSets() {
        if (this.props.numSets) {
            return (<div>
                <div>SETS</div>
                <div>{this.props.numSets}</div>
            </div>);
        }
        return null;
    }

    _renderLoadRange() {
        if (this.props.loadRange) {
            return (<div>
                    <div>LOAD (LBS)</div>
                    <div>{this.props.loadRange}</div>
                </div>);
        }
        return null;
    }

    _renderAutoregulationRange() {
        if (this.props.autoregulationRange) {
            return (<div>
                <div>{this.props.autoregulationHeader} VELOCITY (M/S)</div>
                <div>{this.props.autoregulationRange}</div>
            </div>);
        }
        return null;
    }

    _renderEntries() {
        return this.props.entryIds.map(id => <EntryComponent key={`athlete-history-entry-${id}`} id={id} />);
    }

    render() {
        if (!this.props.visible) {
            return null;
        }

        if (this.props.carrotVisible) {
            if (this.props.expanded) {
                var carrot = (<div><img src="https://assets.reponestrength.com/carrot_down.png" /></div>);
                var onClick = () => this.props.collapse(this.props.id);
            } else {
                var carrot = (<div><img src="https://assets.reponestrength.com/carrot_right.png" /></div>);
                var onClick = () => this.props.expand(this.props.id);
            }
            var containerStyle = styles.hoverableContainer;
        } else {
            var carrot = <div />;
            var onClick = null;
            var containerStyle = styles.container;
        }

        return (
            <div>
                <div className={containerStyle} onClick={onClick}>
                    {carrot}
                    <div>{this.props.name}</div>
                    {this._renderSets()}
                    {this._renderLoadRange()}
                    {this._renderAutoregulationRange()}
                </div>
                <div>{this._renderEntries()}</div>
            </div>
        );
    }

}

export default View;
