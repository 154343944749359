import React from 'react';
import { Switch, Route } from 'react-router-dom';

import TitleView from 'shared_features/title';
import TabsView from 'shared_features/tab_header';
import General from './general';
import Stations from './stations';
// import Accounts from './accounts';

export default props => {
    return (<div>
        <TitleView title="Settings" />
        <TabsView
            tabs={[{
                text: "General",
                url: `/settings`,
            }, {
                text: "Stations",
                url: `/settings/stations`,
            }/*, {
                text: "Account",
                url: `/settings/account`,
            }*/]}/>
        <Switch>
            <Route path='/settings/stations' component={Stations} />
            {/* <Route path='/settings/account' component={Stations} /> */}
            <Route path='/settings' component={General} />
        </Switch>
    </div>);
}
