import React, {Component} from 'react';

import RosterHeaderView from 'shared_features/roster_header';
import FilterView from './category_filters';
import ResultsView from './results';
import ColumnsFilterView from './columns_filter';
import ImportAthletesView from './import_athletes';
import MacroView from './macro_footer';
import styles from './styles.module.scss';

export default class View extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <div>
                <RosterHeaderView />
                <div className={styles.main}>
                    <div className={styles.grid}>
                        <FilterView />
                        <ResultsView />
                    </div>
                    <MacroView />
                    <ImportAthletesView />
                    <ColumnsFilterView />
                </div>
            </div>
        );
    }
};
