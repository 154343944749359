import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import Spinner from 'shared_features/spinner';

import styles from './styles.module.scss';

export default props => {
    const done = !props.isUploading ? <div className={styles.done} onClick={props.save}>Done</div> : <div className={[styles.done, styles.disabled].join(' ')}>Done</div>;
    return (
        <Modal
            className={styles.modal}
            overlayClassName={styles.overlay}
            isOpen={props.isShowing}
            onRequestClose={props.closeModal}>
                <h1>Choose visible columns</h1>
                <ul className={styles.list}>{props.models.map(model => {
                    const style = model.visible ? styles.checked : styles.unchecked;
                    return <li key={`${model.type}${model.exercise_id}`}>
                        <input type="checkbox"
                            checked={model.visible}
                            onChange={()=> model.visible ? props.deselectColumn(model.type, model.exercise_id) : props.selectColumn(model.type, model.exercise_id)}/>
                        <label className={style}>{model.value}</label>
                    </li>
                })}</ul>
                <Link className={styles.manageMaxes} to={`/settings`}> Manage maxes in settings →</Link>
                {done}
                {props.isUploading ? <Spinner /> : null}
        </Modal>
    );
};
