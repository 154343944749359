import * as math from 'mathjs';
import { createSelector } from 'redux-orm';
import { createSelector as createReselectSelector } from 'reselect';
import orm from 'shared_redux/reducers/models/orm';
import { getRosterSettings } from 'shared_redux/selectors/models/roster_settings';
import { getAccount } from 'shared_redux/selectors/models/accounts';

const stateRoot = state => state.roster;

// routing

export const getURLSearch = (state, props) => {
    if (props.location) {
        return props.location.search;
    }
    return null;
};

export const getPathName = (state, props) => {
    if (props.location) {
        return props.location.pathname;
    }
    return null;
};

// filter

const searchCondition = (athlete, search) => {
    if (!search) {
        return true;
    }
    const searchCompare = search.toLowerCase();

    if (athlete.first_name.toLowerCase().includes(searchCompare)) {
        return true;
    }
    if (athlete.middle_name && athlete.middle_name.toLowerCase().includes(searchCompare)) {
        return true;
    }
    if (athlete.last_name.toLowerCase().includes(searchCompare)) {
        return true;
    }

    return false;
};

// batch editing

export const getNumAthletesSelected = (state, props) => stateRoot(state).numAthletesSelected;
export const getSelectedTeamId = (state, props) => stateRoot(state).team_id;
export const getSelectedGroupId = (state, props) => stateRoot(state).group_id;
export const getSelectedAthleteIds = (state, props) => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const dict = stateRoot(state);
    const result = [];
    for (let key in dict) {
        if (key.match(regex)) {
            if (dict[key] === true) {
                result.push(key);
            }
        }
    }
    return result;
};
export const getIsBatchUploading = (state, props) => stateRoot(state).isBatchUploading;

// roster

export const getRosterIsLoading = (state, props) => !stateRoot(state).loaded;

// NOTE: this returns an array to be directly plugged into react-select
// TODO: check if I need the value property
export const getRosterCategoriesOptions = createSelector(
    orm,
    state => state.database,
    (session) => {
        const { Teams } = session;
        const result = [];
        Teams.all().orderBy('name', 'asc').toModelArray().forEach(team => {
            // push team
            result.push({ label: team.name, team_id: team.id, group_id: null, value: `${team.id}${null}` });

            // push groups
            team.groups.toRefArray().forEach(group => {
                result.push({ label: `${team.name} - ${group.name}`, team_id: team.id, group_id: group.id, value: `${team.id}${group.id}` });
            });
        });
        return result;
    }
);

export const getRosterLabels = createSelector(
    orm,
    state => state.database,
    (session) => {
        const { RosterSettings } = session;
        return RosterSettings.all().orderBy('order', 'asc').filter(o => o.visible === true).toRefArray();
    }
);

export const getRosterAllAthletesSelected = (state, props) => stateRoot(state).allAthletesSelected;

export const makeGetAthleteRowViewModel = () => createSelector(
    orm,
    state => state.database,
    (state, props) => props.id,
    state => stateRoot(state),
    getRosterSettings,
    (session, id, dictionary, rosterSettings) => {
        // session objects
        const { Athletes } = session;
        const athlete = Athletes.withId(id);
        const fields = [];

        if (athlete && rosterSettings) {
            rosterSettings.forEach(s => {
                if (!s.visible) {
                    return;
                }

                switch (s.type) {
                    case 'AVATAR':
                        fields.push({ type: 'AVATAR', value: athlete.avatar_url });
                        return;
                    case 'NAME':
                        fields.push({ type: 'NAME', value: athlete.displayName() });
                        return;
                    case 'CATEGORY':
                        fields.push({ type: 'CATEGORY', team_id: athlete.team_id, group_id: athlete.group_id });
                        return;
                    case 'MAXABLE_EXERCISE': {
                        const max = athlete.maxes.filter(m => m.exercise_id === s.exercise_id).first();
                        let value;
                        if (max) {
                            if (max.weight_unit === 'KGS') {
                                value = (max.weight * 2.20462).toFixed(2);
                            } else {
                                value = max.weight.toString();
                            }
                        } else {
                            value = '';
                        }
                        fields.push({ type: 'MAXABLE_EXERCISE', max_id: max ? max.id : null, exercise_id: s.exercise_id, value });
                        return;
                    }
                    case 'CALCULATED_MAX_EXERCISE': {
                        const max = athlete.maxes.filter(m => m.exercise_id === s.parent_exercise_id).first();
                        let value;
                        if (max) {
                            const calculatedValue = math.evaluate(s.formula, {x:max.weight});
                            if (max.weight_unit === 'KGS') {
                                value = (calculatedValue * 2.20462).toFixed(2);
                            } else {
                                value = calculatedValue.toString();
                            }
                        } else {
                            value = '';
                        }
                        fields.push({ type: 'CALCULATED_MAX_EXERCISE', exercise_id: s.exercise_id, value });
                        return;
                    }
                }
            });
        }

        return {
            id: athlete.id,
            isSelected: dictionary[athlete.id] === true,
            fields,
        };
    }
);

export const getRosterViewModel = createSelector(
    orm,
    state => state.database,
    getAccount,
    getURLSearch,
    getRosterIsLoading,
    (session, account, search, isLoading) => {
        // session objects
        const { Teams, Groups, Athletes } = session;

        // parse location props
        const params = new URLSearchParams(search);
        let teamId = params.get('team_id');
        const groupId = params.get('group_id');
        const name = params.get('name');

        // title
        if (teamId) {
            const team = Teams.withId(teamId);
            var title = team ? team.name : 'Loading...';
        } else if (groupId) {
            const group = Groups.withId(groupId);
            var title = group ? `${group.team.name} - ${group.name}` : 'Loading...';
            teamId = group ? group.team_id : null;
        } else {
            var title = 'All Athletes';
        }
        
        // athletes
        const hasAthletes = Athletes.count() > 0;
        const athleteIds = Athletes.filter(athlete => {
            if (name && !searchCondition(athlete, name)) {
                return false;
            }
            if (teamId && athlete.team_id !== teamId) {
                return false;
            }
            if (groupId && athlete.group_id !== groupId) {
                return false;
            }
            return true;
        }).orderBy('first_name').toRefArray().map(a => a.id);

        return {
            hasAthletes,
            athleteIds,
            title,
            teamId,
            groupId,
            organizationId: account ? account.organization_id : null,
            isLoading,
            search: name || "",
        };
    }
);

export const getRosterVisibleAthleteIds = createReselectSelector(
    getRosterViewModel,
    (model) => {
        return model.athleteIds;
    }
);

// left hand side filter navigation

// TODO: consider making it row by row as well for the selected states for performance purposes
export const getRosterFilterViewModels = createSelector(
    orm,
    state => state.database,
    getURLSearch,
    getPathName,
    (session, search, pathname) => {
        // session objects
        const { Teams } = session;

        // parse location props
        const params = new URLSearchParams(search);
        const teamId = params.get('team_id');
        const groupId = params.get('group_id');
        const name = params.get('name');

        // set ALL ATHLETES
        var allParams = new URLSearchParams(search);
        allParams.delete('team_id');
        allParams.delete('group_id');
        if (!name) {
            allParams.delete('name');
        }
        var models = [{
            type: "all",
            name: "All Athletes",
            url: `${pathname}?${allParams}`,
            selected: !teamId && !groupId,
        }];

        // set TEAMS
        Teams.all().orderBy('name').toModelArray().forEach(team => {
            var teamParams = new URLSearchParams(search);
            teamParams.set('team_id', team.id);
            teamParams.delete('group_id');
            if (!name) {
                teamParams.delete('name');
            }

            models.push({
                type: "team",
                name: team.name,
                selected: teamId && team.id == teamId,
                url: `${pathname}?${teamParams}`,
            });

            // set GROUPS
            team.groups.toModelArray().forEach(group => {
                var groupParams = new URLSearchParams(search);
                groupParams.set('group_id', group.id);
                groupParams.delete('team_id');
                if (!name) {
                    groupParams.delete('name');
                }
                
                models.push({
                    type: "group",
                    name: group.name,
                    selected: groupId && group.id === groupId,
                    url: `${pathname}?${groupParams}`,
                });
            });
        });

        return models;
    }
);
