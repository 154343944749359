import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import View from './view';
import * as Actions from './actions';
import * as RosterSelectors from 'shared_redux/selectors/view/roster';

const mapStateToProps = (state, props) => {
    const result = RosterSelectors.getRosterViewModel(state, props);
    document.title = result.title;
    return result;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchRosterSettings: Actions.fetchRosterSettings,
        fetchAthletes: Actions.fetchAthletes,

        filterAthletes: Actions.filterAthletes,
        createAthlete: Actions.createAthlete,
        filterColumns: Actions.filterColumns,
        importAthletes: Actions.importAthletes,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default withRouter(Container);
