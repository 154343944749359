import React from 'react';
import Modal from 'react-modal';

import styles from './styles.module.scss';

export default props => {
    return (
        <Modal
            className={styles.modal}
            overlayClassName={styles.overlay}
            isOpen={props.isShowing}
            onRequestClose={()=>props.closeModal()}>

            <div className={styles.cardTitle}>Are you sure?</div>
            <div className={styles.description}>This action will remove the max from <div>all athlete profiles</div> and cannot be undone.</div>
            <div className={styles.buttons}>
                <div onClick={()=>props.closeModal()}>Cancel</div>
                <div onClick={()=>props.removeMaxableExercise(props.exerciseId)}>Yes, remove max</div>
            </div>
        </Modal>
    );
};
