import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import SingleProgram from './single';
import ListPrograms from './list';

export default class View extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <Switch>
                <Route path='/programs/:programid/:programname/:tab' component={SingleProgram} />
                <Route path='/programs/:programid/:programname' component={SingleProgram} />
                <Route path='/programs/:programid' component={SingleProgram}/>
                <Route path='/programs' component={ListPrograms}/>
            </Switch>
        );
    }
};
