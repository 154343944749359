import {
    SET_FEED_ITEMS,
    DELETE_FEED_ITEM,

    UPDATE_FEED_ENTRY_ITEM,
    UPDATE_FEED_COMPLETE_ITEM,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

// TODO: need to cascade delete to child relations
class FeedItems extends Model {

    static reducer(action, FeedItems, session) {
        switch (action.type) {
            case SET_FEED_ITEMS:
                action.feed_items.forEach(i => {
                    const {['feed_complete_item']: feed_complete_item, ['feed_entry_item']: feed_entry_item, ['athlete_session']: athlete_session, ['athlete']: athlete, ...raw} = i;
                    FeedItems.upsert(raw);
                })
                break;
            case DELETE_FEED_ITEM: {
                const item = FeedItems.withId(action.feed_item_id);
                if (item) {
                    item.delete();
                }
                break;
            }
        
            case UPDATE_FEED_ENTRY_ITEM:
            case UPDATE_FEED_COMPLETE_ITEM: {
                const {['feed_complete_item']: feed_complete_item, ['feed_entry_item']: feed_entry_item, ['athlete_session']: athlete_session, ['athlete']: athlete, ...raw} = action.feed_item;
                FeedItems.upsert(raw);
                break;
            }

            case SIGN_OUT_SUCCEEDED:
                FeedItems.all().delete();
                break;
        }
    }
    
}

FeedItems.modelName = 'FeedItems';

FeedItems.fields = {
    id: attr(),
    time: attr(),
    athlete_id: fk({
        to: 'Athletes',
        as: 'athlete',
        relatedName: 'feed_items',
    }),
    athlete_session_id: fk({
        to: 'AthleteSessions',
        as: 'athlete_session',
        relatedName: 'feed_items',
    }),
    organization_id: attr(),
};

export default FeedItems;
