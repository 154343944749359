import React from 'react';

// note: id, parentId, and order should be passed down via props as they never change
// context should only contain things that change, aka local state
export default React.createContext({
    dragReady: false, // mousedown state it so the drag preview wont show the hamburger
    hamburgerActive: false, // indicates if it's bolded and menu is showing
    hamburgerReady: false, // to allow for toggling of the hamburger active dismiss, as otherwise it conflicts w/ normal dismiss

    isDragging: false, // if the current component is being dragged. ProgramBuilderHeader passed this to the ProgramBuilderComponent via setIsDragging

    tapMenu: () => {}, // called by ProgramBuilderHeader
    mouseUpMenu: () => {}, // called by ProgramBuilderHeader
    mouseDownMenu: () => {}, // called by ProgramBuilderHeader
    tapDrag: () => {}, // called by ProgramBuilderHeader
    releaseDrag: () => {}, // called by ProgramBuilderHeader
    dismissMenu: () => {}, // called by ProgramBuilderMenu
    setIsDragging: () => {}, // called by ProgramBuilderHeader
});
