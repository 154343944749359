import {
    ATTEMPT_FETCH_ATHLETE_SESSIONS,
    UPDATE_ATHLETE_HISTORY_FILTER,
} from 'shared_redux/actions';

export const fetchAthleteSessions = (athleteId) => ({
    type: ATTEMPT_FETCH_ATHLETE_SESSIONS,
    athleteId,
});

export const updateFilterDate = (date) => ({
    type: UPDATE_ATHLETE_HISTORY_FILTER,
    date,
});
