import { put } from 'redux-saga/effects';
import {
    ATTEMPT_SIGN_OUT,
} from 'shared_redux/actions';

let alertRef = null;

export function *handleError(error, message, shouldDisplay=true) {
    if (error && error.statusCode && error.statusCode === 401) {
        yield put({ type: ATTEMPT_SIGN_OUT });
    } else if (shouldDisplay) {
        var msg = (error && error.statusCode) ? `HTTP ${error.statusCode}, ${message}` : message;
        if (alertRef) {
            alertRef.error(msg);
        } else {
            alert(msg);
        }
        console.log(error);
    } else {
        console.log(error + " " + message);
    }
};

export function displaySuccessAlert(message) {
    if (alertRef) {
        alertRef.success(message);
    } else {
        alert(message);
    }
}

export function displayAlert(message) {
    if (alertRef) {
        alertRef.show(message);
    } else {
        alert(message);
    }
}

export function displayErrorAlert(message) {
    if (alertRef) {
        alertRef.error(message);
    } else {
        alert(message);
    }
}

export function setAlertRef(ref) {
    alertRef = ref;
}
