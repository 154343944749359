import { all, take, takeEvery, takeLatest, select, apply, put, call } from 'redux-saga/effects';

import {
    FETCH_ORGANIZATION_SETTINGS_ATTEMPT,
    FETCH_ORGANIZATION_SETTINGS_SUCCEEDED,

    UPDATE_AUTO_PROGRESS_ATTEMPT,
    UPDATE_AUTO_PROGRESS_SUCCEEDED,

    UPDATE_DEFAULT_REST,
    UPDATE_DEFAULT_NOTES,
    UPDATE_DEFAULT_AUTOREGULATION,
    UPDATE_DEFAULT_SENSOR_TRACKING,
    UPDATE_DEFAULT_AMRAP,
    UPDATE_DEFAULT_SET_COUNT_FIXED,
    UPDATE_DEFAULT_WEIGHT_UNIT,
    UPDATE_DEFAULT_AUTOREGULATION_QUANTIFIER,
    UPDATE_DEFAULT_AUTOREGULATION_METRIC,
    SAVE_DEFAULTS_ATTEMPT,
    SAVE_DEFAULTS_SUCCEEDED,

    FETCH_ACCOUNT_SUCCEEDED,
} from 'shared_redux/actions';
import API from 'services/settings';
import { handleError, displayErrorAlert } from 'services/alerts';

import * as AccountsSelectors from 'shared_redux/selectors/models/accounts';
import * as SettingsSelectors from 'shared_redux/selectors/view/settings';

export default function* SettingsSaga() {
    yield all([
        takeEvery(FETCH_ORGANIZATION_SETTINGS_ATTEMPT, fetchSettings),
        takeEvery(UPDATE_AUTO_PROGRESS_ATTEMPT, updateSettings),

        takeEvery(UPDATE_DEFAULT_REST, saveDefaults),
        takeEvery(UPDATE_DEFAULT_NOTES, saveDefaults),
        takeEvery(UPDATE_DEFAULT_AUTOREGULATION, saveDefaults),
        takeEvery(UPDATE_DEFAULT_SENSOR_TRACKING, saveDefaults),
        takeEvery(UPDATE_DEFAULT_AMRAP, saveDefaults),
        takeEvery(UPDATE_DEFAULT_SET_COUNT_FIXED, saveDefaults),
        takeEvery(UPDATE_DEFAULT_WEIGHT_UNIT, saveDefaults),
        takeEvery(UPDATE_DEFAULT_AUTOREGULATION_QUANTIFIER, saveDefaults),
        takeEvery(UPDATE_DEFAULT_AUTOREGULATION_METRIC, saveDefaults),

        takeEvery(SAVE_DEFAULTS_ATTEMPT, saveDefaults),
    ]);
};

function *fetchSettings(action) {
    try {
        // TODO: these are ugly, make them not so ugly
        var account = yield select(AccountsSelectors.getAccount);
        if (!account) {
            yield take(FETCH_ACCOUNT_SUCCEEDED);
            account = yield select(AccountsSelectors.getAccount);
        }

        const payload = yield apply(API, API.getSettings, [account.organization_id]);
        yield put({
            type: FETCH_ORGANIZATION_SETTINGS_SUCCEEDED,
            payload,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch settings');
    }
}

function *updateSettings(action) {
    try {
        // TODO: these are ugly, make them not so ugly
        var account = yield select(AccountsSelectors.getAccount);
        if (!account) {
            yield take(FETCH_ACCOUNT_SUCCEEDED);
            account = yield select(AccountsSelectors.getAccount);
        }

        const payload = yield apply(API, API.updateSettings, [account.organization_id, action.payload]);
        yield put({
            type: UPDATE_AUTO_PROGRESS_SUCCEEDED,
            payload,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to save settings');
    }
}

function *saveDefaults(action) {
    try {
        // TODO: these are ugly, make them not so ugly
        var account = yield select(AccountsSelectors.getAccount);
        if (!account) {
            yield take(FETCH_ACCOUNT_SUCCEEDED);
            account = yield select(AccountsSelectors.getAccount);
        }

        // get settings
        const isValid = yield select(SettingsSelectors.getSettingsValid);
        if (!isValid) {
            // error
            displayErrorAlert(`The defaults you entered are invalid. Please check the fields and try again.`);
        } else {
            // success
            const settings = yield select(SettingsSelectors.getOrganizationSettings);
            const payload = yield apply(API, API.updateSettings, [account.organization_id, settings]);
            yield put({
                type: SAVE_DEFAULTS_SUCCEEDED,
                payload,
            });
        }
    } catch(error) {
        yield handleError(error, 'Error: Unable to save settings');
    }
}
