import React, {Component} from 'react';
import Spinner from 'shared_features/spinner';
import ReportsGraphFilters from 'shared_features/graphs/filters';

import styles from './styles.module.scss';

class View extends Component {

    constructor(props) {
        super(props);
        this.anchor = React.createRef();
        this._onExportClicked = this._onExportClicked.bind(this);
        this.state = {
            isExportDisabled: false
        };
    }

    componentDidMount() {
        this.props.fetchTeams();
        this.props.fetchDataExports();
    }

    _onExportClicked(event) {
        event.preventDefault();
        this.setState({
            isExportDisabled: true
        });
    
        setTimeout(() => this.setState({ isExportDisabled: false }), 1500);
    
        return this.props.startDataExport();
    }

    _handleDataExport(data_export) {
        switch (data_export.status) {
            case 'COMPLETED':
                // download
                this.props.downloadDataExport(data_export.id);
                break;
            case 'FAILED':
                // retry it
                this.props.retryDataExport(data_export.id);
                break;
            default:
                break;
        }
    }

    _renderDataExports() {
        // not ready, spin
        if (this.props.dataExports.length <= 0 && this.props.isFetching) {
            return <Spinner className={styles.pageSpinner} />;
        }

        // render data exports list
        const results = [];
        const that = this;
        this.props.dataExports.forEach(de => {
            const deStyles = de.status === 'WAITING' ? [styles.dataExport, styles.disabled].join(' ') : styles.dataExport;
            let status;
            switch (de.status) {
                case 'WAITING':
                    status = <div className={styles.rowSpinner}><Spinner /></div>;
                    break;
                case 'COMPLETED':
                    status = 'Download';
                    break;
                case 'FAILED':
                    status = 'Click to Retry';
                    break;
                default:
                    status = 'Unknown';
                    break;
            }
            results.push(<div className={deStyles} key={`data-export${de.id}`} onClick={()=>that._handleDataExport(de)}>
                {de.name}
                <div className={styles.dataExportStatus}>{status}</div>
            </div>);
        });
        return results;
    }

    render() {
        return (<div className={styles.main}>
            <div className={styles.container}>
                <h1>Export training history CSV</h1>
                <div className={styles.filtersContainer}>
                    <ReportsGraphFilters showGroups={true} showInterval={false} />
                    <div className={styles.exportButton} onClick={this._onExportClicked} disabled={this.state.isExportDisabled}>Export</div>
                </div>
            </div>
            {this._renderDataExports()}
        </div>);
    }

}

export default View;
