import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'redux-orm';

import View from './view';
import * as Actions from './actions';
import orm from 'shared_redux/reducers/models/orm';

// TODO: consider, do I prefer this in its own selector or in the container?
const getViewModel = createSelector(
    orm,
    state => state.database,
    (session) => {
        const Exercises = session.Exercises;

        const allExercises = Exercises.all().orderBy('name', 'asc').toRefArray();
        var exercises = [];
        var maxableExercises = [];
        allExercises.forEach(exercise => {
            if (exercise.maxable === true) {
                maxableExercises.push(exercise);
            } else {
                exercises.push({ value: exercise.id, label: exercise.name });
            }
        });
        return {
            maxableExercises,
            exercises,
        };
    }
);

const mapStateToProps = (state) => getViewModel(state);

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchExercises: Actions.fetchExercises,
        addMaxableExercise: Actions.addMaxableExercise,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
