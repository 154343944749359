import React, {Component} from 'react';
import Select from 'react-select';

import 'react-big-calendar/lib/sass/styles.scss';
import styles from './styles.module.scss';

import Calendar from './timezone_calendar';
import SchedulerPopup from './popup';

export default class View extends Component {

    componentDidMount() {
        this.props.fetchProgramSchedules();
        this.props.fetchCategories();
    }

    _renderFilter() {
        if (!this.props.groups) {
            return null;
        }

        var groups = this.props.groups.map(model => ({
            type: model.type,
            value: model.id,
            label: model.description,
        }));
        groups.unshift({
            type: 'all',
            value: 'all',
            label: 'Everyone',
        })

        if (this.props.selectedGroup) {
            var value = groups.find(g => g.type === 'group' && g.value === this.props.selectedGroup);
        } else if (this.props.selectedTeam) {
            var value = groups.find(g => g.type === 'team' && g.value === this.props.selectedTeam);
        } else {
            var value = groups[0];
        }

        return (<div className={styles.selectContainer}>
            <Select
                name="category"
                className={styles.select}
                clearable={false}
                deleteRemoves={false}
                value={value}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        switch (selectedOption.type) {
                        case 'group':
                            this.props.updateGroupFilter(null, selectedOption.value);
                            break;
                        case 'team':
                            this.props.updateGroupFilter(selectedOption.value, null);
                            break;
                        default:
                            this.props.updateGroupFilter(null, null);
                            break;
                        }
                    }
                }}
                options={groups}
                components={{
                    IndicatorSeparator: () => null
                }}
            />
        </div>);
    }

    render() {
        const popup = this.props.modalVisible ? <SchedulerPopup /> : null;
        return (
            <div>
                <div className={styles.calendarControls}>
                {this._renderFilter()}
                <h1 className={styles.description}>Choose start date</h1>
                </div>
                <Calendar
                    className={styles.cal}
                    events={this.props.calendarEvents}
                    views={['month']}
                    selectable={true}
                    startAccessor="start"
                    endAccessor="end"
                    popup={true}
                    onSelecting={slot => false}
                    onSelectSlot={(slotInfo)=>this.props.createSchedule(this.props.match.params.programid, slotInfo.start, this.props.programLengthInDays)}
                    onSelectEvent={(slotInfo)=>this.props.editSchedule(slotInfo.id, slotInfo.start, slotInfo.end)}
                    />
                {popup}
            </div>
        );
    };

};
