import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Actions from './actions';
import View from './view';
import * as OrganizationsSelectors from 'shared_redux/selectors/models/organizations';
import * as AccountsSelectors from 'shared_redux/selectors/models/accounts';

const mapStateToProps = (state) => {
    const organization = OrganizationsSelectors.getOrganization(state);
    const account = AccountsSelectors.getAccount(state);
    return {
        logoURL: organization.logo_url || "https://assets.reponestrength.com/white_logo.png",
        email: account ? account.email : 'Account', 
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAccount: Actions.fetchAccount,
        signOut: Actions.signOut,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default withRouter(Container);
