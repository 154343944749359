import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';

import * as ProfileSelectors from 'shared_redux/selectors/view/profile';
import * as RosterSelectors from 'shared_redux/selectors/view/roster';

const makeMapStateToProps = () => {
    const getAthleteRowViewModel = RosterSelectors.makeGetAthleteRowViewModel();
    return (state, props) => {
        return {
            ...getAthleteRowViewModel(state, props),
            categories: RosterSelectors.getRosterCategoriesOptions(state),
            isUploadingAvatar: ProfileSelectors.getIsUploadingAvatar(state), // HACK: get is uploading
        };
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        selectAthlete: Actions.selectAthlete,
        deselectAthlete: Actions.deselectAthlete,

        upload: Actions.upload,
        editAthlete: Actions.editAthlete,
        updateAthleteCategory: Actions.updateAthleteCategory,
        saveMax: Actions.saveMax,
    }, dispatch);
};

const Container = connect(
    makeMapStateToProps,
    mapDispatchToProps,
)(View);

export default Container;
