import React, { Component } from 'react';
import ProgramBuilderContext from './programBuilderContext';
import { ContextMenu } from "react-contextmenu";

export default class ProgramBuilderMenu extends Component {
    static displayName = 'ProgramBuilderMenu';

    render() {
        return (
            <ProgramBuilderContext.Consumer>
                {({isDragging, dragReady, dismissMenu}) => {
                    if (isDragging || dragReady || this.props.isReordering) {
                        return null;
                    } else {
                        return (
                            <ContextMenu id={`programbuildercontext${this.props.id}`} onHide={dismissMenu}>
                                {this.props.children}
                            </ContextMenu>
                        );
                    }
                }}
            </ProgramBuilderContext.Consumer>
        );
    }

}
