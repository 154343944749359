import { all, call } from 'redux-saga/effects';
import AccountsSaga from './accounts';
import ColorsSaga from './colors';
import DuplicateSaga from './duplicate';
import SettingsSaga from './settings';
import ProgramsSaga from './programs';
import MicrocyclesSaga from './microcycles';
import MicrocycleSessionsSaga from './microcycle_sessions';
import BlocksSaga from './blocks';
import PlansSaga from './plans';
import AthletesSaga from './athletes';
import EntriesSaga from './entries';
import RacksSaga from './racks';
import GroupsSaga from './groups';
import TeamsSaga from './teams';
import AthleteSessionsSaga from './athlete_sessions';
import ExercisesSaga from './exercises';
import ExerciseLinksSaga from './exercise_links';
import MaxesSaga from './maxes';
import ReportsSaga from './reports';
import ProgramSchedulesSaga from './program_schedules';
import NewsSaga from './news';
import QueueSaga from './queue';
import TicketsSaga from './tickets';
import WebSocketSaga from './websocket';

const Sagas = function* Sagas(dispatch, getState) {
    yield all([
        call(AccountsSaga, dispatch),
        call(ColorsSaga),
        call(DuplicateSaga),
        call(SettingsSaga),
        call(ProgramsSaga),
        call(MicrocyclesSaga),
        call(MicrocycleSessionsSaga),
        call(BlocksSaga),
        call(PlansSaga),
        call(AthletesSaga),
        call(EntriesSaga),
        call(RacksSaga),
        call(GroupsSaga),
        call(TeamsSaga),
        call(AthleteSessionsSaga),
        call(ExercisesSaga),
        call(ExerciseLinksSaga),
        call(MaxesSaga),
        call(ReportsSaga, dispatch),
        call(ProgramSchedulesSaga, dispatch, getState),
        call(NewsSaga),
        call(QueueSaga, dispatch, getState),
        call(TicketsSaga),
        call(WebSocketSaga, dispatch),
    ]);
};

export default Sagas;
