import React, {Component} from 'react';
import ReactCrop from 'react-image-crop';
import Modal from 'react-modal';

import Spinner from 'shared_features/spinner';
import styles from './styles.module.scss';

class View extends Component {

    constructor(props) {
        super(props);
        this.uploader = React.createRef();

        // TODO: Consider moving this to redux view state
        this.state = {
            image: null,
            percentCrop: null,
            crop: {
                aspect: 1/1,
                x: 0,
                y: 0,
                width: 30,
                height: 30,
            },
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isUploadingAvatar && !this.props.isUploadingAvatar) {
            this._closeAvatarModal();
        }
    }

    // select avatar image and load it into memory

    _clickedPhoto() {
        this.uploader.current.click();
    }

    _selectedImage(event) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({image: e.target.result});
            };
            reader.readAsDataURL(file);
        }

        // clear it
        this.uploader.current.value = "";
    }

    // crop the image

    _getCroppedImg(image, percentCrop, fileName="imagefile") {
        const x = percentCrop.x / 100 * image.width;
        const y = percentCrop.y / 100 * image.height;
        const width = percentCrop.width / 100 * image.width;
        const height = percentCrop.height / 100 * image.height;
        const finalWidth = 116;
        const finalHeight = 116;
        const canvas = document.createElement('canvas');
        canvas.width = finalWidth;
        canvas.height = finalHeight;
        const ctx = canvas.getContext('2d');
      
        ctx.drawImage(
          image,
          x,
          y,
          width,
          height,
          0,
          0,
          finalWidth,
          finalHeight
        );
      
        // As Base64 string
        // const base64Image = canvas.toDataURL('image/png');
      
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(file => {
                file.name = fileName;
                resolve(file);
            }, 'image/png');
        });
    }
    
    // upload the image

    _upload() {
        var that = this;
        var image = new Image();
        image.onload = async function () {
            const croppedImg = await that._getCroppedImg(image, that.state.percentCrop);
            that.props.upload(croppedImg);
            if (!that.props.uploadEnabled) {
                that._closeAvatarModal();
            }
        };
        image.src = this.state.image;
    }

    // close image modal

    _closeAvatarModal() {
        this.setState({
            image: null,
            percentCrop: null,
            crop: {
                aspect: 1/1,
                x: 0,
                y: 0,
                width: 30,
            }
        });
    }

    // render

    _renderAvatar() {
        if (this.props.isSmall) {
            return (
                <div className={styles.container} onClick={()=>this._clickedPhoto()}>
                    <div className={styles.avatarContainerSmall}>
                        <img alt="avatar" className={styles.avatarImg} src={this.props.imageURL} />
                        <div className={styles.avatarOverlay}><img src="https://assets.reponestrength.com/edit.png" /></div>
                    </div>
                </div>
            );
        } else if (!this.props.description) {
            return (
                <div className={styles.container} onClick={()=>this._clickedPhoto()}>
                    <div className={styles.avatarContainerRounded}>
                        <img alt="avatar" className={styles.avatarImg} src={this.props.imageURL} />
                        <div className={styles.avatarOverlay}><div>CHANGE PHOTO</div></div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.borderedContainer} onClick={()=>this._clickedPhoto()}>
                    <div className={styles.avatarContainer}>
                        <img alt="avatar" className={styles.avatarImg} src={this.props.imageURL} />
                    </div>
                    <div className={styles.description}>+ Upload athlete photo</div>
                </div>
            );
        }
    }

    _renderCrop() {
        if (!this.state.image) {
            return null;
        }

        return <ReactCrop
                    className={styles.crop}
                    src={this.state.image}
                    crop={this.state.crop}
                    onChange={(crop, percentCrop) => {
                        this.setState({ crop, percentCrop });
                    }} />
    }

    _renderDone() {
        if (this.props.isUploadingAvatar) {
            return <div>
                <Spinner />
                <div className={styles.upload}>Uploading...</div>
            </div>
        } else {
            return <div className={styles.upload} onClick={()=>this._upload()}>Done</div>;
        }
    }

    render() {
        return (
            <div>
                {this._renderAvatar()}

                <div>
                    <input type="file"
                        ref={this.uploader}
                        accept="image/png,image/jpeg,image/jpg,image/webp"
                        onChange={(event)=>this._selectedImage(event)} />
                </div>

                <Modal
                    className={styles.modal}
                    overlayClassName={styles.overlay}
                    isOpen={this.state.image !== null}
                    onRequestClose={()=>this._closeAvatarModal()}>
                
                    {this._renderCrop()}
                    
                    {this._renderDone()}
                </Modal>

            </div>
        );
    }

}

export default View;
