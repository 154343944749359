import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    call,
} from 'redux-saga/effects';

import {
    ATTEMPT_CREATE_MICROCYCLE,
    ATTEMPT_MOVE_MICROCYCLE,
    ATTEMPT_DELETE_MICROCYCLE,

    QUEUE_ITEM_SUCCEEDED,
    QUEUE_ITEM_FAILED,
} from 'shared_redux/actions';
import API from 'services/microcycles';
import { handleError } from 'services/alerts';

export default function* MicrocyclesSaga() {
    yield all([
        takeEvery(ATTEMPT_CREATE_MICROCYCLE, createMicrocycle),
        takeEvery(ATTEMPT_MOVE_MICROCYCLE, moveMicrocycle),
        takeEvery(ATTEMPT_DELETE_MICROCYCLE, deleteMicrocycle),
    ]);
};

function *createMicrocycle(action) {
    try {
        yield apply(API, API.createMicrocycle, [action.payload]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
    } catch(error) {
        if (error.statusCode === 409) {
            // already created, treat as success
            yield put({ type: QUEUE_ITEM_SUCCEEDED });
        } else {
            yield put({ type: QUEUE_ITEM_FAILED });
            yield handleError(error, 'Error: Unable to create microcycle');
        }
    }
}

function *moveMicrocycle(action) {
    try {
        yield apply(API, API.updateMicrocycle, [action.payload]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
    } catch(error) {
        yield put({ type: QUEUE_ITEM_FAILED });
        yield handleError(error, 'Error: Unable to move microcycle');
    }
}

function *deleteMicrocycle(action) {
    try {
        yield apply(API, API.deleteMicrocycle, [action.id]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
    } catch(error) {
        if (error.statusCode === 403) {
            // POSSIBLY already deleted, going to assume it and treat it as success
            yield put({ type: QUEUE_ITEM_SUCCEEDED });
        } else {
            yield put({ type: QUEUE_ITEM_FAILED });
            yield handleError(error, 'Error: Unable to delete microcycle');
        }
    }
}
