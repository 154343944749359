import React from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

import SilentSaveTextField from 'shared_features/silent_save_textfield';
import SilentSaveSelect from 'shared_features/silent_save_select';
import Avatar from 'shared_features/avatar';

export const selectStyles = {
    control: () => ({
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center',
        boxSizing: 'border-box',
    }),
    container: () => ({
        position: 'relative',
    }),
    singleValue: () => ({
        position: 'absolute',
        maxWidth: 217,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        marginLeft: 4,

        color: '#333333',
        fontSize: 14,
        fontWeight: 'regular',
    }),
    input: () => ({
        marginLeft: 4,
        color: '#333333',
        fontSize: 14,
        fontWeight: 'regular',
    }),
    indicatorSeparator: (styles) => ({display:'none'}),
};

export default props => {
   return (
        <li className={styles.athletesContainer}>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={()=> props.isSelected ? props.deselectAthlete(props.id) : props.selectAthlete(props.id)}
                className={styles.checkbox} />
            <div className={styles.athletesTable}>
            {props.fields.map(f => {
                switch (f.type) {
                    case 'AVATAR':
                        return <span key={`${props.id}COL${f.type}`} className={styles.avatar}>
                                    <Avatar
                                        isSmall={true}
                                        imageURL={f.value}
                                        uploadEnabled={true}
                                        isUploadingAvatar={props.isUploadingAvatar}
                                        upload={(image) => props.upload(props.id, image)} />
                                </span>;
                    case 'NAME':
                        return <Link to={`/athletes/${props.id}`} key={`${props.id}COL${f.type}`}>
                                    <span className={styles.name}>
                                        {f.value}
                                    </span>
                                </Link>
                    case 'CATEGORY':
                        const value = props.categories.find(c => c.team_id === f.team_id && c.group_id === f.group_id);
                        return <span key={`${props.id}COL${f.type}`} className={styles.category}>
                                    <SilentSaveSelect
                                        name="assignees"
                                        styles={selectStyles}
                                        placeholder="None"
                                        value={value}
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        isClearable={false}
                                        deleteRemoves={false}
                                        onChange={(selectedOption) => {
                                            props.updateAthleteCategory(props.id, selectedOption.team_id, selectedOption.group_id);
                                        }}
                                        options={props.categories}
                                        />
                        </span>
                    case 'MAXABLE_EXERCISE':
                        return <span key={`${props.id}COL${f.exercise_id}`} className={styles.maxableExercise}>
                            <SilentSaveTextField type="number"
                                value={f.value}
                                onComplete={value => props.saveMax(f.max_id, f.exercise_id, props.id, value === '' ? null : parseFloat(value))} />
                        </span>
                    case 'CALCULATED_MAX_EXERCISE':
                        return <span key={`${props.id}COL${f.exercise_id}`} className={styles.maxableExercise}>
                            <input type="number"
                                value={f.value}
                                disabled={true} />
                        </span>
            }})}
            <img src="https://assets.reponestrength.com/edit.png" className={styles.edit} onClick={e => props.editAthlete(props.id)} />
            </div>
        </li>
    );
};
