import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import View from 'shared_features/tab_header';
import * as ProgramBuilderSelectors from 'shared_redux/selectors/view/program_builder';

const mapStateToProps = (state, props) => ProgramBuilderSelectors.getProgramTabs(state, props);

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default withRouter(Container);
