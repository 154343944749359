import { createSelector } from 'redux-orm';
import orm from 'shared_redux/reducers/models/orm';

export const getAccount = createSelector(
    orm,
    state => state.database,
    session => {
        const result = session.Accounts.all().first();
        return result ? result.ref : null;
    }
);
