import {
    CLOSE_EDIT_ATHLETE,
    UPDATE_ATHLETE_FIRST_NAME,
    UPDATE_ATHLETE_MIDDLE_NAME,
    UPDATE_ATHLETE_LAST_NAME,
    UPDATE_ATHLETE_GROUP,
    UPDATE_ATHLETE_TEAM,
    ATTEMPT_SAVE_ATHLETE_METADATA,
    ATTEMPT_REMOVE_ATHLETE,
} from 'shared_redux/actions';

// TODO: can create and edit share the same view store for the popup?!

export const closeModal = () => ({ type: CLOSE_EDIT_ATHLETE });

export const updateFirstName = (firstName) => ({ type: UPDATE_ATHLETE_FIRST_NAME, firstName });

export const updateMiddleName = (middleName) => ({ type: UPDATE_ATHLETE_MIDDLE_NAME, middleName });

export const updateLastName = (lastName) => ({ type: UPDATE_ATHLETE_LAST_NAME, lastName });

export const updateGroup = (groupId) => ({ type: UPDATE_ATHLETE_GROUP, groupId });

export const updateTeam = (teamId) => ({ type: UPDATE_ATHLETE_TEAM, teamId });

export const remove = () => ({ type: ATTEMPT_REMOVE_ATHLETE });

export const save = () => ({ type: ATTEMPT_SAVE_ATHLETE_METADATA });
