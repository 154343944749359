import { createSelector } from 'redux-orm';
import orm from 'shared_redux/reducers/models/orm';

export const getTeamsAndGroups = createSelector(
    orm,
    state => state.database,
    (session) => {
        // session objects
        const { Teams } = session;

        // obj
        let array = [];

        // loop
        const teams = Teams.all().orderBy('name', 'asc').toModelArray();
        for (let team of teams) {
            array.push({
                type: 'team',
                id: team.id,
                description: `${team.name} - All`,
                team_id: team.id,
                group_id: null,
            });
            const groups = team.groups.orderBy('name', 'asc').toRefArray();
            for (let group of groups) {
                array.push({
                    type: 'group',
                    id: group.id,
                    description: `${team.name} - ${group.name}`,
                    group_id: group.id,
                    team_id: team.id,
                });
            }
        }

        // return
        return array;
    }
);
