import React from 'react';

export default props => {
    return (
        <svg >
            <rect x="0.5" y="3.5" width="6" height="6"/>
            <rect x="3.5" y="0.5" width="6" height="6"/>
       </svg>
    );
};
