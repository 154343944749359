import { all, takeEvery, select } from 'redux-saga/effects';

import {
    INITIALIZED,
    SIGN_IN_SUCCEEDED,
} from 'shared_redux/actions';
import { handleError } from 'services/alerts';
import * as OrganizationsSelectors from 'shared_redux/selectors/models/organizations';
import colors from 'shared_styles/colors.scss';

export default function* ColorsSaga() {
    yield all([
        takeEvery(INITIALIZED, updateColors),
        takeEvery(SIGN_IN_SUCCEEDED, updateColors),
    ]);
};

function *updateColors(action) {
    try {
        var organization = yield select(OrganizationsSelectors.getOrganization);

        if (organization && organization.primary_color) {
            document.body.style.setProperty('--primary-color', colorStringFrom(organization.primary_color));
        } else {
            document.body.style.setProperty('--primary-color', colors.blue);
        }

        if (organization && organization.secondary_color) {
            document.body.style.setProperty('--secondary-color', colorStringFrom(organization.secondary_color));
        } else {
            document.body.style.setProperty('--secondary-color', colors.yellow);
        }

        if (organization && organization.destructive_color) {
            document.body.style.setProperty('--destructive-color', colorStringFrom(organization.destructive_color));
        } else {
            document.body.style.setProperty('--destructive-color', colors.red);
        }

        if (organization && organization.nav_background_color) {
            document.body.style.setProperty('--nav-background-color', colorStringFrom(organization.nav_background_color));
        } else {
            document.body.style.setProperty('--nav-background-color', colors.grey_6);
        }

        if (organization && organization.nav_text_color) {
            document.body.style.setProperty('--nav-text-color', colorStringFrom(organization.nav_text_color));
        } else {
            document.body.style.setProperty('--nav-text-color', colors.grey_3);
        }
        
        if (organization && organization.nav_active_color) {
            document.body.style.setProperty('--nav-active-color', colorStringFrom(organization.nav_active_color));
        } else {
            document.body.style.setProperty('--nav-active-color', colors.yellow);
        }
    } catch(error) {
        yield handleError(error, 'Error: Unable to update colors');
    }
}


// conversion from https://stackoverflow.com/questions/17945972/converting-rgba-values-into-one-integer-in-javascript
const colorStringFrom = (value) => {
    if (!value) {
        return null;
    }

    var red = value >> 24 & 0xFF;
    var green = value >> 16 & 0xFF;
    var blue = value >> 8 & 0xFF;
    var alpha = (value & 0xFF) / 100;
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

// to reverse it
const rgbaToInt = (red, green, blue, alpha) => {
    var r = red & 0xFF;
    var g = green & 0xFF;
    var b = blue & 0xFF;
    var a = (alpha * 100) & 0xFF;

    return (r << 24) + (g << 16) + (b << 8) + (a);
};
