import React, {useEffect} from 'react';
import Select from 'react-select';

import styles from './styles.module.scss';
import ManageMaxRow from './row';
import DeleteMaxView from './delete_max';

const renderMaxableExercises = props => {
    const result = [];
    for (var exercise of props.maxableExercises) {
        result.push(<ManageMaxRow id={exercise.id}/>)
    }
    return result;
};

export default props => {
    useEffect(() => {
        props.fetchExercises();
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.rowHeader}>
                <span>MOVEMENT</span>
                <span>LINKED MOVEMENT</span>
                <span>FORMULA</span>
            </div>
            {renderMaxableExercises(props)}
            <div className={styles.addLift}>
                <Select
                    name="exercise"
                    placeholder={"+ ADD LIFT"}
                    className={styles.select}
                    clearable={false}
                    deleteRemoves={false}
                    value={null}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                    }}
                    styles={{
                        control: (base) => ({
                            ...base,
                            border: '0px',
                            boxShadow: 'none',
                            height: 42,
                            minHeight: 42,
                        }),
                        input: () => ({
                            height: 38,
                        }),
                    }}
                    onChange={(selectedOption)=> {
                        if (selectedOption) {
                            props.addMaxableExercise(selectedOption.value);
                        }
                    }}
                    options={props.exercises}
                />
            </div>
            <DeleteMaxView />
        </div>
    );
}
