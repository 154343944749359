import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';

import * as AthleteHistorySelectors from 'shared_redux/selectors/view/athlete_history';
import * as HistorySessionsSelectors from 'shared_redux/selectors/view/history_sessions';
import * as HistoryPlansSelectors from 'shared_redux/selectors/view/history_plans';

// expects id
const makeMapStateToProps = () => {
    const getAthleteHistoryPlan = AthleteHistorySelectors.makeGetAthleteHistoryPlan();
    return (state, props) => {
        const model = getAthleteHistoryPlan(state, props);
        return {
            ...model,
            visible: HistorySessionsSelectors.getIsSessionExpanded(state, model.session_id),
            expanded: HistoryPlansSelectors.getIsPlanExpanded(state, props.id),
        };
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        expand: Actions.expand,
        collapse: Actions.collapse,
    }, dispatch);
};

const Container = connect(
    makeMapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
