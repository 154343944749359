import API from './api';

export default {

    createMicrocycle: (json) => API({
        url: 'microcycles',
        method: 'POST',
        json,
    }),

    updateMicrocycle: (json) => API({
        url: `microcycles/${json.id}`,
        method: 'PATCH',
        json,
    }),

    deleteMicrocycle: (microcycleId) => API({
        url: `microcycles/${microcycleId}`,
        method: 'DELETE',
    }),

};
