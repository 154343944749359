import React, {Component} from 'react';
import { Switch, Route } from 'react-router-dom';
import uuidv4 from 'uuid/v4';

import Header from './header';
import Tabs from './tabs';
import Builder from './builder';
import Schedule from './schedule';

export default class View extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    componentDidMount() {
        if (this.props.match.params.programid === 'new') {
            // TODO: don't do all the logic here, just hacking it like this for now as it's easier
            // refactor into a saga that listens draft create and then updating url and creating microcycle
            const id = uuidv4();
            this.props.createProgram(id);
            this.props.history.replace(`/programs/${id}`);
            this.props.createMicrocycle(id, 1);
        } else {
            this.props.fetchProgram(this.props.match.params.programid);
        }
        this.props.fetchExercises();
        this.props.fetchSettings();
    }

    render () {
        return (
            <div>
                <Header />
                <Tabs />
                <Switch>
                    <Route path='/programs/:programid/:programname/:tab' component={Schedule} />
                    <Route path='/programs/:programid/:programname' component={Builder} />
                </Switch>
            </div>
        );
    };
};
