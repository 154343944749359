import {
    UPDATE_ENTRY_ATTEMPT,
} from 'shared_redux/actions';

export const updateEntryLoad = (id, orig_weight, orig_weight_unit) => {
    const weight = isNaN(orig_weight) ? null : orig_weight;
    const weight_unit = weight !== null ? orig_weight_unit : null;
    return {
        type: UPDATE_ENTRY_ATTEMPT,
        payload: {
            id,
            weight,
            weight_unit,
        }
    };
};

export const updateEntryWeightUnit = (id, weight_unit) => ({
    type: UPDATE_ENTRY_ATTEMPT,
    payload: {
        id,
        weight_unit,
    },
});

export const updateEntryRPE = (id, rpe) => ({
    type: UPDATE_ENTRY_ATTEMPT,
    payload: {
        id,
        rpe,
    },
});

export const updateEntryReps = (id, manual_rep_count) => ({
    type: UPDATE_ENTRY_ATTEMPT,
    payload: {
        id,
        manual_rep_count,
    },
});
