import {
    UPDATE_REGISTER_EMAIL,
    UPDATE_REGISTER_PASSWORD,
    UPDATE_REGISTER_ORGANIZATION_NAME,

    REGISTER_ATTEMPT,
    REGISTER_SUCCEEDED,
    REGISTER_FAILED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    email: "",
    password: "",
    organization_name: "",
    registered: false,
    registering: false,
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case UPDATE_REGISTER_EMAIL:
            return {
                ...state,
                email: action.email,
            };
        case UPDATE_REGISTER_PASSWORD:
            return {
                ...state,
                password: action.password,
            };
        case UPDATE_REGISTER_ORGANIZATION_NAME:
            return {
                ...state,
                organization_name: action.organization_name,
            };
        case REGISTER_ATTEMPT:
            return {
                ...state,
                registering: true,
            };
        case REGISTER_SUCCEEDED:
            return {
                ...state,
                registering: false,
                registered: true,
            };
        case REGISTER_FAILED:
            return {
                ...state,
                registering: false,
            };
        default:
            return state;
    }
};
