import {
    CONFIRM_REMOVE_MAX,
    UPDATE_EXERCISE_LINK_ATTEMPT,
    DELETE_EXERCISE_LINK_ATTEMPT,
} from 'shared_redux/actions';

export const removeMaxableExercise = (exerciseId) => ({ type: CONFIRM_REMOVE_MAX, exerciseId });

export const updateExerciseLink = (child_exercise_id, parent_exercise_id, formula) => ({
    type: UPDATE_EXERCISE_LINK_ATTEMPT,
    child_exercise_id,
    parent_exercise_id,
    formula,
});

export const deleteExerciseLink = (child_exercise_id) => ({
    type: DELETE_EXERCISE_LINK_ATTEMPT,
    child_exercise_id,
});
