import {
    SHOW_IMPORT_ATHLETES,
    CLOSE_IMPORT_ATHLETES,
    IMPORT_ATHLETES_SUCCEEDED,
    IMPORT_ATHLETES_MISSING_COLUMNS,
    IMPORT_ATHLETES_FAILED,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    // import
    isShowingImport: false,
    missingColumns: [],
    failedRow: -1,
    failedMessage: null,
    generalError: false,
    succeeded: false,
    numAthletesCreated: 0,
    numGroupsCreated: 0,
    numTeamsCreated: 0,
    isBatchUploading: false,
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case SHOW_IMPORT_ATHLETES:
            return {
                ...state,
                isShowingImport: true,
            };
        case CLOSE_IMPORT_ATHLETES:
            return createDefaultState();
       case IMPORT_ATHLETES_SUCCEEDED:
            return {
                ...state,
                missingColumns: [],
                failedRow: -1,
                failedMessage: null,
                generalError: false,
                succeeded: true,
                numAthletesCreated: action.payload.athletes,
                numGroupsCreated: action.payload.groups,
                numTeamsCreated: action.payload.teams,
            };
        case IMPORT_ATHLETES_MISSING_COLUMNS:
            return {
                ...state,
                missingColumns: action.columns,
                failedRow: -1,
                failedMessage: null,
                generalError: false,
                succeeded: false,
            };
        case IMPORT_ATHLETES_FAILED:
            if (action.row && action.reason) {
                return {
                    ...state,
                    missingColumns: [],
                    failedRow: action.row,
                    failedMessage: action.reason,
                    generalError: false,
                    succeeded: false,
                };
            } else {
                return {
                    ...state,
                    missingColumns: [],
                    failedRow: -1,
                    failedMessage: null,
                    generalError: true,
                    succeeded: false,
                };
            }
        
        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
