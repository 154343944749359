import API from './api';

export default {

    createExerciseLink: (exercise_id, parent_exercise_id, formula) => API({
        url: `exercises/${exercise_id}/link`,
        method: 'POST',
        json: {
            parent_exercise_id,
            formula,
        },
    }),

    patchExerciseLink: (exercise_id, parent_exercise_id, formula) => API({
        url: `exercises/${exercise_id}/link`,
        method: 'PATCH',
        json: {
            parent_exercise_id,
            formula,
        },
    }),

    deleteExerciseLink: (exercise_id) => API({
        url: `exercises/${exercise_id}/link`,
        method: 'DELETE',
    }),
    
};
