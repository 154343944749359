import {
    ATTEMPT_UPLOAD_AVATAR,
    UPLOAD_AVATAR_SUCCEEDED,
    UPLOAD_AVATAR_FAILED,
    ATTEMPT_CREATE_ATHLETE,
    CREATE_ATHLETE_SUCCEEDED,
    CREATE_ATHLETE_FAILED,

    CREATE_ATHLETE,
    EDIT_ATHLETE,
    CLOSE_CREATE_ATHLETE,
    CLOSE_EDIT_ATHLETE,
    LOADED_CACHED_ATHLETE,
    UPDATE_ATHLETE_FIRST_NAME,
    UPDATE_ATHLETE_MIDDLE_NAME,
    UPDATE_ATHLETE_LAST_NAME,
    UPDATE_ATHLETE_TEAM,
    UPDATE_ATHLETE_GROUP,
    SAVE_ATHLETE_METADATA_SUCCEEDED,
    REMOVE_ATHLETE_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    isUploadingAvatar: false,
    isCreating: false,
    isEditing: false,
    organizationId: null,
    athleteId: null,
    groupId: null,
    teamId: null,
    firstName: null,
    middleName: null,
    lastName: null,
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case ATTEMPT_UPLOAD_AVATAR:
            return {
                ...state,
                isUploadingAvatar: true,
            };
        case ATTEMPT_CREATE_ATHLETE:
            if (action.file) {
                return {
                    ...state,
                    isUploadingAvatar: true,
                };
            } else {
                return state;
            }
        case UPLOAD_AVATAR_SUCCEEDED:
        case UPLOAD_AVATAR_FAILED:
        case CREATE_ATHLETE_FAILED:
            return {
                ...state,
                isUploadingAvatar: false,
            };
        case CREATE_ATHLETE:
            return {
                ...state,
                isUploadingAvatar: false,
                isCreating: true,
                isEditing: false,
                athleteId: null,
                organizationId: action.organizationId,
                groupId: action.groupId,
                teamId: action.teamId,
                firstName: null,
                middleName: null,
                lastName: null,            
            };
        case EDIT_ATHLETE:
            return {
                ...state,
                isUploadingAvatar: false,
                isCreating: false,
                isEditing: true,
                athleteId: action.athleteId,
                organizationId: null,
                groupId: null,
                teamId: null,
                firstName: null,
                middleName: null,
                lastName: null,            
            };
        case LOADED_CACHED_ATHLETE:
            return {
                ...state,
                firstName: action.athlete.first_name,
                middleName: action.athlete.middle_name,
                lastName: action.athlete.last_name,
                // NOTE: do not do org id here as that should NOT be updated
                groupId: action.athlete.group_id,
                teamId: action.athlete.team_id,
            };
        case UPDATE_ATHLETE_FIRST_NAME:
            return {
                ...state,
                firstName: action.firstName,
            };
        case UPDATE_ATHLETE_MIDDLE_NAME:
            return {
                ...state,
                middleName: action.middleName,
            };
        case UPDATE_ATHLETE_LAST_NAME:
            return {
                ...state,
                lastName: action.lastName,
            };
        case UPDATE_ATHLETE_TEAM:
            return {
                ...state,
                teamId: action.teamId,
                groupId: action.teamId === null ? null : state.groupId,
            };
        case UPDATE_ATHLETE_GROUP:
            return {
                ...state,
                groupId: action.groupId,
            };
        case CLOSE_CREATE_ATHLETE:
        case CLOSE_EDIT_ATHLETE:
        case SAVE_ATHLETE_METADATA_SUCCEEDED:
        case CREATE_ATHLETE_SUCCEEDED:
        case REMOVE_ATHLETE_SUCCEEDED:
            return {
                ...state,
                isUploadingAvatar: false,
                isCreating: false,
                isEditing: false,
                athleteId: null,
                organizationId: null,
                groupId: null,
                teamId: null,
                firstName: null,
                middleName: null,
                lastName: null,
            };
        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
