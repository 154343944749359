import { createSelector } from 'redux-orm';
import moment from 'moment';

import orm from 'shared_redux/reducers/models/orm';
// import * as Calculator from 'math/autoregulation_calculator';
import BlockTypes from 'constants/blockTypes';
import * as HistorySessionsSelectors from 'shared_redux/selectors/view/history_sessions';
import * as HistoryPlansSelectors from 'shared_redux/selectors/view/history_plans'; // TODO: add this?

// HISTORY

// TODO: stop using updated time, just rely on redux-orm selectors to do their job
// TODO: break this up into smaller functions, in particular the velocity math
// TODO: use working_set_type
export const getAthleteHistory = createSelector(
    orm,
    state => state.database,
    (state, props) => props.match.params.athleteid,
    HistorySessionsSelectors.getUpdatedTime,
    (session, athlete_id, updatedTime) => {
        const { Athletes } = session;
        var lastDate = null;
        var lastMonth = null;
        var lastYear = null;
        var lastProgramId = null;
        var lastProgramName = null;
        var lastMicrocycleNum = null;
        var minDate = null;
        var maxDate = null;
        var models = [];
        
        // get athlete
        const athlete = Athletes.withId(athlete_id);
        if (!athlete) {
            return {
                models: [],
                minDate,
                maxDate,
                updatedTime,
            };
        }

        // loop sessions
        let athlete_sessions = athlete.athlete_sessions.toModelArray();
        athlete_sessions.sort((a, b) => {
            const date1 = moment(a.date);
            const date2 = moment(b.date);
            if (date1.isBefore(date2)) {
                return -1;
            }
            if (date1.isAfter(date2)) {
                return 1;
            }
            return 0;
        });
        // TODO: sort them here properly
        athlete_sessions.forEach(athlete_session => {
            // microcycle

            // MODEL - cycle program label
            var didChangeCycle = false;
            if (lastMicrocycleNum !== athlete_session.microcycle_number || lastProgramId !== athlete_session.program_id) {
                if (lastMicrocycleNum !== null) {
                    models.unshift({
                        type: 'cycle',
                        padded: true,
                        text: `CYCLE ${lastMicrocycleNum} - ${lastProgramName}`.toUpperCase(),
                    });
                }
                lastMicrocycleNum = athlete_session.microcycle_number;
                lastProgramId = athlete_session.program_id;
                lastProgramName = athlete_session.program_name;
                didChangeCycle = true;
            }

            // MODEL - date label
            const date = moment(athlete_session.date);
            const month = date.month();
            const year = date.year();
            var didChangeDate = false;
            if (lastMonth !== month || lastYear !== year) {
                if (lastDate) {
                    if (!didChangeCycle) {
                        // extra cycle if date changed
                        models.unshift({
                            type: 'cycle',
                            padded: false,
                            text: `CYCLE ${lastMicrocycleNum} - ${lastProgramName}`.toUpperCase(),
                        });
                    }

                    // add date labels
                    var loopDate = lastDate;
                    while (loopDate.month() !== date.month() || loopDate.year() !== date.year()) {
                        models.unshift({
                            type: 'date',
                            text: loopDate.format('MMMM YYYY'),
                            anchor: loopDate.format('YYYY-M'),
                            date: loopDate.clone(),
                        });
                        loopDate = loopDate.add(1, 'M');
                    }
                }
                lastMonth = month;
                lastYear = year;
                lastDate = date;
                didChangeDate = true;
            }

            // MODEL - session
            models.unshift({
                type: 'session',
                id: athlete_session.session_id,
                displayNumber: athlete_session.session_number,
            });

            // update min max
            if (didChangeDate) {
                if (!minDate || date.isBefore(minDate)) {
                    minDate = date.clone();
                }
                if (!maxDate || date.isAfter(maxDate)) {
                    maxDate = date.clone();
                }
            }
        });

        // add "first" cycle
        if (lastMicrocycleNum && lastProgramName) {
            models.unshift({
                type: 'cycle',
                padded: false,
                text: `CYCLE ${lastMicrocycleNum} - ${lastProgramName}`.toUpperCase()
            });
        }

        // add "first" date
        if (lastDate) {
            models.unshift({
                type: 'date',
                text: lastDate.format('MMMM YYYY'),
                anchor: lastDate.format('YYYY-M'),
                date: lastDate.clone(),
            });
        }

        return {
            models,
            maxDate,
            minDate,
            updatedTime,
        };
    }
 );

// SESSION

export const makeGetAthleteHistorySession = () => createSelector(
    orm,
    state => state.database,
    (state, props) => props.id,
    HistorySessionsSelectors.getUpdatedTime,
    (session, id, updatedTime) => {
        // session
        const { AthleteSessions } = session;
        const athlete_session = AthleteSessions.withId(id);
        if (!athlete_session || !athlete_session.session || !athlete_session.session.blocks) {
            return {
                blockIds: [],
                summary: '',
                date: null,
            };
        }
        
        // blocks, exercise summary, and date
        let blockIds = [];
        let exercises = [];
        athlete_session.session.blocks.orderBy('order', 'asc').toModelArray().forEach(block => {
            blockIds.push(block.id);
            block.plans.orderBy('order', 'asc').toModelArray().forEach(plan => {
                if (plan.exercise) {
                    exercises.push(plan.exercise.name);
                }
            });
        });

        return {
            blockIds,
            summary: exercises.join(" • "), // TODO: handle if there's too many exercises (... it or +3 more or something)
            date: moment(athlete_session.date).format('MMM Do'), // TODO: don't use entry create time hack, search entries instead
        };
    }
);

// BLOCKS

export const makeGetAthleteHistoryBlock = () => createSelector(
    orm,
    state => state.database,
    (state, props) => props.id,
    HistorySessionsSelectors.getUpdatedTime,
    (session, id, updatedTime) => {
        // session

        // block
        const { Blocks } = session;
        const block = Blocks.withId(id);
        if (!block) {
            return {
                name: '',
                planIds: [],
            };
        }

        // name
        let name;
        switch (block.type) {
            case BlockTypes.STANDARD:
                name = 'STANDARD BLOCK';
                break;
            case BlockTypes.SUPERSET:
                name = 'SUPERSET BLOCK';
                break;
            case BlockTypes.ALTERNATING:
                name = 'ALTERNATING BLOCK';
                break;
            default:
                name = '';
                break;
        }

        // ids
        const planIds = block.plans.orderBy('order', 'asc').toModelArray().map(p => p.id);

        return {
            name,
            session_id: block.session_id,
            planIds,
        };
    } 
);

// PLANS

export const makeGetAthleteHistoryPlan = () => createSelector(
    orm,
    state => state.database,
    (state, props) => props.id,
    HistorySessionsSelectors.getUpdatedTime,
    (session, id, updatedTime) => {
        // entry ids
        let entryIds = [];

        // plan
        const { Plans } = session;
        const plan = Plans.withId(id);
        if (!plan || !plan.entries || !plan.block) {
            return {
                name: '',
                carrotVisible: false,
                numSets: 0,
                autoregulationHeader: null,
                autoregulationRange: null,
                loadRange: null,
                session_id: null,
                entryIds,
            };
        }

        // variables
        let minVel = null;
        let maxVel = null;
        const autoregulationMetric = plan.autoregulation_metric;
        let minLoad = null;
        let maxLoad = null;

        // loop through entries
        const entries = plan.entries.orderBy('order', 'desc').toModelArray().filter(e => e.working_set_type !== 'READY' && e.archived === false);
        entries.forEach(entry => {
            // ids
            entryIds.push(entry.id);

            // load range
            const load = entry.entryLoadLbs();
            if (load) {
                if (!minLoad || load < minLoad) {
                    minLoad = load;
                }
                if (!maxLoad || load > maxLoad) {
                    maxLoad = load;
                }
            }

            // velocity range
            if (entry.reps) {
                const reps = entry.reps.orderBy('order', 'asc').toRefArray().filter(r => r.removed === false);
                if (autoregulationMetric) {
                    // autoregulated
                    const metrics = getMetrics(reps, autoregulationMetric);
                    const target = getCompareTarget(metrics, plan.autoregulation_quantifier);
                    if (target !== null) {
                        if (minVel === null || target < minVel) {
                            minVel = target;
                        }
                        if (maxVel === null || target > maxVel) {
                            maxVel = target;
                        }
                    }
                } else {
                    // not autoregulated
                    const metrics = getMetrics(reps, 'AVERAGE_VELOCITY');

                    const min = getCompareTarget(metrics, 'MIN');
                    if (min !== null) {
                        if (minVel === null || min < minVel) {
                            minVel = min;
                        }
                    }

                    const max = getCompareTarget(metrics, 'MAX');
                    if (max !== null) {
                        if (maxVel === null || max > maxVel) {
                            maxVel = max;
                        }
                    }
                }
            }
        });

        return {
            name: plan.exercise.name, // TODO: don't rely on plan.exercise.name
            carrotVisible: entryIds.length > 0,
            numSets: entryIds.length,
            autoregulationHeader: autoregulationMetric ? autoregulationMetric.toUpperCase() : null, // TODO: maybe make this cleaner?
            autoregulationRange: minVel && maxVel ? `${(minVel/1000).toFixed(2)} - ${(maxVel/1000).toFixed(2)}` : null,
            loadRange: minLoad && maxLoad ? `${minLoad} - ${maxLoad}` : plan.manual ? null : ' - ',
            session_id: plan.block.session_id,
            entryIds,
        };
    }
);

// ENTRY

export const makeGetAthleteHistoryEntry = () => createSelector(
    orm,
    state => state.database,
    (state, props) => props.id,
    HistorySessionsSelectors.getUpdatedTime,
    (session, id, updatedTime) => {
        // entry
        const { Entries } = session;
        const entry = Entries.withId(id);
        if (!entry || !entry.reps || !entry.plan || !entry.plan.block) {
            return {
                id: null,
                setNumber: null,
                load: null,
                feedback: null,
                rpe: null,
                reps: [],
                manualRepCount: null,
                plan_id: null,
                session_id: null,
            };
        }

        
        // reps
        const actualReps = entry.reps.orderBy('start_time', 'asc').toRefArray().filter(r => r.removed === false);
        const reps = getRepsDisplayValues(entry.plan, actualReps);

        return {
            id: entry.id,
            setNumber: entry.order,
            load: entry.weight && entry.weight_unit ? `${entry.weight} ${entry.weight_unit}` : 'None',
            weight: entry.weight,
            weight_unit: entry.weight_unit,
            feedback: entry.autoregulation_feedback,
            rpe: entry.rpe,
            reps,
            plannedRepCount: entry.plan.num_reps,
            manualRepCount: actualReps.length <= 0 ? entry.manual_rep_count : null,
            plan_id: entry.plan_id,
            session_id: entry.plan.block.session_id,
        };
    }
);

//////////////////////
// HELPER FUNCTIONS //
//////////////////////

const getRepsDisplayValues = (plan, reps) => {
    var target = plan.autoregulation_target;
    var range = plan.autoregulation_range;
    var autoregulationMetric = plan.autoregulation_metric;

    return reps.map(rep => {
        if (!rep.valid) {
            return { id: rep.id, text: 'INVALID', status: 'invalid' };
        }
        if (autoregulationMetric === 'PEAK_VELOCITY') {
            // peak
            var velocity = (rep.peak_velocity / 1000).toFixed(2);
        } else {
            // average
            var velocity = (rep.average_velocity / 1000).toFixed(2);
        }

        var inRange = velocityInRange(velocity, target, range);
        return {
            id: rep.id,
            text: velocity,
            status: inRange === 0 ? 'inside' : 'outside',
        };
    });
};

const velocityInRange = (velocity, target, range) => {
    if (velocity < target - range) {
        return -1;
    }
    if (velocity > target + range) {
        return 1;
    }
    return 0;
};

//////////////////////////
// CALCULATE ENTRY DATA //
//////////////////////////

// NOTE: consider moving these to its own class / helper function area
// for now leaving it here because it's only ever used here

// TODO: proper constants file for the diff types
// right now the constants file is an array, not a true dictionary

export function getCompareTarget(metrics, prefix) {
    switch (prefix) {
        case 'AVERAGE':
            return getAvgOfMetrics(metrics);
        case 'FIRST':
            return getFirstRepOfMetrics(metrics);
        case 'LAST':
            return getLastRepMetrics(metrics);
        case 'MIN':
            return getMinMetrics(metrics);
        case 'MAX':
            return getMaxMetrics(metrics);
        case 'PEAK_END':
            return getPeakEndMetrics(metrics);
        case 'ABSOLUTE_LOSS':
            return getAbsLossOfMetrics(metrics);
        case 'SET_LOSS':
            return getSetLossMetrics(metrics);
        case 'PERCENT_LOSS':
            return getPercentLossOfMetrics(metrics);
    }
}

export function getMetrics(reps, autoregulationMetric) {
    // TODO: add other types of metrics, like rom or peak veloc location
    switch (autoregulationMetric) {
        case 'AVERAGE_VELOCITY':
            return reps.map(r => r.average_velocity);
        case 'PEAK_VELOCITY':
            return reps.map(r => r.peak_velocity);
        case 'ROM':
            return reps.map(r => r.rom);
        case 'PEAK_HEIGHT':
            return reps.map(r => r.peak_height);
        case 'DURATION': // note, forcing it through for now because eventually repone devices should have all properties enabled
            return reps.map(r => r.duration ? r.duration / 1000000 : -1);
        default:
            return [];
    }
}

//////////////////////
// INTERNAL HELPERS //
//////////////////////

function getAvgOfMetrics(metrics) {
    if (metrics.length <= 0) {
        return null;
    }

    const sum = metrics.reduce((previous, current) => current + previous);
    return Number((sum / metrics.length).toFixed(2));
};

function getAbsLossOfMetrics(metrics) {
    if (metrics.length <= 0) {
        return null;
    }

    const max = Math.max(...metrics);
    const min = Math.min(...metrics);
    
    return Number((max - min).toFixed(2));
};

function getPercentLossOfMetrics(metrics) {
    if (metrics.length <= 0) {
        return null;
    }

    const max = Math.max(...metrics);
    const min = Math.min(...metrics);
    
    return Number((100*(max - min)/max).toFixed(2));
};

function getFirstRepOfMetrics(metrics) {
    if (metrics.length <= 0) {
        return null;
    }

    return Number((metrics[0]).toFixed(2));
};

function getLastRepMetrics(metrics) {
    if (metrics.length <= 0) {
        return null;
    }

    return Number((metrics[metrics.length - 1]).toFixed(2));
};

function getMinMetrics(metrics) {
    if (metrics.length <= 0) {
        return null;
    }

    return Number(Math.min(...metrics).toFixed(2));
};

function getMaxMetrics(metrics) {
    if (metrics.length <= 0) {
        return null;
    }

    return Number(Math.max(...metrics).toFixed(2));
};

function getPeakEndMetrics(metrics) {
    if (metrics.length <= 0) {
        return null;
    }

    const min = getMinMetrics(metrics);
    if (min === null) {
        return null;
    }

    const lastRepMetric = getLastRepMetrics(metrics);
    if (lastRepMetric === null) {
        return null;
    }
    
    return Number(((lastRepMetric + min) / 2).toFixed(2));
};

function getSetLossMetrics(metrics) {
    const lastRepMetric = getLastRepMetrics(metrics);
    if (lastRepMetric === null) {
        return null;
    }

    const firstRepMetric = getFirstRepOfMetrics(metrics);
    if (firstRepMetric === null) {
        return null;
    }

    if (metrics.length > 0) {
        return Number((lastRepMetric - firstRepMetric).toFixed(2));
    } else {
        return null;
    }
};
