import {
    WEBSOCKET_CONNECTED,
    WEBSOCKET_DISCONNECTED,

    UPDATE_FEED_TICK_TIMER,

    SET_FEED_ITEMS,
    UPDATE_FEED_COMPLETE_ITEM,
    UPDATE_FEED_ENTRY_ITEM,
    DELETE_FEED_ITEM,
    SIGN_OUT_SUCCEEDED,

    UPDATE_LIVE_GROUPS_FILTER,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    updated: null,
    tickTime: null,
    connected: false,
    selectedTeamFilter: null,
    selectedGroupFilter: null,
});

// todo: tick time should use toisostrings
export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case WEBSOCKET_DISCONNECTED: {
            return {
                ...state,
                connected: false,
            };
        }
        case WEBSOCKET_CONNECTED: {
            return {
                ...state,
                connected: true,
            };
        }

        case UPDATE_FEED_TICK_TIMER:
            return {
                ...state,
                tickTime: new Date()
            };
        
        case SET_FEED_ITEMS:
        case UPDATE_FEED_COMPLETE_ITEM:
        case UPDATE_FEED_ENTRY_ITEM:
        case DELETE_FEED_ITEM:
            return {
                ...state,
                updated: new Date(),
            };

        case UPDATE_LIVE_GROUPS_FILTER:
            return {
                ...state,
                selectedTeamFilter: action.team || null,
                selectedGroupFilter: action.group || null,
            };

        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
