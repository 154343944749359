import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    delay,
    call,
    race,
} from 'redux-saga/effects';

import {
    RETRY_QUEUE,
    PUSH_QUEUE,
    POP_QUEUE,
    QUEUE_EXECUTING,
    QUEUE_EXECUTION_COMPLETE,
    QUEUE_ITEM_SUCCEEDED,
    QUEUE_ITEM_FAILED,
    
    DRAFT_CREATE_PROGRAM,
    DRAFT_SAVE_PROGRAM,
    
    DRAFT_CREATE_MICROCYCLE,
    DRAFT_MOVE_MICROCYCLE,
    DRAFT_DELETE_MICROCYCLE,

    DRAFT_CREATE_MICROCYCLE_SESSION,
    DRAFT_MOVE_MICROCYCLE_SESSION,
    DRAFT_DELETE_MICROCYCLE_SESSION,

    DRAFT_CREATE_BLOCK,
    DRAFT_UPDATE_BLOCK_TYPE,
    DRAFT_SAVE_BLOCK,
    ATTEMPT_SAVE_BLOCK,
    DRAFT_MOVE_BLOCK,
    DRAFT_DELETE_BLOCK,

    ATTEMPT_CREATE_PLAN,
    DRAFT_CREATE_PLAN,
    DRAFT_UPDATE_PLAN_EXERCISE,
    DRAFT_UPDATE_PLAN_SENSOR_TRACKING,
    DRAFT_UPDATE_PLAN_AUTOREGULATION,
    DRAFT_UPDATE_PLAN_AUTOREGULATION_QUANTIFIER,
    DRAFT_UPDATE_PLAN_AUTOREGULATION_METRIC,
    DRAFT_UPDATE_PLAN_WEIGHT_UNIT,
    DRAFT_UPDATE_PLAN_AMRAP,
    DRAFT_UPDATE_PLAN_SET_COUNT_FIXED,
    DRAFT_SAVE_PLAN,
    ATTEMPT_SAVE_PLAN,
    DRAFT_MOVE_PLAN,
    DRAFT_DELETE_PLAN,

    DRAFT_CREATE_EXERCISE,
} from 'shared_redux/actions';
import Plans from 'shared_redux/reducers/models/plans';
import * as QueueSelectors from 'shared_redux/selectors/system/queue';
import orm from 'shared_redux/reducers/models/orm';

export default function* QueueSaga(dispatch, getState) {
    yield all([
        takeEvery(RETRY_QUEUE, startQueue),
        takeEvery(QUEUE_ITEM_SUCCEEDED, continueQueue),
        takeEvery(QUEUE_ITEM_FAILED, handleQueueError),

        takeEvery(DRAFT_CREATE_PROGRAM, push),
        takeEvery(DRAFT_SAVE_PROGRAM, push),

        takeEvery(DRAFT_CREATE_MICROCYCLE, push),
        takeEvery(DRAFT_MOVE_MICROCYCLE, push),
        takeEvery(DRAFT_DELETE_MICROCYCLE, push),

        takeEvery(DRAFT_CREATE_MICROCYCLE_SESSION, push),
        takeEvery(DRAFT_MOVE_MICROCYCLE_SESSION, push),
        takeEvery(DRAFT_DELETE_MICROCYCLE_SESSION, push),

        takeEvery(DRAFT_CREATE_BLOCK, push),
        takeEvery(DRAFT_UPDATE_BLOCK_TYPE, pushUpdateBlock, getState),
        takeEvery(DRAFT_SAVE_BLOCK, pushUpdateBlock, getState),
        takeEvery(DRAFT_MOVE_BLOCK, push),
        takeEvery(DRAFT_DELETE_BLOCK, push),

        takeEvery(DRAFT_CREATE_PLAN, pushCreatePlan),
        takeEvery(DRAFT_UPDATE_PLAN_EXERCISE, pushUpdatePlan, getState),
        takeEvery(DRAFT_UPDATE_PLAN_SENSOR_TRACKING, pushUpdatePlan, getState),
        takeEvery(DRAFT_UPDATE_PLAN_AMRAP, pushUpdatePlan, getState),
        takeEvery(DRAFT_UPDATE_PLAN_SET_COUNT_FIXED, pushUpdatePlan, getState),
        takeEvery(DRAFT_UPDATE_PLAN_AUTOREGULATION, pushUpdatePlan, getState),
        takeEvery(DRAFT_UPDATE_PLAN_AUTOREGULATION_QUANTIFIER, pushUpdatePlan, getState),
        takeEvery(DRAFT_UPDATE_PLAN_AUTOREGULATION_METRIC, pushUpdatePlan, getState),
        takeEvery(DRAFT_UPDATE_PLAN_WEIGHT_UNIT, pushUpdatePlan, getState),
        takeEvery(DRAFT_SAVE_PLAN, pushUpdatePlan, getState),
        takeEvery(DRAFT_MOVE_PLAN, push),
        takeEvery(DRAFT_DELETE_PLAN, push),

        takeEvery(DRAFT_CREATE_EXERCISE, push),
    ]);
    // TODO: consider re-enabling auto restart of queue
    // yield call(checkRestart);
};

function *push(action) {
    yield put({
        type: PUSH_QUEUE,
        item: {
            ...action,
            type: action.pushType,
        }
    });
    yield call(startQueue);
}

function *pushUpdateBlock(getState, action) {
    // get block
    const id = action.id || action.payload.id;
    const state = getState();
    const session = orm.session(state.draft_database);
    const { Blocks } = session;
    const block = Blocks.withId(id).ref;

    // valid check
    if (!Blocks.isValid(block)) {
        return;
    }

    // push
    yield put({
        type: PUSH_QUEUE,
        item: {
            type: ATTEMPT_SAVE_BLOCK,
            payload: Blocks.toJSON(block),
        }
    });
    yield call(startQueue);
}

function *pushCreatePlan(action) {
    yield put({
        type: PUSH_QUEUE,
        item: {
            type: ATTEMPT_CREATE_PLAN,
            payload: Plans.denormalize(action.payload),
        }
    });
    yield call(startQueue);
}

function *pushUpdatePlan(getState, action) {
    // get block
    const id = action.id || action.payload.id;
    const state = getState();
    const session = orm.session(state.draft_database);
    const { Plans } = session;
    const plan = Plans.withId(id).ref;

    // valid check
    if (!Plans.isValid(plan)) {
        return;
    }

    // push
    yield put({
        type: PUSH_QUEUE,
        item: {
            type: ATTEMPT_SAVE_PLAN,
            payload: Plans.toJSON(plan),
        }
    });
    yield call(startQueue);
}

function *startQueue() {
    // execution check
    const isExecuting = yield select(QueueSelectors.getIsExecuting);
    if (isExecuting) {
        return;
    }

    // item check
    const item = yield select(QueueSelectors.getNextItem);
    if (!item) {
        return;
    }

    // execute
    yield put({ type: QUEUE_EXECUTING });
    yield put(item);
}

function *continueQueue() {
    // kill the old one
    yield put({ type: POP_QUEUE });

    const item = yield select(QueueSelectors.getNextItem);
    if (!item) {
        yield put({ type: QUEUE_EXECUTION_COMPLETE });
    } else {
        yield put(item);
    }
}

function *handleQueueError() {
    // TODO: check the kind of error to determine best course of action
    // for now, simply retry
    // eventually, may want retries to occur after a delay

    // const item = yield select(QueueSelectors.getNextItem);
    // if (!item) {
    //     yield put({ type: QUEUE_EXECUTION_COMPLETE });
    // } else {
    //     yield put(item);
    // }

    // TODO: retry delay should increase over time
    yield put({ type: QUEUE_EXECUTION_COMPLETE });
 }

function *checkRestart() {
    // restart attempt
    while(true) {
        yield take(QUEUE_EXECUTION_COMPLETE);
        const item = yield select(QueueSelectors.getNextItem);        
        if (!item) {
            continue;
        }
    
        // item check
        const { task, cancel } = yield race({
            task: delay(10000),
            cancel: take(QUEUE_EXECUTING) // someone else started it
        });

        if (task) {
            // it was not interrupted before the delay was over, start the queue again
            yield call(startQueue);
        }
    }
}
