import {
    ATTEMPT_SIGN_IN,
    UPDATE_SIGN_IN_EMAIL,
    UPDATE_SIGN_IN_PASSWORD,
} from 'shared_redux/actions';

export const signIn = (email, password) => ({ type: ATTEMPT_SIGN_IN, email, password });

export const updateEmail = (email) => ({ type: UPDATE_SIGN_IN_EMAIL, email });

export const updatePassword = (password) => ({ type: UPDATE_SIGN_IN_PASSWORD, password });
