import React from 'react';

import styles from './styles.module.scss';
import EditAthleteProfile from 'shared_features/athlete_popup/edit_profile';
import Avatar from 'shared_features/avatar';

export default props => {
    return (
        <div className={styles.titlebg}>
            <div className={styles.title}>
                <div className={styles.grid}>
                    <div>
                        <Avatar
                            imageURL={props.imageURL}
                            uploadEnabled={true}
                            isUploadingAvatar={props.isUploadingAvatar}
                            upload={(image) => props.upload(props.athleteId, image)} />
                    </div>

                    <div>
                        <div>{props.displayName}</div>
                        <div className={styles.labelContainer}>
                            <div className={styles.label}>
                                <div>TEAM</div>
                                <div>{props.teamName}</div>
                            </div>
                            <div className={styles.label}>
                                <div>GROUP</div>
                                <div>{props.groupName}</div>
                            </div>
                            {/* <div className={styles.label}>
                                <div>PROGRAM</div>
                                <div>{props.programName}</div>
                            </div> */}
                        </div>
                    </div>
                    <div>
                        <div className={styles.editButton} onClick={()=>props.editAthlete(props.athleteId)}>Edit Profile</div>
                    </div>
                </div>
            </div>
            
            <EditAthleteProfile />

        </div>
    );
};
