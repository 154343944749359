import uuidv4 from 'uuid/v4';

import {
    CANCEL_DRAFT_AND_QUEUE,

    RETRY_QUEUE,

    ATTEMPT_FETCH_PROGRAM,
    ATTEMPT_FETCH_EXERCISES,

    DRAFT_CREATE_MICROCYCLE,
    ATTEMPT_CREATE_MICROCYCLE,
} from 'shared_redux/actions';

export const cancelAndClear = () => ({ type: CANCEL_DRAFT_AND_QUEUE });

export const retryQueue = () => ({ type: RETRY_QUEUE });

export const fetchProgram = (program_id) => ({
    type: ATTEMPT_FETCH_PROGRAM,
    program_id,
});

export const fetchExercises = () => ({ type: ATTEMPT_FETCH_EXERCISES });

export const createMicrocycle = (program_id, order) => {
    const id = uuidv4();
    return {
        type: DRAFT_CREATE_MICROCYCLE,
        pushType: ATTEMPT_CREATE_MICROCYCLE,
        payload: {
            id,
            program_id,
            order,
        },
    };
};
