import React, {useState} from 'react';
import WeightUnits from 'constants/weightUnits';
import styles from './styles.module.scss';

export default props => {
    // local state
    const [manual_rep_count, setManualRepCount] = useState(props.manualRepCount);
    const [weight, setWeight] = useState(props.weight);
    const [rpe, setRPE] = useState(props.rpe);
    const [weightUnitVisible, setWeightUnitVisible] = useState(props.weight !== null);

    // refs
    const repsRef = React.useRef();
    const weightRef = React.useRef();
    const rpeRef = React.useRef();

    // visibility check
    if (!props.visible) {
        return null;
    }

    // weight unit
    if (!props.weight_unit) {
        var weight_unit = WeightUnits[2];
        var next_weight_unit = WeightUnits[0];
    } else {
        var weight_unit = props.weight_unit;
        switch (props.weight_unit) {
            case WeightUnits[0]:
                var next_weight_unit = WeightUnits[1];
                break;
            case WeightUnits[1]:
                var next_weight_unit = WeightUnits[2];
                break;
            case WeightUnits[2]:
                var next_weight_unit = WeightUnits[0];
                break;
        }
    }

    // callbacks
    const handleKeyPress = e => {
        if(e.key === 'Enter') {
            repsRef.current.blur();
            weightRef.current.blur();
            rpeRef.current.blur();
        }
    };

    // styling for weight unit
    const weightUnitStyle = weightUnitVisible ? styles.weightUnit : [styles.weightUnit, styles.hidden].join(' ');

    // render feedback
    var feedback = null;
    if (props.feedback) {
        feedback = (<div>
            <div>FEEDBACK</div>
            <div>{props.feedback}</div>
        </div>);
    }

    // render status
    var status = null;
    if (props.status) {
        status = (<div>
            <div>STATUS</div>
            <div>{props.status}</div>
        </div>);
    }

    // render manual rep count
    var manualRepCount = null;
    if (props.reps.length <= 0) {
        manualRepCount = (<div>
            <div>REPS</div>
            <div>
                <input ref={repsRef}
                    type="number"
                    className={styles.manualReps}
                    value={manual_rep_count}
                    placeholder={props.plannedRepCount}
                    onKeyPress={(e)=> handleKeyPress(e)}
                    onChange={(event)=> setManualRepCount(parseFloat(event.target.value))}
                    onBlur={()=> props.updateEntryReps(props.id, manual_rep_count)} />
            </div>
        </div>);
    }

    // render reps
    var reps = props.reps.map(rep => {
        if (rep.status === 'inside') {
            var style = styles.repOnTarget;
        } else if (rep.status === 'failed') {
            var style = styles.repFailed;
        } else if (rep.status === 'invalid') {
            var style = styles.repInvalid;
        } else {
            var style = styles.rep;
        }
        return <div key={`rep${rep.id}`} className={style}>{rep.text}</div>;
    });

    return (
        <div className={styles.container}>
            <div>
                <div>SET</div>
                <div>{props.setNumber}</div>
            </div>
            {manualRepCount}
            <div>
                <div>LOAD</div>
                <div className={styles.loadContainer}>
                    <input ref={weightRef}
                        type="number"
                        className={styles.weight}
                        value={weight}
                        onKeyPress={(e)=> handleKeyPress(e)}
                        onChange={(event)=> {
                            setWeight(parseFloat(event.target.value));
                            setWeightUnitVisible(event.target.value !== '');
                        }}
                        onBlur={()=> props.updateEntryLoad(props.id, weight, weight_unit)} />
                    <span className={weightUnitStyle} onClick={()=>props.updateEntryWeightUnit(props.id, next_weight_unit)}>
                        <p>{weight_unit}</p>
                    </span>
                </div>
            </div>
            {feedback}
            <div>
                <div>RPE</div>
                <div>
                    <input ref={rpeRef}
                        type="number"
                        className={styles.rpe}
                        value={rpe}
                        onKeyPress={(e)=> handleKeyPress(e)}
                        onChange={(event)=> setRPE(parseFloat(event.target.value))} 
                        onBlur={()=> props.updateEntryRPE(props.id, rpe)} />
                </div>
            </div>
            {status}
            <div className={styles.repsContainer}>{reps}</div>
        </div>
    );
};
