import React, {useState} from 'react';

export default props => {
   // states
    const [allValues, setAllValues] = useState({
        prevValue: false, // prev value prevents flicker by ensuring it won't revert to the old value after finishing the edit
        value: props.value,
        isEditing: false,
    });

    // get individual values
    const prevValue = allValues.prevValue;
    const value = allValues.value;
    const isEditing = allValues.isEditing;

    // refs
    const inputRef = React.useRef();

    // handlers
    const onFocus = () => {
        setAllValues({
            ...allValues,
            prevValue: props.value,
            isEditing: true,
        });

        if (props.onFocus) {
            props.onFocus();
        }
    };
    const onChange = e => {
        setAllValues({
            ...allValues,
            value: e.target.value,
        });

        if (props.onChange) {
            props.onChange(e);
        }
    };
    const onBlur = () => {
        if (value !== prevValue) {
            // change occured, complete
            props.onComplete(value);
            setAllValues({
                ...allValues,
                isEditing: false,
            });
        } else {
            // change did not occur, back out
            setAllValues({
                ...allValues,
                prevValue: false,
                isEditing: false,
            });
        }

        if (props.onBlur) {
            props.onBlur();
        }
    };
    const onKeyPress = e => {
        if(e.key === 'Enter') {
            inputRef.current.blur();
        }

        if (props.onKeyPress) {
            props.onKeyPress(e);
        }
    };

    // override after editing
    if (!isEditing) {
        if (prevValue !== false) {
            // prev value exists, only override once it's different from previous
            if (props.value !== prevValue) {
                setAllValues({
                    ...allValues,
                    prevValue: false,
                    value: props.value,
                });
            }
        } else {
            // not editing and no previous, override once it's different from current
            if (props.value !== value) {
                setAllValues({
                    ...allValues,
                    value: props.value,
                });
            }
        }
    }

    // render
    const inputProps = {...props};
    delete inputProps.onComplete;
    return <input
                {...inputProps}
                ref={inputRef}
                value={value || ''}
                onKeyPress={onKeyPress}
                onFocus={onFocus}
                onChange={onChange}
                onBlur={onBlur}
                />
};
