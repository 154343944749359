import React, {Component} from 'react';

import RosterHeaderView from 'shared_features/roster_header';
import CreateTeamView from './create_team';
import EditTeamView from './edit_team';
import CreateGroupView from './create_group';
import EditGroupView from './edit_group';
import styles from './styles.module.scss';

class View extends Component {

    componentDidMount() {
        this.props.fetchTeams();
    }

    _renderTeam(team) {
        if (!team.collapsed) {
            var carrot = (<div className={styles.carrot} onClick={()=>this.props.collapseTeam(team.id)}>
                <img src="https://assets.reponestrength.com/carrot_down.png" />
            </div>);
        } else {
            var carrot = (<div className={styles.carrot} onClick={()=>this.props.expandTeam(team.id)}>
                <img src="https://assets.reponestrength.com/carrot_right.png" />
            </div>);
        }
        return (<React.Fragment key={`team${team.id}`}>
            {carrot}
            <div className={styles.teamName}>{team.name}</div>
            <div className={styles.edit} onClick={()=>this.props.editTeam(team.id)}>Edit</div>
        </React.Fragment>)
    }

    _renderGroup(group) {
        return (<React.Fragment key={`group${group.id}`}>
            <div className={styles.groupName}>{group.name}</div>
            <div className={styles.edit} onClick={()=>this.props.editGroup(group.id)}>Edit</div>
        </React.Fragment>)
    }

    _renderNew(model) {
        return <div className={styles.createGroup} key={`creategroup${model.teamId}`} onClick={()=>this.props.createGroup(model.teamId)}>+ NEW GROUP</div>
    }

    _renderModels() {
        var results = [];
        for (var model of this.props.models) {
            switch (model.type) {
                case "team":
                    results.push(this._renderTeam(model));
                    break;
                case "group":
                    results.push(this._renderGroup(model));
                    break;
                case "new":
                    results.push(this._renderNew(model));
                    break;
            }
        }
        return results;
    }

    render() {
        return (
            <div>
                <RosterHeaderView />
                <div className={styles.main}>
                    <div className={styles.subheader}>
                        <div className={styles.subtitle}>Manage Teams &amp; Groups</div>
                        <div className={styles.createTeam} onClick={()=>this.props.createTeam(this.props.organizationId)}>+ New Team</div>
                    </div>
                    <div className={styles.grid}>
                        {this._renderModels()}
                    </div>
                </div>
                <CreateTeamView />
                <EditTeamView />
                <CreateGroupView />
                <EditGroupView />
            </div>
        );
    }

}

export default View;
