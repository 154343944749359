import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Actions from './actions';
import * as RegisterSelectors from 'shared_redux/selectors/view/register';
import View from './view';

const mapStateToProps = (state, props) => {
    document.title = "RepOne";

    const beta = props.location.pathname !== '/signup';
    const registered = RegisterSelectors.getIsRegistered(state);

    return {
        registering: RegisterSelectors.getIsRegistering(state),
        registered,
        beta,

        email: RegisterSelectors.getEmail(state),
        password: RegisterSelectors.getPassword(state),
        organization_name: RegisterSelectors.getOrganizationName(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateEmail: Actions.updateEmail,
        updatePassword: Actions.updatePassword,
        updateOrganizationName: Actions.updateOrganizationName,
        register: Actions.register,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default withRouter(Container);
