import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as ReportsManagerSelectors from 'shared_redux/selectors/view/reports_manager';
import * as CategoriesSelectors from 'shared_redux/selectors/view/categories';

const mapStateToProps = (state, props) => {
    const startDate = ReportsManagerSelectors.getStartDateFilter(state);
    const endDate = ReportsManagerSelectors.getEndDateFilter(state);
    const timeRange = ReportsManagerSelectors.getTimeRangeFilter(state);
    const interval = ReportsManagerSelectors.getIntervalFilter(state);
    const selectedTeam = ReportsManagerSelectors.getSelectedTeamFilter(state);
    const selectedGroup = ReportsManagerSelectors.getSelectedGroupFilter(state);

    if (props.showGroups) {
        var groups = CategoriesSelectors.getTeamsAndGroups(state);
    } else {
        var groups = null;
    }

    return {
        startDate,
        endDate,
        timeRange,
        interval,
        groups,
        selectedTeam,
        selectedGroup,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateStartDate: Actions.updateStartDate,
        updateEndDate: Actions.updateEndDate,
        updateTimeRange: Actions.updateTimeRange,
        updateInterval: Actions.updateInterval,
        updateGroupFilter: Actions.updateGroupFilter,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
