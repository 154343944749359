import React, {Component} from 'react';
import CreatableSelect from 'react-select/creatable';
import Validator from 'validator';

import styles from './styles.module.scss';
import { selectStyles } from '../plan/view';

export default class View extends Component {

    // TODO: styling on each dropdown option, may need to iterate through types to do so unfortunately
    render() {
        let exercises = [];
        for (var exercise of this.props.exercises) {
            const option = { value: exercise.id, label: exercise.name };
            exercises.push(option);
        }

        return (
            <div className={styles.container}>
                <CreatableSelect
                    className={styles.select}
                    styles={selectStyles}
                    isClearable
                    name="exercise"
                    value={null}
                    menuIsOpen={true}
                    clearable={false}
                    deleteRemoves={false}
                    tabSelectsValue={false}
                    onChange={(selectedOption)=> {
                        if (selectedOption) {
                            if (!Validator.isUUID(selectedOption.value)) {
                                // hack, sending org_id will create the exercise, this is pretty shitty
                                this.props.createPlan(this.props.block_id, this.props.order, selectedOption.value, this.props.organizationId);
                            } else {
                                this.props.createPlan(this.props.block_id, this.props.order, selectedOption.value);
                            }
                        }
                    }}
                    options={exercises}
                    onBlur={()=>this.props.cancelInsertPlan()}
                    autoFocus
                />
                <label>MOVEMENT</label>
            </div>
        );
    }

};
