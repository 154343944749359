import { ORM } from 'redux-orm';
import Accounts from './accounts';
import RosterSettings from './roster_settings';
import Organizations from './organizations';
import Racks from './racks';
import Programs from './programs';
import Sessions from './sessions';
import Microcycles from './microcycles';
import MicrocycleSessions from './microcycle_sessions';
import Blocks from './blocks';
import Plans from './plans';
import Exercises from './exercises';
import ExerciseLinks from './exercise_links';
import DataExports from './data_exports';
import Teams from './teams';
import Groups from './groups';
import Athletes from './athletes';
import AthleteSessions from './athlete_sessions';
import News from './news';
import ProgramSchedules from './program_schedules';
import Maxes from './maxes';
import Entries from './entries';
import Reps from './reps';
import FeedItems from './feed_items';
import FeedEntryItems from './feed_entry_items';
import FeedCompleteItems from './feed_complete_items';

const orm = new ORM();
orm.register(
    Accounts,
    RosterSettings,
    Organizations,
    Racks,
    Programs,
    Sessions,
    Microcycles,
    MicrocycleSessions,
    Blocks,
    Plans,
    Exercises,
    ExerciseLinks,
    Teams,
    Groups,
    Athletes,
    AthleteSessions,
    News,
    ProgramSchedules,
    Maxes,
    Entries,
    Reps,
    FeedItems,
    FeedEntryItems,
    FeedCompleteItems,
    DataExports,
);

export default orm;
