import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import View from './view';

import * as Actions from './actions';
import * as FeedSelectors from 'shared_redux/selectors/view/feed';
import * as CategoriesSelectors from 'shared_redux/selectors/view/categories';

const mapStateToProps = (state) => {
    document.title = "Live";

    return {
        feedModels: FeedSelectors.getViewModels(state),
        isConnected: FeedSelectors.getIsConnected(state),
        groups: CategoriesSelectors.getTeamsAndGroups(state),
        selectedTeam: FeedSelectors.getSelectedTeamFilter(state),
        selectedGroup: FeedSelectors.getSelectedGroupFilter(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCategories: Actions.fetchCategories,
        updateGroupFilter: Actions.updateGroupFilter,
        updateFeedTimer: Actions.updateFeedTimer,
        connectFeed: Actions.connectFeed,
        disconnectFeed: Actions.disconnectFeed,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
