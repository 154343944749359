import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as ProgramsModelsSelectors from 'shared_redux/selectors/models/programs';
import * as ProgramsSelectors from 'shared_redux/selectors/view/programs';
import * as Actions from './actions';

const mapStateToProps = (state) => {
    document.title = "Programs";

    return {
        isLoading: ProgramsSelectors.getIsLoadingPrograms(state),
        programs: ProgramsModelsSelectors.getPrograms(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchPrograms: Actions.fetchPrograms,
        duplicateProgram: Actions.duplicateProgram,
        deleteProgram: Actions.deleteProgram,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
