import {
    UPDATE_ROSTER_SELECTED_TEAM,
    UPDATE_ROSTER_SELECTED_GROUP,
    DELETE_ROSTER_SELECTED_ATHLETES_ATTEMPT,
    SAVE_SELECTED_ATHLETES_CATEGORIES_ATTEMPT,
} from 'shared_redux/actions';

export const updateSelectedTeam = (team_id) => ({
    type: UPDATE_ROSTER_SELECTED_TEAM,
    team_id,
});

export const updateSelectedGroup = (group_id) => ({
    type: UPDATE_ROSTER_SELECTED_GROUP,
    group_id,
});

export const deleteSelectedAthletes = () => ({
    type: DELETE_ROSTER_SELECTED_ATHLETES_ATTEMPT,
});

export const saveSelectedAthletesCategories = () => ({
    type: SAVE_SELECTED_ATHLETES_CATEGORIES_ATTEMPT,
});
