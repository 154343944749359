import API from './api';

export default {
    
    getProgramSchedules: (organization_id) => API({
        url: `program_schedules?organization_id=${organization_id}`,
        method: 'GET',
    }),

    createProgramSchedule: (json) => API({
        url: 'program_schedules',
        method: 'POST',
        json,
    }),

    updateProgramSchedule: (json) => API({
        url: `program_schedules/${json.id}`,
        method: 'PATCH',
        json,
    }),

    deleteProgramSchedule: (id) => API({
        url: `program_schedules/${id}`,
        method: 'DELETE',
    }),

};
