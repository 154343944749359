import {
    CONFIRM_REMOVE_MAX,
    REMOVE_MAXABLE_EXERCISE_SUCCEEDED,
    CLOSE_REMOVE_MAX_POPUP,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    removeId: null, // id of the thing
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case CONFIRM_REMOVE_MAX:
            return {
                ...state,
                removeId: action.exerciseId,
            };
        case REMOVE_MAXABLE_EXERCISE_SUCCEEDED:
        case CLOSE_REMOVE_MAX_POPUP:
            return {
                ...state,
                removeId: null,
            };
        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
