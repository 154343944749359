import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createBlock: Actions.createBlock,
    }, dispatch);
};

const Container = connect(
    null,
    mapDispatchToProps,
)(View);

export default Container;
