// APP
export const INITIALIZED = 'INITIALIZED';

// AUTH
export const ATTEMPT_SIGN_IN = 'ATTEMPT_SIGN_IN';
export const ATTEMPT_SIGN_OUT = 'ATTEMPT_SIGN_OUT';
export const AUTH_STATUS_CHANGED = 'AUTH_STATUS_CHANGED';
export const SIGN_IN_SUCCEEDED = 'SIGN_IN_SUCCEEDED';
export const SIGN_OUT_SUCCEEDED = 'SIGN_OUT_SUCCEEDED';
export const ATTEMPT_RESET_PASSWORD = 'ATTEMPT_RESET_PASSWORD';
export const RESET_PASSWORD_SUCCEEDED = 'RESET_PASSWORD_SUCCEEDED';

// ACCOUNT
export const ATTEMPT_FETCH_ACCOUNT = 'ATTEMPT_FETCH_ACCOUNT';
export const FETCH_ACCOUNT_SUCCEEDED = 'FETCH_ACCOUNT_SUCCEEDED';

export const FETCH_ACCOUNT_ROSTER_SETTINGS_ATTEMPT = 'FETCH_ACCOUNT_ROSTER_SETTINGS_ATTEMPT';
export const FETCH_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED = 'FETCH_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED';

// REGISTRATION
export const UPDATE_REGISTER_EMAIL = 'UPDATE_REGISTER_EMAIL';
export const UPDATE_REGISTER_PASSWORD = 'UPDATE_REGISTER_PASSWORD';
export const UPDATE_REGISTER_ORGANIZATION_NAME = 'UPDATE_REGISTER_ORGANIZATION_NAME';

export const REGISTER_ATTEMPT = 'REGISTER_ATTEMPT';
export const REGISTER_SUCCEEDED = 'REGISTER_SUCCEEDED';
export const REGISTER_FAILED = 'REGISTER_FAILED';

// SETTINGS
export const FETCH_ORGANIZATION_SETTINGS_ATTEMPT = 'FETCH_ORGANIZATION_SETTINGS_ATTEMPT';
export const FETCH_ORGANIZATION_SETTINGS_SUCCEEDED = 'FETCH_ORGANIZATION_SETTINGS_SUCCEEDED';
export const UPDATE_AUTO_PROGRESS_ATTEMPT = 'UPDATE_AUTO_PROGRESS_ATTEMPT';
export const UPDATE_AUTO_PROGRESS_SUCCEEDED = 'UPDATE_AUTO_PROGRESS_SUCCEEDED';

// SETTINGS DEFAULTS
export const UPDATE_DEFAULT_REST = 'UPDATE_DEFAULT_REST ';
export const UPDATE_DEFAULT_NOTES = 'UPDATE_DEFAULT_NOTES';
export const UPDATE_DEFAULT_AUTOREGULATION = 'UPDATE_DEFAULT_AUTOREGULATION';
export const UPDATE_DEFAULT_SENSOR_TRACKING = 'UPDATE_DEFAULT_SENSOR_TRACKING';
export const UPDATE_DEFAULT_NUM_REPS = 'UPDATE_DEFAULT_NUM_REPS';
export const UPDATE_DEFAULT_AMRAP = 'UPDATE_DEFAULT_AMRAP';
export const UPDATE_DEFAULT_MIN_SETS = 'UPDATE_DEFAULT_MIN_SETS';
export const UPDATE_DEFAULT_MAX_SETS = 'UPDATE_DEFAULT_MAX_SETS';
export const UPDATE_DEFAULT_SET_COUNT_FIXED = 'UPDATE_DEFAULT_SET_COUNT_FIXED';
export const UPDATE_DEFAULT_WEIGHT = 'UPDATE_DEFAULT_WEIGHT';
export const UPDATE_DEFAULT_WEIGHT_UNIT = 'UPDATE_DEFAULT_WEIGHT_UNIT';
export const UPDATE_DEFAULT_AUTOREGULATION_TARGET = 'UPDATE_DEFAULT_AUTOREGULATION_TARGET';
export const UPDATE_DEFAULT_AUTOREGULATION_RANGE = 'UPDATE_DEFAULT_AUTOREGULATION_RANGE';
export const UPDATE_DEFAULT_AUTOREGULATION_QUANTIFIER = 'UPDATE_DEFAULT_AUTOREGULATION_QUANTIFIER';
export const UPDATE_DEFAULT_AUTOREGULATION_METRIC = 'UPDATE_DEFAULT_AUTOREGULATION_METRIC';

export const SAVE_DEFAULTS_ATTEMPT = 'SAVE_DEFAULTS_ATTEMPT';
export const SAVE_DEFAULTS_SUCCEEDED = 'SAVE_DEFAULTS_SUCCEEDED';

// LOGIN
export const UPDATE_SIGN_IN_EMAIL = 'UPDATE_SIGN_IN_EMAIL';
export const UPDATE_SIGN_IN_PASSWORD = 'UPDATE_SIGN_IN_PASSWORD';

// DASHBOARD
export const ATTEMPT_FETCH_NEWS = 'ATTEMPT_FETCH_NEWS';
export const FETCH_NEWS_SUCCEEDED = 'FETCH_NEWS_SUCCEEDED';

// RACKS
export const ATTEMPT_FETCH_RACKS = 'ATTEMPT_FETCH_RACKS';
export const FETCH_RACKS_SUCCEEDED = 'FETCH_RACKS_SUCCEEDED';
export const UPDATE_RACK_ATTEMPT = 'UPDATE_RACK_ATTEMPT';
export const UPDATE_RACK_SUCCEEDED = 'UPDATE_RACK_SUCCEEDED';

// LIVE FEED
export const UPDATE_LIVE_GROUPS_FILTER = 'UPDATE_LIVE_GROUPS_FILTER';
export const UPDATE_FEED_TICK_TIMER = 'UPDATE_FEED_TICK_TIMER';
export const SET_FEED_ITEMS = 'SET_FEED_ITEMS';
export const UPDATE_FEED_COMPLETE_ITEM = 'UPDATE_FEED_COMPLETE_ITEM';
export const UPDATE_FEED_ENTRY_ITEM = 'UPDATE_FEED_ENTRY_ITEM';
export const DELETE_FEED_ITEM = 'DELETE_FEED_ITEM';

// TEAMS
export const LOADED_CACHED_TEAM = 'LOADED_CACHED_TEAM';
export const ATTEMPT_FETCH_TEAMS = 'ATTEMPT_FETCH_TEAMS';
export const FETCH_TEAMS_SUCCEEDED = 'FETCH_TEAMS_SUCCEEDED';
export const ATTEMPT_CREATE_TEAM = 'ATTEMPT_CREATE_TEAM';
export const CREATE_TEAM_SUCCEEDED = 'CREATE_TEAM_SUCCEEDED';
export const ATTEMPT_PATCH_TEAM = 'ATTEMPT_PATCH_TEAM';
export const PATCH_TEAM_SUCCEEDED = 'PATCH_TEAM_SUCCEEDED';
export const ATTEMPT_REMOVE_TEAM = 'ATTEMPT_REMOVE_TEAM';
export const REMOVE_TEAM_SUCCEEDED = 'REMOVE_TEAM_SUCCEEDED';

// GROUPS
export const LOADED_CACHED_GROUP = 'LOADED_CACHED_GROUP';
export const FETCH_GROUPS_SUCCEEDED = 'FETCH_GROUPS_SUCCEEDED';
export const ATTEMPT_CREATE_GROUP = 'ATTEMPT_CREATE_GROUP';
export const CREATE_GROUP_SUCCEEDED = 'CREATE_GROUP_SUCCEEDED';
export const ATTEMPT_PATCH_GROUP = 'ATTEMPT_PATCH_GROUP';
export const PATCH_GROUP_SUCCEEDED = 'PATCH_GROUP_SUCCEEDED';
export const ATTEMPT_TOGGLE_GROUP = 'ATTEMPT_TOGGLE_GROUP';
export const TOGGLE_GROUP_SUCCEEDED = 'TOGGLE_GROUP_SUCCEEDED';
export const ATTEMPT_REMOVE_GROUP = 'ATTEMPT_REMOVE_GROUP';
export const REMOVE_GROUP_SUCCEEDED = 'REMOVE_GROUP_SUCCEEDED';

// TEAM MANAGEMENT
export const CREATE_TEAM = 'CREATE_TEAM';
export const COLLAPSE_TEAM = 'COLLAPSE_TEAM';
export const EXPAND_TEAM = 'EXPAND_TEAM';
export const EDIT_TEAM = 'EDIT_TEAM';
export const EDIT_GROUP = 'EDIT_GROUP';
export const CREATE_GROUP = 'CREATE_GROUP';
export const UPDATE_TEAM_NAME = 'UPDATE_TEAM_NAME';
export const UPDATE_GROUP_NAME = 'UPDATE_GROUP_NAME';
export const CLOSE_CREATE_TEAM = 'CLOSE_CREATE_TEAM';
export const CLOSE_EDIT_TEAM = 'CLOSE_EDIT_TEAM';
export const CLOSE_CREATE_GROUP = 'CLOSE_CREATE_GROUP';
export const CLOSE_EDIT_GROUP = 'CLOSE_EDIT_GROUP';

// ATHLETES
export const LOADED_CACHED_ATHLETE = 'LOADED_CACHED_ATHLETE';
export const ATTEMPT_FETCH_ATHLETES = 'ATTEMPT_FETCH_ATHLETES';
export const FETCH_ATHLETES_SUCCEEDED = 'FETCH_ATHLETES_SUCCEEDED';
export const ATTEMPT_FETCH_ATHLETE = 'ATTEMPT_FETCH_ATHLETE';
export const FETCH_ATHLETE_SUCCEEDED = 'FETCH_ATHLETE_SUCCEEDED';
export const ATTEMPT_CREATE_ATHLETE = 'ATTEMPT_CREATE_ATHLETE';
export const CREATE_ATHLETE_SUCCEEDED = 'CREATE_ATHLETE_SUCCEEDED';
export const CREATE_ATHLETE_FAILED = 'CREATE_ATHLETE_FAILED';
export const ATTEMPT_SAVE_ATHLETE_METADATA = 'ATTEMPT_SAVE_ATHLETE_METADATA';
export const SAVE_ATHLETE_METADATA_SUCCEEDED = 'SAVE_ATHLETE_METADATA_SUCCEEDED';
export const ATTEMPT_UPLOAD_AVATAR = 'ATTEMPT_UPLOAD_AVATAR';
export const UPLOAD_AVATAR_SUCCEEDED = 'UPLOAD_AVATAR_SUCCEEDED';
export const UPLOAD_AVATAR_FAILED = 'UPLOAD_AVATAR_FAILED';
export const ATTEMPT_IMPORT_ATHLETES = 'ATTEMPT_IMPORT_ATHLETES';
export const IMPORT_ATHLETES_SUCCEEDED = 'IMPORT_ATHLETES_SUCCEEDED';
export const IMPORT_ATHLETES_MISSING_COLUMNS = 'IMPORT_ATHLETES_MISSING_COLUMNS';
export const IMPORT_ATHLETES_FAILED = 'IMPORT_ATHLETES_FAILED';
export const ATTEMPT_REMOVE_ATHLETE = 'ATTEMPT_REMOVE_ATHLETE';
export const REMOVE_ATHLETE_SUCCEEDED = 'REMOVE_ATHLETE_SUCCEEDED';

// ROSTER
export const FILTER_ATHLETES = 'FILTER_ATHLETES';
export const FILTER_ALL_ATHLETES = 'FILTER_ALL_ATHLETES';
export const FILTER_ATHLETES_BY_GROUP = 'FILTER_ATHLETES_BY_GROUP';
export const FILTER_ATHLETES_BY_TEAM = 'FILTER_ATHLETES_BY_TEAM';

export const SELECT_ROSTER_ATHLETE = 'SELECT_ROSTER_ATHLETE';
export const DESELECT_ROSTER_ATHLETE = 'DESELECT_ROSTER_ATHLETE';

export const SELECT_VISIBLE_ROSTER_ATHLETES = 'SELECT_VISIBLE_ROSTER_ATHLETES';
export const DESELECT_ROSTER_ATHLETES = 'DESELECT_ROSTER_ATHLETES';

export const UPDATE_ROSTER_SELECTED_TEAM = 'UPDATE_ROSTER_SELECTED_TEAM';
export const UPDATE_ROSTER_SELECTED_GROUP = 'UPDATE_ROSTER_SELECTED_GROUP';

export const UPDATE_ATHLETE_CATEGORY_ATTEMPT = 'UPDATE_ATHLETE_CATEGORY_ATTEMPT';
export const UPDATE_ATHLETE_CATEGORY_SUCCEEDED = 'UPDATE_ATHLETE_CATEGORY_SUCCEEDED';

export const DELETE_ROSTER_SELECTED_ATHLETES_ATTEMPT = 'DELETE_ROSTER_SELECTED_ATHLETES_ATTEMPT';
export const DELETE_ROSTER_SELECTED_ATHLETES_SUCCEEDED = 'DELETE_ROSTER_SELECTED_ATHLETES_SUCCEEDED';
export const DELETE_ROSTER_SELECTED_ATHLETES_FAILED = 'DELETE_ROSTER_SELECTED_ATHLETES_FAILED';
export const SAVE_SELECTED_ATHLETES_CATEGORIES_ATTEMPT = 'SAVE_SELECTED_ATHLETES_CATEGORIES_ATTEMPT';
export const SAVE_SELECTED_ATHLETES_CATEGORIES_SUCCEEDED = 'SAVE_SELECTED_ATHLETES_CATEGORIES_SUCCEEDED';
export const SAVE_SELECTED_ATHLETES_CATEGORIES_FAILED = 'SAVE_SELECTED_ATHLETES_CATEGORIES_FAILED';

// COLUMNS FILTER

export const SHOW_ROSTER_COLUMNS_FILTER = 'SHOW_ROSTER_COLUMNS_FILTER';
export const CLOSE_ROSTER_COLUMNS_FILTER = 'CLOSE_ROSTER_COLUMNS_FILTER';

export const SELECT_ROSTER_COLUMN_FILTER = 'SELECT_ROSTER_COLUMN_FILTER';
export const DESELECT_ROSTER_COLUMN_FILTER = 'DESELECT_ROSTER_COLUMN_FILTER';

export const UPDATE_ACCOUNT_ROSTER_SETTINGS_ATTEMPT = 'UPDATE_ACCOUNT_ROSTER_SETTINGS_ATTEMPT';
export const UPDATE_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED = 'UPDATE_ACCOUNT_ROSTER_SETTINGS_SUCCEEDED';
export const UPDATE_ACCOUNT_ROSTER_SETTINGS_FAILED = 'UPDATE_ACCOUNT_ROSTER_SETTINGS_FAILED';

// IMPORT

export const SHOW_IMPORT_ATHLETES = 'SHOW_IMPORT_ATHLETES';
export const CLOSE_IMPORT_ATHLETES = 'CLOSE_IMPORT_ATHLETES';

// PROFILE
export const CREATE_ATHLETE = 'CREATE_ATHLETE';
export const EDIT_ATHLETE = 'EDIT_ATHLETE';
export const CLOSE_CREATE_ATHLETE = 'CLOSE_CREATE_ATHLETE';
export const CLOSE_EDIT_ATHLETE = 'CLOSE_EDIT_ATHLETE';
export const UPDATE_ATHLETE_FIRST_NAME = 'UPDATE_ATHLETE_FIRST_NAME';
export const UPDATE_ATHLETE_MIDDLE_NAME = 'UPDATE_ATHLETE_MIDDLE_NAME';
export const UPDATE_ATHLETE_LAST_NAME = 'UPDATE_ATHLETE_LAST_NAME';
export const UPDATE_ATHLETE_TEAM = 'UPDATE_ATHLETE_TEAM';
export const UPDATE_ATHLETE_GROUP = 'UPDATE_ATHLETE_GROUP';

// ATHLETE HISTORY
export const UPDATE_ATHLETE_HISTORY_FILTER = 'UPDATE_ATHLETE_HISTORY_FILTER';
export const EXPAND_SESSION = 'EXPAND_SESSION';
export const COLLAPSE_SESSION = 'COLLAPSE_SESSION';
export const EXPAND_PLAN = 'EXPAND_PLAN';
export const COLLAPSE_PLAN = 'COLLAPSE_PLAN';

// REPORTS / ATHLETE REPORTS FILTERS
export const UPDATE_TONNAGE_EXERCISE_FILTER = 'UPDATE_TONNAGE_EXERCISE_FILTER';
export const UPDATE_REPORTS_START_DATE_FILTER = 'UPDATE_REPORTS_START_DATE_FILTER';
export const UPDATE_REPORTS_END_DATE_FILTER = 'UPDATE_REPORTS_END_DATE_FILTER';
export const UPDATE_REPORTS_TIME_RANGE_FILTER = 'UPDATE_REPORTS_TIME_RANGE_FILTER';
export const UPDATE_REPORTS_INTERVAL_FILTER = 'UPDATE_REPORTS_INTERVAL_FILTER';
export const UPDATE_REPORTS_GROUPS_FILTER = 'UPDATE_REPORTS_GROUPS_FILTER';

// REPORTS
export const FETCH_DATA_EXPORTS_ATTEMPT = 'FETCH_DATA_EXPORTS_ATTEMPT';
export const FETCH_DATA_EXPORTS_SUCCEEDED = 'FETCH_DATA_EXPORTS_SUCCEEDED';
export const FETCH_DATA_EXPORTS_FAILED = 'FETCH_DATA_EXPORTS_FAILED';

export const START_DATA_EXPORTS_ATTEMPT = 'START_DATA_EXPORTS_ATTEMPT';
export const START_DATA_EXPORTS_SUCCEEDED = 'START_DATA_EXPORTS_SUCCEEDED';
export const START_DATA_EXPORTS_FAILED = 'START_DATA_EXPORTS_FAILED';

export const RETRY_DATA_EXPORTS_ATTEMPT = 'RETRY_DATA_EXPORTS_ATTEMPT';
export const RETRY_DATA_EXPORTS_SUCCEEDED = 'RETRY_DATA_EXPORTS_SUCCEEDED';
export const RETRY_DATA_EXPORTS_FAILED = 'RETRY_DATA_EXPORTS_FAILED';

export const DOWNLOAD_DATA_EXPORTS_ATTEMPT = 'DOWNLOAD_DATA_EXPORTS_ATTEMPT';
export const DOWNLOAD_DATA_EXPORTS_SUCCEEDED = 'DOWNLOAD_DATA_EXPORTS_SUCCEEDED';
export const DOWNLOAD_DATA_EXPORTS_FAILED = 'DOWNLOAD_DATA_EXPORTS_FAILED';

export const UPDATE_DATA_EXPORT = 'UPDATE_DATA_EXPORT';

export const DATA_EXPORTS_STREAM_CONNECTED = 'DATA_EXPORTS_STREAM_CONNECTED';
export const DATA_EXPORTS_STREAM_DISCONNECTED = 'DATA_EXPORTS_STREAM_DISCONNECTED';

// PROGRAM BUILDER

export const EXPAND_MICROCYCLE = 'EXPAND_MICROCYCLE';
export const COLLAPSE_MICROCYCLE = 'COLLAPSE_MICROCYCLE';

export const EXPAND_MICROCYCLE_SESSION = 'EXPAND_MICROCYCLE_SESSION';
export const COLLAPSE_MICROCYCLE_SESSION = 'COLLAPSE_MICROCYCLE_SESSION';

export const DUPLICATE_MICROCYCLE = 'DUPLICATE_MICROCYCLE';
export const DUPLICATE_MICROCYCLE_SESSION = 'DUPLICATE_MICROCYCLE_SESSION';
export const DUPLICATE_BLOCK = 'DUPLICATE_BLOCK';
export const DUPLICATE_PLAN = 'DUPLICATE_PLAN';

export const INSERT_PLAN = 'INSERT_PLAN';
export const CANCEL_INSERT_PLAN = 'CANCEL_INSERT_PLAN';

// PROGRAM SCHEDULES

export const ATTEMPT_FETCH_PROGRAM_SCHEDULES = 'ATTEMPT_FETCH_PROGRAM_SCHEDULES';
export const FETCH_PROGRAM_SCHEDULES_SUCCEEDED = 'FETCH_PROGRAM_SCHEDULES_SUCCEEDED';

export const ATTEMPT_CREATE_PROGRAM_SCHEDULES = 'ATTEMPT_CREATE_PROGRAM_SCHEDULES';
export const CREATE_PROGRAM_SCHEDULE_SUCCEEDED = 'CREATE_PROGRAM_SCHEDULE_SUCCEEDED';
export const CREATE_PROGRAM_SCHEDULE_FAILED = 'CREATE_PROGRAM_SCHEDULE_FAILED';

export const ATTEMPT_DELETE_PROGRAM_SCHEDULE = 'ATTEMPT_DELETE_PROGRAM_SCHEDULE';
export const DELETE_PROGRAM_SCHEDULE_SUCCEEDED = 'DELETE_PROGRAM_SCHEDULE_SUCCEEDED';
export const DELETE_PROGRAM_SCHEDULE_FAILED = 'DELETE_PROGRAM_SCHEDULE_FAILED';

export const ATTEMPT_SAVE_PROGRAM_SCHEDULE = 'ATTEMPT_SAVE_PROGRAM_SCHEDULE'; 
export const SAVE_PROGRAM_SCHEDULE_SUCCEEDED = 'SAVE_PROGRAM_SCHEDULE_SUCCEEDED'; 
export const SAVE_PROGRAM_SCHEDULE_FAILED = 'SAVE_PROGRAM_SCHEDULE_FAILED'; 
export const UPDATE_PROGRAM_SCHEDULER_GROUPS_FILTER = 'UPDATE_PROGRAM_SCHEDULER_GROUPS_FILTER';

// PROGRAM SCHEDULE CALENDAR

export const CREATE_PROGRAM_SCHEDULE = 'CREATE_PROGRAM_SCHEDULE';
export const EDIT_PROGRAM_SCHEDULE = 'EDIT_PROGRAM_SCHEDULE';
export const UPDATE_PROGRAM_SCHEDULE_START_DATE = 'UPDATE_PROGRAM_SCHEDULE_START_DATE';
export const UPDATE_PROGRAM_SCHEDULE_END_DATE = 'UPDATE_PROGRAM_SCHEDULE_END_DATE';
export const UPDATE_PROGRAM_SCHEDULE_ASSIGNEES = 'UPDATE_PROGRAM_SCHEDULE_ASSIGNEES';
export const CLOSE_PROGRAM_SCHEDULE = 'CLOSE_PROGRAM_SCHEDULE';

// PROGRAMS

export const ATTEMPT_FETCH_PROGRAMS = 'ATTEMPT_FETCH_PROGRAMS';
export const FETCH_PROGRAMS_SUCCEEDED = 'FETCH_PROGRAMS_SUCCEEDED';

export const ATTEMPT_FETCH_PROGRAM = 'ATTEMPT_FETCH_PROGRAM';
export const FETCH_PROGRAM_SUCCEEDED = 'FETCH_PROGRAM_SUCCEEDED';

export const DRAFT_CREATE_PROGRAM = 'DRAFT_CREATE_PROGRAM';
export const ATTEMPT_CREATE_PROGRAM = 'ATTEMPT_CREATE_PROGRAM';
export const CREATE_PROGRAM_SUCCEEDED = 'CREATE_PROGRAM_SUCCEEDED';

export const DRAFT_UPDATE_PROGRAM_NAME = 'DRAFT_UPDATE_PROGRAM_NAME';
export const DRAFT_UPDATE_PROGRAM_NOTES = 'DRAFT_UPDATE_PROGRAM_NOTES';
export const DRAFT_SAVE_PROGRAM = 'DRAFT_SAVE_PROGRAM';
export const ATTEMPT_UPDATE_PROGRAM = 'ATTEMPT_UPDATE_PROGRAM';
export const UPDATE_PROGRAM_SUCCEEDED = 'UPDATE_PROGRAM_SUCCEEDED';

export const ATTEMPT_DUPLICATE_PROGRAM = 'ATTEMPT_DUPLICATE_PROGRAM';
export const DUPLICATE_PROGRAM_SUCCEEDED = 'DUPLICATE_PROGRAM_SUCCEEDED';

export const ATTEMPT_DELETE_PROGRAM = 'ATTEMPT_DELETE_PROGRAM';
export const DELETE_PROGRAM_SUCCEEDED = 'DELETE_PROGRAM_SUCCEEDED';
export const DELETE_PROGRAM_FAILED = 'DELETE_PROGRAM_FAILED';

// MICROCYCLES

export const DRAFT_CREATE_MICROCYCLE = 'DRAFT_CREATE_MICROCYCLE';
export const ATTEMPT_CREATE_MICROCYCLE = 'ATTEMPT_CREATE_MICROCYCLE';

export const DRAFT_MOVE_MICROCYCLE = 'DRAFT_MOVE_MICROCYCLE';
export const ATTEMPT_MOVE_MICROCYCLE = 'ATTEMPT_MOVE_MICROCYCLE';

export const DRAFT_DELETE_MICROCYCLE = 'DRAFT_DELETE_MICROCYCLE';
export const ATTEMPT_DELETE_MICROCYCLE = 'ATTEMPT_DELETE_MICROCYCLE';

// MICROCYCLE_SESSIONS

export const DRAFT_CREATE_MICROCYCLE_SESSION = 'DRAFT_CREATE_MICROCYCLE_SESSION';
export const ATTEMPT_CREATE_MICROCYCLE_SESSION = 'ATTEMPT_CREATE_MICROCYCLE_SESSION';

export const DRAFT_MOVE_MICROCYCLE_SESSION = 'DRAFT_MOVE_MICROCYCLE_SESSION';
export const ATTEMPT_MOVE_MICROCYCLE_SESSION = 'ATTEMPT_MOVE_MICROCYCLE_SESSION';
export const MOVE_MICROCYCLE_SESSION_SUCCEEDED = 'MOVE_MICROCYCLE_SESSION_SUCCEEDED';

export const DRAFT_DELETE_MICROCYCLE_SESSION = 'DRAFT_DELETE_MICROCYCLE_SESSION';
export const ATTEMPT_DELETE_MICROCYCLE_SESSION = 'ATTEMPT_DELETE_MICROCYCLE_SESSION';
export const DELETE_MICROCYCLE_SESSION_SUCCEEDED = 'DELETE_MICROCYCLE_SESSION_SUCCEEDED';

// ATHLETE_SESSIONS

export const ATTEMPT_FETCH_ATHLETE_SESSIONS = 'ATTEMPT_FETCH_ATHLETE_SESSIONS';
export const FETCH_ATHLETE_SESSIONS_SUCCEEDED = 'FETCH_ATHLETE_SESSIONS_SUCCEEDED';

// BLOCKS

export const DRAFT_CREATE_BLOCK = 'DRAFT_CREATE_BLOCK';
export const ATTEMPT_CREATE_BLOCK = 'ATTEMPT_CREATE_BLOCK';

export const DRAFT_UPDATE_BLOCK_TYPE = 'DRAFT_UPDATE_BLOCK_TYPE';
export const DRAFT_UPDATE_BLOCK_MIN = 'DRAFT_UPDATE_BLOCK_MIN';
export const DRAFT_UPDATE_BLOCK_MAX = 'DRAFT_UPDATE_BLOCK_MAX';
export const DRAFT_UPDATE_BLOCK_REST = 'DRAFT_UPDATE_BLOCK_REST';

export const DRAFT_SAVE_BLOCK = 'DRAFT_SAVE_BLOCK';
export const ATTEMPT_SAVE_BLOCK = 'ATTEMPT_SAVE_BLOCK';

export const DRAFT_MOVE_BLOCK = 'DRAFT_MOVE_BLOCK';
export const ATTEMPT_MOVE_BLOCK = 'ATTEMPT_MOVE_BLOCK';

export const DRAFT_DELETE_BLOCK = 'DRAFT_DELETE_BLOCK';
export const ATTEMPT_DELETE_BLOCK = 'ATTEMPT_DELETE_BLOCK';

// PLANS

export const DRAFT_CREATE_PLAN = 'DRAFT_CREATE_PLAN';
export const ATTEMPT_CREATE_PLAN = 'ATTEMPT_CREATE_PLAN';

export const DRAFT_UPDATE_PLAN_EXERCISE = 'DRAFT_UPDATE_PLAN_EXERCISE';
export const DRAFT_UPDATE_PLAN_REST = 'DRAFT_UPDATE_PLAN_REST';
export const DRAFT_UPDATE_PLAN_NOTES = 'DRAFT_UPDATE_PLAN_NOTES';
export const DRAFT_UPDATE_PLAN_AUTOREGULATION = 'DRAFT_UPDATE_PLAN_AUTOREGULATION';
export const DRAFT_UPDATE_PLAN_SENSOR_TRACKING = 'DRAFT_UPDATE_PLAN_SENSOR_TRACKING';
export const DRAFT_UPDATE_PLAN_MIN_SETS = 'DRAFT_UPDATE_PLAN_MIN_SETS';
export const DRAFT_UPDATE_PLAN_MAX_SETS = 'DRAFT_UPDATE_PLAN_MAX_SETS';
export const DRAFT_UPDATE_PLAN_SET_COUNT_FIXED = 'DRAFT_UPDATE_PLAN_SET_COUNT_FIXED';
export const DRAFT_UPDATE_PLAN_NUM_REPS = 'DRAFT_UPDATE_PLAN_NUM_REPS';
export const DRAFT_UPDATE_PLAN_AMRAP = 'DRAFT_UPDATE_PLAN_AMRAP';
export const DRAFT_UPDATE_PLAN_AUTOREGULATION_QUANTIFIER = 'DRAFT_UPDATE_PLAN_AUTOREGULATION_QUANTIFIER';
export const DRAFT_UPDATE_PLAN_AUTOREGULATION_TARGET = 'DRAFT_UPDATE_PLAN_AUTOREGULATION_TARGET';
export const DRAFT_UPDATE_PLAN_AUTOREGULATION_RANGE = 'DRAFT_UPDATE_PLAN_AUTOREGULATION_RANGE';
export const DRAFT_UPDATE_PLAN_AUTOREGULATION_METRIC = 'DRAFT_UPDATE_PLAN_AUTOREGULATION_METRIC';
export const DRAFT_UPDATE_PLAN_WEIGHT = 'DRAFT_UPDATE_PLAN_WEIGHT';
export const DRAFT_UPDATE_PLAN_WEIGHT_UNIT = 'DRAFT_UPDATE_PLAN_WEIGHT_UNIT';

export const DRAFT_SAVE_PLAN = 'DRAFT_SAVE_PLAN';
export const ATTEMPT_SAVE_PLAN = 'ATTEMPT_SAVE_PLAN';

export const DRAFT_MOVE_PLAN = 'DRAFT_MOVE_PLAN';
export const ATTEMPT_MOVE_PLAN = 'ATTEMPT_MOVE_PLAN';

export const DRAFT_DELETE_PLAN = 'DRAFT_DELETE_PLAN';
export const ATTEMPT_DELETE_PLAN = 'ATTEMPT_DELETE_PLAN';

// EXERCISES

export const ATTEMPT_FETCH_EXERCISES = 'ATTEMPT_FETCH_EXERCISES';
export const FETCH_EXERCISES_SUCCEEDED = 'FETCH_EXERCISES_SUCCEEDED';

export const DRAFT_CREATE_EXERCISE = 'DRAFT_CREATE_EXERCISE';
export const ATTEMPT_CREATE_EXERCISE = 'ATTEMPT_CREATE_EXERCISE';

export const ATTEMPT_FETCH_MAXABLE_EXERCISES = 'ATTEMPT_FETCH_MAXABLE_EXERCISES';
export const FETCH_MAXABLE_EXERCISES_SUCCEEDED = 'FETCH_MAXABLE_EXERCISES_SUCCEEDED';

export const ATTEMPT_ADD_MAXABLE_EXERCISE = 'ATTEMPT_ADD_MAXABLE_EXERCISE';
export const ADD_MAXABLE_EXERCISE_SUCCEEDED = 'ADD_MAXABLE_EXERCISE_SUCCEEDED';

export const ATTEMPT_REMOVE_MAXABLE_EXERCISE = 'ATTEMPT_REMOVE_MAXABLE_EXERCISE';
export const REMOVE_MAXABLE_EXERCISE_SUCCEEDED = 'REMOVE_MAXABLE_EXERCISE_SUCCEEDED';

// LINKS

export const UPDATE_EXERCISE_LINK_ATTEMPT = 'UPDATE_EXERCISE_LINK_ATTEMPT';
export const UPDATE_EXERCISE_LINK_SUCCEEDED = 'UPDATE_EXERCISE_LINK_SUCCEEDED';

export const DELETE_EXERCISE_LINK_ATTEMPT = 'DELETE_EXERCISE_LINK_ATTEMPT';
export const DELETE_EXERCISE_LINK_SUCCEEDED = 'DELETE_EXERCISE_LINK_SUCCEEDED';

// MAXES
export const ATTEMPT_UPDATE_MAX = 'ATTEMPT_UPDATE_MAX';
export const UPDATE_MAX_SUCCEEDED = 'UPDATE_MAX_SUCCEEDED';
export const CREATE_MAX_SUCCEEDED = 'CREATE_MAX_SUCCEEDED';
export const REMOVE_MAX_SUCCEEDED = 'REMOVE_MAX_SUCCEEDED';

// MAXES UI
export const UPDATE_MAX = 'UPDATE_MAX';
export const CONFIRM_REMOVE_MAX = 'CONFIRM_REMOVE_MAX';
export const CLOSE_REMOVE_MAX_POPUP = 'CLOSE_REMOVE_MAX_POPUP';

// ENTRIES
export const UPDATE_ENTRY_ATTEMPT = 'UPDATE_ENTRY_ATTEMPT';
export const UPDATE_ENTRY_SUCCEEDED = 'UPDATE_ENTRY_SUCCEEDED';

// HISTORY
export const FINISH_DOWNLOAD = 'FINISH_DOWNLOAD';

// OLD REPORTS
export const ATTEMPT_FETCH_REPORTS_TONNAGE = 'ATTEMPT_FETCH_REPORTS_TONNAGE';
export const FETCH_REPORTS_TONNAGE_SUCCEEDED = 'FETCH_REPORTS_TONNAGE_SUCCEEDED';
export const ATTEMPT_FETCH_REPORTS_VOLUME = 'ATTEMPT_FETCH_REPORTS_VOLUME';
export const FETCH_REPORTS_VOLUME_SUCCEEDED = 'FETCH_REPORTS_VOLUME_SUCCEEDED';
export const ATTEMPT_FETCH_ATHLETE_TONNAGE = 'ATTEMPT_FETCH_ATHLETE_TONNAGE';
export const FETCH_ATHLETE_TONNAGE_SUCCEEDED = 'FETCH_ATHLETE_TONNAGE_SUCCEEDED';
export const ATTEMPT_FETCH_ATHLETE_VOLUME = 'ATTEMPT_FETCH_ATHLETE_VOLUME';
export const FETCH_ATHLETE_VOLUME_SUCCEEDED = 'FETCH_ATHLETE_VOLUME_SUCCEEDED';
export const ATTEMPT_FETCH_EXPORT_CSV = 'ATTEMPT_FETCH_EXPORT_CSV';
export const FETCH_EXPORT_CSV_SUCCEEDED = 'FETCH_EXPORT_CSV_SUCCEEDED';

// DRAFT
export const RETRY_QUEUE = 'RETRY_QUEUE';
export const PUSH_QUEUE = 'PUSH_QUEUE';
export const POP_QUEUE = 'POP_QUEUE';
export const QUEUE_EXECUTING = 'QUEUE_EXECUTING';
export const QUEUE_EXECUTION_COMPLETE = 'QUEUE_EXECUTION_COMPLETE';
export const QUEUE_ITEM_SUCCEEDED = 'QUEUE_ITEM_SUCCEEDED';
export const QUEUE_ITEM_FAILED = 'QUEUE_ITEM_FAILED';

export const INVALIDATE_DRAFT = 'INVALIDATE_DRAFT';
export const VALIDATE_DRAFT = 'VALIDATE_DRAFT';

export const CANCEL_DRAFT_AND_QUEUE = 'CANCEL_DRAFT_AND_QUEUE';

// SOCKETS
export const ATTEMPT_CREATE_TICKET = 'ATTEMPT_CREATE_TICKET';
export const CREATE_TICKET_SUCCEEDED = 'CREATE_TICKET_SUCCEEDED';
export const DISCONNECT_WEBSOCKET = 'DISCONNECT_WEBSOCKET';
export const WEBSOCKET_CONNECTED = 'WEBSOCKET_CONNECTED';
export const WEBSOCKET_DISCONNECTED = 'WEBSOCKET_DISCONNECTED';
export const ATTEMPT_RECONNECT = 'ATTEMPT_RECONNECT';
