import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    call,
} from 'redux-saga/effects';

import {
    ATTEMPT_CREATE_MICROCYCLE_SESSION,
    ATTEMPT_MOVE_MICROCYCLE_SESSION,
    ATTEMPT_DELETE_MICROCYCLE_SESSION,

    QUEUE_ITEM_SUCCEEDED,
    QUEUE_ITEM_FAILED,
} from 'shared_redux/actions';
import API from 'services/microcycle_sessions';
import { handleError } from 'services/alerts';

export default function* MicrocycleSessionsSaga() {
    yield all([
        takeEvery(ATTEMPT_CREATE_MICROCYCLE_SESSION, createMicrocycleSession),
        takeEvery(ATTEMPT_MOVE_MICROCYCLE_SESSION, moveMicrocycleSession),
        takeEvery(ATTEMPT_DELETE_MICROCYCLE_SESSION, deleteMicrocycleSession),
    ]);
};

function *createMicrocycleSession(action) {
    try {
        yield apply(API, API.createMicrocycleSession, [action.payload]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
    } catch(error) {
        if (error.statusCode === 409) {
            // already created, treat as success
            yield put({ type: QUEUE_ITEM_SUCCEEDED });
        } else {
            yield put({ type: QUEUE_ITEM_FAILED });
            yield handleError(error, 'Error: Unable to create microcycle session because ' + error);
        }
    }
}

function *moveMicrocycleSession(action) {
    try {
        yield apply(API, API.moveMicrocycleSession, [action.payload]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
    } catch(error) {
        yield put({ type: QUEUE_ITEM_FAILED });
        yield handleError(error, 'Error: Unable to move microcycle session because ' + error);
    }
}

function *deleteMicrocycleSession(action) {
    try {
        yield apply(API, API.deleteMicrocycleSession, [action.session_id]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
    } catch(error) {
        if (error.statusCode === 403) {
            // POSSIBLY already deleted, going to assume it and treat it as success
            yield put({ type: QUEUE_ITEM_SUCCEEDED });
        } else {
            yield put({ type: QUEUE_ITEM_FAILED });
            yield handleError(error, 'Error: Unable to delete microcycle session because ' + error);
        }
    }
}
