import React, {Component} from 'react';

import styles from './styles.module.scss';
import TabHeader from '../tab_header';

export default class View extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <div>
                <div className={styles.titlebg}>
                    <div className={styles.title}>
                        <div>Roster</div>
                    </div>
                </div>
                <TabHeader tabs={[
                    { url: '/athletes', text: 'Athletes' },
                    { url: '/teams', text: 'Teams & Groups' },
                ]} />
            </div>
        );
    }
};
