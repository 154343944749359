import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import View from './view';
import * as HistoryFilterSelectors from 'shared_redux/selectors/view/history_filter';

const mapStateToProps = (state, props) => {
    if (!props.minDate || !props.maxDate) {
        return { models: [] };
    }

    const selectedDate = HistoryFilterSelectors.getSelectedDate(state);
    var date = props.maxDate.clone();
    var prevYear = null;
    var models = [];
    do {
        // year model
        if (prevYear !== date.year()) {
            prevYear = date.year();
            models.push({
                type: 'year',
                text: prevYear,
            });
        }

        // month model
        models.push({
            type: 'month',
            date: date.clone(),
            text: date.format('MMMM'),
            // TODO: this shouldn't have an internal override to first, that should be preset by the shared redux store
            selected: selectedDate ? selectedDate.year() === date.year() && selectedDate.month() === date.month() : models.length === 1,
        });

        // subtract
        date = date.subtract(1, 'month');
    } while (date.isAfter(props.minDate));

    return { models };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
