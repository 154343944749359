import API from './api';

export default {

    getDataExports: () => API({
        url: `data_exports`,
        method: 'GET',
    }),

    startDataExport: (team_id, group_id, start_date, end_date) => {
        return API({
            url: 'data_exports',
            method: 'POST',
            json: {
                team_id,
                group_id,
                start_date,
                end_date,
            },
        });
    },

    retryDataExport: (id) => API({
        url: `data_exports/${id}`,
        method: 'POST',
    }),

    downloadDataExport: (id) => API({
        url: `data_exports/${id}`,
        method: 'GET',
    }),

    // TODO: SSE Listen? Should it be here or different entirely?
    // may need to modify the API code to allow for it honestly

};
