import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    call,
} from 'redux-saga/effects';

import {
    ATTEMPT_CREATE_PLAN,
    ATTEMPT_SAVE_PLAN,
    ATTEMPT_MOVE_PLAN,
    ATTEMPT_DELETE_PLAN,

    QUEUE_ITEM_SUCCEEDED,
    QUEUE_ITEM_FAILED,
} from 'shared_redux/actions';
import API from 'services/plans';
import { handleError } from 'services/alerts';

export default function* PlansSaga() {
    yield all([
        takeEvery(ATTEMPT_CREATE_PLAN, createPlan),
        takeEvery(ATTEMPT_SAVE_PLAN, savePlan),
        takeEvery(ATTEMPT_MOVE_PLAN, movePlan),
        takeEvery(ATTEMPT_DELETE_PLAN, deletePlan),
    ]);
};

function *createPlan(action) {
    try {
        yield apply(API, API.createPlan, [action.payload]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
    } catch(error) {
        if (error.statusCode === 409) {
            // already created, treat as success
            yield put({ type: QUEUE_ITEM_SUCCEEDED });
        } else {
            yield put({ type: QUEUE_ITEM_FAILED });
            yield handleError(error, 'Error: Unable to create plan');
        }
    }
}

function *savePlan(action) {
    try {
        yield apply(API, API.updatePlan, [action.payload]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
    } catch(error) {
        yield put({ type: QUEUE_ITEM_FAILED });
        yield handleError(error, 'Error: Unable to save plan');
    }
}

function *movePlan(action) {
    try {
        yield apply(API, API.updatePlan, [action.payload]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
    } catch(error) {
        yield put({ type: QUEUE_ITEM_FAILED });
        yield handleError(error, 'Error: Unable to move plan');
    }
}

function *deletePlan(action) {
    try {
        yield apply(API, API.deletePlan, [action.id]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
    } catch(error) {
        if (error.statusCode === 403) {
            // POSSIBLY already deleted, going to assume it and treat it as success
            yield put({ type: QUEUE_ITEM_SUCCEEDED });
        } else {
            yield put({ type: QUEUE_ITEM_FAILED });
            yield handleError(error, 'Error: Unable to delete plan');
        }
    }
}
