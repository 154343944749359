import { Model, fk, many, attr } from 'redux-orm';

import {
    FETCH_PROGRAM_SUCCEEDED,
    FETCH_ATHLETE_SESSIONS_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';
import BlockTypes from 'constants/blockTypes';

class Blocks extends Model {

    static reducer(action, Blocks, session) {
        switch (action.type) {
            case FETCH_PROGRAM_SUCCEEDED: {
                for (const microcycle of action.payload.microcycles) {
                    for (const microcycle_session of microcycle.microcycle_sessions) {
                        for (const block of microcycle_session.session.blocks) {
                            Blocks.upsert({
                                id: block.id,
                                session_id: block.session_id,
                                type: block.type,
                                min_sets: block.min_sets,
                                max_sets: block.max_sets,
                                rest: block.rest,
                                order: block.order,
                            });
                        }
                    }
                }
                break;
            }
            case FETCH_ATHLETE_SESSIONS_SUCCEEDED: {
                for (const athlete_session of action.payload) {
                    for (const block of athlete_session.session.blocks) {
                        Blocks.upsert({
                            id: block.id,
                            session_id: block.session_id,
                            type: block.type,
                            min_sets: block.min_sets,
                            max_sets: block.max_sets,
                            rest: block.rest,
                            order: block.order,
                        });
                    }
                }
                break;
            }
            case SIGN_OUT_SUCCEEDED:
                Blocks.all().delete();
                break;
        }
    }
    
}
Blocks.modelName = 'Blocks';

Blocks.fields = {
    id: attr(),
    session_id: fk({
        to: 'Sessions',
        as: 'session',
        relatedName: 'blocks',
    }),
    type: attr(),
    min_sets: attr(),
    max_sets: attr(),
    rest: attr(),
    order: attr(),
};

// TODO: consider using json schema instead
Blocks.isValid = block => {
    // type check
    if (block.type === BlockTypes.STANDARD) {
        return true;
    }

    // check invalid min sets
    if (!Number.isInteger(block.min_sets) || block.min_sets <= 0) {
        return false;
    }
    
    // check max sets
    if (block.max_sets && (!Number.isInteger(block.max_sets) || block.min_sets > block.max_sets)) {
        return false;
    }

    // check invalid rest if it exists
    if (block.rest && !Number.isInteger(block.rest)) {
        return false;
    }

    return true;
};

Blocks.toJSON = block => {
    if (block.type === BlockTypes.STANDARD) {
        var payload = {
            id: block.id,
            name: block.name,
            type: block.type,
        };
    } else if (block.type === BlockTypes.ALTERNATING) {
        var payload = {
            id: block.id,
            name: block.name,
            type: block.type,
            min_sets: block.min_sets,
            max_sets: block.max_sets,
        };
    } else if (block.type === BlockTypes.SUPERSET) {
        var payload = {
            id: block.id,
            name: block.name,
            type: block.type,
            min_sets: block.min_sets,
            max_sets: block.max_sets,
            rest: block.rest,
        };
    }

    return payload;
};

export default Blocks;
