import React from 'react';

import styles from './styles.module.scss';
import {
    ProgramBuilderComponent,
    ProgramBuilderHeader,
    ProgramBuilderMenu,
    ProgramBuilderMenuItem,
    ProgramBuilderFooter,
} from '../program_builder_component';
import BlocksView from '../block';
import BlockCreator from '../block_creator';

import DragItemTypes from 'constants/dragItemTypes';

export default props => {
    if (props.expanded) {
        var title = <span onClick={()=>props.collapseMicrocycleSession(props.session_id)}>SESSION {props.order} <img src="https://assets.reponestrength.com/carrot_down.png" /></span>
        var blocks = props.block_ids.map(id => {
            return <BlocksView
                key={`Block${id}`}
                id={id} hoverIndicator={props.hoverIndicator}
                isReordering={props.isReordering}
                finishDrag={props.finishDrag}
                updateHoverIndicator={props.updateHoverIndicator}/>;
        });
        if (props.block_ids.length > 0) {
            var createBlock = null;
        } else {
            var createBlock = (
                <ProgramBuilderFooter
                    hoverIndicatorOffset={-2}
                    childIds={props.block_ids}>

                    <div className={styles.addBlock}>
                        <BlockCreator session_id={props.session_id} order={props.block_ids.length+1} />
                        <div onClick={() => props.createMicrocycleSession(props.microcycle_id, props.order+1)}>+ SESSION</div>
                    </div>

                </ProgramBuilderFooter>
            );
        }

    } else {
        var title = <span onClick={()=>props.expandMicrocycleSession(props.session_id)}>SESSION {props.order} <img src="https://assets.reponestrength.com/carrot_right.png" /></span>
        var blocks = null;
        var createBlock = null;
    }

    return (<div className={styles.microcycleSession}>
        <ProgramBuilderComponent
            parentType={DragItemTypes.MICROCYCLE_SESSION}
            childType={DragItemTypes.BLOCK}
            id={props.session_id}
            parentId={props.microcycle_id}
            order={props.order}
            isReordering={props.isReordering}
            finishDrag={props.finishDrag}
            updateHoverIndicator={props.updateHoverIndicator}
            hoverIndicatorTopOffset={-2}
            hoverIndicatorBottomOffset={-2}>

            <ProgramBuilderHeader
                hoverIndicatorOffset={0}
                dropParentHandler={props.moveMicrocycleSession}>

                <div className={styles.microcycleSessionTitle}>{title}</div>

            </ProgramBuilderHeader>

            {blocks}

            <ProgramBuilderMenu>
                <ProgramBuilderMenuItem onClick={()=>props.duplicateMicrocycleSession(props.session_id)}>
                    DUPLICATE
                </ProgramBuilderMenuItem>
                <ProgramBuilderMenuItem onClick={()=>window.confirm("Are you sure?") && props.deleteMicrocycleSession(props.session_id)}>
                    DELETE
                </ProgramBuilderMenuItem>
            </ProgramBuilderMenu>

            {createBlock}

        </ProgramBuilderComponent>
    </div>);
};
