import uuidv4 from 'uuid/v4';

import {
    EXPAND_MICROCYCLE,
    COLLAPSE_MICROCYCLE,

    DRAFT_CREATE_MICROCYCLE_SESSION,
    ATTEMPT_CREATE_MICROCYCLE_SESSION,

    DRAFT_MOVE_MICROCYCLE,
    ATTEMPT_MOVE_MICROCYCLE,

    DUPLICATE_MICROCYCLE,

    DRAFT_DELETE_MICROCYCLE,
    ATTEMPT_DELETE_MICROCYCLE,
} from 'shared_redux/actions';

export const expandMicrocycle = (id) => ({
    type: EXPAND_MICROCYCLE,
    id,
});

export const collapseMicrocycle = (id) => ({
    type: COLLAPSE_MICROCYCLE,
    id,
});

export const createMicrocycleSession = (microcycle_id, order) => {
    const session_id = uuidv4();
    return {
        type: DRAFT_CREATE_MICROCYCLE_SESSION,
        pushType: ATTEMPT_CREATE_MICROCYCLE_SESSION,
        payload: {
            session_id,
            microcycle_id,
            order,
        }
    };
};

export const duplicateMicrocycle = (id) => ({
    type: DUPLICATE_MICROCYCLE,
    id,
});

export const moveMicrocycle = (id, program_id, order) => ({
    type: DRAFT_MOVE_MICROCYCLE,
    pushType: ATTEMPT_MOVE_MICROCYCLE,
    payload: {
        id,
        program_id,
        order,
    },
});

export const deleteMicrocycle = (id) => ({
    type: DRAFT_DELETE_MICROCYCLE,
    pushType: ATTEMPT_DELETE_MICROCYCLE,
    id,
});
