import API from './api';

export default {

    createTicket: () => API({
        url: `tickets`,
        method: 'POST',
    }),
    
};
