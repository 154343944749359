import moment from 'moment';

import {
    UPDATE_REPORTS_START_DATE_FILTER,
    UPDATE_REPORTS_END_DATE_FILTER,
    UPDATE_REPORTS_TIME_RANGE_FILTER,
    UPDATE_REPORTS_INTERVAL_FILTER,
    UPDATE_TONNAGE_EXERCISE_FILTER,
    UPDATE_REPORTS_GROUPS_FILTER,

    FETCH_DATA_EXPORTS_ATTEMPT,
    FETCH_DATA_EXPORTS_FAILED,
    FETCH_DATA_EXPORTS_SUCCEEDED,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const START_FILTER_TIME = {hour:0,minute:0,second:0,millisecond:0};
const END_FILTER_TIME = {hour:23,minute:59,second:59,millisecond:999};

const createDefaultState = () => ({
    // TODO: toisostrings man
    startDateFilter: moment().subtract(30, 'day').set(START_FILTER_TIME),
    endDateFilter: moment().set(END_FILTER_TIME),
    timeRangeFilter: 30,
    intervalFilter: 'weekly',
    selectedTeamFilter: null,
    selectedGroupFilter: null,
    isFetching: false,

    // OUTDATED
    tonnageExerciseFilter: null,
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case UPDATE_REPORTS_START_DATE_FILTER: {
            const startDateFilter = moment(action.date).set(START_FILTER_TIME);
            return {
                ...state,
                startDateFilter,
                timeRangeFilter: timeRangeFrom(startDateFilter, state.endDateFilter),
            };
        }
        case UPDATE_REPORTS_END_DATE_FILTER: {
            const endDateFilter = moment(action.date).set(END_FILTER_TIME);
            return {
                ...state,
                endDateFilter,
                timeRangeFilter: timeRangeFrom(state.startDateFilter, endDateFilter),
            };
        }
        case UPDATE_REPORTS_TIME_RANGE_FILTER: {
            if (action.timeRange === 0) {
                return {
                    ...state,
                    timeRangeFilter: action.timeRange,
                };
            }

            const endDateFilter = moment().set(END_FILTER_TIME);
            const startDateFilter = moment().set(START_FILTER_TIME).subtract(action.timeRange-1, 'day');
            return {
                ...state,
                timeRangeFilter: action.timeRange,
                startDateFilter,
                endDateFilter,
            };
        }
        case UPDATE_REPORTS_INTERVAL_FILTER:
            return {
                ...state,
                intervalFilter: action.interval,
            };
        case UPDATE_TONNAGE_EXERCISE_FILTER:
            return {
                ...state,
                tonnageExerciseFilter: action.exerciseId,
            };
        case UPDATE_REPORTS_GROUPS_FILTER:
            return {
                ...state,
                selectedTeamFilter: action.team || null,
                selectedGroupFilter: action.group || null,
            };
        
        // spinner
        case FETCH_DATA_EXPORTS_ATTEMPT:
            return {
                ...state,
                isFetching: true,
            };
        case FETCH_DATA_EXPORTS_FAILED:
        case FETCH_DATA_EXPORTS_SUCCEEDED:
            return {
                ...state,
                isFetching: false,
            };
        
        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};

const timeRangeFrom = (start, end) => {
    // end date must be today
    if (!end.isSame(moment(), 'day')) {
        return 0;
    }

    const diff = end.diff(start, 'day');
    switch (diff) {
        case 7:
            return 7;
        case 30:
            return 30;
        case 90:
            return 90;
        case 365:
            return 365;
        default:
            return 0; // custom
    }
};
