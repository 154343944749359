import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    call,
} from 'redux-saga/effects';

import {
    FETCH_ACCOUNT_SUCCEEDED,

    ATTEMPT_FETCH_TEAMS,
    FETCH_TEAMS_SUCCEEDED,

    ATTEMPT_CREATE_TEAM,
    CREATE_TEAM_SUCCEEDED,

    ATTEMPT_PATCH_TEAM,
    PATCH_TEAM_SUCCEEDED,

    ATTEMPT_REMOVE_TEAM,
    REMOVE_TEAM_SUCCEEDED,

    EDIT_ATHLETE,

    EDIT_TEAM,
    LOADED_CACHED_TEAM,
} from 'shared_redux/actions';

import orm from 'shared_redux/reducers/models/orm';
import API from 'services/teams';
import { handleError } from 'services/alerts';
import * as AccountsSelectors from 'shared_redux/selectors/models/accounts';
import * as TeamsManagerSelectors from 'shared_redux/selectors/view/teams_manager';

export default function* TeamsSaga() {
    yield all([
        takeLatest(EDIT_ATHLETE, fetchTeams),
        takeLatest(ATTEMPT_FETCH_TEAMS, fetchTeams),
        takeEvery(ATTEMPT_CREATE_TEAM, createTeam),
        takeEvery(ATTEMPT_PATCH_TEAM, patchTeam),
        takeEvery(ATTEMPT_REMOVE_TEAM, removeTeam),
        takeLatest(EDIT_TEAM, fetchCachedTeam),
    ]);
};

function *fetchTeams(action) {
    try {
        var account = yield select(AccountsSelectors.getAccount);
        if (!account) {
            yield take(FETCH_ACCOUNT_SUCCEEDED);
            account = yield select(AccountsSelectors.getAccount);
        }
        const teams = yield apply(API, API.getTeams, [account.organization_id]);
        yield put({
            type: FETCH_TEAMS_SUCCEEDED,
            payload: teams,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch teams');
    }
}

function *createTeam(action) {
    try {
        const json = yield select(TeamsManagerSelectors.getTeamJSON);
        const team = yield apply(API, API.createTeam, [json]);
        yield put({
            type: CREATE_TEAM_SUCCEEDED,
            payload: team,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to create team');
    }
}

function *patchTeam(action) {
    try {
        const json = yield select(TeamsManagerSelectors.getTeamJSON);
        const team = yield apply(API, API.updateTeam, [json]);
        yield put({
            type: PATCH_TEAM_SUCCEEDED,
            payload: team,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to patch team');
    }
}

function *removeTeam(action) {
    try {
        const id = yield select(TeamsManagerSelectors.getTeamId);
        yield apply(API, API.removeTeam, [id]);
        yield put({
            type: REMOVE_TEAM_SUCCEEDED,
            id: id,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to remove team');
    }
}

function *fetchCachedTeam(action) {
    const database = yield select(state => state.database);
    const { Teams } = orm.mutableSession(database);
    const team = Teams.withId(action.teamId);

    yield put({
        type: LOADED_CACHED_TEAM,
        team,
    });
}
