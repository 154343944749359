import {
    ATTEMPT_FETCH_ATHLETE_SESSIONS,
    EXPAND_PLAN,
    COLLAPSE_PLAN,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

export default (state = {}, action) => {
    switch (action.type) {
        case EXPAND_PLAN: {
            let obj = {
                ...state
            };
            obj[action.planId] = true;
            return obj;
        }
        case COLLAPSE_PLAN: {
            let obj = {
                ...state
            };
            obj[action.planId] = false;
            return obj;
        }
        case ATTEMPT_FETCH_ATHLETE_SESSIONS:
        case SIGN_OUT_SUCCEEDED:
            return {};
        default:
            return state;
    }
};
