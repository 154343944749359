import { all, takeLatest, select, put } from 'redux-saga/effects';

import {
    // ticket
    ATTEMPT_CREATE_TICKET,
    CREATE_TICKET_SUCCEEDED,

    // websocket
    DISCONNECT_WEBSOCKET,
    WEBSOCKET_CONNECTED,
    WEBSOCKET_DISCONNECTED,
    ATTEMPT_RECONNECT,

    // feed
    SET_FEED_ITEMS,
    UPDATE_FEED_ENTRY_ITEM,
    UPDATE_FEED_COMPLETE_ITEM,
    DELETE_FEED_ITEM,
} from 'shared_redux/actions';
import { handleError } from 'services/alerts';
import ConnectionConfig from 'constants/connectionConfig';
import * as FeedSelectors from 'shared_redux/selectors/view/feed';

// NOTE: looks like no need to yield here at all, connection works
var socket = null;
var reconnectTimer = null;
var heartbeatTimer = null;

export default function* WebSocketSaga(dispatch) {
    yield all([
        takeLatest(CREATE_TICKET_SUCCEEDED, connectWebSocket, dispatch),
        takeLatest(DISCONNECT_WEBSOCKET, disconnectWebSocket),
        takeLatest(ATTEMPT_RECONNECT, reconnect),
    ]);
};

function *connectWebSocket(dispatch, action) {
    try {
        // check if currently connected, if so then ignore
        const connected = yield select(FeedSelectors.getIsConnected);
        if (connected) {
            return;
        }

        // close existing socket if it exists but you aren't connected
        if (socket) {
            socket.ignoreCallback = true;
            socket.close();
            socket = null;
        }

        // connect to the websocket
        const host = window.location.host;
        const ticket = action.ticket;
        if (host.includes('localhost')) {
            var url = `ws://localhost:3001/?ticket=${ticket}`;
        } else {
            var url = `wss://${host}/?ticket=${ticket}`;
        }
        socket = new WebSocket(url);
        socket.onopen = () => {
            // clearReconnectTimer();
            // heartbeat(socket);
            dispatch({
                type: WEBSOCKET_CONNECTED,
            });
        };
        socket.onmessage = (message) => {
            try {
                const json = JSON.parse(message.data);
                if (json.type && (json.type === SET_FEED_ITEMS || json.type === DELETE_FEED_ITEM || json.type === UPDATE_FEED_ENTRY_ITEM || json.type === UPDATE_FEED_COMPLETE_ITEM)) {
                    dispatch(json);
                }
            } catch(err) {
                // NOTE: This assumes any message from the server that can't be recognized is a ping request
                if (message.data === 'ping' && socket) {
                    console.log(`received ping, going to pong`);
                    // heartbeat(socket);
                    socket.send("pong");
                } else {
                    console.log(`Unable to parse message from server, data is ${message.data}, error is ${err}`);
                }
            }
        };
        socket.onclose = () => {
            // ignore if it was closing an existing socket
            if (!socket || socket.ignoreCallback) {
                return;
            }

            clearHeartbeatTimer();

            dispatch({
                type: WEBSOCKET_DISCONNECTED,
            });
            
            // NOTE: assumes single threaded
            if (socket && !reconnectTimer) {
                // TODO: make this progresively slower?
                reconnectTimer = setInterval(() => {
                    if (socket) {
                        dispatch({
                            type: ATTEMPT_RECONNECT,
                        });
                    }
                }, ConnectionConfig.WEBSOCKET_RETRY_INTERVAL);
            }
        };
    } catch(error) {
        yield handleError(error, 'Error: Unable to connect to websocket');
        // TODO: retry herer
    }
}

function *disconnectWebSocket(action) {
    if (socket) {
        socket.close();
    }
    socket = null;
    clearReconnectTimer();
}

function *reconnect(action) {
    const connected = yield select(FeedSelectors.getIsConnected);
    if (!connected && socket) {
        // disconnected and socket exists aka on live screen
        yield put({
            type: ATTEMPT_CREATE_TICKET,
        });
    } else {
        // clear timer if it exists otherwise
        clearReconnectTimer();
    }
}

const clearReconnectTimer = () => {
    if (reconnectTimer) {
        // clear timer if it exists otherwise
        clearInterval(reconnectTimer);
        reconnectTimer = null;
    }
};

const clearHeartbeatTimer = () => {
    if (heartbeatTimer) {
        clearTimeout(heartbeatTimer);
        heartbeatTimer = null;
    }
};

const heartbeat = (socket) => {
    clearHeartbeatTimer();
  
    heartbeatTimer = setTimeout(() => {
        socket.close();
    }, ConnectionConfig.WEBSOCKET_TIMEOUT);
};
