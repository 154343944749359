// TODO: draft system should utilize a true queuing system like on the kiosk rather than this bullshit

// TODO: consider having each model's static reducer be for draft and a standalone reducer for non draft
// as this is most of the logic
// ideally instead of switch statements everything is standalone and the reducer is composed for the draft_orm

import orm from './orm';
import {
    ATTEMPT_FETCH_PROGRAM,
    FETCH_PROGRAM_SUCCEEDED,
    DRAFT_CREATE_PROGRAM,
    DRAFT_UPDATE_PROGRAM_NAME,
    DRAFT_UPDATE_PROGRAM_NOTES,

    DRAFT_CREATE_MICROCYCLE,
    DRAFT_MOVE_MICROCYCLE,
    DRAFT_DELETE_MICROCYCLE,

    DRAFT_CREATE_MICROCYCLE_SESSION,
    DRAFT_MOVE_MICROCYCLE_SESSION,
    DRAFT_DELETE_MICROCYCLE_SESSION,

    DRAFT_CREATE_BLOCK,
    DRAFT_UPDATE_BLOCK_TYPE,
    DRAFT_UPDATE_BLOCK_MIN,
    DRAFT_UPDATE_BLOCK_MAX,
    DRAFT_UPDATE_BLOCK_REST,
    DRAFT_MOVE_BLOCK,
    DRAFT_DELETE_BLOCK,

    DRAFT_CREATE_PLAN,
    DRAFT_UPDATE_PLAN_EXERCISE,
    DRAFT_UPDATE_PLAN_REST,
    DRAFT_UPDATE_PLAN_NOTES,
    DRAFT_UPDATE_PLAN_AUTOREGULATION,
    DRAFT_UPDATE_PLAN_SENSOR_TRACKING,
    DRAFT_UPDATE_PLAN_MIN_SETS,
    DRAFT_UPDATE_PLAN_MAX_SETS,
    DRAFT_UPDATE_PLAN_SET_COUNT_FIXED,
    DRAFT_UPDATE_PLAN_NUM_REPS,
    DRAFT_UPDATE_PLAN_AMRAP,
    DRAFT_UPDATE_PLAN_AUTOREGULATION_QUANTIFIER,
    DRAFT_UPDATE_PLAN_AUTOREGULATION_TARGET,
    DRAFT_UPDATE_PLAN_AUTOREGULATION_RANGE,
    DRAFT_UPDATE_PLAN_AUTOREGULATION_METRIC,
    DRAFT_UPDATE_PLAN_WEIGHT,
    DRAFT_UPDATE_PLAN_WEIGHT_UNIT,
    DRAFT_MOVE_PLAN,
    DRAFT_DELETE_PLAN,

    DRAFT_CREATE_EXERCISE,
    FETCH_EXERCISES_SUCCEEDED,

    CANCEL_DRAFT_AND_QUEUE,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

export default (state, action) => {
    const session = orm.session(state);

    const { Programs, Microcycles, MicrocycleSessions, Sessions, Blocks, Plans, Exercises } = session;

    switch (action.type) {
        // programs
        case FETCH_PROGRAM_SUCCEEDED:
            const {['microcycles']: microcycles, ...program} = action.payload;
            Programs.upsert(program);
            for (var microcycle of microcycles) {
                const {['microcycle_sessions']: microcycle_sessions, ...rawMicrocycle} = microcycle;
                Microcycles.upsert(rawMicrocycle);
                for (var microcycle_session of microcycle_sessions) {
                    const {['session']: s, ...rawMicrocycleSession} = microcycle_session;
                    MicrocycleSessions.upsert(rawMicrocycleSession);
                    const {['blocks']: blocks, ...rawSession} = s;
                    Sessions.upsert(rawSession);
                    for (var block of blocks) {
                        const {['plans']: plans, ...rawBlock} = block;
                        Blocks.upsert(rawBlock);
                        for (var plan of plans) {
                            Plans.upsert(Plans.normalize(plan));
                        }
                    }
                }
            }
            break;
        case DRAFT_CREATE_PROGRAM:
            Programs.upsert(action.payload);
            break;
        case DRAFT_UPDATE_PROGRAM_NAME:
        case DRAFT_UPDATE_PROGRAM_NOTES:
            Programs.withId(action.payload.id).update(action.payload);
            break;
        
        // microcycles
        case DRAFT_CREATE_MICROCYCLE:
            Microcycles.all().filter(microcycle => microcycle.program_id === action.payload.program_id && microcycle.order >= action.payload.order).toRefArray().forEach(m => {
                Microcycles.withId(m.id).update({order: m.order+1});
            });
            Microcycles.upsert(action.payload);
            break;
        case DRAFT_MOVE_MICROCYCLE: {
            const origMicrocycle = Microcycles.withId(action.payload.id);
            if (action.payload.program_id && origMicrocycle.program_id !== action.payload.program_id) {
                // decrement for previous list
                Microcycles.all().filter(microcycle => microcycle.program_id === origMicrocycle.program_id && microcycle.order > origMicrocycle.order).toModelArray().forEach(microcycle => {
                    Microcycles.withId(microcycle.id).update({order: microcycle.order-1});
                });

                // increment for new list
                Microcycles.all().filter(microcycle => microcycle.program_id === action.payload.program_id && microcycle.order >= action.payload.order).toModelArray().forEach(microcycle => {
                    Microcycles.withId(microcycle.id).update({order: microcycle.order+1});
                });
            } else if (action.payload.order > origMicrocycle.order) {
                // decrement when moving object forwards
                Microcycles.all().filter(microcycle => microcycle.program_id === origMicrocycle.program_id && microcycle.order > origMicrocycle.order && microcycle.order <= action.payload.order).toModelArray().forEach(microcycle => {
                    Microcycles.withId(microcycle.id).update({order: microcycle.order-1});
                });
            } else if (action.payload.order < origMicrocycle.order) {
                // increment when moving object backwards
                Microcycles.all().filter(microcycle => microcycle.program_id === origMicrocycle.program_id && microcycle.order >= action.payload.order && microcycle.order < origMicrocycle.order).toModelArray().forEach(microcycle => {
                    Microcycles.withId(microcycle.id).update({order: microcycle.order+1});
                });
            }
            origMicrocycle.update(action.payload);
            break;
        }
        case DRAFT_DELETE_MICROCYCLE: {
            // update order
            const origMicrocycle = Microcycles.withId(action.id);
            Microcycles.all().filter(microcycle => microcycle.program_id === origMicrocycle.program_id && microcycle.order > origMicrocycle.order).toModelArray().forEach(microcycle => {
                Microcycles.withId(microcycle.id).update({order: microcycle.order-1});
            });

            // delete
            MicrocycleSessions.all().filter(ms => ms.microcycle_id === action.id).toModelArray().forEach(ms => {
                Blocks.all().filter(block => block.session_id === ms.session_id).toModelArray().forEach(block => {
                    Plans.all().filter(plan => plan.block_id === block.id).delete();
                    block.delete();    
                });
                ms.delete();
            })
            origMicrocycle.delete();
            break;
        }

        // microcycle sessions
        case DRAFT_CREATE_MICROCYCLE_SESSION:
            MicrocycleSessions.all().filter(ms => ms.microcycle_id === action.payload.microcycle_id && ms.order >= action.payload.order).toRefArray().forEach(ms => {
                MicrocycleSessions.withId(ms.session_id).update({order: ms.order+1});
            });
            MicrocycleSessions.upsert(action.payload);
            Sessions.upsert({ id: action.payload.session_id });
            break;
        case DRAFT_MOVE_MICROCYCLE_SESSION: {
            const origMS = MicrocycleSessions.withId(action.payload.session_id);
            if (action.payload.microcycle_id && origMS.microcycle_id !== action.payload.microcycle_id) {
                // decrement for previous list
                MicrocycleSessions.all().filter(ms => ms.microcycle_id === origMS.microcycle_id && ms.order > origMS.order).toModelArray().forEach(ms => {
                    MicrocycleSessions.withId(ms.session_id).update({order: ms.order-1});
                });

                // increment for new list
                MicrocycleSessions.all().filter(ms => ms.microcycle_id === action.payload.microcycle_id && ms.order >= action.payload.order).toModelArray().forEach(ms => {
                    MicrocycleSessions.withId(ms.session_id).update({order: ms.order+1});
                });
            } else if (action.payload.order > origMS.order) {
                // decrement when moving object forwards
                MicrocycleSessions.all().filter(ms => ms.microcycle_id === origMS.microcycle_id && ms.order > origMS.order && ms.order <= action.payload.order).toModelArray().forEach(ms => {
                    MicrocycleSessions.withId(ms.session_id).update({order: ms.order-1});
                });
            } else if (action.payload.order < origMS.order) {
                // increment when moving object backwards
                MicrocycleSessions.all().filter(ms => ms.microcycle_id === origMS.microcycle_id && ms.order >= action.payload.order && ms.order < origMS.order).toModelArray().forEach(ms => {
                    MicrocycleSessions.withId(ms.session_id).update({order: ms.order+1});
                });
            }
            origMS.update(action.payload);
            break;
        }
        case DRAFT_DELETE_MICROCYCLE_SESSION: {
            // update order
            const origMS = MicrocycleSessions.withId(action.session_id);
            MicrocycleSessions.all().filter(ms => ms.microcycle_id === origMS.microcycle_id && ms.order > origMS.order).toModelArray().forEach(ms => {
                MicrocycleSessions.withId(ms.session_id).update({order: ms.order-1});
            });

            // delete
            Blocks.all().filter(block => block.session_id === action.session_id).toModelArray().forEach(block => {
                Plans.all().filter(plan => plan.block_id === block.id).delete();
                block.delete();    
            });
            origMS.delete();
            Sessions.withId(action.session_id).delete();
            break;
        }

        // blocks
        case DRAFT_CREATE_BLOCK:
            Blocks.all().filter(block => block.session_id === action.payload.session_id && block.order >= action.payload.order).toRefArray().forEach(b => {
                Blocks.withId(b.id).update({order: b.order+1});
            });
            Blocks.create(action.payload);
            break;
        case DRAFT_UPDATE_BLOCK_TYPE:
        case DRAFT_UPDATE_BLOCK_MIN:
        case DRAFT_UPDATE_BLOCK_MAX:
        case DRAFT_UPDATE_BLOCK_REST:
            Blocks.withId(action.payload.id).update(action.payload);
            break;
        case DRAFT_MOVE_BLOCK: {
            const origBlock = Blocks.withId(action.payload.id);
            if (action.payload.session_id && origBlock.session_id !== action.payload.session_id) {
                // decrement for previous list
                Blocks.all().filter(block => block.session_id === origBlock.session_id && block.order > origBlock.order).toModelArray().forEach(block => {
                    Blocks.withId(block.id).update({order: block.order-1});
                });

                // increment for new list
                Blocks.all().filter(block => block.session_id === action.payload.session_id && block.order >= action.payload.order).toModelArray().forEach(block => {
                    Blocks.withId(block.id).update({order: block.order+1});
                });
            } else if (action.payload.order > origBlock.order) {
                // decrement when moving object forwards
                Blocks.all().filter(block => block.session_id === origBlock.session_id && block.order > origBlock.order && block.order <= action.payload.order).toModelArray().forEach(block => {
                    Blocks.withId(block.id).update({order: block.order-1});
                });
            } else if (action.payload.order < origBlock.order) {
                // increment when moving object backwards
                Blocks.all().filter(block => block.session_id === origBlock.session_id && block.order >= action.payload.order && block.order < origBlock.order).toModelArray().forEach(block => {
                    Blocks.withId(block.id).update({order: block.order+1});
                });
            }
            origBlock.update(action.payload);
            break;
        }
        case DRAFT_DELETE_BLOCK: {
            const origBlock = Blocks.withId(action.id);
            Blocks.all().filter(block => block.session_id === origBlock.session_id && block.order > origBlock.order).toModelArray().forEach(block => {
                Blocks.withId(block.id).update({order: block.order-1});
            });
            Plans.all().filter(plan => plan.block_id === action.id).delete();
            origBlock.delete();
            break;
        }

        // plans
        case DRAFT_CREATE_PLAN:
            Plans.all().filter(plan => plan.block_id === action.payload.block_id && plan.order >= action.payload.order).toRefArray().forEach(p => {
                Plans.withId(p.id).update({order: p.order+1});
            });
            Plans.create(action.payload);
            break;
        case DRAFT_UPDATE_PLAN_EXERCISE:
        case DRAFT_UPDATE_PLAN_REST:
        case DRAFT_UPDATE_PLAN_NOTES:
        case DRAFT_UPDATE_PLAN_AUTOREGULATION:
        case DRAFT_UPDATE_PLAN_SENSOR_TRACKING:
        case DRAFT_UPDATE_PLAN_MIN_SETS:
        case DRAFT_UPDATE_PLAN_MAX_SETS:
        case DRAFT_UPDATE_PLAN_SET_COUNT_FIXED:
        case DRAFT_UPDATE_PLAN_NUM_REPS:
        case DRAFT_UPDATE_PLAN_AMRAP:
        case DRAFT_UPDATE_PLAN_AUTOREGULATION_QUANTIFIER:
        case DRAFT_UPDATE_PLAN_AUTOREGULATION_TARGET:
        case DRAFT_UPDATE_PLAN_AUTOREGULATION_RANGE:
        case DRAFT_UPDATE_PLAN_AUTOREGULATION_METRIC:
        case DRAFT_UPDATE_PLAN_WEIGHT:
        case DRAFT_UPDATE_PLAN_WEIGHT_UNIT:
            Plans.withId(action.payload.id).update(action.payload);
            break;
        case DRAFT_MOVE_PLAN: {
            // NOT: currently assuming that it will properly calculate the order so you don't have to "cap" the order
            const origPlan = Plans.withId(action.payload.id);
            if (action.payload.block_id && origPlan.block_id !== action.payload.block_id) {
                // decrement for previous list
                Plans.all().filter(plan => plan.block_id === origPlan.block_id && plan.order > origPlan.order).toModelArray().forEach(plan => {
                    Plans.withId(plan.id).update({order: plan.order-1});
                });

                // increment for new list
                Plans.all().filter(plan => plan.block_id === action.payload.block_id && plan.order >= action.payload.order).toModelArray().forEach(plan => {
                    Plans.withId(plan.id).update({order: plan.order+1});
                });
            } else if (action.payload.order > origPlan.order) {
                // decrement when moving object forwards
                Plans.all().filter(plan => plan.block_id === origPlan.block_id && plan.order > origPlan.order && plan.order <= action.payload.order).toModelArray().forEach(plan => {
                    Plans.withId(plan.id).update({order: plan.order-1});
                });
            } else if (action.payload.order < origPlan.order) {
                // increment when moving object backwards
                Plans.all().filter(plan => plan.block_id === origPlan.block_id && plan.order >= action.payload.order && plan.order < origPlan.order).toModelArray().forEach(plan => {
                    Plans.withId(plan.id).update({order: plan.order+1});
                });
            }
            origPlan.update(action.payload);
            break;
        }
        case DRAFT_DELETE_PLAN: {
            const origPlan = Plans.withId(action.id);
            Plans.all().filter(plan => plan.block_id === origPlan.block_id && plan.order > origPlan.order).toModelArray().forEach(plan => {
                Plans.withId(plan.id).update({order: plan.order-1});
            });
            origPlan.delete();
            break;
        }

        // exercises and other
        case DRAFT_CREATE_EXERCISE:
            Exercises.create(action.payload);
            break;
        case FETCH_EXERCISES_SUCCEEDED: {
            for (const exercise of action.payload) {
                const {['parent_exercise_links']: parent_exercise_links, ...rawExercise} = exercise;
                Exercises.upsert(rawExercise);
            }
            break;
        }
        case ATTEMPT_FETCH_PROGRAM: {
            const program = Programs.withId(action.program_id);
            if (!program) {
                Programs.all().delete();
                Microcycles.all().delete();
                MicrocycleSessions.all().delete();
                Sessions.all().delete();
                Blocks.all().delete();
                Plans.all().delete();
                Exercises.all().delete();
            }
            break;
        }
        case CANCEL_DRAFT_AND_QUEUE:
        case SIGN_OUT_SUCCEEDED:
            Programs.all().delete();
            Microcycles.all().delete();
            MicrocycleSessions.all().delete();
            Sessions.all().delete();
            Blocks.all().delete();
            Plans.all().delete();
            Exercises.all().delete();
            break;
    }
    
    return session.state;
};
