import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    call,
    spawn,
} from 'redux-saga/effects';

import {
    FETCH_ACCOUNT_SUCCEEDED,

    ATTEMPT_FETCH_PROGRAM_SCHEDULES,
    FETCH_PROGRAM_SCHEDULES_SUCCEEDED,

    ATTEMPT_CREATE_PROGRAM_SCHEDULES,
    CREATE_PROGRAM_SCHEDULE_SUCCEEDED, 
    CREATE_PROGRAM_SCHEDULE_FAILED,
    
    ATTEMPT_DELETE_PROGRAM_SCHEDULE,
    DELETE_PROGRAM_SCHEDULE_SUCCEEDED,
    DELETE_PROGRAM_SCHEDULE_FAILED,

    ATTEMPT_SAVE_PROGRAM_SCHEDULE,
    SAVE_PROGRAM_SCHEDULE_SUCCEEDED,
    SAVE_PROGRAM_SCHEDULE_FAILED, 
} from 'shared_redux/actions';
import API from 'services/program_schedules';
import { handleError } from 'services/alerts';
import orm from 'shared_redux/reducers/models/orm';
import * as ProgramSchedulerPopupSelectors from 'shared_redux/selectors/view/program_scheduler_popup';
import * as AccountsSelectors from 'shared_redux/selectors/models/accounts';

export default function* ProgramSchedulesSaga(dispatch, getState) {
    yield all([
        takeEvery(ATTEMPT_FETCH_PROGRAM_SCHEDULES, fetchProgramSchedules),
        takeEvery(ATTEMPT_CREATE_PROGRAM_SCHEDULES, createProgramSchedules, getState),
        takeEvery(ATTEMPT_SAVE_PROGRAM_SCHEDULE, saveProgramSchedule),
        takeEvery(ATTEMPT_DELETE_PROGRAM_SCHEDULE, deleteProgramSchedule),
    ]);
};

function *fetchProgramSchedules(action) {
    try {
        var account = yield select(AccountsSelectors.getAccount);
        if (!account) {
            yield take(FETCH_ACCOUNT_SUCCEEDED);
            account = yield select(AccountsSelectors.getAccount);
        }
        const payload = yield apply(API, API.getProgramSchedules, [account.organization_id]);
        yield put({
            type: FETCH_PROGRAM_SCHEDULES_SUCCEEDED,
            payload,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch program schedules');
    }
}

function *createProgramSchedules(getState, action) {
    const state = getState();
    const session = orm.session(state.database);

    const assignees = yield select(ProgramSchedulerPopupSelectors.getAssignees);
    for (var assignee of assignees) {
        yield call(createProgramSchedule, session, assignee);
    }
}

function *createProgramSchedule(session, assignee) {
    const { Athletes, Groups, Teams } = session;
    const athlete = Athletes.withId(assignee);
    const group = Groups.withId(assignee);
    const team = Teams.withId(assignee);

    const programId = yield select(ProgramSchedulerPopupSelectors.getProgramId);
    const startDate = yield select(ProgramSchedulerPopupSelectors.getStartDate);
    const endDate = yield select(ProgramSchedulerPopupSelectors.getEndDate);

    var json = {
        athlete_id: '00000000-0000-0000-0000-000000000000',
        group_id: '00000000-0000-0000-0000-000000000000',
        team_id: '00000000-0000-0000-0000-000000000000',
        program_id: programId,
        start_date: startDate,
        end_date: endDate,
    };
    if (athlete) {
        json.athlete_id = assignee;
    } else if (group) {
        json.group_id = assignee;
    } else if (team) {
        json.team_id = assignee;
    }

    try {
        const payload = yield apply(API, API.createProgramSchedule, [json]);
        yield put({
            type: CREATE_PROGRAM_SCHEDULE_SUCCEEDED,
            payload,
        });
    } catch(error) {
        if (error && error.hasOwnProperty('json')) {
            yield put({
                type: CREATE_PROGRAM_SCHEDULE_FAILED,
                payload: error.json, 
            });
            yield handleError(error, 'Error: Unable to create program schedule', false);
        } else {
            yield put({
                type: CREATE_PROGRAM_SCHEDULE_FAILED,
                payload: null, 
            });
            yield handleError(error, `Error: Unable to create program schedule ${JSON.stringify(error)}`);
        }
    }
}

function *saveProgramSchedule(action) {
    try {
        const id = yield select(ProgramSchedulerPopupSelectors.getEditingId);
        const start_date = yield select(ProgramSchedulerPopupSelectors.getStartDate);
        const end_date = yield select(ProgramSchedulerPopupSelectors.getEndDate);
        const json = {
            id,
            start_date,
            end_date,
        };
        const payload = yield apply(API, API.updateProgramSchedule, [json]);
        yield put({
            type: SAVE_PROGRAM_SCHEDULE_SUCCEEDED,
            payload,
        });
    } catch(error) {
        yield put({
            type: SAVE_PROGRAM_SCHEDULE_FAILED,
            payload: error.json,
        });
        yield handleError(error, 'Error: Unable to save program schedule', false);
    }
}

function *deleteProgramSchedule(action) {
    try {
        const id = yield select(ProgramSchedulerPopupSelectors.getEditingId);
        yield apply(API, API.deleteProgramSchedule, [id]);
        yield put({
            type: DELETE_PROGRAM_SCHEDULE_SUCCEEDED,
            id,
        });
    } catch(error) {
        yield put({ type: DELETE_PROGRAM_SCHEDULE_FAILED });
        yield handleError(error, 'Error: Unable to delete program schedule');
    }
}
