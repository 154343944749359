import React, {Component} from 'react';
import Select from 'react-select';
import styles from './styles.module.scss';
import Trash from 'shared_features/svgs/trash';
import Spinner from 'shared_features/spinner';

export const selectStyles = {
    indicatorSeparator: (styles) => ({display:'none'}),
};

export default props => {
    // don't display unless at least 1 selected
    if (props.numSelected <= 0) {
        return null;
    }

    // set up team
    let teamValue = null;
    const teams = [];
    for (var team of props.teams) {
        const option = { value: team.id, label: team.name };
        teams.push(option);
        if (team.id === props.teamId) {
            teamValue = option;
        }
    }

    // set up group
    let groupValue = null;
    const groups = [];
    for (var group of props.groups) {
        const option = { value: group.id, label: group.name };
        groups.push(option);
        if (group.id === props.groupId) {
            groupValue = option;
        }
    }

    // delete and done 
    if (props.isUploading) {
        var del = <span className={[styles.delete, styles.disabled].join(' ')}><Trash />Delete</span>;
        var done = <span className={styles.spinner}><Spinner /></span>
    } else {
        var del = <span className={styles.delete} onClick={()=>window.confirm("Are you sure?") && props.deleteSelectedAthletes()}><Trash />Delete</span>;
        var done = <span className={styles.done} onClick={()=>props.saveSelectedAthletesCategories()}>DONE</span>;
    }

    return (<div className={styles.container}>
        <span className={styles.selected}>{props.numSelected} Selected</span>
        <span>
            <Select
                name="team"
                className={styles.team}
                styles={selectStyles}
                value={teamValue}
                isClearable
                clearable={true}
                deleteRemoves={false}
                placeholder="Set team..."
                menuPlacement="top"
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        props.updateSelectedTeam(selectedOption.value);
                    } else {
                        props.updateSelectedTeam(null);
                    }
                }}
                options={teams}
            />
        </span>
        <span>
            <Select
                name="group"
                className={styles.group}
                styles={selectStyles}
                value={groupValue}
                isClearable
                clearable={true}
                deleteRemoves={false}
                placeholder="Set group..."
                menuPlacement="top"
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        props.updateSelectedGroup(selectedOption.value);
                    } else {
                        props.updateSelectedGroup(null);
                    }
                }}
                options={groups}
            />
        </span>
        {del}
        {done}
    </div>);
};
