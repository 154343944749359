import {
    FETCH_ATHLETE_SESSIONS_SUCCEEDED,

    SET_FEED_ITEMS,
    UPDATE_FEED_ENTRY_ITEM,
    UPDATE_FEED_COMPLETE_ITEM,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, oneToOne, many, attr } from 'redux-orm';

class AthleteSessions extends Model {

    static reducer(action, AthleteSessions, session) {
        switch (action.type) {
            case FETCH_ATHLETE_SESSIONS_SUCCEEDED:
                for (var athlete_session of action.payload) {
                    AthleteSessions.upsert({
                        session_id: athlete_session.session_id,
                        athlete_id: athlete_session.athlete_id,
                        microcycle_session_id: athlete_session.microcycle_session_id,
                        program_schedule_id: athlete_session.program_schedule_id,
                        date: athlete_session.date,
                        program_name: athlete_session.program_name,
                        microcycle_number: athlete_session.microcycle_number,
                        session_number: athlete_session.microcycle_number,
                        program_id: athlete_session.program_id,
                    });
                }
                break;

            case SET_FEED_ITEMS:
                action.feed_items.forEach(i => {
                    AthleteSessions.upsert(i.athlete_session);
                });
                break;

            case UPDATE_FEED_ENTRY_ITEM:
            case UPDATE_FEED_COMPLETE_ITEM:
                AthleteSessions.upsert(action.feed_item.athlete_session);
                break;

           case SIGN_OUT_SUCCEEDED:
                AthleteSessions.all().delete();
                break;
        }
    }

}
AthleteSessions.modelName = 'AthleteSessions';

AthleteSessions.options = {
    idAttribute: 'session_id',
};

AthleteSessions.fields = {
    session_id: oneToOne({
        to: 'Sessions',
        as: 'session',
        relatedName: 'athlete_session',
    }),
    athlete_id: fk({
        to: 'Athletes',
        as: 'athlete',
        relatedName: 'athlete_sessions',
    }),
    microcycle_session_id: fk({
        to: 'MicrocycleSessions',
        as: 'microcycle_session',
        relatedName: 'athlete_sessions',
    }),
    program_schedule_id: fk({
        to: 'ProgramSchedules',
        as: 'program_schedule',
        relatedName: 'athlete_sessions',
    }),
    date: attr(),
    program_name: attr(),
    microcycle_number: attr(),
    session_number: attr(),
    program_id: fk({
        to: 'Programs',
        as: 'program',
        relatedName: 'athlete_sessions',
    }),
};

export default AthleteSessions;
