import React from 'react';
import { Router, Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'shared_features/privateRoute';
import history from 'shared_redux/history';
import Modal from 'react-modal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// css import hack here, as it's used in multiple places
import 'react-datepicker/dist/react-datepicker.css';

import Navigation from '../navigation';
import Athletes from '../athletes';
import Teams from '../teams';
import Live from '../live';
import Reports from '../reports';
// import History from '../history';
import Programs from '../programs';
import Login from '../login';
import ForgotPw from '../forgotpw';
import Settings from '../settings';
import Register from '../register';

Modal.setAppElement('body');

export default props => {
    if (props.loggedIn) {
        var navigation = <Navigation />;
    } else {
        var navigation = null;
    }

    var body = (<div>
        {navigation}
        <PrivateRoute exact path="/" loggedIn={props.loggedIn}>
            <Redirect to="/athletes" />
        </PrivateRoute>
        <PrivateRoute path='/athletes' loggedIn={props.loggedIn} component={Athletes} />
        <PrivateRoute path='/teams' loggedIn={props.loggedIn} component={Teams} />
        <PrivateRoute path='/live' loggedIn={props.loggedIn} component={Live} />
        <PrivateRoute path='/reports' loggedIn={props.loggedIn} component={Reports} />
        {/* <PrivateRoute path='/history' loggedIn={props.loggedIn} component={History}/> */}
        <PrivateRoute path='/programs' loggedIn={props.loggedIn} component={Programs} />
        <PrivateRoute path='/settings' loggedIn={props.loggedIn} component={Settings} />
        <Route path='/login' component={Login} />
        <Route path='/forgotpw' component={ForgotPw} />
        <Route path='/signup' component={Register} />
        <Route path='/beta' component={Register} />
    </div>);

    return (
        <Router history={history}>
            <DndProvider backend={HTML5Backend}>
                {body}
            </DndProvider>
        </Router>
    );
};
