import React, {Component} from 'react';
import Select from 'react-select';

import styles from './styles.module.scss';
import {
    ProgramBuilderComponent,
    ProgramBuilderHeader,
    ProgramBuilderMenu,
    ProgramBuilderMenuItem,
    ProgramBuilderFooter,
} from '../program_builder_component';
import PlanView from '../plan';
import BlockTypes from 'constants/blockTypes';
import DragItemTypes from 'constants/dragItemTypes';
import PlanCreator from '../plan_creator';
import PlanInserter from '../plan_inserter';

export const selectStyles = {
    control: () => ({
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        height: 44,
        alignItems: 'center',
        boxSizing: 'border-box',
    }),
    container: () => ({
        position: 'relative',
    }),
    singleValue: () => ({
        position: 'absolute',
        maxWidth: 217,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',

        color: 'white',
        fontSize: 12,
        fontWeight: 'medium',
    }),
    input: () => ({
        color: 'white',
        fontSize: 12,
        fontWeight: 'medium',
    }),
    placeholder: () => ({
        color: 'white',
        fontSize: 12,
        fontWeight: 'medium',
    }),
    indicatorSeparator: (styles) => ({display:'none'}),
};

export default class BlockComponent extends Component {

    insertPlanAfter() {
        this.props.insertPlan(this.props.id, 1);
    }
    
    render() {
        const props = this.props;

        // selector types
        // TODO: consider having these passed in instead of hardcoding them in the view
        const types = [
            { value: BlockTypes.STANDARD, label: 'Standard Block'},
            { value: BlockTypes.SUPERSET, label: 'Superset Block'},
            { value: BlockTypes.ALTERNATING, label: 'Alternating Block'},
        ];
        const value = types.find(type => type.value === props.type);

        // additional fields
        if (props.type !== BlockTypes.STANDARD) {
            var fields = (
                <React.Fragment>
                    <div className={styles.minSets}>
                        <input type="number"
                            value={props.min_sets || ''}
                            onChange={(event) => props.updateBlockMin(props.id, parseInt(event.target.value))}
                            onBlur={()=>props.saveBlock(props.id)}
                            onFocus={(event) => event.target.select()} />
                        <label>MIN SETS</label>
                    </div>
                    <div className={styles.maxSets}>
                        <input type="number"
                            value={props.max_sets || ''}
                            onChange={(event) => props.updateBlockMax(props.id, event.target.value ? parseInt(event.target.value) : null)}
                            onFocus={(event) => event.target.select()}
                            placeholder="None"
                            onBlur={()=>props.saveBlock(props.id)} />
                        <label>MAX SETS</label>
                    </div>
                    {/* <div className={styles.inputContainer}>
                        <label>REST</label>
                        <input type="number"
                            value={props.rest}
                            onChange={(event) => props.updateBlockRest(props.id, parseInt(event.target.value))}
                            onFocus={(event) => event.target.select()}
                            onBlur={()=>props.saveBlock(props.id)} />
                    </div> */}
                </React.Fragment>
            );
        } else {
            var fields = null;
        }

        // plans
        let plans = [];
        props.plan_ids.forEach((id, index) => {
            if (props.insertPlanPosition !== null && props.insertPlanPosition === index+1) {
                plans.push(<PlanInserter key="quickinsert" block_id={props.id} order={index+1} />);
            }
            plans.push(<PlanView
                key={`Plan${id}`}
                id={id}
                isReordering={props.isReordering}
                finishDrag={props.finishDrag}
                updateHoverIndicator={props.updateHoverIndicator} />);
        });
        if (props.insertPlanPosition > props.plan_ids.length) {
            plans.push(<PlanInserter key="quickinsert" block_id={props.id} order={props.plan_ids.length+1} />);
        }

        // style
        var style = styles.blockHeader;
        if (!props.isValid) {
            style = [style, styles.invalid].join(' ');
        }

        return (<div className={styles.blockContainer}>
            <ProgramBuilderComponent
                parentType={DragItemTypes.BLOCK}
                childType={DragItemTypes.PLAN}
                id={props.id}
                parentId={props.session_id}
                order={props.order}
                isReordering={props.isReordering}
                finishDrag={props.finishDrag}
                updateHoverIndicator={props.updateHoverIndicator}
                hoverIndicatorTopOffset={-2}
                hoverIndicatorBottomOffset={-2}>

                <ProgramBuilderHeader
                    hoverIndicatorOffset={0}
                    dropParentHandler={props.moveBlock}
                    insertAfterHandler={this.insertPlanAfter.bind(this)}>

                    <div className={style}>
                        <Select
                            name="block type"
                            value={value}
                            className={styles.select}
                            styles={selectStyles}
                            clearable={false}
                            deleteRemoves={false}
                            onChange={(selectedOption)=> {
                                if (selectedOption) {
                                    props.updateBlockType(props.id, selectedOption.value);
                                } else {
                                    props.updateBlockType(props.id, BlockTypes.STANDARD);
                                }
                            }}
                            options={types}
                        />
                        {fields}
                    </div>

                </ProgramBuilderHeader>

                <ProgramBuilderMenu>
                    <ProgramBuilderMenuItem onClick={()=>props.duplicateBlock(props.id)}>
                        DUPLICATE
                    </ProgramBuilderMenuItem>
                    <ProgramBuilderMenuItem onClick={this.insertPlanAfter.bind(this)} rightClickOnly={true}>
                        INSERT MOVEMENT AFTER
                    </ProgramBuilderMenuItem>
                    <ProgramBuilderMenuItem onClick={()=>window.confirm("Are you sure?") && props.deleteBlock(props.id)}>
                        DELETE
                    </ProgramBuilderMenuItem>
                </ProgramBuilderMenu>

                {plans}

                <ProgramBuilderFooter
                    hoverIndicatorOffset={-2}
                    childIds={props.plan_ids}>

                    <div className={styles.addMovement}>
                        <div>
                            <PlanCreator block_id={props.id} order={props.plan_ids.length+1} />
                        </div>
                        <div onClick={()=>props.createBlock(props.session_id, BlockTypes.STANDARD, props.order+1)}>
                            + BLOCK
                        </div>
                        {!props.isLast ? null :
                            <div onClick={()=>props.createMicrocycleSession(props.microcycle_id, props.session_order+1)}>
                                + SESSION
                            </div >
                        }
                    </div>

                </ProgramBuilderFooter>

            </ProgramBuilderComponent>
        </div>);
    }
}
