import { createSelector } from 'redux-orm';
import orm from 'shared_redux/reducers/models/orm';

export const getRosterSettings = createSelector(
    orm,
    state => state.database,
    session => {
        return session.RosterSettings.all().orderBy('order', 'asc').toRefArray();
    }
);
