import {
    FETCH_ACCOUNT_ROSTER_SETTINGS_ATTEMPT,
    ATTEMPT_FETCH_ATHLETES,

    FILTER_ATHLETES,
    CREATE_ATHLETE,
    SHOW_ROSTER_COLUMNS_FILTER,
    SHOW_IMPORT_ATHLETES,
} from 'shared_redux/actions';

// fetch

export const fetchRosterSettings = () => ({
    type: FETCH_ACCOUNT_ROSTER_SETTINGS_ATTEMPT,
});

export const fetchAthletes = (groupId, teamId, search) => ({
    type: ATTEMPT_FETCH_ATHLETES,
    groupId,
    teamId,
    search,
});

// header options

export const filterAthletes = (name) => ({
    type: FILTER_ATHLETES,
    name,
});

export const createAthlete = (organizationId, teamId, groupId) => ({
    type: CREATE_ATHLETE,
    organizationId,
    teamId,
    groupId,
});

export const filterColumns = () => ({ type: SHOW_ROSTER_COLUMNS_FILTER });

export const importAthletes = () => ({ type: SHOW_IMPORT_ATHLETES });
