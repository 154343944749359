import { createSelector } from 'redux-orm';
import orm from 'shared_redux/reducers/models/orm';

export const getIsLoggedIn = createSelector(
    orm,
    state => state.database,
    session => {
        return session.Organizations.all().exists();
    }
);

export const getOrganization = createSelector(
    orm,
    state => state.database,
    session => {
        return session.Organizations.all().first();
    }
);

export const getOrganizationSettings = createSelector(
    orm,
    state => state.database,
    session => {
        const org = session.Organizations.all().first();
        if (!org) {
            return null;
        }
        return org.organization_settings;
    }
);
