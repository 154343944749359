import { createSelector } from 'redux-orm';
import moment from 'moment';
import orm from 'shared_redux/reducers/models/orm';

const stateRoot = (state) => state.reports_manager;

export const getStartDateFilter = (state) => stateRoot(state).startDateFilter;

export const getEndDateFilter = (state) => stateRoot(state).endDateFilter;

export const getTimeRangeFilter = (state) => stateRoot(state).timeRangeFilter;

export const getIntervalFilter = (state) => stateRoot(state).intervalFilter;

export const getSelectedGroupFilter = (state) => stateRoot(state).selectedGroupFilter;

export const getSelectedTeamFilter = (state) => stateRoot(state).selectedTeamFilter;

export const getTonnageExerciseFilter = (state) => stateRoot(state).tonnageExerciseFilter;

export const getIsFetchingDataExports = state => stateRoot(state).isFetching;

export const getDataExports = createSelector(
    orm,
    state => state.database,
    (session) => {
        const models = session.DataExports.all().orderBy("created_on", "desc").toModelArray();
        return models.map(de => {
            // get status
            let status = '';
            if (de.status === 'FAILED') {
                status = '[FAILED] ';
            } else if (de.status === 'WAITING') {
                status = '[EXPORTING] ';
            }

            // get name filter
            let groupFilter = 'Error';
            if (!de.group && !de.team) {
                groupFilter = 'Everyone';
            } else if (de.group && de.team) {
                groupFilter = `${de.team.name} - ${de.group.name}`;
            } else if (de.team) {
                groupFilter = de.team.name;
            }

            return {
                id: de.id,
                name: `${status}${groupFilter} - ${moment(de.start_date, 'YYYY-MM-DD').format('MMM Do')} thru ${moment(de.end_date, 'YYYY-MM-DD').format('MMM Do')}`,
                status: de.status,
            };
        });
    }
);
