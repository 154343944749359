import { all, takeEvery, takeLatest, call, apply, put } from 'redux-saga/effects';

import {
    ATTEMPT_FETCH_ATHLETE_SESSIONS,
    FETCH_ATHLETE_SESSIONS_SUCCEEDED,
} from 'shared_redux/actions';
import API from 'services/athlete_sessions';
import { handleError } from 'services/alerts';

export default function* AthleteSessionsSaga() {
    yield all([
        takeLatest(ATTEMPT_FETCH_ATHLETE_SESSIONS, fetchAthleteSessions),
    ]);
};

function *fetchAthleteSessions(action) {
    try {
        const sessions = yield apply(API, API.getAthleteSessions, [action.athleteId]);
        yield put({
            type: FETCH_ATHLETE_SESSIONS_SUCCEEDED,
            athleteId: action.athleteId,
            payload: sessions,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch athlete sessions: ' + error);
    }
}
