import API from './api';

export default {

    // for csv, currently not ready, need to update server
    getPastSessions: (organization_id) => API({
        url: `sessions?past=true&organization_id=${organization_id}`,
        method: 'GET'
    }),

    // for athlete history
    getAthleteSessions: (athlete_id) => API({
        url: `athletes/${athlete_id}/sessions`,
        method: 'GET'
    }),

};
