import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as ManageMaxesSelectors from 'shared_redux/selectors/view/manage_maxes';

const mapStateToProps = (state) => {
    return {
        exerciseId: ManageMaxesSelectors.getRemoveId(state),
        isShowing: ManageMaxesSelectors.getRemoveId(state) != null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        removeMaxableExercise: Actions.removeMaxableExercise,
        closeModal: Actions.closeModal,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
