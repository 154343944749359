import {
    CLOSE_CREATE_TEAM,
    UPDATE_TEAM_NAME,
    ATTEMPT_CREATE_TEAM,
} from 'shared_redux/actions';

export const closeModal = () => ({ type: CLOSE_CREATE_TEAM });

export const updateName = (name) => ({ type: UPDATE_TEAM_NAME, name });

export const createTeam = () => ({ type: ATTEMPT_CREATE_TEAM });
