import {
    ATTEMPT_FETCH_PROGRAMS,
    ATTEMPT_DUPLICATE_PROGRAM,
    ATTEMPT_DELETE_PROGRAM,
} from 'shared_redux/actions';

export const fetchPrograms = () => ({ type: ATTEMPT_FETCH_PROGRAMS });

export const duplicateProgram = (id) => ({ type: ATTEMPT_DUPLICATE_PROGRAM, id });

export const deleteProgram = (id) => ({ type: ATTEMPT_DELETE_PROGRAM, id });
