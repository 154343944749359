import API from './api';

export default {
    
    getBlock: (blockId) => API({
        url: `blocks/${blockId}`,
        method: 'GET',
    }),

    createBlock: (json) => API({
        url: 'blocks',
        method: 'POST',
        json,
    }),

    updateBlock: (json) => API({
        url: `blocks/${json.id}`,
        method: 'PATCH',
        json,
    }),

    deleteBlock: (blockId) => API({
        url: `blocks/${blockId}`,
        method: 'DELETE',
    }),

};
