import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';

import * as AthleteHistorySelectors from 'shared_redux/selectors/view/athlete_history';
import * as HistorySessionsSelectors from 'shared_redux/selectors/view/history_sessions';
import * as HistoryPlansSelectors from 'shared_redux/selectors/view/history_plans';

// expects id and set
const makeMapStateToProps = () => {
    const getAthleteHistoryEntry = AthleteHistorySelectors.makeGetAthleteHistoryEntry();
    return (state, props) => {
        const model = getAthleteHistoryEntry(state, props);
        return {
            ...model,
            visible: HistorySessionsSelectors.getIsSessionExpanded(state, model.session_id) && HistoryPlansSelectors.getIsPlanExpanded(state, model.plan_id),
        };
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateEntryLoad: Actions.updateEntryLoad,
        updateEntryWeightUnit: Actions.updateEntryWeightUnit,
        updateEntryRPE: Actions.updateEntryRPE,
        updateEntryReps: Actions.updateEntryReps,
    }, dispatch);
};

const Container = connect(
    makeMapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
