import {
    ATTEMPT_FETCH_RACKS,
    FETCH_ORGANIZATION_SETTINGS_ATTEMPT,
    UPDATE_AUTO_PROGRESS_ATTEMPT,
} from 'shared_redux/actions';

export const fetchRacks = () => ({ type: ATTEMPT_FETCH_RACKS });

export const fetchSettings = () => ({ type: FETCH_ORGANIZATION_SETTINGS_ATTEMPT });

export const updateStationAutoProgress = (station_auto_progress) => ({ type: UPDATE_AUTO_PROGRESS_ATTEMPT, payload:{ station_auto_progress}});
