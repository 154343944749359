import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import View from './view';
import * as Actions from './actions';

import * as RosterSelectors from 'shared_redux/selectors/view/roster';

const mapStateToProps = (state, props) => {
    const athleteIds = RosterSelectors.getRosterVisibleAthleteIds(state, props);
    return {
        labels: RosterSelectors.getRosterLabels(state, props),
        athleteIds,
        allAthletesSelected: RosterSelectors.getRosterAllAthletesSelected(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        selectVisibleAthletes: Actions.selectVisibleAthletes,
        deselectAthletes: Actions.deselectAthletes,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default withRouter(Container);
