import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createSelector } from 'redux-orm';

import View from 'shared_features/tab_header';
import orm from 'shared_redux/reducers/models/orm';

const getViewModel = createSelector(
    orm,
    state => state.database,
    (state, props) => props.match.params.athleteid,
    (session, athleteId) => {
        const Athletes = session.Athletes;
        const athlete = Athletes.withId(athleteId);
        const athleteURLName = athlete.urlName();
    
        if (athleteURLName) {
            return {
                tabs: [{
                    text: "History",
                    url: `/athletes/${athleteId}/${athleteURLName}`,
                }]
            };
        }
        
        return {
            tabs: [],
        };
    }
);

const mapStateToProps = (state, props) => getViewModel(state, props);

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default withRouter(Container);
