import {
    SET_FEED_ITEMS,
    DELETE_FEED_ITEM,
    UPDATE_FEED_COMPLETE_ITEM,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, oneToOne, many, attr } from 'redux-orm';

class FeedCompleteItems extends Model {

    static reducer(action, FeedCompleteItems, session) {
        switch (action.type) {
            case SET_FEED_ITEMS:
                action.feed_items.forEach(i => {
                    if (i.feed_complete_item) {
                        FeedCompleteItems.upsert(i.feed_complete_item);
                    }
                });
                break;
            case UPDATE_FEED_COMPLETE_ITEM:
                FeedCompleteItems.upsert(action.feed_item.feed_complete_item);
                break;
            case DELETE_FEED_ITEM: {
                const item = FeedCompleteItems.withId(action.feed_item_id);
                if (item) {
                    item.delete();
                }
                break;
            }
            case SIGN_OUT_SUCCEEDED:
                FeedCompleteItems.all().delete();
                break;
        }
    }
    
}

FeedCompleteItems.modelName = 'FeedCompleteItems';

FeedCompleteItems.options = {
    idAttribute: 'feed_item_id',
};

FeedCompleteItems.fields = {
    feed_item_id: oneToOne({
        to: 'FeedItems',
        as: 'feed_item',
        relatedName: 'feed_complete_item',
    }),
    percentage: attr(),
};

export default FeedCompleteItems;
