import React from 'react';

import styles from './styles.module.scss';
import BlockComponent from '../block';

export default props => {
    const blocks = props.blockIds.map(id => <BlockComponent key={`athlete-history-block-${id}`} id={id} />);

    if (props.expanded) {
        var carrot = (<div><img src="https://assets.reponestrength.com/carrot_down.png" /></div>);
        var onClick = () => props.collapse(props.id);
    } else {
        var carrot = (<div><img src="https://assets.reponestrength.com/carrot_right.png" /></div>);
        var onClick = () => props.expand(props.id);
    }

    return (
        <div>
            <div className={styles.container} onClick={onClick}>
                {carrot}
                <div>Session {props.displayNumber}</div>
                <div>{props.summary}</div>
                <div>{props.date}</div>
            </div>
            {blocks}
        </div>
    );
};
