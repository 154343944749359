import API from './api';

export default {

    createExercise: (json) => API({
        url: 'exercises',
        method: 'POST',
        json,
    }),

    getExercises: (organization_id, includeParentExerciseLinks=false) => API({
        url: !includeParentExerciseLinks ? `exercises?organization_id=${organization_id}` : `exercises?organization_id=${organization_id}&include=parent_exercise_links`,
        method: 'GET',
    }),

    patchExerciseMaxable: (exercise_id) => API({
        url: `exercises/${exercise_id}`,
        method: 'PATCH',
        json: { maxable: true }
    }),

    patchExerciseNotMaxable: (exercise_id) => API({
        url: `exercises/${exercise_id}`,
        method: 'PATCH',
        json: { maxable: false }
    }),
    
};
