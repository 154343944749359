import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

import NewTab from 'shared_features/svgs/new_tab';

class View extends Component {

    componentDidMount() {
        this.props.fetchAccount();
    }

    render() {
        return (
            <div className={styles.navBackground}>
                <div className={styles.nav}>
                    <img className={styles.logo} src={this.props.logoURL} alt="logo" />
                    <NavLink
                        to="/athletes"
                        className={styles.inactive}
                        activeClassName={styles.active}
                        isActive={(match, location)=> {
                            if (match) { return true; }
                            if (location && location.pathname && location.pathname.includes('teams')) { return true; }
                            return false;
                        }}>
                        
                        <div>ROSTER</div>
                    </NavLink>
                    <NavLink to="/programs" className={styles.inactive} activeClassName={styles.active}><div>PROGRAMS</div></NavLink>
                    <NavLink to="/reports" className={styles.inactive} activeClassName={styles.active}><div>REPORTS</div></NavLink>
                    {/* <NavLink to="/history" className={styles.inactive} activeClassName={styles.active}><div>HISTORY</div></NavLink> */}
                    <NavLink to="/live" target="_blank" className={styles.inactive} activeClassName={styles.active}><div>LIVE <NewTab /></div></NavLink>
                    <div className={styles.account}>
                        <div className={styles.email}>
                            {this.props.email}
                            <div>▼</div>
                        </div>
                        <div className={styles.menu}>
                            <NavLink to="/settings" className={styles.inactive} activeClassName={styles.active}>Settings</NavLink>
                            <div onClick={()=>this.props.signOut()}>Logout</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default View;
