import uuidv4 from 'uuid/v4';

import {
    EXPAND_MICROCYCLE_SESSION,
    COLLAPSE_MICROCYCLE_SESSION,

    DRAFT_CREATE_MICROCYCLE_SESSION,
    ATTEMPT_CREATE_MICROCYCLE_SESSION,

    DRAFT_MOVE_MICROCYCLE_SESSION,
    ATTEMPT_MOVE_MICROCYCLE_SESSION,

    DUPLICATE_MICROCYCLE_SESSION,

    DRAFT_DELETE_MICROCYCLE_SESSION,
    ATTEMPT_DELETE_MICROCYCLE_SESSION,
} from 'shared_redux/actions';

export const expandMicrocycleSession = (session_id) => ({
    type: EXPAND_MICROCYCLE_SESSION,
    session_id,
});

export const collapseMicrocycleSession = (session_id) => ({
    type: COLLAPSE_MICROCYCLE_SESSION,
    session_id,
});

export const createMicrocycleSession = (microcycle_id, order) => {
    const session_id = uuidv4();
    return {
        type: DRAFT_CREATE_MICROCYCLE_SESSION,
        pushType: ATTEMPT_CREATE_MICROCYCLE_SESSION,
        payload: {
            session_id,
            microcycle_id,
            order,
        }
    };
};


export const duplicateMicrocycleSession = (session_id) => ({
    type: DUPLICATE_MICROCYCLE_SESSION,
    session_id,
});

export const moveMicrocycleSession = (session_id, microcycle_id, order) => ({
    type: DRAFT_MOVE_MICROCYCLE_SESSION,
    pushType: ATTEMPT_MOVE_MICROCYCLE_SESSION,
    payload: {
        session_id,
        microcycle_id,
        order,
    },
});

export const deleteMicrocycleSession = (session_id) => ({
    type: DRAFT_DELETE_MICROCYCLE_SESSION,
    pushType: ATTEMPT_DELETE_MICROCYCLE_SESSION,
    session_id,
});
