import React from 'react';
import styles from './styles.module.scss';

export default props => {
    const models = props.models.map(model => {
        switch (model.type) {
            case 'year':
                return <div key={`filteryear${model.text}`} className={styles.year}>{model.text}</div>;
            case 'month':
                if (model.selected) {
                    var style = styles.selectedMonth;
                } else {
                    var style = styles.month;
                }
                return <div key={`filtermonth${model.date}`} className={style} onClick={()=>props.selectedDate(model.date)}>{model.text}</div>;
            default:
                return null;
        }
    });

    return (
        <div className={styles.container}>
            {models}
        </div>
    );
};
