import { all, takeEvery, takeLatest, select, apply, put } from 'redux-saga/effects';

import {
    ATTEMPT_UPDATE_MAX,
    CREATE_MAX_SUCCEEDED,
    UPDATE_MAX_SUCCEEDED,
    REMOVE_MAX_SUCCEEDED,
} from 'shared_redux/actions';
import API from 'services/maxes';
import { handleError } from 'services/alerts';

export default function* MaxesSaga() {
    yield all([
        takeEvery(ATTEMPT_UPDATE_MAX, updateMax),
    ]);
};

function *updateMax(action) {
    try {
        let max = action.max;

        if (!max.id && max.weight !== '' && max.weight !== null) {
            // create
            delete max['id'];
            var result = yield apply(API, API.createMax, [max]);
            yield put({
                type: CREATE_MAX_SUCCEEDED,
                payload: result,
            });
        } else if (max.id && (max.weight === '' || max.weight === null)) {
            // delete
            yield apply(API, API.removeMax, [max]);
            yield put({
                type: REMOVE_MAX_SUCCEEDED,
                id: max.id,
            });
        } else if (max.id && max.weight !== '' && max.weight !== null) {
            // update
            const result = yield apply(API, API.patchMax, [max]);
            yield put({
                type: UPDATE_MAX_SUCCEEDED,
                payload: result,
            });
        }
    } catch(error) {
        console.log(error);
        yield handleError(error, 'Error: Unable to modify max');
    }
}
