import React, {Component} from 'react';
import CreatableSelect from 'react-select/creatable';
import Validator from 'validator';

import styles from './styles.module.scss';
import { selectStyles } from '../plan/view';

export default class View extends Component {

    constructor(props) {
        super(props);
        this.selector = React.createRef();
        this.cover = React.createRef();
        this.state = {
            open: false,
        };
    }

    _open() {
        this.cover.current.style.opacity=0;
        this.selector.current.focus();
        this.setState({open: true});
    }

    _close() {
        this.cover.current.style.opacity=1;
        try {
            this.selector.current.blur();
        } catch(err) {
            // TODO: have a better way to prevent this from crashing
            // checking current === null does not work
            // what I don't get is why block_creator does not have this problem as they should follow the same paradigm
        }
        this.setState({open: false});
    }

    // TODO: styling on each dropdown option, may need to iterate through types to do so unfortunately
    render() {
        let exercises = [];
        for (var exercise of this.props.exercises) {
            const option = { value: exercise.id, label: exercise.name };
            exercises.push(option);
        }

        const style = this.state.open ? styles.containerBig : styles.containerSmall;
        return (
            <div className={style}>
                <CreatableSelect
                    ref={this.selector}
                    className={styles.select}
                    styles={selectStyles}
                    isClearable
                    name="exercise"
                    value={null}
                    menuIsOpen={this.state.open}
                    clearable={false}
                    deleteRemoves={false}
                    tabSelectsValue={false}
                    onChange={(selectedOption)=> {
                        if (selectedOption) {
                            if (!Validator.isUUID(selectedOption.value)) {
                                // hack, sending org_id will create the exercise, this is pretty shitty
                                this.props.createPlan(this.props.block_id, this.props.order, selectedOption.value, this.props.organizationId);
                            } else {
                                this.props.createPlan(this.props.block_id, this.props.order, selectedOption.value);
                            }
                            this._close();
                        }
                    }}
                    options={exercises}
                    onFocus={()=>this._open()}
                    onBlur={()=>this._close()}
                />
                <label>MOVEMENT</label>
                <div ref={this.cover} className={styles.cover} onClick={()=>this._open()}>
                    <span>+</span> MOVEMENT
                </div>
            </div>
        );
    }

};
