import uuidv4 from 'uuid/v4';

import {
    ATTEMPT_FETCH_PROGRAM,
    ATTEMPT_FETCH_EXERCISES,

    DRAFT_CREATE_PROGRAM,
    ATTEMPT_CREATE_PROGRAM,

    DRAFT_CREATE_MICROCYCLE,
    ATTEMPT_CREATE_MICROCYCLE,

    FETCH_ORGANIZATION_SETTINGS_ATTEMPT,
} from 'shared_redux/actions';

export const fetchProgram = (program_id) => ({
    type: ATTEMPT_FETCH_PROGRAM,
    program_id,
});

export const fetchExercises = () => ({ type: ATTEMPT_FETCH_EXERCISES });

export const fetchSettings = () => ({ type: FETCH_ORGANIZATION_SETTINGS_ATTEMPT });

export const createProgram = (id) => {
    return {
        type: DRAFT_CREATE_PROGRAM,
        pushType: ATTEMPT_CREATE_PROGRAM,
        payload: {
            id,
            name: 'New Program',
            microcycle_name: 'Week',
        },
    };
};

export const createMicrocycle = (program_id, order) => {
    const id = uuidv4();
    return {
        type: DRAFT_CREATE_MICROCYCLE,
        pushType: ATTEMPT_CREATE_MICROCYCLE,
        payload: {
            id,
            program_id,
            order,
        },
    };
};
