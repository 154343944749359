import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    call,
} from 'redux-saga/effects';

import {
    FETCH_ACCOUNT_SUCCEEDED,

    FILTER_ATHLETES,

    ATTEMPT_FETCH_ATHLETES,
    FETCH_ATHLETES_SUCCEEDED,

    ATTEMPT_FETCH_ATHLETE,
    FETCH_ATHLETE_SUCCEEDED,

    EDIT_ATHLETE,
    LOADED_CACHED_ATHLETE,

    ATTEMPT_CREATE_ATHLETE,
    CREATE_ATHLETE_SUCCEEDED,
    CREATE_ATHLETE_FAILED,

    ATTEMPT_SAVE_ATHLETE_METADATA,
    SAVE_ATHLETE_METADATA_SUCCEEDED,

    UPDATE_ATHLETE_CATEGORY_ATTEMPT,
    UPDATE_ATHLETE_CATEGORY_SUCCEEDED,

    ATTEMPT_UPLOAD_AVATAR,
    UPLOAD_AVATAR_SUCCEEDED,
    UPLOAD_AVATAR_FAILED,

    ATTEMPT_IMPORT_ATHLETES,
    IMPORT_ATHLETES_SUCCEEDED,
    IMPORT_ATHLETES_MISSING_COLUMNS,
    IMPORT_ATHLETES_FAILED,

    ATTEMPT_REMOVE_ATHLETE,
    REMOVE_ATHLETE_SUCCEEDED,

    SAVE_SELECTED_ATHLETES_CATEGORIES_ATTEMPT,
    SAVE_SELECTED_ATHLETES_CATEGORIES_SUCCEEDED,
    SAVE_SELECTED_ATHLETES_CATEGORIES_FAILED,

    DELETE_ROSTER_SELECTED_ATHLETES_ATTEMPT,
    DELETE_ROSTER_SELECTED_ATHLETES_SUCCEEDED,
    DELETE_ROSTER_SELECTED_ATHLETES_FAILED,
} from 'shared_redux/actions';

import orm from 'shared_redux/reducers/models/orm';
import API from 'services/athletes';
import { handleError } from 'services/alerts';
import * as AccountsSelectors from 'shared_redux/selectors/models/accounts';
import * as ProfileSelectors from 'shared_redux/selectors/view/profile';
import * as RosterSelectors from 'shared_redux/selectors/view/roster';
import history from 'shared_redux/history';

export default function* AthletesSaga() {
    yield all([
        takeLatest(FILTER_ATHLETES, filterAthletes),
        takeLatest(ATTEMPT_FETCH_ATHLETES, fetchAthletes),
        takeLatest(ATTEMPT_FETCH_ATHLETE, fetchAthlete),
        takeLatest(EDIT_ATHLETE, fetchCachedAthlete),
        takeEvery(ATTEMPT_CREATE_ATHLETE, createAthlete),
        takeEvery(ATTEMPT_SAVE_ATHLETE_METADATA, patchAthlete),
        takeEvery(UPDATE_ATHLETE_CATEGORY_ATTEMPT, patchAthleteCategory),
        takeEvery(ATTEMPT_UPLOAD_AVATAR, uploadAvatar),
        takeEvery(ATTEMPT_IMPORT_ATHLETES, importAthletes),
        takeEvery(ATTEMPT_REMOVE_ATHLETE, removeAthlete),
        takeLatest(IMPORT_ATHLETES_SUCCEEDED, refetchAthletes),

        // bulk
        takeEvery(SAVE_SELECTED_ATHLETES_CATEGORIES_ATTEMPT, patchMultipleAthletes),
        takeEvery(DELETE_ROSTER_SELECTED_ATHLETES_ATTEMPT, removeMultipleAthletes),
    ]);
};

function *filterAthletes(action) {
    var params = new URLSearchParams(history.location.search);
    if (!action.name) {
        params.delete('name');
    } else {
        params.set('name', action.name);
    }
    history.replace(`${history.location.pathname}?${params}`);
}

function *fetchAthletes(action) {
    try {
        if (action.groupId) {
            var athletes = yield apply(API, API.getGroupAthletes, [action.groupId]);
        } else if (action.teamId) {
            var athletes = yield apply(API, API.getTeamAthletes, [action.teamId]);
        } else {
            var account = yield select(AccountsSelectors.getAccount);
            if (!account) {
                yield take(FETCH_ACCOUNT_SUCCEEDED);
                account = yield select(AccountsSelectors.getAccount);
            }
            var athletes = yield apply(API, API.getAllAthletes, [account.organization_id]);
        }
        yield put({
            type: FETCH_ATHLETES_SUCCEEDED,
            payload: athletes,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch athletes');
    }
}

function *fetchAthlete(action) {
    try {
        const athlete = yield apply(API, API.getAthlete, [action.id]);
        yield put({
            type: FETCH_ATHLETE_SUCCEEDED,
            payload: athlete,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch athlete');
    }
}

function *fetchCachedAthlete(action) {
    const database = yield select(state => state.database);
    const { Athletes } = orm.mutableSession(database);
    const cachedAthlete = Athletes.withId(action.athleteId);

    if (cachedAthlete) {
        yield put({ type: LOADED_CACHED_ATHLETE, athlete: cachedAthlete });
    }
}

function *createAthlete(action) {
    try {
        const json = yield select(ProfileSelectors.getAthleteJSON);
        let athlete = yield apply(API, API.createAthlete, [json]);
        if (action.file) {
            const payload = yield apply(API, API.uploadAvatar, [athlete.id]);

            // upload to s3
            yield fetch(payload.signed_url, {
                method: 'PUT',
                body: action.file,
            });

            // set athlete
            athlete = payload.athlete;
        }
        yield put({
            type: CREATE_ATHLETE_SUCCEEDED,
            payload: athlete,
        });
    } catch(error) {
        yield put({ type: CREATE_ATHLETE_FAILED, error });
        yield handleError(error, 'Error: Unable to create athlete');
    }
}

function *patchAthlete(action) {
    try {
        const json = yield select(ProfileSelectors.getAthleteJSON);
        const athlete = yield apply(API, API.patchAthlete, [json]);
        yield put({
            type: SAVE_ATHLETE_METADATA_SUCCEEDED,
            payload: athlete,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to patch athlete metadata');
    }
}

function *patchAthleteCategory(action) {
    try {
        const json = { id: action.athleteId, team_id: action.teamId, group_id: action.groupId };
        const athlete = yield apply(API, API.patchAthlete, [json]);
        yield put({
            type: UPDATE_ATHLETE_CATEGORY_SUCCEEDED,
            payload: athlete,
        });
    } catch(error) {
        yield handleError(error, 'Error: Failed to change athlete team and group');
    }
}

function *uploadAvatar(action) {
    try {
        // get payload, which contains signed_url, and athlete
        const payload = yield apply(API, API.uploadAvatar, [action.athleteId]);

        // upload to s3
        yield fetch(payload.signed_url, {
            method: 'PUT',
            body: action.file,
        });

        // force refresh of image
        payload.athlete.avatar_url = `${payload.athlete.avatar_url}?${new Date().getTime()}`;

        // success
        yield put({
            type: UPLOAD_AVATAR_SUCCEEDED,
            payload: payload.athlete,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to upload avatar');
        yield put({ type: UPLOAD_AVATAR_FAILED, error });
    }
}

function *importAthletes(action) {
    try {
        const payload = yield apply(API, API.importAthletes, [action.csv]);
        yield put({
            type: IMPORT_ATHLETES_SUCCEEDED,
            payload,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to import athletes');
        if (error && error.statusCode && error.statusCode === 400) {
            if (error.json) {
                console.log(`ERROR JSON IS ${JSON.stringify(error.json)}`);
                if (error.json.row) {
                    yield put({ type: IMPORT_ATHLETES_FAILED, row: error.json.row, reason: error.json.reason });
                } else if (error.json.length > 0) {
                    yield put({ type: IMPORT_ATHLETES_MISSING_COLUMNS, columns: error.json });
                }
            }
        } else {
            yield put({ type: IMPORT_ATHLETES_FAILED });
        }
    }
}

function *refetchAthletes() {
    // HACK: refetch athletes
    // hack as the import command only returns the aggregate success data, not the actual individual pieces of data
    // can technically make it do so
    var params = new URLSearchParams(history.location.search);
    const groupId = params.get('group_id');
    const teamId = params.get('team_id');
    yield put({
        type: ATTEMPT_FETCH_ATHLETES,
        groupId,
        teamId,
    });
}

function *removeAthlete(action) {
    try {
        const id = yield select(ProfileSelectors.getAthleteId);
        yield apply(API, API.removeAthlete, [id]);
        yield put({
            type: REMOVE_ATHLETE_SUCCEEDED,
            id,
        });
        yield call(history.push, `/athletes`);
    } catch(error) {
        yield handleError(error, 'Error: Unable to remove athlete');
    }
}

function *patchMultipleAthletes(action) {
    try {
        const ids = yield select(RosterSelectors.getSelectedAthleteIds);
        const team_id = yield select(RosterSelectors.getSelectedTeamId);
        const group_id = yield select(RosterSelectors.getSelectedGroupId);
        const payload = yield apply(API, API.patchMultipleAthleteCategories, [ids, team_id, group_id]);
        yield put({
            type: SAVE_SELECTED_ATHLETES_CATEGORIES_SUCCEEDED,
            payload,
        });
    } catch (err) {
        yield handleError(err, 'Error: Unable to move multiple athletes');
        yield put({ type: SAVE_SELECTED_ATHLETES_CATEGORIES_FAILED });
    }
}

function *removeMultipleAthletes(action) {
    try {
        const ids = yield select(RosterSelectors.getSelectedAthleteIds);
        const payload = yield apply(API, API.removeMultipleAthletes, [ids]);
        yield put({
            type: DELETE_ROSTER_SELECTED_ATHLETES_SUCCEEDED,
            athlete_ids: ids,
            payload,
        });
    } catch (err) {
        yield handleError(err, 'Error: Unable to delete multiple athletes');
        yield put({ type: DELETE_ROSTER_SELECTED_ATHLETES_FAILED });
    }
}
