import React, {Component} from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';

import BlackLogo from 'shared_features/svgs/black_logo';
import RedX from 'shared_features/svgs/red_x';
import YellowUp from 'shared_features/svgs/yellow_up';
import YellowDown from 'shared_features/svgs/yellow_down';
import GreenCheck from 'shared_features/svgs/green_check';

import FeedbackTypes from 'constants/feedbackTypes';

class View extends Component {

    componentDidMount() {
        this.props.connectFeed();
        this.props.fetchCategories();

        var that = this;
        this.timer = setInterval(() => {
            that.props.updateFeedTimer();
        }, 1000);
    }

    componentWillUnmount() {
        this.props.disconnectFeed();
        clearInterval(this.timer);
    }

    // render

    _renderFilter() {
        if (!this.props.groups) {
            return null;
        }

        var groups = this.props.groups.map(model => ({
            type: model.type,
            value: model.id,
            label: model.description,
        }));
        groups.unshift({
            type: 'all',
            value: 'all',
            label: 'Everyone',
        })

        if (this.props.selectedGroup) {
            var value = groups.find(g => g.type === 'group' && g.value === this.props.selectedGroup);
        } else if (this.props.selectedTeam) {
            var value = groups.find(g => g.type === 'team' && g.value === this.props.selectedTeam);
        } else {
            var value = groups[0];
        }

        return (
            <Select
                name="category"
                className={styles.select}
                clearable={false}
                deleteRemoves={false}
                value={value}
                onChange={(selectedOption)=> {
                    if (selectedOption) {
                        switch (selectedOption.type) {
                        case 'group':
                            this.props.updateGroupFilter(null, selectedOption.value);
                            break;
                        case 'team':
                            this.props.updateGroupFilter(selectedOption.value, null);
                            break;
                        default:
                            this.props.updateGroupFilter(null, null);
                            break;
                        }
                    }
                }}
                options={groups}
                components={{
                    IndicatorSeparator: () => null
                }}
            />
        );
    }

    _renderNav() {
        return (
            <div className={styles.liveNav}>
                <BlackLogo />
                <h1>RepOne Live</h1>
                {this._renderFilter()}
           </div>
        );
    }
    
    _renderConnecting() {
        if (this.props.isConnected) {
            return;
        }

        return (
            <div>Reconnecting...</div>
        );
    }

    _renderFeedModels() {
        return this.props.feedModels.map((model, index) => {
            if (model.type === "complete") {
                // feed complete item
                return (
                    <div className={styles.completed} key={`feed${model.id}`}>
                        <img src={model.avatar_url} />
                        <div>
                            <div>{model.name} completed {model.programName}</div>
                            <div>{model.sessionDescription}</div>
                        </div>
                        <div>{model.percentage}% of sets completed</div>
                        <div className={styles.time}>{model.time}</div>
                    </div>
                );
            } else if (model.type === "autoregulated") {
                // feed entry item autoregulated
                let icon = null;
                switch (model.feedback) {
                    case FeedbackTypes.FAILED:
                        icon = <RedX />;
                        break;
                    case FeedbackTypes.KEEP:
                        icon = <GreenCheck />;
                        break;
                    case FeedbackTypes.RAISE:
                        icon = <YellowUp />;
                        break;
                    case FeedbackTypes.LOWER:
                        icon = <YellowDown />;
                        break;
                    default:
                        break;
                }
                return (
                    <div className={styles.autoregulated} key={`feed${model.id}`}>
                        <img src={model.avatar_url} />
                        <div>{model.name}</div>
                        <div>
                            <div>{model.exercise}</div>
                        </div>
                        <div>
                            <div>{model.data}</div>
                            <div>{model.target}</div>
                        </div>
                        <div className={styles.feedback}>
                            <div>{icon} {model.feedbackDescription}</div>
                            <div>{model.autoregulationMetric}</div>
                        </div>
                        <div className={styles.time}>{model.time}</div>
                    </div>
                );
            } else {
                // feed entry item for manual
                return (
                    <div className={styles.manual} key={`feed${model.id}`}>
                        <img src={model.avatar_url} />
                        <div>{model.name}</div>
                        <div>
                            <div>{model.exercise}</div>
                        </div>
                        <div>
                            <div>{model.data}</div>
                            <div>{model.target}</div>
                        </div>
                        <div className={styles.time}>{model.time}</div>
                    </div>
                );
            }
        });
    }

    render() {
        return (
            <div className={styles.liveContainer}>
                {this._renderNav()}
                <div className={styles.feedContainer}>
                    {this._renderConnecting()}
                    {this._renderFeedModels()}
                </div>
            </div>
        );
    }
}

export default View;
