import { all, takeLatest, takeEvery, apply, put } from 'redux-saga/effects';

import {
    UPDATE_ENTRY_ATTEMPT,
    UPDATE_ENTRY_SUCCEEDED,
} from 'shared_redux/actions';
import API from 'services/entries';
import { handleError } from 'services/alerts';

export default function* EntriesSaga() {
    yield all([
        takeEvery(UPDATE_ENTRY_ATTEMPT, updateEntry),
    ]);
};

function *updateEntry(action) {
    try {
        const payload = yield apply(API, API.updateEntry, [action.payload]);
        yield put({
            type: UPDATE_ENTRY_SUCCEEDED,
            payload,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to update entry: ' + error.message);
    }
}
