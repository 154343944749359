import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DropTarget } from 'react-dnd';
import { showMenu, hideMenu } from 'react-contextmenu/modules/actions';

import styles from './styles.module.scss';
import ProgramBuilderContext from './programBuilderContext';

 /**
 * PROPERTIES:
 * id - for context purposes of the menu and such
 * order - the current order of this thing
 * parentId - the current parent of this thing
 * parentType - for the source and target callback for dragging / dropping
 * childType - for the target callback on dropping headers / footers
 * isReordering - is anything in program builder isReordering, separate from this.state.isDragging which is if the current component is being dragged
 * finishDrag - callback on finishing drag
 * updateHoverIndicator - callback to update the hover indicator position
 * hoverIndicatorTopOffset
 * hoverIndicatorBottomOffsetn
 **/
class ProgramBuilderComponent extends Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();

        this.state = {
            dragReady: false, // mousedown state it so the drag preview wont show the hamburger
            hamburgerActive: false, // indicates if it's bolded and menu is showing
            hamburgerReady: false, // to allow for toggling of the hamburger active dismiss, as otherwise it conflicts w/ normal dismiss
        
            isDragging: false, // if the current component is being dragged. ProgramBuilderHeader passed this to the ProgramBuilderComponent via setIsDragging

            tapMenu: this.tapMenu.bind(this), // called by ProgramBuilderHeader
            mouseUpMenu: this.mouseUpMenu.bind(this), // called by ProgramBuilderHeader
            mouseDownMenu: this.mouseDownMenu.bind(this), // called by ProgramBuilderHeader
            tapDrag: this.tapDrag.bind(this), // called by ProgramBuilderHeader
            releaseDrag: this.releaseDrag.bind(this), // called by ProgramBuilderHeader
            dismissMenu: this.dismissMenu.bind(this), // called by ProgramBuilderMenu
            setIsDragging: this.setIsDragging.bind(this), // called by ProgramBuilderHeader
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // reset drag ready and hamburger if needed
        if (this.state.isDragging && this.state.dragReady) {
            this.setState({
                dragReady: false,
                hamburgerActive: false,
            });
        }
    }

    setIsDragging(d) {
        if (d !== this.state.isDragging) {
            this.setState({
                isDragging: d,
            });
        }
    }

    tapDrag(e) {
        hideMenu({
            id: `programbuildercontext${this.props.id}`
        });
        this.setState({dragReady: true, hamburgerActive: false});
    }

    releaseDrag(e) {
        this.setState({dragReady: false, hamburgerActive: false});
    }

    mouseDownMenu(e) {
        this.setState({hamburgerReady: true});
    }

    mouseUpMenu(e) {
        if (this.state.hamburgerReady) {
            this.setState({hamburgerReady: false});
        }
    }

    tapMenu(e) {
        if (!this.state.hamburgerActive) {
            this.setState({
                hamburgerActive: true,
                hamburgerReady: false,
            });
            const rect = this.element.current.getBoundingClientRect();
            showMenu({
                position: {x: 59, y: rect.top + 10}, // TODO: consider making this static to the element?
                target: {},
                id: `programbuildercontext${this.props.id}`
            });
        } else {
            this.dismissMenu();
        }
    }

    dismissMenu() {
        if (this.state.hamburgerActive && !this.state.hamburgerReady) {
            this.setState({
                hamburgerActive: false,
            });
        }
    }

    render() {
        const props = this.props;
        const children = React.Children.map(
            props.children,
            child => {
                if (child && child.type.displayName && child.type.displayName.includes('ProgramBuilder')) {
                    return React.cloneElement(child, {
                        id: props.id,
                        parentId: props.parentId,
                        order: props.order,
                        parentType: props.parentType,
                        childType: props.childType,
                        isReordering: props.isReordering,
                        finishDrag: props.finishDrag,
                        updateHoverIndicator: props.updateHoverIndicator,
                    });
                } else {
                    return child;
                }
            }
        );
      
        const containerStyle = this.state.isDragging ? styles.transparent: null;
        return (this.props.connectDropTarget(<div className={styles.container}>
            <ProgramBuilderContext.Provider value={this.state}>
                <div ref={this.element} className={containerStyle}>
                    {children}
                </div>
            </ProgramBuilderContext.Provider>
        </div>));
    }

}

const target = {

    hover(props, monitor, component) {
        // get vars
        const origItem = monitor.getItem();
        const origId = origItem.id;
        const origParentId = origItem.orig_parent_id;
        const origOrder = origItem.orig_order;
        const fromParentId = origItem.parent_id;
        const fromOrder = origItem.order;
        const toParentId = props.parentId;

        // helpers to calculate hover position
        const node = ReactDOM.findDOMNode(component);
        const hoverBoundingRect = node.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // hover and to order
        let hoverPosition = hoverClientY <= hoverMiddleY;
        let toOrder = hoverPosition ? props.order : props.order + 1;
        if (origItem.id === props.id) {
            // if it's the original component, ignore all ups and downs
            toOrder = origOrder;
        } else if (origParentId === toParentId && props.order > origOrder) {
            // if moving down within same parent, gotta minus 1 it
            toOrder -= 1;
        }
        if (origParentId === toParentId && toOrder === origOrder) {
            // if it would've been the original position, hide the hover position
            hoverPosition = null;
        }

        // determine if updates needed
        if (fromParentId === toParentId && fromOrder === toOrder) {
            return;
        }

        // update
        origItem.parent_id = toParentId;
        origItem.order = toOrder;

        // render
        if (hoverPosition === null) {
            props.updateHoverIndicator(null);
        } else {
            if (hoverPosition === true) {
                props.updateHoverIndicator(`${hoverBoundingRect.top+window.scrollY+props.hoverIndicatorTopOffset}px`);
            } else {
                props.updateHoverIndicator(`${hoverBoundingRect.bottom+window.scrollY+props.hoverIndicatorBottomOffset}px`);
            }
        }
    },

};

const targetCollect = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
    };
};

export default DropTarget((props) => props.parentType, target, targetCollect)(ProgramBuilderComponent);
