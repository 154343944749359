import { createSelector } from 'redux-orm';
import orm from 'shared_redux/reducers/models/orm';

export const getPrograms = createSelector(
    orm,
    state => state.database,
    session => {
        return session.Programs.all().orderBy('order', 'desc').toRefArray();
    }
);
