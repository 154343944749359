import API from './api';

export default {

    getNews: () => API({
        url: `news`,
        method: 'GET',
    }),
    
};
