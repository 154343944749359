import React, { Component } from 'react';
import ProgramBuilderContext from './programBuilderContext';
import { MenuItem } from "react-contextmenu";

/**
 * Properties:
 * rightClickOnly - note can update this to visibility = RightClick/All/Hamburger
 **/
export default class ProgramBuilderMenuItem extends Component {
    static displayName = 'ProgramBuilderMenuItem';
    static contextType = ProgramBuilderContext;

    render() {
        if (this.props.rightClickOnly && this.context.hamburgerActive) {
            return null;
        } else {
            return <MenuItem {...this.props} />;
        }
    }

}
