import moment from 'moment';
import { createSelector } from 'redux-orm';
import orm from 'shared_redux/reducers/models/orm';

const stateRoot = (state) => state.program_scheduler;

export const getSelectedGroupFilter = (state) => stateRoot(state).selectedGroupFilter;

export const getSelectedTeamFilter = (state) => stateRoot(state).selectedTeamFilter;

export const getCalendarEvents = createSelector(
    orm,
    state => state.database,
    getSelectedTeamFilter,
    getSelectedGroupFilter,
    (session, teamFilter, groupFilter) => {
        const { ProgramSchedules, Organizations } = session;
        const org = Organizations.first();
        if (!org) {
            return [];
        }

        const tz = new Date().getTimezoneOffset();
        let query = ProgramSchedules.all();
        if (teamFilter) {
            query = query.filter(schedule => schedule.team_id === teamFilter);
        } else if (groupFilter) {
            query = query.filter(schedule => schedule.group_id === groupFilter);
        }
        return query.toModelArray().map(schedule => {
            const programName = schedule.program ? schedule.program.ref.name : null;
            const assigneeName = schedule.getAssigneeName();
            return {
                id: schedule.id,
                title: `${programName} - ${assigneeName}`,
                allDay: true,
                start: moment.utc(schedule.start_date).hour(12).utcOffset(tz).toDate(),
                end: moment.utc(schedule.end_date).hour(12).utcOffset(tz).toDate(),
            };
        });
    }
);

export const getProgramLengthInDays = createSelector(
    orm,
    state => state.draft_database,
    (state, props) => props.match.params.programid,
    (session, program_id) => {
        if (!program_id) {
            return null;
        }
        const { Programs } = session;
        const program = Programs.withId(program_id);
        if (!program || !program.microcycles) {
            return null;
        }
        return program.microcycles.toRefArray().length * 7;
    }
);
