import API from './api';

export default {
    
    getEntries: (sessionId) => API({
        url: `entries?session_id=${sessionId}`,
        method: 'GET',
        headers: {
            'Accept': 'application/csv',
            'Content-Type': 'application/json',
        }
    }),
    
    updateEntry: (json) => API({
        url: `entries/${json.id}`,
        method: 'PATCH',
        json,
    }),

};
