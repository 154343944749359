import {
    FETCH_PROGRAM_SUCCEEDED,
    FETCH_ATHLETE_SESSIONS_SUCCEEDED,
    FETCH_EXERCISES_SUCCEEDED,

    ADD_MAXABLE_EXERCISE_SUCCEEDED,
    REMOVE_MAXABLE_EXERCISE_SUCCEEDED,

    SET_FEED_ITEMS,
    DELETE_FEED_ITEM,
    UPDATE_FEED_ENTRY_ITEM,
    UPDATE_FEED_COMPLETE_ITEM,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class Exercises extends Model {

    static reducer(action, Exercises, session) {
        switch (action.type) {
            case FETCH_PROGRAM_SUCCEEDED: {
                for (const microcycle of action.payload.microcycles) {
                    for (const microcycle_session of microcycle.microcycle_sessions) {
                        for (const block of microcycle_session.session.blocks) {
                            for (const plan of block.plans) {
                                Exercises.upsert(plan.exercise);
                            }
                        }
                    }
                }
                break;
            }
            case FETCH_ATHLETE_SESSIONS_SUCCEEDED: {
                for (const athlete_session of action.payload) {
                    for (const block of athlete_session.session.blocks) {
                        for (const plan of block.plans) {
                            Exercises.upsert(plan.exercise);
                        }
                    }
                }
                break;
            }
            case FETCH_EXERCISES_SUCCEEDED: {
                for (const exercise of action.payload) {
                    const {['parent_exercise_links']: parent_exercise_links, ...rawExercise} = exercise;
                    Exercises.upsert(rawExercise);
                }
                break;
            }
            case ADD_MAXABLE_EXERCISE_SUCCEEDED:
            case REMOVE_MAXABLE_EXERCISE_SUCCEEDED:
                Exercises.upsert(action.payload);
                break;

            case SET_FEED_ITEMS:
            case DELETE_FEED_ITEM:
            case UPDATE_FEED_ENTRY_ITEM:
            case UPDATE_FEED_COMPLETE_ITEM:
                action.entries.forEach(e => {
                    if (e.plan && e.plan.exercise) {
                        Exercises.upsert(e.plan.exercise);
                    }
                });
                break;

            case SIGN_OUT_SUCCEEDED:
                Exercises.all().delete();
                break;
        }
    }

}
Exercises.modelName = 'Exercises';

Exercises.fields = {
    id: attr(),
    name: attr(),
    organization_id: attr(),
};

export default Exercises;
