import React, {Component}  from 'react';
import { Switch, Route } from 'react-router-dom';

import SingleAthlete from './single';
import ListAthletes from './list';

export default class View extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <Switch>
                <Route path='/athletes/:athleteid/:athletename/:tab' component={SingleAthlete} />
                <Route path='/athletes/:athleteid/:athletename' component={SingleAthlete} />
                <Route path='/athletes/:athleteid' component={SingleAthlete} />
                <Route path='/athletes' component={ListAthletes} />
            </Switch>
        );
    }
};
