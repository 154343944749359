import * as Cookies from "js-cookie";
import { displayErrorAlert } from 'services/alerts';

// TODO: json schema validation, as right now the application HEAVILY relies on the format being exactly correct
// a single bug from the server would break everything client side
const baseURL = window.location.protocol + '//' + window.location.host + "/api";

let numRequests = 0;
let number = -1;

const removeCookie = () => {
    numRequests -= 1;
    if (numRequests <= 0) {
        Cookies.remove('csrf');
    }
}

export default (params) => {
    return new Promise(async (resolve, reject) => {
        try {
            var dict = {
                method: params.method,
                credentials: 'same-origin',
            };
            if (params.method !== 'GET') {
                // generate number
                if (numRequests <= 0) {
                    let array = new Uint32Array(1);
                    crypto.getRandomValues(array);
                    number = array[0];
                }

                // cookie
                var inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
                Cookies.set('csrf', number, {
                    expires: inFifteenMinutes,
                    secure: true,
                    sameSite: 'strict',
                });

                // body
                if (params.json) {
                    params.json.csrf = number;
                } else if (params.formData) {
                    params.formData.append('csrf', number );
                } else {
                    params.json = { csrf: number };
                }
            }

            dict.headers = {
                'Accept': 'application/json',
            };
            if (params.json) {
                dict.headers['Content-Type'] = 'application/json';
            }
            if (params.headers) {
                for (let header in params.headers) {
                    dict.headers[header] = params.headers[header];
                }
            }

            if (params.json) {
                dict.body = JSON.stringify(params.json);
            } else if (params.formData) {
                dict.body = params.formData;
            }

            numRequests += 1;
            const response = await fetch(`${baseURL}/${params.url}`, dict);
             if (response.ok) {
                // TODO: don't crash if there's no result
                var result;
                if (params.method === 'DELETE') {
                    // TODO: check that this doesn't cause problems
                    result = null;
                } else if (dict.headers.Accept === 'application/csv') {
                    result = await response.text();
                } else if (response.headers.get("content-length") == 0) {
                    result = null;
                } else {
                    result = await response.json();
                }

                removeCookie();
                resolve(result);
            } else {
                try {
                    // attempt to parse json
                    const json = await response.clone().json();
                    if (json) {
                        removeCookie();
                        reject({ statusCode: response.status, json });
                    } else {
                        // attempt to parse text instead
                        const text = await response.clone().text();
                        removeCookie();
                        reject({ statusCode: response.status, message: text });
                    }
                } catch (error) {
                    // attempt to parse text instead
                    const text = await response.clone().text();
                    removeCookie();
                    reject({ statusCode: response.status, message: text });
                }
            }
        } catch(error) {
            displayErrorAlert(error.toString()); // TODO: re-enable for debugging
            removeCookie();
            reject(error);
        }
    });
};
