import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';

export default React.memo(props => {
    const tabs = props.tabs.map(tab => <NavLink exact to={tab.url} key={`tab${tab.url}`} className={styles.tab} activeClassName={styles.selectedTab}>{tab.text}</NavLink> );

    return (
        <div className={styles.titlebg}>
            <div className={styles.title}>
                {tabs}
            </div>
        </div>
    );

});

