import uuidv4 from 'uuid/v4';

import {
    DRAFT_CREATE_BLOCK,
    ATTEMPT_CREATE_BLOCK,

    DRAFT_CREATE_MICROCYCLE_SESSION,
    ATTEMPT_CREATE_MICROCYCLE_SESSION,

    DRAFT_UPDATE_BLOCK_TYPE,
    DRAFT_UPDATE_BLOCK_MIN,
    DRAFT_UPDATE_BLOCK_MAX,
    DRAFT_UPDATE_BLOCK_REST,
    
    DRAFT_SAVE_BLOCK,
    ATTEMPT_SAVE_BLOCK,

    DRAFT_MOVE_BLOCK,
    ATTEMPT_MOVE_BLOCK,

    DUPLICATE_BLOCK,

    DRAFT_DELETE_BLOCK,
    ATTEMPT_DELETE_BLOCK,

    INSERT_PLAN,
} from 'shared_redux/actions';

export const createBlock = (session_id, type, order) => {
    const id = uuidv4();
    return {
        type: DRAFT_CREATE_BLOCK,
        pushType: ATTEMPT_CREATE_BLOCK,
        payload: {
            id,
            session_id: session_id,
            type,
            min_sets: 3,
            max_sets: 3,
            order: order,
        }
    };
};

export const createMicrocycleSession = (microcycle_id, order) => {
    const session_id = uuidv4();
    return {
        type: DRAFT_CREATE_MICROCYCLE_SESSION,
        pushType: ATTEMPT_CREATE_MICROCYCLE_SESSION,
        payload: {
            session_id,
            microcycle_id,
            order,
        }
    };
};

export const updateBlockType = (id, type) => ({
    type: DRAFT_UPDATE_BLOCK_TYPE,
    payload: {
        id,
        type,
    },
});

export const updateBlockMin = (id, min_sets) => {
    if (Number.isInteger(min_sets) && min_sets <= 0) {
        min_sets = 1;
    }
    return {
        type: DRAFT_UPDATE_BLOCK_MIN,
        payload: {
            id,
            min_sets,
        },
    };
};

export const updateBlockMax = (id, max_sets) => {
    if (Number.isInteger(max_sets) && max_sets <= 0) {
        max_sets = 1;
    }
    return {
        type: DRAFT_UPDATE_BLOCK_MAX,
        payload: {
            id,
            max_sets,
        },
    };
};

export const updateBlockRest = (id, rest) => ({
    type: DRAFT_UPDATE_BLOCK_REST,
    payload: {
        id,
        rest,
    },
});

export const saveBlock = (id) => ({
    type: DRAFT_SAVE_BLOCK,
    pushType: ATTEMPT_SAVE_BLOCK,
    payload: { id },
});

export const moveBlock = (id, session_id, order) => ({
    type: DRAFT_MOVE_BLOCK,
    pushType: ATTEMPT_MOVE_BLOCK,
    payload: {
        id,
        session_id,
        order,
    },
});

export const duplicateBlock = (id) => ({
    type: DUPLICATE_BLOCK,
    id,
});

export const deleteBlock = (id) => ({
    type: DRAFT_DELETE_BLOCK,
    pushType: ATTEMPT_DELETE_BLOCK,
    id,
});

export const insertPlan = (block_id, order) => ({
    type: INSERT_PLAN,
    block_id,
    order,
});
