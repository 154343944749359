import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    call,
} from 'redux-saga/effects';

import {
    ATTEMPT_FETCH_NEWS,
    FETCH_NEWS_SUCCEEDED,
} from 'shared_redux/actions';
import API from 'services/news';
import { handleError } from 'services/alerts';

export default function* NewsSaga() {
    yield all([
        takeLatest(ATTEMPT_FETCH_NEWS, fetchNews),
    ]);
};

function *fetchNews(action) {
    try {
        const news = yield apply(API, API.getNews);
        yield put({
            type: FETCH_NEWS_SUCCEEDED,
            payload: news,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch news');
    }
}
