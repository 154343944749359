import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as ProgramBuilderSelectors from 'shared_redux/selectors/view/program_builder';

const makeMapStateToProps = () => {
    const getDraftPlan = ProgramBuilderSelectors.makeGetDraftPlan();
    return (state, props) => {
        return getDraftPlan(state, props);
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        duplicatePlan: Actions.duplicatePlan,
        insertPlan: Actions.insertPlan,

        createExercise: Actions.createExercise,
        updatePlanExercise: Actions.updatePlanExercise,
        updatePlanRest: Actions.updatePlanRest,
        updatePlanNotes: Actions.updatePlanNotes,
        updatePlanAutoregulation: Actions.updatePlanAutoregulation,
        updatePlanSensorTracking: Actions.updatePlanSensorTracking,
        updatePlanMinSets: Actions.updatePlanMinSets,
        updatePlanMaxSets: Actions.updatePlanMaxSets,
        updatePlanSetCountFixed: Actions.updatePlanSetCountFixed,
        updatePlanNumReps: Actions.updatePlanNumReps,
        updatePlanAMRAP: Actions.updatePlanAMRAP,
        updatePlanAutoregulationQuantifier: Actions.updatePlanAutoregulationQuantifier,
        updatePlanAutoregulationTarget: Actions.updatePlanAutoregulationTarget,
        updatePlanAutoregulationRange: Actions.updatePlanAutoregulationRange,
        updatePlanAutoregulationMetric: Actions.updatePlanAutoregulationMetric,
        updatePlanWeight: Actions.updatePlanWeight,
        updatePlanWeightUnit: Actions.updatePlanWeightUnit,
        savePlan: Actions.savePlan,
        movePlan: Actions.movePlan,
        deletePlan: Actions.deletePlan,
    }, dispatch);
};

const Container = connect(
    makeMapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
)(View);

export default Container;
