import React, {Component} from 'react';
import { Waypoint } from 'react-waypoint';
import moment from 'moment';

import styles from './styles.module.scss';
import SessionComponent from './session';
import Filter from './filter';
import Spinner from 'shared_features/spinner';

class View extends Component {

    componentDidMount() {
        this.props.fetchAthleteSessions(this.props.match.params.athleteid);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (this.props.updatedTime !== nextProps.updatedTime);
    }

    _scrollToDate(date) {
        // Note: document.getElement is used as it's simpler than trying to maintain an array of react references given string api problems
        // Note: scrollIntoView is used instead of a simple anchor because this can force it to the top of the screen
        // const element = document.getElementById("2018-12");
        const element = document.getElementById(moment(date).format("YYYY-M"));
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    // render

    _renderDate(model) {
        return (
            <Waypoint key={`waypoint${model.anchor}`} bottomOffset="75%" onEnter={()=>this.props.updateFilterDate(model.date)}>
                <div id={model.anchor} className={styles.dateLabel}>{model.text}</div>
            </Waypoint>
        );
    }

    _renderCycle(model) {
        if (model.padded) {
            var style = styles.paddedCycleLabel;
        } else {
            var style = styles.cycleLabel;
        }

        return (
            <div key={`cycle${model.text}`} className={style}>{model.text}</div>
        );
    }

    _renderSession(model) {
        return <SessionComponent key={`athlete-history-session${model.id}`} id={model.id} displayNumber={model.displayNumber} athleteId={this.props.match.params.athleteid} />
    }

    _renderModels() {
        if (!this.props.updatedTime && this.props.models && this.props.models.length <= 0) {
            return <Spinner />;
        }
        var result = [];
        for (var model of this.props.models) {
            switch (model.type) {
                case 'date':
                    result.push(this._renderDate(model));
                    break;
                case 'cycle':
                    result.push(this._renderCycle(model));
                    break;
                case 'session':
                    result.push(this._renderSession(model));
                    break;
                default:
                    return null;
            }
        }
        return result;
    }

    render() {
        return (
            <div className={styles.main}>
                <Filter
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    selectedDate={(date)=>this._scrollToDate(date)} />
                <div className={styles.container}>{this._renderModels()}</div>
            </div>
        );
    }
}

export default View;
