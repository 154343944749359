import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as OrganizationsSelectors from 'shared_redux/selectors/models/organizations';
import View from './view';

const mapStateToProps = (state) => ({
    loggedIn: OrganizationsSelectors.getIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
