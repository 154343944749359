import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    call,
} from 'redux-saga/effects';

import {
    FETCH_ACCOUNT_SUCCEEDED,

    ATTEMPT_CREATE_EXERCISE,
    QUEUE_ITEM_SUCCEEDED,
    QUEUE_ITEM_FAILED,

    ATTEMPT_FETCH_EXERCISES,
    FETCH_EXERCISES_SUCCEEDED,

    ATTEMPT_ADD_MAXABLE_EXERCISE,
    ADD_MAXABLE_EXERCISE_SUCCEEDED,

    ATTEMPT_REMOVE_MAXABLE_EXERCISE,
    REMOVE_MAXABLE_EXERCISE_SUCCEEDED,
} from 'shared_redux/actions';
import API from 'services/exercises';
import { handleError } from 'services/alerts';
import * as AccountsSelectors from 'shared_redux/selectors/models/accounts';

export default function* ExercisesSaga() {
    yield all([
        takeEvery(ATTEMPT_CREATE_EXERCISE, createExercise),
        takeLatest(ATTEMPT_FETCH_EXERCISES, fetchExercises),
        takeEvery(ATTEMPT_ADD_MAXABLE_EXERCISE, patchAddMaxable),
        takeEvery(ATTEMPT_REMOVE_MAXABLE_EXERCISE, patchRemoveMaxable),
    ]);
};

function *createExercise(action) {
    try {
        var account = yield select(AccountsSelectors.getAccount);
        if (!account) {
            yield take(FETCH_ACCOUNT_SUCCEEDED);
            account = yield select(AccountsSelectors.getAccount);
        }
        yield apply(API, API.createExercise, [action.payload]);
        yield put({ type: QUEUE_ITEM_SUCCEEDED });
    } catch(error) {
        if (error.statusCode === 409) {
            // already created, treat as success
            yield put({ type: QUEUE_ITEM_SUCCEEDED });
        } else {
            yield put({ type: QUEUE_ITEM_FAILED });
            yield handleError(error, 'Error: Unable to create exercise');
        }
    }
}

function *fetchExercises(action) {
    try {
        var account = yield select(AccountsSelectors.getAccount);
        if (!account) {
            yield take(FETCH_ACCOUNT_SUCCEEDED);
            account = yield select(AccountsSelectors.getAccount);
        }
        const includeParentExerciseLinks = action.includeParentExerciseLinks === true;
        const exercises = yield apply(API, API.getExercises, [account.organization_id, includeParentExerciseLinks]);
        yield put({
            type: FETCH_EXERCISES_SUCCEEDED,
            payload: exercises,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch exercises');
    }
}

function *patchAddMaxable(action) {
    try {
        const payload = yield apply(API, API.patchExerciseMaxable, [action.exerciseId]);
        yield put({
            type: ADD_MAXABLE_EXERCISE_SUCCEEDED,
            payload,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to patch exercise to be maxable');
    }
}

function *patchRemoveMaxable(action) {
    try {
        const payload = yield apply(API, API.patchExerciseNotMaxable, [action.exerciseId]);
        yield put({
            type: REMOVE_MAXABLE_EXERCISE_SUCCEEDED,
            payload,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to patch exercise to not be maxable');
    }
}
