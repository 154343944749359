import API from './api';

export default {
    
    getPrograms: (organization_id) => API({
        url: `programs?organization_id=${organization_id}`,
        method: 'GET',
    }),

    getProgram: (programId, organization_id) => API({
        url: `programs/${programId}?organization_id=${organization_id}`,
        method: 'GET',
    }),

    createProgram: (json) => API({
        url: 'programs',
        method: 'POST',
        json,
    }),

    updateProgram: (json) => API({
        url: `programs/${json.id}`,
        method: 'PATCH',
        json,
    }),

    deleteProgram: (id) => API({
        url: `programs/${id}`,
        method: 'DELETE',
    }),

};
