import React, {Component} from 'react';
import {animateScroll} from 'react-scroll';
import { Prompt } from 'react-router';

import styles from './styles.module.scss';
import MicrocycleView from './microcycle';
import Spinner from 'shared_features/spinner';
import Reorder from 'shared_features/svgs/reorder';
import ErrorBanner from 'shared_features/error_banner';

const scrollPercent = 0.1;

const scrollTopOptions = {
    duration: px => px * -5,
    delay: 0,
    smooth: "linear"
};

const scrollBottomOptions = {
    duration: px => px * 5,
    delay: 0,
    smooth: "linear"
};

// TODO: Consider changing this to a functional component
// TODO: Consider adding back in fetch actions for retry options
// TODO: Consider not using this. for variables as that prevents multiple instances of this component from existing at once
export default class View extends Component {

    constructor(props) {
        super(props);
        this.state = {
            style: {
                top: 0
            },
            isReordering: false,
        };
        this.reorderButton = React.createRef();
        this.hoverIndicator = React.createRef();
    }

    componentDidMount() {
        this._boundHandleBeforeUnload = this._handleBeforeUnload.bind(this);
        window.addEventListener("beforeunload", this._boundHandleBeforeUnload);

        this._boundHandleUnload = this._handleUnload.bind(this);
        window.addEventListener("onunload", this._boundHandleUnload);
    }
    
    componentWillUnmount() {
        window.removeEventListener("beforeunload", this._boundHandleBeforeUnload);
        window.removeEventListener("onunload", this._boundHandleUnload);
        this._handleUnload(); 
    }

    _handleBeforeUnload(e) {
        if (this.props.shouldWarnLeave) {
            e.preventDefault();
            e.returnValue = `You may lose your program builder changes that haven't been synced yet to the server. Are you sure?`;
        }
    }

    _handleUnload() {
        if (this.props.shouldWarnLeave) {
            this.props.cancelAndClear();
            this.props.fetchProgram(this.props.match.params.programid);
            this.props.fetchExercises();
        }
    }

    _handleDrag(e) {
        if (e.clientY === 0) {
            return;
        }

        e.persist();

        if (this.animation) {
            cancelAnimationFrame(this.animation);
        }

        this.reorderButton.current.style.display = 'block';
        this.reorderButton.current.style.top = `${e.clientY - (25/2)}px`;

        this.animation = requestAnimationFrame(() => {
            this.reorderButton.current.style.top = `${e.clientY - (25/2)}px`;
            this.animation = null;
        });

        if (this.isScrollingUp !== true && e.clientY < scrollPercent * window.innerHeight) {
            animateScroll.scrollToTop(scrollTopOptions);
            this.isScrollingUp = true;
        } else if (this.isScrollingUp !== false && e.clientY > (1-scrollPercent) * window.innerHeight) { 
            animateScroll.scrollToBottom(scrollBottomOptions);
            this.isScrollingUp = false;
        } else if (this.isScrollingUp !== null && e.clientY >= scrollPercent * window.innerHeight && e.clientY <= (1-scrollPercent) * window.innerHeight) {
            animateScroll.stopScrolling();
            this.isScrollingUp = null;
        }

        if (!this.state.isReordering) {
            this.setState({ isReordering: true });
        }
    }

    _stopDrag(e) {
        // cancel animating the reorder icon and hide it
        if (this.animation) {
            cancelAnimationFrame(this.animation);
            this.animation = null;
        }
        this.reorderButton.current.style.display = 'none';

        // stop the scrolling
        animateScroll.stopScrolling();
        this.isScrollingUp = null;

        // hide the hover indicator
        this.hoverIndicator.current.style.display = 'none';

        // set drag state
        if (this.state.isReordering) {
            this.setState({ isReordering: false });
        }
    }

    _updateHoverIndicator(offset) {
        if (offset === null) {
            this.hoverIndicator.current.style.display = 'none';
        } else {
            this.hoverIndicator.current.style.display = 'block';
            this.hoverIndicator.current.style.top = offset;
        }
    }

    _renderContents() {
        return (
            <div className={styles.main}>
                <div>{this._renderMicrocycles()}</div>
                <div className={styles.addMicrocycle} onClick={()=>this.props.createMicrocycle(this.props.match.params.programid, this.props.microcycle_ids.length+1)}>
                    <div className={styles.microcycleBorder} />
                    <span>+</span>{this.props.microcycle_name.toUpperCase()} {this.props.microcycle_ids.length+1}
                </div>
            </div>
        ); 
    }

    _renderMicrocycles() {
        var result = [];
        for (var id of this.props.microcycle_ids) {
            result.push(<MicrocycleView
                key={`Microcycle${id}`}
                id={id}
                name={this.props.microcycle_name}
                isReordering={this.state.isReordering}
                updateHoverIndicator={this._updateHoverIndicator.bind(this)}
                finishDrag={this._stopDrag.bind(this)} />);
        }
        return result;
    }

    render() {
        // loaded spinner
        if (!this.props.isLoaded) {
            return (
                <Spinner />
            );
        }

        // syncing spinner
        if (this.props.isSyncing) {
            var spinner = <div className={styles.spinner}><Spinner /></div>;
        } else {
            var spinner = null;
        }

        return (
            <div onDrag={this._handleDrag.bind(this)}>
                {this._renderContents()}
                <div ref={this.reorderButton} className={styles.reorder}><Reorder /></div>
                <div ref={this.hoverIndicator} className={styles.hoverIndicator} />
                {spinner}
                <ErrorBanner errorState={this.props.errorState} callback={this.props.retryQueue.bind(this)} />
                <Prompt
                    when={this.props.shouldWarnLeave}
                    message="You may lose your program builder changes that haven't been synced yet to the server. Are you sure?"
                    />
            </div>
        );
    }

};
