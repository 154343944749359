import {
    FETCH_EXERCISES_SUCCEEDED,
    UPDATE_EXERCISE_LINK_SUCCEEDED,
    DELETE_EXERCISE_LINK_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class ExerciseLinks extends Model {

    static reducer(action, ExerciseLinks, session) {
        switch (action.type) {
            case FETCH_EXERCISES_SUCCEEDED: {
                for (const exercise of action.payload) {
                    const {['parent_exercise_links']: parent_exercise_links, ...rawExercise} = exercise;
                    if (parent_exercise_links) {
                        for (const parent_exercise_link of parent_exercise_links) {
                            ExerciseLinks.upsert({
                                ...parent_exercise_link,
                                id: parent_exercise_link.child_exercise_id, // duplicate it just for ID purposes
                            });
                        }
                    }
                }
                break;
            }
            case UPDATE_EXERCISE_LINK_SUCCEEDED:
                ExerciseLinks.upsert({
                    ...action.payload,
                    id: action.payload.child_exercise_id,
                });
                break;
            case DELETE_EXERCISE_LINK_SUCCEEDED:
                ExerciseLinks.withId(action.id).delete();
                break;
        
            case SIGN_OUT_SUCCEEDED:
                ExerciseLinks.all().delete();
                break;
        }
    }

}
ExerciseLinks.modelName = 'ExerciseLinks';

ExerciseLinks.fields = {
    id: attr(), // local only, basically the child_exercise_id. local because the real identifier is a combo of org id and child exercise id
    organization_id: attr(),
    formula: attr(),

    child_exercise_id: fk({
        to: 'Exercises',
        as: 'child_exercise',
        relatedName: 'child_exercise_links',
    }),

    parent_exercise_id: fk({
        to: 'Exercises',
        as: 'parent_exercise',
        relatedName: 'parent_exercise_links',
    }),
};

export default ExerciseLinks;
