import { createSelector } from 'redux-orm';
import moment from 'moment';

import orm from 'shared_redux/reducers/models/orm';

const stateRoot = (state) => state.program_scheduler_popup;

export const getProgramId = (state) => stateRoot(state).programId;
export const getEditingId = (state) => stateRoot(state).id;
export const getIsCreating = (state) => stateRoot(state).isCreating;
export const getIsEditing = (state) => stateRoot(state).isEditing;
export const getStartDate = (state) => stateRoot(state).startDate;
export const getEndDate = (state) => stateRoot(state).endDate;
export const getAssignees = (state) => stateRoot(state).assignees;
export const getSucceeded = (state) => stateRoot(state).requestsSucceeded > 0;
export const getRequestsRemaining = (state) => stateRoot(state).requestsRemaining;
export const getIsRequesting = (state) => getRequestsRemaining(state) > 0;
export const getFailed = (state) => stateRoot(state).failed;

// TODO: Consider class names for styling, might need to pull the scss from here to make it work
// would just make the select option a global class name
export const getAssignOptions = createSelector(
    orm,
    state => state.database,
    getAssignees,
    (session, assignees) => {
        const { Athletes, Teams } = session;
        const result = [];
        Teams.all().orderBy('name').toModelArray().forEach(team => {
            const groups = team.groups.toRefArray();
            const athletes = team.athletes.toRefArray().filter(a => a.group_id === null);

            // display team - ALL
            if (athletes.length > 0 || groups.length > 1) {
                // only display all if there's 1 athlete you can access, or if it's only group then at least 2 groups

                // calculate count and group_ids
                const child_values = [];
                let groupCount = 0;
                groups.forEach(g => {
                    child_values.push(g.id);
                    if (assignees.includes(g.id)) {
                        groupCount++;
                    }
                });

                // calculate count and athlete_ids
                let athleteCount = 0;
                athletes.forEach(a => {
                    child_values.push(a.id);
                    if (assignees.includes(a.id)) {
                        athleteCount++;
                    }
                });
                
                // sanity check that they weren't already selected
                if (athletes.length - athleteCount > 0 || groups.length - groupCount > 1) {
                    result.push({ value: team.id, label: `${team.name} - ALL`, child_values });
                }
            }

            // display groups of team
            groups.forEach(group => {
                result.push({ value: group.id, label: `${team.name} - ${group.name}` });
            });
        });
        Athletes.all().orderBy('first_name').toModelArray().forEach(athlete => {
            // display athletes without teams or groups
            if (athlete.group_id === null) {
                result.push({ value: athlete.id, label: athlete.displayName() });
            }
        });
        return result;
    }
);

export const getEditingProgramAssignee = createSelector(
    orm,
    state => state.database,
    getEditingId,
    (session, program_schedule_id) => {
        if (!program_schedule_id) {
            return null;
        }
        const { ProgramSchedules } = session;
        const schedule = ProgramSchedules.withId(program_schedule_id);
        return schedule ? schedule.getAssigneeName() : null;
    }
);

export const getEditingProgramName = createSelector(
    orm,
    state => state.database,
    getEditingId,
    (session, program_schedule_id) => {
        if (!program_schedule_id) {
            return null;
        }
        const { ProgramSchedules } = session;
        const schedule = ProgramSchedules.withId(program_schedule_id);
        if (!schedule || !schedule.program) {
            return null;
        }
        return schedule.program.ref.name;
    }
);

export const getConflicts = createSelector(
    orm,
    state => state.database,
    state => stateRoot(state).conflicts,
    (session, udids) => {
        const { ProgramSchedules } = session;
        var result = [];
        const tz = new Date().getTimezoneOffset();
        udids.forEach(udid => {
            const schedule = ProgramSchedules.withId(udid);
            result.push({
                assignee: schedule.getAssigneeName(),
                programName: schedule.program.name,
                programStartDate: moment.utc(schedule.start_date).hour(12).utcOffset(tz).format('MMM D'),
                programEndDate: moment.utc(schedule.end_date).hour(12).utcOffset(tz).format('MMM D'),
            });
        });
        return result;
    }
);
