import { all, take, takeLatest, select, apply, call, put } from 'redux-saga/effects';
import moment from 'moment';

import {
    FETCH_DATA_EXPORTS_ATTEMPT,
    FETCH_DATA_EXPORTS_FAILED,
    FETCH_DATA_EXPORTS_SUCCEEDED,

    START_DATA_EXPORTS_ATTEMPT,
    START_DATA_EXPORTS_SUCCEEDED,
    START_DATA_EXPORTS_FAILED,

    RETRY_DATA_EXPORTS_ATTEMPT,
    RETRY_DATA_EXPORTS_SUCCEEDED,
    RETRY_DATA_EXPORTS_FAILED,

    DOWNLOAD_DATA_EXPORTS_ATTEMPT,
    DOWNLOAD_DATA_EXPORTS_SUCCEEDED,
    DOWNLOAD_DATA_EXPORTS_FAILED,

    DATA_EXPORTS_STREAM_CONNECTED,
    DATA_EXPORTS_STREAM_DISCONNECTED,
    UPDATE_DATA_EXPORT,
} from 'shared_redux/actions';
import API from 'services/reports';
import { handleError, displayErrorAlert, displaySuccessAlert } from 'services/alerts';
import * as ReportsManagerSelectors from 'shared_redux/selectors/view/reports_manager';
import * as DataExportsSelectors from 'shared_redux/selectors/models/data_exports';

export default function* ReportsSaga(dispatch) {
    yield all([
        takeLatest(FETCH_DATA_EXPORTS_ATTEMPT, fetchDataExports),
        takeLatest(START_DATA_EXPORTS_ATTEMPT, startDataExport),
        takeLatest(RETRY_DATA_EXPORTS_ATTEMPT, retryDataExport),
        takeLatest(DOWNLOAD_DATA_EXPORTS_ATTEMPT, downloadDataExport),

        // sse
        call(fetchOnReconnect),
        takeLatest([
            DATA_EXPORTS_STREAM_DISCONNECTED,
            FETCH_DATA_EXPORTS_SUCCEEDED,
            START_DATA_EXPORTS_SUCCEEDED,
            RETRY_DATA_EXPORTS_ATTEMPT,
            RETRY_DATA_EXPORTS_FAILED,
            UPDATE_DATA_EXPORT,
        ], updateEventSourceStatus, dispatch),
    ]);
};

function* fetchDataExports(action) {
    try {
        const payload = yield apply(API, API.getDataExports, []);
        yield put({
            type: FETCH_DATA_EXPORTS_SUCCEEDED,
            payload,
        });
    } catch (err) {
        yield put({
            type: FETCH_DATA_EXPORTS_FAILED,
        });
        yield handleError(err, 'Error: Unable to fetch data exports');
    }
}

function* startDataExport() {
    try {
        const startDate = moment(yield select(ReportsManagerSelectors.getStartDateFilter)).format('YYYY-MM-DD');
        const endDate = moment(yield select(ReportsManagerSelectors.getEndDateFilter)).format('YYYY-MM-DD');
        const selectedTeam = yield select(ReportsManagerSelectors.getSelectedTeamFilter);
        const selectedGroup = yield select(ReportsManagerSelectors.getSelectedGroupFilter);
        const payload = yield apply(API, API.startDataExport, [selectedTeam, selectedGroup, startDate, endDate]);
        yield put({
            type: START_DATA_EXPORTS_SUCCEEDED,
            payload,
        });
    } catch (err) {
        yield put({
            type: START_DATA_EXPORTS_FAILED,
        });
        yield handleError(err, 'Error: Unable to start data export');
    }
}

function* retryDataExport(action) {
    try {
        yield apply(API, API.retryDataExport, [action.id]);
        yield put({
            type: RETRY_DATA_EXPORTS_SUCCEEDED,
        });
    } catch (err) {
        yield put({
            type: RETRY_DATA_EXPORTS_FAILED,
        });
        yield handleError(err, 'Error: Unable to retry data export');
    }
}

function* downloadDataExport(action) {
    try {
        // get download url from server
        const payload = yield apply(API, API.downloadDataExport, [action.id]);

        // TODO: prove this works
        // grab from CF signed url, hopefully it auto downloads
        window.open(payload);

        // success
        yield put({
            type: DOWNLOAD_DATA_EXPORTS_SUCCEEDED,
        });
    } catch (err) {
        yield put({
            type: DOWNLOAD_DATA_EXPORTS_FAILED,
        });
        yield handleError(err, 'Error: Unable to download data export');
    }
}

// TODO: consider refactoring into its own module similar to $socket
// The caveat here is that, I'm not sure if we want separate streams of SSE per situation
// OR if we'll have a single SSE from which all events flow through
// It's theoretically possible to write a module tha can handle multiple SSE types too
// Note, we could even move live screen on the website to SSE long run
// Note too, I may want to long run move websocket connections to different urls if possible, so a station connecting would use a separate url from the coaching portal 
let eventSource = null;
function* updateEventSourceStatus(dispatch) {
    const hasIncomplete = yield select(DataExportsSelectors.getHasIncompleteDataExport);

    if (hasIncomplete && eventSource) {
        // already listening, ignore
        return;
    } else if (!hasIncomplete) {
        if (eventSource) {
            // listening but shouldn't be, shutdown
            eventSource.close();
            eventSource = null;
        }

        return;
    }

    // listen now
    eventSource = new EventSource(`/api/data_exports/subscribe`, { withCredentials: true });
    eventSource.onmessage = (event) => {
        if (event.data === 'ping') {
            return;
        }

        // TODO: Consider changing this from fetching everything into just updating what changed
        // Right now done the dumb way just to prove SSE works
        // const parsedData = JSON.parse(event.data);
        const json = JSON.parse(event.data);
        if (json.command_type === 'UPDATE_DATA_EXPORT') {
            // display success and fail messages
            if (json.status === 'FAILED') {
                displayErrorAlert(`Data export failed, but can be retried`);
            } else if (json.status === 'COMPLETED') {
                displaySuccessAlert(`Data export complete!`);
            }
            
            // do the update
            dispatch({ type: UPDATE_DATA_EXPORT, payload: json });
        } else {
            // unknown type, just refetch for safety
            dispatch({ type: FETCH_DATA_EXPORTS_ATTEMPT });
        }
    };
    eventSource.onerror = function (event) {
        eventSource.close();
        eventSource = null;
        dispatch({ type: DATA_EXPORTS_STREAM_DISCONNECTED });
    }
}

function* fetchOnReconnect() {
    while (true) {
        yield take(DATA_EXPORTS_STREAM_DISCONNECTED);
        yield take(DATA_EXPORTS_STREAM_CONNECTED);
        yield put({ type: FETCH_DATA_EXPORTS_ATTEMPT });
    }
}
