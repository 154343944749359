import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Actions from './actions';
import View from 'shared_features/name_popup';
import * as TeamsManagerSelectors from 'shared_redux/selectors/view/teams_manager';

const mapStateToProps = (state) => ({
    isShowing: TeamsManagerSelectors.getIsEditingTeam(state),
    title: "Team Information",
    description: "Team Name",
    saveText: "Save team",
    name: TeamsManagerSelectors.getName(state),
    removeText: "Remove team",
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        closeModal: Actions.closeModal,
        updateName: Actions.updateName,
        remove: Actions.remove,
        save: Actions.patchTeam,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
