import { combineReducers } from 'redux';
// import { createReducer } from 'redux-orm';

import orm from './models/orm';
import draft_database from './models/draft_orm';
import login from './view/login';
import teams_manager from './view/teams_manager';
import programs from './view/programs';
import program_builder from './view/program_builder';
import queue from './system/queue';
import program_scheduler_popup from './view/program_scheduler_popup';
import profile from './view/profile';
import roster from './view/roster';
import import_athletes from './view/import_athletes';
import columns_filter from './view/columns_filter';
import settings from './view/settings';
import register from './view/register';
import program_scheduler from './view/program_scheduler';

import manage_maxes from './view/manage_maxes';

// old, update these these
import reports_manager from './view/reports_manager';
import history_filter from './view/history_filter';
import history_sessions from './view/history_sessions';
import history_plans from './view/history_plans';
import feed from './view/feed';


// From: https://github.com/redux-orm/redux-orm/issues/228#issuecomment-419891508
// TODO: don't hack in preloaded state like this
// When redux-orm provides a better interface for it, then refactor to that method
import { defaultUpdater } from 'redux-orm/lib/redux';
const initializeState = (orm) => {
    const state = orm.getEmptyState();
    const organization = localStorage.getItem('organizations');
    if (organization) {
        const { Organizations } = orm.mutableSession(state);
        Organizations.create(JSON.parse(organization));
    }
    return state;
};
const createReducer = (orm, updater = defaultUpdater) => {
    return (state, action) => {
        const session = orm.session(state || initializeState(orm));
        updater(session, action);
        return session.state;
    };
};


export default combineReducers({
    database: createReducer(orm),
    draft_database,
    login,
    teams_manager,
    programs,
    program_builder,
    queue,
    program_scheduler_popup,
    roster,
    import_athletes,
    columns_filter,
    profile,
    manage_maxes,
    settings,
    register,
    program_scheduler,
    
    // old and outdated
    reports_manager,
    history_filter,
    history_sessions,
    history_plans,
    feed,
});
