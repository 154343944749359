import { createSelector } from 'redux-orm';
import orm from 'shared_redux/reducers/models/orm';

export const getGroupsOfTeam = createSelector(
    orm,
    state => state.database,
    (state, props) => props.team_id,
    (session, team_id) => {
        const team = session.Teams.withId(team_id);
        if (!team) {
            return [];
        }
        return team.groups.toRefArray();
    }
);
