import { createSelector } from 'redux-orm';
import orm from 'shared_redux/reducers/models/orm';

export const getHasIncompleteDataExport = createSelector(
    orm,
    state => state.database,
    (session) => {
        return session.DataExports.all().filter(de => de.status !== 'COMPLETED').count() > 0;
    }
);
