import {
    FETCH_RACKS_SUCCEEDED,
    UPDATE_RACK_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class Racks extends Model {

    static reducer(action, Racks, session) {
        switch (action.type) {
            case FETCH_RACKS_SUCCEEDED:
                action.payload.forEach(rack => {
                    Racks.upsert(rack);
                });
                break;
            case UPDATE_RACK_SUCCEEDED:
                Racks.upsert(action.payload);
                break;
            case SIGN_OUT_SUCCEEDED:
                Racks.all().delete();
                break;
        }
    }

}
Racks.modelName = 'Racks';

Racks.fields = {
    id: attr(),
    sensor_name: attr(),
    organization_id: attr(),
    number: attr(),
    app_version: attr(),
    expiration: attr(),
};

export default Racks;
