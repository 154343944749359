import React, {Component} from 'react';
import { Switch, Route } from 'react-router-dom';

import Header from './header';
import Tabs from './tabs';
import History from './history';

class View extends Component {

    componentDidMount() {
        this.props.fetchAthlete(this.props.match.params.athleteid);
        window.scrollTo(0, 0); // TODO: this may not be the best way to accomplish it
    }

    render() {
        if (!this.props.athlete) {
            return null;
        }

        return (
            <div>
                <Header athleteId={this.props.athlete.id}/>
                <Tabs />
                <Switch>
                    <Route path='/athletes/:athleteid/:athletename' component={History} />
                </Switch>
            </div>
        );
    }

}

export default View;
