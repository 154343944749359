import {
    ATTEMPT_FETCH_ATHLETE_SESSIONS,
    EXPAND_SESSION,
    COLLAPSE_SESSION,
    FETCH_ATHLETE_SESSIONS_SUCCEEDED,

    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    updated: null,
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case EXPAND_SESSION: {
            let obj = {
                ...state
            };
            obj[action.sessionId] = true;
            return obj;
        }
        case COLLAPSE_SESSION: {
            let obj = {
                ...state
            };
            obj[action.sessionId] = false;
            return obj;
        }
        case FETCH_ATHLETE_SESSIONS_SUCCEEDED:
            return {
                ...state,
                updated: new Date().getTime(), // note, time doesn't matter here as it's just for updates, but long term shouldn't rely on updated flash and instead on redux-orm
            };
        case ATTEMPT_FETCH_ATHLETE_SESSIONS:
        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
