import {
    CREATE_PROGRAM_SCHEDULE,
    EDIT_PROGRAM_SCHEDULE,
    UPDATE_PROGRAM_SCHEDULE_START_DATE,
    UPDATE_PROGRAM_SCHEDULE_END_DATE,
    UPDATE_PROGRAM_SCHEDULE_ASSIGNEES,

    ATTEMPT_CREATE_PROGRAM_SCHEDULES,
    CREATE_PROGRAM_SCHEDULE_SUCCEEDED,
    CREATE_PROGRAM_SCHEDULE_FAILED,

    ATTEMPT_SAVE_PROGRAM_SCHEDULE,
    SAVE_PROGRAM_SCHEDULE_SUCCEEDED,
    SAVE_PROGRAM_SCHEDULE_FAILED,

    ATTEMPT_DELETE_PROGRAM_SCHEDULE,
    DELETE_PROGRAM_SCHEDULE_SUCCEEDED,
    DELETE_PROGRAM_SCHEDULE_FAILED,

    CLOSE_PROGRAM_SCHEDULE,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    programId: null,
    conflicts: [], // udids of program schedules - to be converted 
    assignees: [], // udids of athlete/group/team - to be used by create mode only
    startDate: null,
    endDate: null,
    isCreating: false,
    isEditing: false,
    id: null, // only for edit mode
    requestsRemaining: 0,
    requestsSucceeded: 0,
    failed: false,
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case CREATE_PROGRAM_SCHEDULE:
            return {
                ...state,
                programId: action.programId,
                conflicts: [],
                assignees: [],
                startDate: action.startDate,
                endDate: action.endDate,
                isCreating: true,
                isEditing: false,
                id: null,
                requestsRemaining: 0,
                requestsSucceeded: 0,
                failed: false,
            };
        case EDIT_PROGRAM_SCHEDULE:
            return {
                ...state,
                programId: action.programId,
                conflicts: [],
                assignees: [],
                startDate: action.startDate,
                endDate: action.endDate,
                isCreating: false,
                isEditing: true,
                id: action.id,
                requestsRemaining: 0,
                requestsSucceeded: 0,
                failed: false,
            };
        case UPDATE_PROGRAM_SCHEDULE_START_DATE:
            return {
                ...state,
                startDate: action.startDate,
            };
        case UPDATE_PROGRAM_SCHEDULE_END_DATE:
            return {
                ...state,
                endDate: action.endDate,
            };
        case UPDATE_PROGRAM_SCHEDULE_ASSIGNEES:
            return {
                ...state,
                assignees: action.assignees,
            };
        case ATTEMPT_CREATE_PROGRAM_SCHEDULES:
            return {
                ...state,
                requestsRemaining: action.numRequests, // TODO: is this ideal? It forces the component to know too much
            };
        case ATTEMPT_SAVE_PROGRAM_SCHEDULE:
        case ATTEMPT_DELETE_PROGRAM_SCHEDULE:
            return {
                ...state,
                requestsRemaining: 1,
            };
        case CREATE_PROGRAM_SCHEDULE_SUCCEEDED:
        case SAVE_PROGRAM_SCHEDULE_SUCCEEDED:
        case DELETE_PROGRAM_SCHEDULE_SUCCEEDED:
            if (state.requestsRemaining <= 1 && state.conflicts.length <= 0) {
                return createDefaultState();
            } else {
                return {
                    ...state,
                    requestsRemaining: state.requestsRemaining-1,
                    requestsSucceeded: state.requestsSucceeded+1,
                };
            }
        case DELETE_PROGRAM_SCHEDULE_FAILED:
            return {
                ...state,
                requestsRemaining: state.requestsRemaining-1,
            };
        case SAVE_PROGRAM_SCHEDULE_FAILED:
        case CREATE_PROGRAM_SCHEDULE_FAILED:
            if (action.payload) {
                const ids = action.payload.map(o => o.id);
                return {
                    ...state,
                    requestsRemaining: state.requestsRemaining-1,
                    conflicts: [
                        ...state.conflicts,
                        ...ids,
                    ],
                };
            }
            return {
                ...state,
                requestsRemaining: state.requestsRemaining-1,
                failed: true,
            };
        case CLOSE_PROGRAM_SCHEDULE:
        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
