import React, {Component} from 'react';

import styles from './styles.module.scss';

export default class View extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <img className={styles.spinner} src='https://assets.reponestrength.com/black_logo.png' />
        );
    }
};
