import {
    FETCH_ATHLETE_SUCCEEDED,
    FETCH_ATHLETES_SUCCEEDED,
    FETCH_TEAMS_SUCCEEDED,
    FETCH_GROUPS_SUCCEEDED,
    FETCH_PROGRAM_SCHEDULES_SUCCEEDED,
    CREATE_GROUP_SUCCEEDED,
    PATCH_GROUP_SUCCEEDED,
    REMOVE_GROUP_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

const updateIfChanged = (Model, json) => {
    const existing = Model.withId(json.id);
    if (existing && existing.last_modified === json.last_modified && existing.created_on === json.created_on) {
        return;
    }
    Model.upsert(json);
};

class Groups extends Model {

    static reducer(action, Groups, session) {
        switch (action.type) {
            case FETCH_ATHLETE_SUCCEEDED:
                if (action.payload.group) {
                    updateIfChanged(Groups, action.payload.group);
                }
                break;
            case FETCH_ATHLETES_SUCCEEDED:
                action.payload.forEach(athlete => {
                    if (athlete.group) {
                        updateIfChanged(Groups, athlete.group);
                    }
                });
                break;
            case FETCH_TEAMS_SUCCEEDED:
                action.payload.forEach(team => {
                    team.groups.forEach(group => {
                        updateIfChanged(Groups, group);
                    });
                });
                break;
            case FETCH_GROUPS_SUCCEEDED:
                action.payload.forEach(group => {
                    updateIfChanged(Groups, group);
                });
                break;
            case FETCH_PROGRAM_SCHEDULES_SUCCEEDED: {
                for (var schedule of action.payload) {
                    if (schedule.group) {
                        updateIfChanged(Groups, schedule.group);
                    }
                }
                break;
            }
            case CREATE_GROUP_SUCCEEDED:
                Groups.upsert(action.payload);
                break;
            case PATCH_GROUP_SUCCEEDED:
                Groups.withId(action.payload.id).update(action.payload);
                break;
            case REMOVE_GROUP_SUCCEEDED:
                Groups.withId(action.id).deleteCascade();
                break;
            case SIGN_OUT_SUCCEEDED:
                Groups.all().delete();
                break;
        }
    }

    deleteCascade() {
        if (this.program_schedules) {
            this.program_schedules.toModelArray().forEach(ps => {
                ps.delete();
            });
        }
        this.delete();
    }

}
Groups.modelName = 'Groups';

Groups.fields = {
    id: attr(),
    name: attr(),
    team_id: fk({
        to: 'Teams',
        as: 'team',
        relatedName: 'groups',
    }),
};

export default Groups;
