import React, {useEffect} from 'react';

import styles from './styles.module.scss';
import SilentSaveSelect from 'shared_features/silent_save_select';
import SilentSaveTextField from 'shared_features/silent_save_textfield';
import GreyX from 'shared_features/svgs/grey_x';

export default props => {
    const onChange = (selectedOption, action) => {
        props.updateExerciseLink(props.id, selectedOption.value, props.formula);
    };

    const onClear = () => {
        props.deleteExerciseLink(props.id);
    };

    const clearButton = props.linkedExercise ? <div className={styles.clear} onClick={onClear}><GreyX /></div> : null;

    return (<div className={styles.containerContainer}>
        <div className={styles.container}>
            <div>{props.name}</div>
            <SilentSaveSelect
                name="exercise link"
                placeholder={"None"}
                className={styles.select}
                clearable={false}
                deleteRemoves={false}
                value={props.linkedExercise}
                components={{
                    IndicatorSeparator: () => null,
                }}
                styles={{
                    control: (base) => ({
                        ...base,
                        border: '0px',
                        boxShadow: 'none',
                        height: 42,
                        minHeight: 42,
                    }),
                    input: () => ({
                        height: 38,
                    }),
                }}
                isDisabled={props.isParent}
                onChange={onChange}
                options={props.linkableExercises}
            />
            <div>
                <SilentSaveTextField
                    type="text"
                    value={props.formula}
                    disabled={props.isParent || !props.linkedExercise}
                    onComplete={(value) => props.updateExerciseLink(props.id, props.parent_exercise_id, value)} />
            </div>
            <div onClick={()=>props.removeMaxableExercise(props.id)}><img src="https://assets.reponestrength.com/x.png" /></div>
        </div>
        {clearButton}
    </div>);
}
