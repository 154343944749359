import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as ManageMaxesSelectors from 'shared_redux/selectors/view/manage_maxes';

const makeMapStateToProps = () => {
    const getMaxableExerciseModel = ManageMaxesSelectors.makeGetMaxableExerciseModel();
    return (state, props) => {
        return getMaxableExerciseModel(state, props);
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        removeMaxableExercise: Actions.removeMaxableExercise,
        updateExerciseLink: Actions.updateExerciseLink,
        deleteExerciseLink: Actions.deleteExerciseLink,
    }, dispatch);
};

const Container = connect(
    makeMapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
