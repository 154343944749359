import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import View from './view';
import * as Actions from './actions';
import * as ImportAthletesSelectors from 'shared_redux/selectors/view/import_athletes';

const mapStateToProps = (state, props) => {
    return {
        // showing modal
        isShowing: ImportAthletesSelectors.getIsShowingImport(state),

        // failures
        missingColumns: ImportAthletesSelectors.getImportMissingColumns(state),
        failedRow: ImportAthletesSelectors.getImportFailedAtRow(state), // we ran into the following error on line X and had to stop
        failedMessage: ImportAthletesSelectors.getImportFailedReason(state),
        generalError: ImportAthletesSelectors.getImportFailedUnknownReason(state),

        // success
        uploadSucceeded: ImportAthletesSelectors.getImportSucceeded(state),
        numAthletesCreated: ImportAthletesSelectors.getImportAthleteCount(state),
        numTeamsCreated: ImportAthletesSelectors.getImportTeamCount(state),
        numGroupsCreated: ImportAthletesSelectors.getImportGroupCount(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
       closeModal: Actions.closeModal,
       importAthletes: Actions.importAthletes,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default withRouter(Container);
