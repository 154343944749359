import {
    ATTEMPT_FETCH_TEAMS,
    FETCH_DATA_EXPORTS_ATTEMPT,
    START_DATA_EXPORTS_ATTEMPT,
    RETRY_DATA_EXPORTS_ATTEMPT,
    DOWNLOAD_DATA_EXPORTS_ATTEMPT,
} from 'shared_redux/actions';

export const fetchTeams = () => ({ type: ATTEMPT_FETCH_TEAMS });

export const fetchDataExports = () => ({ type: FETCH_DATA_EXPORTS_ATTEMPT });

export const startDataExport = () => ({ type: START_DATA_EXPORTS_ATTEMPT });

export const retryDataExport = (id) => ({ type: RETRY_DATA_EXPORTS_ATTEMPT, id });

export const downloadDataExport = (id) => ({ type: DOWNLOAD_DATA_EXPORTS_ATTEMPT, id });
