import { all, takeLatest, apply, put } from 'redux-saga/effects';

import {
    ATTEMPT_CREATE_TICKET,
    CREATE_TICKET_SUCCEEDED,
} from 'shared_redux/actions';
import API from 'services/tickets';
import { handleError } from 'services/alerts';

export default function* TicketsSaga() {
    yield all([
        takeLatest(ATTEMPT_CREATE_TICKET, createTicket),
    ]);
};

function *createTicket(action) {
    try {
        const result = yield apply(API, API.createTicket);
        yield put({
            type: CREATE_TICKET_SUCCEEDED,
            ticket: result.ticket,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to fetch ticket', false);
    }
}
