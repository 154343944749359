import {
    UPDATE_DEFAULT_REST,
    UPDATE_DEFAULT_NOTES,
    UPDATE_DEFAULT_AUTOREGULATION,
    UPDATE_DEFAULT_SENSOR_TRACKING,
    UPDATE_DEFAULT_NUM_REPS,
    UPDATE_DEFAULT_AMRAP,
    UPDATE_DEFAULT_MIN_SETS,
    UPDATE_DEFAULT_MAX_SETS,
    UPDATE_DEFAULT_SET_COUNT_FIXED,
    UPDATE_DEFAULT_WEIGHT,
    UPDATE_DEFAULT_WEIGHT_UNIT,
    UPDATE_DEFAULT_AUTOREGULATION_TARGET,
    UPDATE_DEFAULT_AUTOREGULATION_RANGE,
    UPDATE_DEFAULT_AUTOREGULATION_QUANTIFIER,
    UPDATE_DEFAULT_AUTOREGULATION_METRIC,
    SAVE_DEFAULTS_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    // everything is undefined here, so no overrides
    default_rest: undefined,
    default_notes: undefined,
    default_autoregulation: undefined,
    default_sensor_tracking: undefined,
    default_num_reps: undefined,
    default_amrap: undefined,
    default_min_sets: undefined,
    default_max_sets: undefined,
    default_set_count_fixed: undefined,
    default_weight: undefined,
    default_weight_unit: undefined,
    default_autoregulation_target: undefined,
    default_autoregulation_range: undefined,
    default_autoregulation_quantifier: undefined,
    default_autoregulation_metric: undefined,
});

export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case UPDATE_DEFAULT_REST:
            return {
                ...state,
                default_rest: action.rest,
            };
        case UPDATE_DEFAULT_NOTES:
            return {
                ...state,
                default_notes: action.notes,
            };
        case UPDATE_DEFAULT_AUTOREGULATION:
            return {
                ...state,
                default_autoregulation: action.autoregulation,
            };
        case UPDATE_DEFAULT_SENSOR_TRACKING:
            return {
                ...state,
                default_sensor_tracking: action.sensor_tracking,
            };
        case UPDATE_DEFAULT_NUM_REPS:
            return {
                ...state,
                default_num_reps: action.num_reps,
            };
        case UPDATE_DEFAULT_AMRAP:
            return {
                ...state,
                default_amrap: action.amrap,
            };
        case UPDATE_DEFAULT_MIN_SETS:
            return {
                ...state,
                default_min_sets: action.min_sets,
            };
        case UPDATE_DEFAULT_MAX_SETS:
            return {
                ...state,
                default_max_sets: action.max_sets,
            };
        case UPDATE_DEFAULT_SET_COUNT_FIXED:
            return {
                ...state,
                default_set_count_fixed: action.set_count_fixed,
                default_max_sets: null,
            };
        case UPDATE_DEFAULT_WEIGHT:
            return {
                ...state,
                default_weight: action.weight,
            };
        case UPDATE_DEFAULT_WEIGHT_UNIT:
            return {
                ...state,
                default_weight_unit: action.weight_unit,
            };
        case UPDATE_DEFAULT_AUTOREGULATION_TARGET:
            return {
                ...state,
                default_autoregulation_target: action.autoregulation_target,
            };
        case UPDATE_DEFAULT_AUTOREGULATION_RANGE:
            return {
                ...state,
                default_autoregulation_range: action.autoregulation_range,
            };
        case UPDATE_DEFAULT_AUTOREGULATION_QUANTIFIER:
            return {
                ...state,
                default_autoregulation_quantifier: action.autoregulation_quantifier,
            };
        case UPDATE_DEFAULT_AUTOREGULATION_METRIC:
            return {
                ...state,
                default_autoregulation_metric: action.autoregulation_metric,
            };
    
        case SAVE_DEFAULTS_SUCCEEDED:
        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
