import {
    FETCH_PROGRAMS_SUCCEEDED,
    FETCH_PROGRAM_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class Microcycles extends Model {

    static reducer(action, Microcycles, session) {
        switch (action.type) {
            case FETCH_PROGRAMS_SUCCEEDED: {
                for (var program of action.payload) {
                    for (var microcycle of program.microcycles) {
                        // note: this call does not eager beyond the microcycle
                        Microcycles.upsert(microcycle);
                    }
                }
                break;
            }
            case FETCH_PROGRAM_SUCCEEDED:
                for (var microcycle of action.payload.microcycles) {
                    const {['microcycle_sessions']: microcycle_sessions, ...rawMicrocycle} = microcycle;
                    Microcycles.upsert(rawMicrocycle);
                }
                break;
            case SIGN_OUT_SUCCEEDED:
                Microcycles.all().delete();
                break;
        }
    }
    
}

Microcycles.modelName = 'Microcycles';

Microcycles.fields = {
    id: attr(),
    order: attr(),
    program_id: fk({
        to: 'Programs',
        as: 'program',
        relatedName: 'microcycles',
    }),
};

export default Microcycles;
