import {
    CLOSE_CREATE_GROUP,
    UPDATE_GROUP_NAME,
    ATTEMPT_CREATE_GROUP,
} from 'shared_redux/actions';

export const closeModal = () => ({ type: CLOSE_CREATE_GROUP });

export const updateName = (name) => ({ type: UPDATE_GROUP_NAME, name });

export const createGroup = () => ({ type: ATTEMPT_CREATE_GROUP });
