
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import View from './view';
import * as Actions from './actions';
import * as ProgramBuilderSelectors from 'shared_redux/selectors/view/program_builder';

const mapStateToProps = (state, props) => {
    if (!props.match.params.tab) {
        return {
            name: ProgramBuilderSelectors.getDraftProgramName(state, props),
            notes: ProgramBuilderSelectors.getDraftProgramNotes(state, props),
            isEditable: true,
        };
    } else {
        return {
            name: ProgramBuilderSelectors.getActualProgramName(state, props),
            notes: ProgramBuilderSelectors.getActualProgramNotes(state, props),
            isEditable: false,
        };
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateProgramName: Actions.updateProgramName,
        saveProgramName: Actions.saveProgramName,
        updateProgramNotes: Actions.updateProgramNotes,
        saveProgramNotes: Actions.saveProgramNotes,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
)(View);

export default withRouter(Container);
