import {
    FETCH_PROGRAM_SCHEDULES_SUCCEEDED,
    CREATE_PROGRAM_SCHEDULE_SUCCEEDED,
    CREATE_PROGRAM_SCHEDULE_FAILED,
    SAVE_PROGRAM_SCHEDULE_SUCCEEDED,
    DELETE_PROGRAM_SCHEDULE_SUCCEEDED,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

import { Model, fk, many, attr } from 'redux-orm';

class ProgramSchedules extends Model {

    getAssigneeName() {
        if (this.team) {
            return this.team.name;
        } else if (this.group) {
            if (this.group.team) {
                return `${this.group.team.name} - ${this.group.name}`
            } else {
                return this.group.name;
            }
        } else if (this.athlete) {
            return this.athlete.displayName();
        }
        return null;
    }

    static reducer(action, ProgramSchedules, session) {
        switch (action.type) {
            case FETCH_PROGRAM_SCHEDULES_SUCCEEDED:
                for (var schedule of action.payload) {
                    const {['program']: program, ['team']: team, ['group']: group, ['athlete']: athlete, ...rawSchedule} = schedule;
                    ProgramSchedules.upsert(rawSchedule);
                }
                break;
            case CREATE_PROGRAM_SCHEDULE_SUCCEEDED:
            case SAVE_PROGRAM_SCHEDULE_SUCCEEDED:
                ProgramSchedules.upsert(action.payload);
                break;
            case CREATE_PROGRAM_SCHEDULE_FAILED:
                if (action.payload) {
                    for (var schedule of action.payload) {
                        ProgramSchedules.upsert(schedule);
                    }
                }
                break;
            case DELETE_PROGRAM_SCHEDULE_SUCCEEDED:
                ProgramSchedules.withId(action.id).delete();
                break;
            case SIGN_OUT_SUCCEEDED:
                ProgramSchedules.all().delete();
                break;
        }
    }
    
}
ProgramSchedules.modelName = 'ProgramSchedules';

ProgramSchedules.fields = {
    id: attr(),
    program_id: fk({
        to: 'Programs',
        as: 'program',
        relatedName: 'program_schedules',
    }),
    team_id: fk({
        to: 'Teams',
        as: 'team',
        relatedName: 'program_schedules',
    }),
    group_id: fk({
        to: 'Groups',
        as: 'group',
        relatedName: 'program_schedules',
    }),
    athlete_id: fk({
        to: 'Athletes',
        as: 'athlete',
        relatedName: 'program_schedules',
    }),
    start_date: attr(),
    end_date: attr(),
};

export default ProgramSchedules;
