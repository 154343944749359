import {
    CLOSE_EDIT_TEAM,
    UPDATE_TEAM_NAME,
    ATTEMPT_PATCH_TEAM,
    ATTEMPT_REMOVE_TEAM,
} from 'shared_redux/actions';

export const closeModal = () => ({ type: CLOSE_EDIT_TEAM });

export const updateName = (name) => ({ type: UPDATE_TEAM_NAME, name });

export const patchTeam = () => ({ type: ATTEMPT_PATCH_TEAM });

export const remove = () => ({ type: ATTEMPT_REMOVE_TEAM });
