import {
    SELECT_ROSTER_ATHLETE,
    DESELECT_ROSTER_ATHLETE,

    ATTEMPT_UPLOAD_AVATAR,
    EDIT_ATHLETE,
    UPDATE_ATHLETE_CATEGORY_ATTEMPT,
    ATTEMPT_UPDATE_MAX,
} from 'shared_redux/actions';

// checkboxes

export const selectAthlete = athleteId => ({ type: SELECT_ROSTER_ATHLETE, athleteId });

export const deselectAthlete = athleteId => ({ type: DESELECT_ROSTER_ATHLETE, athleteId });

// row options

export const upload = (athleteId, file) => ({ type: ATTEMPT_UPLOAD_AVATAR, athleteId, file });

export const editAthlete = athleteId => ({ type: EDIT_ATHLETE, athleteId });

export const updateAthleteCategory = (athleteId, teamId, groupId) => ({
    type: UPDATE_ATHLETE_CATEGORY_ATTEMPT,
    athleteId,
    teamId,
    groupId,
});

export const saveMax = (id, exercise_id, athlete_id, weight, weight_unit='LBS') => ({
    type: ATTEMPT_UPDATE_MAX,
    max: {
        id,
        exercise_id,
        athlete_id,
        weight,
        weight_unit,
    }
});
