import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as ProgramBuilderSelectors from 'shared_redux/selectors/view/program_builder';

const makeMapStateToProps = () => {
    const getDraftMicrocycle = ProgramBuilderSelectors.makeGetDraftMicrocycle();
    return (state, props) => {
        return getDraftMicrocycle(state, props);
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        expandMicrocycle: Actions.expandMicrocycle,
        collapseMicrocycle: Actions.collapseMicrocycle,
        
        createMicrocycleSession: Actions.createMicrocycleSession,
        duplicateMicrocycle: Actions.duplicateMicrocycle,
        moveMicrocycle: Actions.moveMicrocycle,
        deleteMicrocycle: Actions.deleteMicrocycle,
    }, dispatch);
};

const Container = connect(
    makeMapStateToProps,
    mapDispatchToProps,
)(View);

export default Container;
