import {
    UPDATE_PROGRAM_SCHEDULER_GROUPS_FILTER,
    SIGN_OUT_SUCCEEDED,
} from 'shared_redux/actions';

const createDefaultState = () => ({
    selectedTeamFilter: null,
    selectedGroupFilter: null,
});

// todo: tick time should use toisostrings
export default (state = createDefaultState(), action) => {
    switch (action.type) {
        case UPDATE_PROGRAM_SCHEDULER_GROUPS_FILTER:
            return {
                ...state,
                selectedTeamFilter: action.team || null,
                selectedGroupFilter: action.group || null,
            };

        case SIGN_OUT_SUCCEEDED:
            return createDefaultState();
        default:
            return state;
    }
};
