import {
    SELECT_ROSTER_COLUMN_FILTER,
    DESELECT_ROSTER_COLUMN_FILTER,

    CLOSE_ROSTER_COLUMNS_FILTER,

    UPDATE_ACCOUNT_ROSTER_SETTINGS_ATTEMPT,
} from 'shared_redux/actions';

export const deselectColumn = (column_type, exercise_id) => ({
    type: DESELECT_ROSTER_COLUMN_FILTER,
    column_type,
    exercise_id,
});

export const selectColumn = (column_type, exercise_id) => ({
    type: SELECT_ROSTER_COLUMN_FILTER,
    column_type,
    exercise_id,
});

export const closeModal = () => ({
    type: CLOSE_ROSTER_COLUMNS_FILTER,
});

export const save = () => ({
    type: UPDATE_ACCOUNT_ROSTER_SETTINGS_ATTEMPT
});
