import {
    ATTEMPT_FETCH_TEAMS,
    UPDATE_LIVE_GROUPS_FILTER,
    ATTEMPT_CREATE_TICKET,
    UPDATE_FEED_TICK_TIMER,
    DISCONNECT_WEBSOCKET,
} from 'shared_redux/actions';

export const fetchCategories = () => ({ type: ATTEMPT_FETCH_TEAMS });

export const updateGroupFilter = (team, group) => ({ type: UPDATE_LIVE_GROUPS_FILTER, team, group });

export const updateFeedTimer = () => ({ type: UPDATE_FEED_TICK_TIMER });

export const connectFeed = () => ({ type: ATTEMPT_CREATE_TICKET });

export const disconnectFeed = () => ({ type: DISCONNECT_WEBSOCKET });
