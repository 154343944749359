import { initializeApp } from "firebase/app";

// initialize firebase
// TODO: consider moving this to another file, just it can't be in a folder called firebase due to name conflict
const config = {
    apiKey: "AIzaSyC7vUNUQ0QRus6dhtqfrjS-7tqLfXjcV1E",
    authDomain: "reponealpha-54a7d.firebaseapp.com",
    databaseURL: "https://reponealpha-54a7d.firebaseio.com",
    projectId: "reponealpha-54a7d",
    storageBucket: "reponealpha-54a7d.appspot.com",
    messagingSenderId: "329962124799"
};
export default initializeApp(config);
