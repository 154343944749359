import React from 'react';
import Modal from 'react-modal';

import styles from './styles.module.scss';

export default React.memo(props => {
    var deleteOption = null;
    if (props.removeText) {
        deleteOption = <div className={styles.deleteButton} onClick={()=>window.confirm("Are you sure?") && props.remove()}>{props.removeText}</div>
    }

    return (
        <Modal
            className={styles.modal}
            overlayClassName={styles.overlay}
            isOpen={props.isShowing}
            onRequestClose={()=>props.closeModal()}>

            <div className={styles.cardTitle}>{props.title}</div>
            <div className={styles.label}>{props.description}</div>
            <input type="text"
                className={styles.input}
                value={props.name}
                onChange={(event)=> props.updateName(event.target.value)}
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        props.save();
                    }
                }}
                autoFocus />
            <div className={styles.optionsContainer}>
                {deleteOption}
                <div className={styles.saveButton} onClick={()=>props.save()}>{props.saveText}</div>
            </div>
        </Modal>
    );

});
