import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';

import * as RosterSelectors from 'shared_redux/selectors/view/roster';
import * as TeamsSelectors from 'shared_redux/selectors/models/teams';
import * as GroupsSelectors from 'shared_redux/selectors/models/groups';

const mapStateToProps = (state, props) => {
    const teamId = RosterSelectors.getSelectedTeamId(state);
    return {
        numSelected: RosterSelectors.getNumAthletesSelected(state),
        teamId,
        groupId: RosterSelectors.getSelectedGroupId(state),
        teams: TeamsSelectors.getTeams(state),
        groups: GroupsSelectors.getGroupsOfTeam(state, {team_id: teamId}),
        isUploading: RosterSelectors.getIsBatchUploading(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateSelectedTeam: Actions.updateSelectedTeam,
        updateSelectedGroup: Actions.updateSelectedGroup,
        deleteSelectedAthletes: Actions.deleteSelectedAthletes,
        saveSelectedAthletesCategories: Actions.saveSelectedAthletesCategories,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
