import API from './api';

export default {

    createMicrocycleSession: (json) => API({
        url: 'microcycle_sessions',
        method: 'POST',
        json,
    }),

    moveMicrocycleSession: (json) => API({
        url: `microcycle_sessions/${json.session_id}`,
        method: 'PATCH',
        json,
    }),

    deleteMicrocycleSession: (microcycle_session_id) => API({
        url: `microcycle_sessions/${microcycle_session_id}`,
        method: 'DELETE',
    }),

};
