
import {
    DRAFT_UPDATE_PROGRAM_NAME,
    DRAFT_UPDATE_PROGRAM_NOTES,
    DRAFT_SAVE_PROGRAM,
    ATTEMPT_UPDATE_PROGRAM,
} from 'shared_redux/actions';

export const updateProgramName = (id, name) => ({
    type: DRAFT_UPDATE_PROGRAM_NAME,
    payload: {
        id,
        name,
    },
});

export const saveProgramName = (id, name) => ({
    type: DRAFT_SAVE_PROGRAM,
    pushType: ATTEMPT_UPDATE_PROGRAM,
    payload: {
        id,
        name,
    },
});

export const updateProgramNotes = (id, notes) => ({
    type: DRAFT_UPDATE_PROGRAM_NOTES,
    payload: {
        id,
        notes: notes === '' ? null : notes,
    },
});

export const saveProgramNotes = (id, notes) => ({
    type: DRAFT_SAVE_PROGRAM,
    pushType: ATTEMPT_UPDATE_PROGRAM,
    payload: {
        id,
        notes: notes === '' ? null : notes,
    },
});
