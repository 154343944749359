import {
    all,
    take,
    takeEvery,
    takeLatest,
    select,
    apply,
    put,
    call,
} from 'redux-saga/effects';

import {
    UPDATE_EXERCISE_LINK_ATTEMPT,
    UPDATE_EXERCISE_LINK_SUCCEEDED,

    DELETE_EXERCISE_LINK_ATTEMPT,
    DELETE_EXERCISE_LINK_SUCCEEDED,
 } from 'shared_redux/actions';
 import orm from 'shared_redux/reducers/models/orm';
import API from 'services/exercise_links';
import { handleError } from 'services/alerts';

export default function* ExercisesSaga() {
    yield all([
        takeEvery(UPDATE_EXERCISE_LINK_ATTEMPT, updateExerciseLink),
        takeEvery(DELETE_EXERCISE_LINK_ATTEMPT, deleteExerciseLink),
    ]);
};


function *updateExerciseLink(action) {
    try {
        // fetch existing one
        // if it doesn't exist, create
        // if it exists, patch
        // NOTE: this should really use a queue as the diff between create and patch could cause issues
        const database = yield select(state => state.database);
        const { ExerciseLinks } = orm.mutableSession(database);
        const existingChildExerciseLink = ExerciseLinks.withId(action.child_exercise_id);
        if (!existingChildExerciseLink) {
            // create
            const payload = yield apply(API, API.createExerciseLink, [action.child_exercise_id, action.parent_exercise_id, 'x*1']); // defaulting to x here on create
            yield put({
                type: UPDATE_EXERCISE_LINK_SUCCEEDED,
                payload,
            });
        } else {
            // update
            const payload = yield apply(API, API.patchExerciseLink, [action.child_exercise_id, action.parent_exercise_id, action.formula]);
            yield put({
                type: UPDATE_EXERCISE_LINK_SUCCEEDED,
                payload,
            });
        }
    } catch(error) {
        yield handleError(error, 'Error: Unable to update exercise link');
    }
}

function *deleteExerciseLink(action) {
    try {
        yield apply(API, API.deleteExerciseLink, [action.child_exercise_id]);
        yield put({
            type: DELETE_EXERCISE_LINK_SUCCEEDED,
            id: action.child_exercise_id,
        });
    } catch(error) {
        yield handleError(error, 'Error: Unable to delete exercise link');
    }
}
