const stateRoot = state => state.import_athletes;

export const getIsShowingImport = state => stateRoot(state).isShowingImport;

export const getImportSucceeded = state => stateRoot(state).succeeded;

export const getImportAthleteCount = state => stateRoot(state).numAthletesCreated;

export const getImportTeamCount = state => stateRoot(state).numTeamsCreated;

export const getImportGroupCount = state => stateRoot(state).numGroupsCreated;

export const getImportMissingColumns = state => stateRoot(state).missingColumns;

export const getImportFailedAtRow = state => stateRoot(state).failedRow;

export const getImportFailedReason = state => stateRoot(state).failedMessage;

export const getImportFailedUnknownReason = state => stateRoot(state).generalError;
