import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'redux-orm';

import View from 'shared_features/athlete_popup';
import * as Actions from './actions';
import orm from 'shared_redux/reducers/models/orm';
import * as ProfileSelectors from 'shared_redux/selectors/view/profile';

const getViewModel = createSelector(
    orm,
    state => state.database,
    ProfileSelectors.getIsEditing,
    ProfileSelectors.getTeamId,
    ProfileSelectors.getGroupId,
    ProfileSelectors.getFirstName,
    ProfileSelectors.getMiddleName,
    ProfileSelectors.getLastName,
    (session, isShowing, teamId, groupId, firstName, middleName, lastName) => {
        const Teams = session.Teams;
        const teams = Teams.all().toRefArray();

        let groups = [];
        if (teamId) {
            const team = Teams.withId(teamId);
            if (team) {
                groups = team.groups.toRefArray();
            }
        }

        return {
            isShowing,
            firstName,
            middleName,
            lastName,
            teamId,
            groupId,
            teams,
            groups,
            showAvatarOption: false,
            showRemove: true,
        }
    }
);

const mapStateToProps = (state) => getViewModel(state);

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateFirstName: Actions.updateFirstName,
        updateMiddleName: Actions.updateMiddleName,
        updateLastName: Actions.updateLastName,
        updateTeam: Actions.updateTeam,
        updateGroup: Actions.updateGroup,
        remove: Actions.remove,
        save: Actions.save,
        closeModal: Actions.closeModal,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
