import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './view';
import * as Actions from './actions';
import * as AthleteHistorySelectors from 'shared_redux/selectors/view/athlete_history';

const mapStateToProps = (state, props) => AthleteHistorySelectors.getAthleteHistory(state, props);

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAthleteSessions: Actions.fetchAthleteSessions,
        updateFilterDate: Actions.updateFilterDate,
    }, dispatch);
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);

export default Container;
