import React, {useState} from 'react';
import Select from 'react-select';

export default props => {
    // states
    const [allValues, setAllValues] = useState({
        prevValue: false, // prev value prevents flicker by ensuring it won't revert to the old value after finishing the edit
        value: props.value,
        isEditing: false,
    });

    // get individual values
    const prevValue = allValues.prevValue;
    const value = allValues.value;
    const isEditing = allValues.isEditing;

    // handlers
    const onFocus = () => {
        setAllValues({
            ...allValues,
            prevValue: props.value,
            isEditing: true,
        });

        if (props.onFocus) {
            props.onFocus();
        }
    };
    const onChange = (selectedOption, action) => {
        if (selectedOption !== prevValue) {
            // change occured, complete
            setAllValues({
                ...allValues,
                value: selectedOption,
                isEditing: false,
            });

            props.onChange(selectedOption, action);
        } else {
            // change did not occur, back out
            setAllValues({
                ...allValues,
                prevValue: false,
                value: selectedOption,
                isEditing: false,
            });
        }
    };

    // override after editing
    if (!isEditing) {
        if (prevValue !== false) {
            // prev value has a value
            if (props.value !== prevValue) {
                setAllValues({
                    ...allValues,
                    prevValue: false,
                    value: props.value,
                });
            }
        } else {
            // prev value is false, just reset it to whatever is needed
            if (props.value !== value) {
                setAllValues({
                    ...allValues,
                    value: props.value,
                });
            }
        }
    }

    // render
    return <Select
                {...props}
                value={value}
                onFocus={onFocus}
                onChange={onChange}
                />
};
